import { FormFields, FormHeader, FormSection } from '../form';
import { YesNoButton, Input } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';

interface ISpanReplacementProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const SpanReplacement: FC<ISpanReplacementProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const isDamageUnderground: boolean | null = formContext.watch(claimFields.wasDamageUnderground);
    const isSpanReplacementNeeded: boolean | null = formContext.watch(claimFields.wasSpanReplaced);
    const isSpanFootageReplacedUnknown: boolean = formContext.watch(claimFields.isSpanFootageUnknown);
    const isSpanOtherMaterialUnknown: boolean = formContext.watch('isSpanOtherMaterialUnknown');
    const isSpanUgCableInConduit: boolean | null = formContext.watch(claimFields.cableInConduit);

    const onIsSpanReplacementNeededYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.cableInConduit,
            claimFields.cableSize,
            claimFields.isSpanFootageUnknown,
            claimFields.isSpanReplacementNeeded,
            claimFields.spanFootage,
            claimFields.wasSpanReplaced,
        ]);
        formContext.setValue(claimFields.wasSpanReplaced, true);

        if (formContext.getValues(claimFields.wasDamageUnderground) === false) {
            formContext.setValue(claimFields.cableInConduit, false);
        }

        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.cableInConduit);
        formContext.clearErrors(claimFields.spanFootage);
        formContext.clearErrors(claimFields.isSpanFootageUnknown);
        formContext.clearErrors(claimFields.cableSize);
        formContext.clearErrors('isSpanOtherMaterialUnknown');
    };

    const onIsSpanReplacementNeededNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.cableInConduit,
            claimFields.cableSize,
            claimFields.isSpanFootageUnknown,
            claimFields.isSpanReplacementNeeded,
            claimFields.spanFootage,
            claimFields.wasSpanReplaced,
        ]);
        formContext.setValue(claimFields.wasSpanReplaced, false);
        formContext.setValue(claimFields.cableInConduit, null);
        formContext.setValue(claimFields.spanFootage, '');
        formContext.setValue(claimFields.isSpanFootageUnknown, false);
        formContext.setValue(claimFields.cableSize, '');
        formContext.setValue('isSpanOtherMaterialUnknown', false);
        formContext.clearErrors(claimFields.cableInConduit);
        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.spanFootage);
        formContext.clearErrors(claimFields.isSpanFootageUnknown);
        formContext.clearErrors(claimFields.cableSize);
        formContext.clearErrors('isSpanOtherMaterialUnknown');
    };

    const onSpanReplacementUnknownChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'SpanFootage',
            claimFields.wasSpanReplaced,
            claimFields.isSpanFootageUnknown,
        ]);
        formContext.setValue(claimFields.spanFootage, checked ? 'Unknown' : null);
        formContext.setValue(claimFields.isSpanFootageUnknown, checked);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.clearErrors(claimFields.spanFootage);
        formContext.clearErrors(claimFields.isSpanFootageUnknown);
        formContext.clearErrors(claimFields.isSpanReplacementNeeded);
        formContext.clearErrors(claimFields.wasSpanReplaced);
    };

    const onSpanOtherMaterialUnknownChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'CableSize', claimFields.wasSpanReplaced]);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.setValue(claimFields.cableSize, checked ? 'Unknown' : '');
        formContext.setValue('isSpanOtherMaterialUnknown', checked);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.clearErrors(claimFields.cableSize);
        formContext.clearErrors('isSpanOtherMaterialUnknown');
        formContext.clearErrors(claimFields.isSpanReplacementNeeded);
        formContext.clearErrors(claimFields.wasSpanReplaced);
    };

    const onSpanFootageReplacedChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'SpanFootage', claimFields.wasSpanReplaced]);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.isSpanReplacementNeeded);
    };

    const onIsSpanUgCableInConduitChangeYes = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'CableInConduit', claimFields.wasSpanReplaced]);
        formContext.setValue(claimFields.cableInConduit, true);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.cableInConduit);
    };

    const onIsSpanUgCableInConduitChangeNo = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'CableInConduit', claimFields.wasSpanReplaced]);
        formContext.setValue(claimFields.cableInConduit, false);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.cableInConduit);
    };

    const onSpanOtherMaterialChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'CableSize', claimFields.wasSpanReplaced]);
        formContext.setValue(claimFields.isSpanReplacementNeeded, true);
        formContext.setValue(claimFields.wasSpanReplaced, true);
        formContext.clearErrors(claimFields.wasSpanReplaced);
        formContext.clearErrors(claimFields.isSpanReplacementNeeded);
    };

    return (
        <FormSection>
            <FormHeader>Span Replacement</FormHeader>

            <FormFields>
                {/* WAS SPAN REPLACEMENT NEEDED? */}
                <YesNoButton
                    isRequired={fieldRequirements.WasSpanReplaced}
                    isSelected={isSpanReplacementNeeded}
                    labelText={'Was span replacement needed OR was fiber damaged?'}
                    name={claimFields.wasSpanReplaced}
                    onNoClick={onIsSpanReplacementNeededNoClick}
                    onYesClick={onIsSpanReplacementNeededYesClick}
                    tooltip={
                        'Was a replacement of some length of cable/fiber done on site or will one be required in the\n' +
                        'future (if a temporary repair was done)? \n'
                    }
                />

                {isSpanReplacementNeeded !== false && (
                    <>
                        {/* WAS CABLE IN CONDUIT? */}
                        {isDamageUnderground && (
                            <YesNoButton
                                isRequired={fieldRequirements.CableInConduit}
                                isSelected={isSpanUgCableInConduit}
                                labelText={'Was u/g cable in conduit?'}
                                name={claimFields.cableInConduit}
                                onNoClick={onIsSpanUgCableInConduitChangeNo}
                                onYesClick={onIsSpanUgCableInConduitChangeYes}
                                tooltip={'Was the cable housed in a conduit?'}
                            />
                        )}

                        {/* FOOTAGE DAMAGE/REPLACED */}
                        <Input
                            checkboxOptions={{
                                'aria-label': 'Span footage replaced unknown',
                                isSelected: isSpanFootageReplacedUnknown,
                                onChange: onSpanReplacementUnknownChange,
                            }}
                            inputOptions={{
                                isDisabled: isSpanFootageReplacedUnknown,
                                isRequired: fieldRequirements.SpanFootage,
                                label: 'Span Footage Damaged/Replaced',
                                name: claimFields.spanFootage,
                                onChange: onSpanFootageReplacedChange,
                            }}
                            tooltip={'How long is the span that was damaged/replaced?'}
                            type={'number'}
                        />

                        {/* CABLE SIZE/ FIBER COUNT / OTHER */}
                        <Input
                            checkboxOptions={{
                                'aria-label': 'Cable size, fiber count, other material',
                                isSelected: isSpanOtherMaterialUnknown,
                                onChange: onSpanOtherMaterialUnknownChange,
                            }}
                            inputOptions={{
                                isDisabled: isSpanOtherMaterialUnknown,
                                isRequired: fieldRequirements.CableSize,
                                label: 'Cable Size / Fiber Count / Other Material',
                                name: claimFields.cableSize,
                                onChange: onSpanOtherMaterialChange,
                            }}
                            tooltip={
                                'What size is the cable or other materials? (pedestal, power supply, 288 ct. fiber, .750, .500, etc.)'
                            }
                        />
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};