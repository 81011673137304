import { useApi, useErrorHandling, useToggle } from '../../../hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, ListBox } from '../../../components';
import { useFormContext } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useState } from 'react';
import clsx from 'clsx';

import type {
    IClient,
    IGetClientsResponse,
    IReportingTech,
    IReportingTechCMOAssignmentViewModel,
    IVerifyTechEmailAddressResponse,
} from '../../../types';
import type { Dispatch, SetStateAction } from 'react';
import type { IToggle } from '../../../hooks';

interface IClientSelectionProps {
    isEmailUnique: IToggle;
    isSelectingClient: IToggle;
    selectedClient?: IClient;
    setSelectedClient: Dispatch<SetStateAction<IClient>>;
}

export const ClientSelection = ({
    isEmailUnique,
    isSelectingClient,
    setSelectedClient,
    selectedClient,
}: IClientSelectionProps) => {
    const formContext = useFormContext();
    const { getAllVisibleClients, getClientByDomain, verifyTechEmailAddress } = useApi();
    const { handleApiError } = useErrorHandling();

    const [clientDomain, setClientDomain] = useState('');
    const [clients, setClients] = useState<Array<IClient>>([]);
    const [visibleClients, setVisibleClients] = useState<Array<IClient>>([]);
    const [allClients, setAllClients] = useState<Array<IClient>>([]);
    const [reportingTech, setReportingTech] = useState<IReportingTech>();
    const [cmoAssignments, setCMOAssignments] = useState<Array<IReportingTechCMOAssignmentViewModel>>([]);
    const [registerEmailText, setRegisterEmailText] = useState<string>('');
    const isShowingAllClients = useToggle();
    const isValidEmail = useToggle(true);
    const isExistingDbEmail = useToggle();
    const isExistingB2CEmail = useToggle();

    const clientDomainQuery = useQuery({
        enabled: false,
        onSuccess: (response: IGetClientsResponse) => setClients(response?.clients ?? []),
        queryFn: () => getClientByDomain(clientDomain.substring(clientDomain.indexOf('@') + 1)),
        queryKey: ['clientDomain'],
        refetchOnWindowFocus: false,
    });

    const clientsQuery = useQuery({
        enabled: Number(selectedClient?.id) < 0,
        onSuccess: (response: IGetClientsResponse) => setVisibleClients(response?.clients ?? []),
        queryFn: getAllVisibleClients,
        queryKey: ['clients'],
        refetchOnWindowFocus: false,
    });

    const allClientsQuery = useQuery({
        onSuccess: (response: IGetClientsResponse) => setAllClients(response?.clients ?? []),
        queryFn: getAllVisibleClients,
        queryKey: ['allClients'],
        refetchOnWindowFocus: false,
    });

    const onEmailAddressVerify = async (response: IVerifyTechEmailAddressResponse | null) => {
        if (response?.existsInB2C) {
            isExistingB2CEmail.on();

            //Exists in B2C, and there is no reporting tech, create it
            if (!response?.existsInDb) {
                isEmailUnique.on();
                isExistingDbEmail.off();
                setReportingTech(response?.reportingTech!);
                setRegisterEmailText('Registering an existing login!');
                formContext.clearErrors('techEmailAddress');
            } else {
                if (response?.mobileUser === null && response?.existsInDb && response?.reportingTech !== null) {
                    setReportingTech(response?.reportingTech);
                    setCMOAssignments(response?.cmoAssignments);
                    isExistingDbEmail.on();
                    setRegisterEmailText('Registering an existing reporting tech!');
                    setSelectedClient({
                        id: response?.reportingTech.clientId?.toString() ?? '0',
                        name:
                            allClients.find((x) => x.id === response?.reportingTech?.clientId?.toString())?.name ??
                            'Not found',
                    });
                } else if (response?.mobileUser === null && !response?.existsInDb && response?.reportingTech !== null) {
                    isEmailUnique.on();
                    setReportingTech(response?.reportingTech);
                    isExistingDbEmail.off();
                    setRegisterEmailText('Registering an existing login!');
                    formContext.clearErrors('techEmailAddress');
                } else {
                    isExistingDbEmail.off();
                    isEmailUnique.off();
                    formContext.setError('techEmailAddress', {
                        message: 'An account with this email address already exists.',
                    });
                }
            }
        } else {
            isExistingB2CEmail.off();
            if (response?.reportingTech === null) {
                isEmailUnique.on();
                isExistingDbEmail.off();
                formContext.clearErrors('techEmailAddress');
            } else {
                if (response?.mobileUser === null) {
                    setReportingTech(response?.reportingTech);
                    setCMOAssignments(response?.cmoAssignments);
                    isExistingDbEmail.on();
                    setRegisterEmailText('Registering an existing reporting tech!');
                    setSelectedClient({
                        id: response?.reportingTech.clientId?.toString() ?? '0',
                        name:
                            allClients.find((x) => x.id === response?.reportingTech?.clientId?.toString())?.name ??
                            'Not found',
                    });
                } else {
                    isExistingDbEmail.off();
                    isEmailUnique.off();
                    formContext.setError('techEmailAddress', {
                        message: 'An account with this email address already exists.',
                    });
                }
            }
        }
    };

    const verifyTechEmailAddressMutation = useMutation({
        mutationFn: verifyTechEmailAddress,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: onEmailAddressVerify,
    });

    const onContinue = () => {
        if (!selectedClient) return;
        if (Number(selectedClient.id) <= 0) {
            isShowingAllClients.on();
            return;
        }

        if (isExistingDbEmail.isOn) {
            formContext.setValue('isExistingReportingTech', true);
            formContext.setValue('existingReportingTechEmailAddress', reportingTech?.emailAddress);
            formContext.setValue('existingReportingTechReferenceNumber', reportingTech?.techReferenceNumber);

            formContext.setValue('supervisorEmailAddress', reportingTech?.reportingTechSupervisor?.emailAddress);
            formContext.setValue('supervisorFirstName', reportingTech?.reportingTechSupervisor?.firstName);
            formContext.setValue('supervisorLastName', reportingTech?.reportingTechSupervisor?.lastName);
            formContext.setValue(
                'supervisorPhoneNumber',
                reportingTech?.reportingTechSupervisor?.phoneNumber.replace(new RegExp('-', 'g'), '')
            );
            formContext.setValue('techEmailAddress', reportingTech?.emailAddress);
            formContext.setValue('techFirstName', reportingTech?.firstName);
            formContext.setValue('techLastName', reportingTech?.lastName);
            formContext.setValue('techPhoneNumber', reportingTech?.phoneNumber.replace(new RegExp('-', 'g'), ''));
            formContext.setValue('techReferenceNumber', reportingTech?.techReferenceNumber);

            formContext.setValue('techCMOAssignments', cmoAssignments);
        }

        if (isExistingB2CEmail.isOn) {
            formContext.setValue('isExistingB2CLogin', true);
            formContext.setValue('techEmailAddress', reportingTech?.emailAddress);
            formContext.setValue('techFirstName', reportingTech?.firstName);
            formContext.setValue('techLastName', reportingTech?.lastName);
            formContext.setValue('techConfirmEmailAddress', reportingTech?.emailAddress);
        }

        isSelectingClient.off();
        isShowingAllClients.off();
        formContext.clearErrors();
    };

    const onDomainSearch = () => {
        if (isEmailUnique.isOff || clientDomain?.trim()?.length === 0) return;
        formContext.trigger().then();
        isValidEmail.off();
        isShowingAllClients.off();
        clientDomainQuery.refetch().then();
    };

    const onEmailAddressBlur = () => {
        const email: string = formContext.getValues('techEmailAddress');
        if (!email || email?.trim().length === 0) return;
        verifyTechEmailAddressMutation.mutate({
            clientId: 0,
            marketId: 0,
            prefixText: email,
        });
    };

    return (
        <div className={'flex w-full flex-col items-center justify-center'}>
            {/* HEADER */}
            <span className={'mb-5 mt-10 text-lg font-medium dark:text-gray-200'}>
                Please enter your work email address below so that we can attempt to match you to a client.
            </span>

            {/* FIELDS */}
            <div className={'flex flex-col space-y-2'}>
                <div className={'xxs:w-80 sm:w-96'}>
                    {/* INPUT */}
                    <Input
                        inputOptions={{
                            isRequired: true,
                            label: 'Work Email Address',
                            name: 'techEmailAddress',
                            onBlur: onEmailAddressBlur,
                            onChange: setClientDomain,
                        }}
                        tooltip={'What is your work email address?'}
                    />
                </div>
                <div
                    className={clsx(
                        ' text-green-600',
                        isExistingDbEmail.isOff && isExistingB2CEmail.isOff ? 'hidden' : 'block'
                    )}
                >
                    {registerEmailText}
                </div>

                {/* SEARCH BUTTON */}
                <Button buttonProps={{ onPress: onDomainSearch }}>
                    <div className={'w-full'}>Search</div>
                </Button>
            </div>

            {/* CLIENTS */}
            <div className={'mt-4 flex flex-col space-y-4 xxs:w-80 sm:w-96'}>
                {isShowingAllClients.isOff && isExistingDbEmail.isOff && (
                    <ListBox
                        isDisabled={isValidEmail.isOn || isEmailUnique.isOff}
                        isRequired
                        label={'Possible Clients'}
                        listItems={[{ id: '-1', name: 'Not Found' }, ...clients]}
                        name={'clientList'}
                        onSingleChange={setSelectedClient}
                        tooltip={'Please select the correct client or the not found option.'}
                    />
                )}

                {/* CLIENTS */}
                {isShowingAllClients.isOn && isExistingDbEmail.isOff && (
                    <ListBox
                        isRequired
                        label={'All Clients'}
                        listItems={visibleClients}
                        name={'selectClient'}
                        onSingleChange={setSelectedClient}
                        tooltip={'Please select the client you work for.'}
                    />
                )}

                {/* CLIENTS */}
                {isExistingDbEmail.isOn && (
                    <ListBox
                        isRequired
                        label={'Existing Client'}
                        listItems={allClients.filter((x) => x.id === reportingTech?.clientId?.toString())}
                        name={'existingClient'}
                        onSingleChange={setSelectedClient}
                        tooltip={'The client that you work for was identified by your previous claims.'}
                    />
                )}

                {/* CONTINUE */}
                <Button buttonProps={{ isDisabled: !selectedClient, onPress: onContinue }}>
                    <div className={'w-full'}>Continue</div>
                </Button>
            </div>
        </div>
    );
};
