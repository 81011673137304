import { Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';
import clsx from 'clsx';

interface IBackgroundOverlayProps {
    intensity?: 'lighter' | 'light' | 'normal' | 'dark' | 'darker' | 'darkest';
    onAfterEnter?: () => void;
    onAfterLeave?: () => void;
    onBeforeEnter?: () => void;
    onBeforeLeave?: () => void;
}

export const BackgroundOverlay: FC<IBackgroundOverlayProps> = ({
    intensity = 'normal',
    onAfterEnter,
    onAfterLeave,
    onBeforeEnter,
    onBeforeLeave,
}) => {
    return (
        <Transition.Child
            afterEnter={onAfterEnter}
            afterLeave={onAfterLeave}
            as={Fragment}
            beforeEnter={onBeforeEnter}
            beforeLeave={onBeforeLeave}
            enter={'ease-out duration-300'}
            enterFrom={'opacity-0'}
            enterTo={'opacity-100'}
            leave={'ease-in duration-200'}
            leaveFrom={'opacity-100'}
            leaveTo={'opacity-0'}
        >
            <div
                className={clsx(
                    'pointer-events-none fixed inset-0 bg-black',
                    intensity === 'lighter' && 'bg-opacity-20',
                    intensity === 'light' && 'bg-opacity-40',
                    intensity === 'normal' && 'bg-opacity-60',
                    intensity === 'dark' && 'bg-opacity-75',
                    intensity === 'darker' && 'bg-opacity-90',
                    intensity === 'darkest' && 'bg-opacity-100'
                )}
            />
        </Transition.Child>
    );
};