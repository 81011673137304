import { StyledSummaryTableCell, SummaryHeader } from '../../../../components';
import { useFormContext } from 'react-hook-form';

import type { IUpdateVehicleResponse } from '../../../../types';
import type { FC } from 'react';

interface IVehiclesProps {
    setStep: (step: number) => void;
}

export const VehicleSummary: FC<IVehiclesProps> = ({ setStep }) => {
    const formContext = useFormContext();

    const vehicles: Array<IUpdateVehicleResponse> = formContext.getValues('allVehicles');

    const onStepClick = () => setStep(4);
    return (
        <div>
            <SummaryHeader isLink onLink={onStepClick}>
                Vehicles
            </SummaryHeader>

            {/* NO VEHICLE'S MESSAGE */}
            {vehicles?.length === 0 && (
                <span className={'font-medium dark:text-gray-200'}>No vehicles were added to this claim.</span>
            )}

            {vehicles?.length !== 0 && (
                <table className={'mt-1'}>
                    <thead>
                        <tr>
                            <StyledSummaryTableCell isHeader>Name</StyledSummaryTableCell>
                            <StyledSummaryTableCell isHeader isNumeric>
                                Regular Time
                            </StyledSummaryTableCell>
                        </tr>
                    </thead>
                    <tbody>
                        {vehicles.map((v) => {
                            return (
                                <tr key={v.itemId}>
                                    <StyledSummaryTableCell>{v.title}</StyledSummaryTableCell>
                                    <StyledSummaryTableCell isNumeric>{v.regular}</StyledSummaryTableCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};