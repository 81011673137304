import { BrowserCacheLocation } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID ?? '',
        authority: process.env.REACT_APP_AUTHORITY ?? '',
        knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES ?? ''],
        redirectUri: process.env.REACT_APP_REDIRECT_URI ?? '',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: boolean) => {
                //console.log(message);
            },
        },
    },
};

export const graphRequest = {
    scopes: ['https://graph.microsoft.com/User.Read'],
};

export const loginRequest = {
    scopes: [
        'openid',
        'https://phoenixlosscontrolb2c.onmicrosoft.com/8d259f95-4397-401c-83c6-4840a8af7256/access_as_user',
    ],
};

export const accessTokenRequest = {
    forceRefresh: true,
    scopes: ['https://phoenixlosscontrolb2c.onmicrosoft.com/8d259f95-4397-401c-83c6-4840a8af7256/access_as_user'],
};