import { Accept, useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import clsx from 'clsx';

interface IFileUploadProps {
    accepted?: Accept;
    isBusy?: boolean;
    isDisabled?: boolean;
    isMultiple?: boolean;
    onFileAccepted: (acceptedFiles: File[]) => void;
}

export const FileUpload = ({ accepted, isBusy, isDisabled, isMultiple = false, onFileAccepted }: IFileUploadProps) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: accepted,
        disabled: isDisabled,
        multiple: isMultiple,
        onDrop: onFileAccepted,
    });

    return (
        <div
            className={clsx(
                'background-color 0.6s ease flex justify-center rounded-md border-2 border-dashed p-20 outline-none',
                isDragActive
                    ? ': border-teal-300  bg-gray-200 dark:border-gray-300 dark:bg-gray-dark-400'
                    : 'border-teal-500 bg-gray-200 dark:border-gray-500 dark:bg-gray-dark-500',
                isDisabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-dark-400'
            )}
            {...getRootProps()}
        >
            {/* REACT-DROPZONE */}
            <input {...getInputProps()} />
            <div className={'flex items-center justify-center'}>
                {/* DEFAULT MESSAGE */}
                {!isBusy && (
                    <div className={'flex flex-col items-center'}>
                        <span className={'text-lg font-semibold dark:text-gray-200'}>
                            Choose file(s) or drag them here
                        </span>
                        <span className={'text-sm font-semibold text-gray-500 dark:text-gray-400'}>
                            You may upload multiple files but the total size should not exceed 50MB.
                        </span>
                        <span className={'text-sm font-semibold text-gray-500 dark:text-gray-400'}>
                            If you have more than 50MB, please upload in batches.
                        </span>
                    </div>
                )}
                {/* LOADING MESSAGE */}
                {isBusy && (
                    <div className={'flex items-center space-x-2'}>
                        <span className={'text-lg font-semibold dark:text-gray-200'}>Uploading...</span>
                        <div
                            className={
                                'h-5 w-5 animate-spin rounded-full border-b-2 border-gray-900 dark:border-gray-400'
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};