import { ColorModeContext } from '../context';
import { useContext } from 'react';

interface IUseDarkModeReturn {
    isDarkMode: boolean;
    isLightMode: boolean;
    disableDarkMode: () => void;
    enableDarkMode: () => void;
    toggleDarkMode: () => void;
}

export const useDarkMode = (): IUseDarkModeReturn => {
    const { isDarkMode, isLightMode, disable, enable, toggle } = useContext(ColorModeContext);
    return { isDarkMode, isLightMode, disableDarkMode: disable, enableDarkMode: enable, toggleDarkMode: toggle };
};