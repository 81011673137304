import { Transition } from '@headlessui/react';
import { ReactNode } from 'react';

interface IFadeAndScaleTransitionProps {
    children: ReactNode;
    className?: string;
    isOpen?: boolean;
}

export const FadeAndScaleTransition = ({ children, className, isOpen }: IFadeAndScaleTransitionProps) => {
    return (
        <Transition
            appear
            as={'div'}
            className={className || 'z-10'}
            enter={'transform transition ease-in-out duration-300'}
            enterFrom={'scale-0 opacity-0'}
            enterTo={'scale-100 opacity-100'}
            leave={'transform transition ease-in-out duration-300'}
            leaveFrom={'scale-100 opacity-100'}
            leaveTo={'scale-0 opacity-0'}
            show={isOpen}
        >
            {children}
        </Transition>
    );
};
