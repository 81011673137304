import { Input, TextArea, YesNoButton } from '../../../../components';
import { FormFields, FormHeader, FormSection } from '../form';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';

interface IDamagerKnownProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamagerKnown: FC<IDamagerKnownProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const isDamagerKnown: boolean | null = formContext.watch(claimFields.whoWasDamager);
    const isDamagerDetailsKnown: boolean | null = formContext.watch(claimFields.haveDamagerContactDetails);

    const onIsDamagerKnownNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'DamagerContactName',
            'DamagerEmail',
            'DamagerIdentificationComments',
            'DamagerName',
            'DamagerPhone',
            'DamagerStatement',
            claimFields.whoWasDamager,
            claimFields.haveDamagerContactDetails,
        ]);
        formContext?.setValue(claimFields.damagerIdentified, '');
        formContext?.setValue(claimFields.damagerName, '');
        formContext?.setValue(claimFields.haveDamagerContactDetails, false);
        formContext?.clearErrors(claimFields.damagerIdentified);
        formContext?.clearErrors(claimFields.damagerName);
        formContext?.clearErrors(claimFields.whoWasDamager);
    };

    const onIsDamagerKnownYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.whoWasDamager, claimFields.haveDamagerContactDetails]);
        formContext?.clearErrors(claimFields.whoWasDamager);
        formContext?.setValue(claimFields.haveDamagerContactDetails, null);
    };

    const onIsDamagerContactDetailsKnownNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'DamagerContactName',
            'DamagerEmail',
            'DamagerPhone',
            'DamagerStatement',
            claimFields.haveDamagerContactDetails,
        ]);
        formContext?.setValue(claimFields.damagerContactName, '');
        formContext?.setValue(claimFields.damagerPhoneNumber, '');
        formContext?.setValue(claimFields.damagerEmailAddress, '');
        formContext?.setValue(claimFields.damagerStatement, '');
        formContext?.clearErrors(claimFields.damagerContactName);
        formContext?.clearErrors(claimFields.damagerPhoneNumber);
        formContext?.clearErrors(claimFields.damagerEmailAddress);
        formContext?.clearErrors(claimFields.damagerStatement);
        formContext?.clearErrors(claimFields.haveDamagerContactDetails);
    };

    const onIsDamagerContactDetailsKnownYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.haveDamagerContactDetails]);
        formContext?.clearErrors(claimFields.haveDamagerContactDetails);
    };

    const onDamagerKnownInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'DamagerIdentificationComments',
            'DamagerName',
            claimFields.whoWasDamager,
        ]);
        formContext?.setValue(claimFields.whoWasDamager, true);
        formContext?.clearErrors(claimFields.whoWasDamager);
    };

    const onDamagerIdentifiedInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'DamagerContactName',
            'DamagerEmail',
            'DamagerPhone',
            'DamagerStatement',
            claimFields.haveDamagerContactDetails,
        ]);
        formContext?.setValue(claimFields.haveDamagerContactDetails, true);
        formContext?.clearErrors(claimFields.haveDamagerContactDetails);
    };

    return (
        <FormSection>
            <FormHeader>Damager Known</FormHeader>
            <FormFields>
                {/* Is Damager Known? */}
                <YesNoButton
                    isRequired
                    isSelected={isDamagerKnown}
                    labelText={'Do you know who the damager was?'}
                    name={claimFields.whoWasDamager}
                    onNoClick={onIsDamagerKnownNoClick}
                    onYesClick={onIsDamagerKnownYesClick}
                    tooltip={'At this time, is the damager known or unknown?'}
                />

                {isDamagerKnown !== false && (
                    <>
                        {/* DAMAGER NAME */}
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.DamagerName,
                                label: 'Damager Name',
                                name: claimFields.damagerName,
                                onChange: onDamagerKnownInputChange,
                                value: formContext.getValues(claimFields.damagerName),
                            }}
                            tooltip={'What is the name/company name of the suspected damager?'}
                        />

                        {/* DAMAGER IDENTIFIED */}
                        <TextArea
                            options={{
                                isRequired: fieldRequirements.DamagerIdentified,
                                label: 'How was the damager identified?',
                                name: claimFields.damagerIdentified,
                                onChange: onDamagerKnownInputChange,
                            }}
                            tooltip={'How did you identify the suspected damager?'}
                        />
                    </>
                )}

                {/* Is Damager Contact Details Known? */}
                <YesNoButton
                    isRequired
                    isSelected={isDamagerDetailsKnown}
                    labelText={'Do you have contact details or a statement from the damager?'}
                    name={claimFields.haveDamagerContactDetails}
                    onNoClick={onIsDamagerContactDetailsKnownNoClick}
                    onYesClick={onIsDamagerContactDetailsKnownYesClick}
                    tooltip={
                        'Is any contact information for the damager known? Do you have a statement from the damager?'
                    }
                />

                {isDamagerDetailsKnown !== false && (
                    <>
                        {/* CONTACT NAME */}
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.DamagerContactName,
                                label: 'Damager Contact Name',
                                name: claimFields.damagerContactName,
                                onChange: onDamagerIdentifiedInputChange,
                                value: formContext.getValues(claimFields.damagerContactName),
                            }}
                            tooltip={
                                'If known, who is the point of contact for the company? – e.g., foreman,\n' +
                                'superintendent, general manager, owner, etc. '
                            }
                        />

                        {/* PHONE NUMBER  */}
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.DamagerPhoneNumber,
                                label: 'Damager Phone Number',
                                name: claimFields.damagerPhoneNumber,
                                onChange: onDamagerIdentifiedInputChange,
                            }}
                            tooltip={'What is a phone number the damagers can be reached at?'}
                            type={'phone'}
                        />

                        {/* EMAIL ADDRESS */}
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.DamagerEmailAddress,
                                label: 'Damager Email Address',
                                name: claimFields.damagerEmailAddress,
                                onChange: onDamagerIdentifiedInputChange,
                                value: formContext.getValues(claimFields.damagerEmailAddress),
                            }}
                            tooltip={'What is an email address the damager can be reached at?'}
                        />

                        {/* DAMAGER STATEMENT */}
                        <TextArea
                            options={{
                                isRequired: fieldRequirements.DamagerStatement,
                                label: 'Damager Statement',
                                name: claimFields.damagerStatement,
                                onChange: onDamagerIdentifiedInputChange,
                            }}
                            tooltip={'Did the damager make any remarks about the damage?'}
                        />
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};
