import { AriaButtonProps, useButton } from '@react-aria/button';
import { useRef } from 'react';
import clsx from 'clsx';

import type { FCC } from '../../../types';

interface IResetButtonProps {
    ariaProps: AriaButtonProps<'button'>;
}
export const ResetButton: FCC<IResetButtonProps> = ({ ariaProps, children }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { buttonProps } = useButton(ariaProps, buttonRef);

    return (
        <button
            {...buttonProps}
            className={clsx(
                'keyboard-focus absolute right-10 top-9 outline-none hover:scale-110 dark:text-gray-300',
                'focus-visible:rounded-sm disabled:cursor-not-allowed disabled:hover:scale-100',
                ariaProps?.isDisabled ? 'text-gray-300 dark:text-gray-500' : 'text-gray-600'
            )}
            ref={buttonRef}
        >
            {children}
        </button>
    );
};
