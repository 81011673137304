import { FormFields, FormHeader, FormSection } from '../form';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { Input } from '../../../../components';

import type { Dispatch, FC, SetStateAction } from 'react';

interface IInternalIdentificationsProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const InternalIdentifications: FC<IInternalIdentificationsProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const isNodeUnknown: boolean = formContext.watch('isNodeUnknown') ?? false;

    const onIsNodeUnknownChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'ClaimNode']);
        formContext.setValue(claimFields.claimNode, checked ? 'Unknown' : '');
        formContext.setValue('isNodeUnknown', checked);
        formContext.clearErrors('isNodeUnknown');
        formContext.clearErrors(claimFields.claimNode);
    };

    return (
        <FormSection>
            <FormHeader>Internal Identifications</FormHeader>

            <FormFields>
                {/* HUB */}
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.ClaimHub,
                        label: 'Hub',
                        name: claimFields.claimHub,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'ClaimHub']),
                    }}
                    tooltip={'What hub/lead-in do you use?'}
                />

                {/* NODE */}
                <Input
                    checkboxOptions={{
                        'aria-label': 'Node unknown',
                        isSelected: isNodeUnknown,
                        onChange: onIsNodeUnknownChange,
                    }}
                    inputOptions={{
                        isDisabled: isNodeUnknown ?? false,
                        isRequired: fieldRequirements.ClaimNode,
                        label: 'Node',
                        name: claimFields.claimNode,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'ClaimNode']),
                    }}
                    tooltip={'What nodes are affected by this damage?'}
                />

                {/* OUTAGE ID */}
                <div className={'flex w-full'}>
                    {/* INPUT */}
                    <Input
                        inputOptions={{
                            isRequired: fieldRequirements.RemedyId,
                            label: 'Outage ID',
                            name: claimFields.remedyId,
                            onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'RemedyID']),
                        }}
                        tooltip={'What is the outage, job, or work order number associated with this damage?'}
                    />
                </div>
            </FormFields>
        </FormSection>
    );
};