import { ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

interface ITooltipIcon {
    isError?: boolean;
}

export const TooltipIcon = ({ isError }: ITooltipIcon) => {
    if (isError) return <ExclamationCircleIcon className={'h-5 w-5 text-red-500 hover:scale-110 hover:opacity-80'} />;

    return <QuestionMarkCircleIcon className={'h-5 w-5 text-emerald-600 hover:scale-110 dark:text-emerald-300'} />;
};