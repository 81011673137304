import { claimFields, defaultFieldRequirements, defaultRequired } from '../config';
import { ClaimConfigContext } from '../context';
import { useContext, useEffect } from 'react';
import {
    damageDetailsDefaultFormValues,
    damageLocationDefaultFormValues,
    damagerDetailsDefaultFormValues,
} from '../features/claim/components';
import type { AnyObjectSchema } from 'yup';
import * as yup from 'yup';

import type { IClaimFieldConfig, IFieldRequirements } from '../types';
import { UseFormReturn } from 'react-hook-form';

interface IUseValidationProps {
    activeStep?: number;
    claimFieldConfig?: Array<IClaimFieldConfig> | null;
}

export const useValidation = ({ activeStep = 0, claimFieldConfig }: IUseValidationProps) => {
    const config = useContext(ClaimConfigContext);

    // Update the claim fields configuration only when it changes to a new one.
    useEffect(() => {
        if (!claimFieldConfig) return;
        config.setClaimFieldConfig(claimFieldConfig);
    }, [claimFieldConfig]);

    const validZipCodeLength = 5;

    // Set claim fields required status from the config.
    const fieldRequirements: IFieldRequirements = { ...defaultFieldRequirements };
    config.claimFieldConfig?.forEach((field) => (fieldRequirements[field.name] = field.isRequired));

    const damageLocationValidationSchema: yup.ObjectSchema<any> = yup.object().shape({
        [claimFields.claimHub]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.claimHub]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.claimNode]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.claimNode]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.damageStreetAddress]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.damageStreetAddress]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.damageCity]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.damageCity]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.damageCrossStreet]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.damageCrossStreet]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.damageDate]: yup
            .date()
            .when('isDamageDateUnknown', {
                is: false,
                then: yup
                    .date()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.damageDate]) return true;
                        // required and has value. pass.
                        if (value) return true;
                        // default to fail.
                        return false;
                    })
                    .max(yup.ref(claimFields.discoveryDate), '')
                    .nullable(),
            })
            .nullable(),
        [claimFields.discoveryDate]: yup
            .date()
            .when('isDamageDateUnknown', {
                is: false,
                then: yup
                    .date()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.discoveryDate]) return true;
                        // required and has value. pass.
                        if (value) return true;
                        // default to fail.
                        return false;
                    })
                    .min(yup.ref(claimFields.damageDate), '')
                    .nullable(),
            })
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.discoveryDate]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.damageLocationType]: yup
            .number()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damageLocationType]) return true;
                // required and has valid value. pass.
                if (value && value !== damageLocationDefaultFormValues[claimFields.damageLocationType]) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.damageZipCode]: yup
            .string()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damageZipCode]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            })
            .test('isZipCode', 'Invalid zip code.', (value) => {
                // not required and empty. pass.
                if (!fieldRequirements[claimFields.damageZipCode]) return true;
                // valid. pass.
                if (value && value.length === validZipCodeLength) return true;
                // default to fail.
                return false;
            }),
        [claimFields.damageState]: yup.number().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.damageState]) return true;
            // required and has valid value. pass.
            if (value && value !== damageLocationDefaultFormValues[claimFields.damageState]) return true;
            // default to fail.
            return false;
        }),
        [claimFields.depthOfDamage]: yup.string().when(claimFields.wasDamageUnderground, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.depthOfDamage]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.facilityNumber]: yup.string().when(claimFields.wasDamageAerial, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.facilityNumber]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.remedyId]: yup
            .string()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.remedyId]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.wasDamageAerial]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.wasDamageAerial]) return true;
                // required and has value. pass.
                if (value || value === false) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.wasDamageUnderground]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.wasDamageUnderground]) return true;
                // required and has value. pass.
                if (value || value === false) return true;
                // default to fail.
                return false;
            })
            .nullable(),
    });

    const damageDetailsValidationSchema: yup.ObjectSchema<any> = yup.object().shape({
        [claimFields.cableInConduit]: yup
            .boolean()
            .when(claimFields.wasSpanReplaced, {
                is: true,
                then: yup.boolean().required(defaultRequired).nullable(),
            })
            .when(claimFields.wasSpanReplaced, {
                is: null,
                then: yup.boolean().required(defaultRequired).nullable(),
            })
            .nullable(),
        [claimFields.cableSize]: yup
            .string()
            .when(claimFields.wasSpanReplaced, {
                is: true,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.cableSize]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            })
            .when(claimFields.wasSpanReplaced, {
                is: null,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.cableSize]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            }),
        [claimFields.damageActivityType]: yup
            .number()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damageActivityType]) return true;
                // required and has valid value. pass.
                if (value && value !== damageDetailsDefaultFormValues[claimFields.damageActivityType]) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.damageByType]: yup
            .number()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damageByType]) return true;
                // required and has valid value. pass.
                if (value && value !== damageDetailsDefaultFormValues[claimFields.damageByType]) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.damageFacilityTypes]: yup
            .array()
            .of(yup.string())
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damageFacilityTypes]) return true;
                // required and has valid value. pass.
                if (value && value.length > 0) return true;
                // default to fail.
                return false;
            }),
        [claimFields.detailsOfDamageLoss]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.detailsOfDamageLoss]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.estimatedSubsAffected]: yup.string().test('isRequired', defaultRequired, (value) => {
            // not required. pass.
            if (!fieldRequirements[claimFields.estimatedSubsAffected]) return true;
            // required and has value. pass.
            if (value) return true;
            // default to fail.
            return false;
        }),
        [claimFields.locateAccuracy]: yup.number().when(claimFields.wereLocatesPresent, {
            is: '1',
            then: yup.number().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.locateAccuracy]) return true;
                // required and has valid value. pass.
                if (value && value !== damageDetailsDefaultFormValues[claimFields.locateAccuracy]) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.locateMarkingFlags]: fieldRequirements[claimFields.locateMarking]
            ? yup.boolean().when(claimFields.wereLocatesPresent, {
                  is: '1',
                  then: yup.boolean().when('isMarkingUnknown', {
                      is: false,
                      then: yup.boolean().when(claimFields.locateMarkingPaint, {
                          is: false,
                          then: yup.boolean().when(claimFields.locateMarkingStakes, {
                              is: false,
                              then: yup.bool().oneOf([true], defaultRequired),
                          }),
                      }),
                  }),
              })
            : yup.boolean().nullable(),
        [claimFields.locateMarkingPaint]: fieldRequirements[claimFields.locateMarking]
            ? yup.boolean().when(claimFields.wereLocatesPresent, {
                  is: '1',
                  then: yup.boolean().when('isMarkingUnknown', {
                      is: false,
                      then: yup.boolean().when(claimFields.locateMarkingStakes, {
                          is: false,
                          then: yup.boolean().when(claimFields.locateMarkingFlags, {
                              is: false,
                              then: yup.bool().oneOf([true], defaultRequired),
                          }),
                      }),
                  }),
              })
            : yup.boolean().nullable(),
        [claimFields.locateMarkingStakes]: fieldRequirements[claimFields.locateMarking]
            ? yup.boolean().when(claimFields.wereLocatesPresent, {
                  is: '1',
                  then: yup.boolean().when('isMarkingUnknown', {
                      is: false,
                      then: yup.boolean().when(claimFields.locateMarkingFlags, {
                          is: false,
                          then: yup.boolean().when(claimFields.locateMarkingPaint, {
                              is: false,
                              then: yup.bool().oneOf([true], defaultRequired),
                          }),
                      }),
                  }),
              })
            : yup.boolean().nullable(),
        [claimFields.outageDuration]: yup
            .string()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.outageDuration]) return true;
                // required and has a valid value. pass.
                if (value && value !== '0:00') return true;
                // default to fail.
                return false;
            })
            .test('isValid', 'Minutes must be between 0 and 59.', (value) => {
                // Get the minute value from the string.
                const minutes = Number(value?.includes(':') ? value?.split(':')[1] : '0');
                // Between 0 and 59. pass.
                if (minutes >= 0 && minutes <= 59) return true;
                // default to fail.
                return false;
            }),
        [claimFields.spanFootage]: yup
            .string()
            .when(claimFields.wasSpanReplaced, {
                is: true,
                then: yup
                    .string()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.spanFootage]) return true;
                        // required and has value. pass.
                        if (value) return true;
                        // default to fail.
                        return false;
                    })
                    .nullable(),
            })
            .when(claimFields.wasSpanReplaced, {
                is: null,
                then: yup
                    .string()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.spanFootage]) return true;
                        // required and has value. pass.
                        if (value) return true;
                        // default to fail.
                        return false;
                    })
                    .nullable(),
            })
            .nullable(),
        [claimFields.wasSpanReplaced]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.wasSpanReplaced]) return true;
                // required and has valid value. pass.
                if (value || value === false) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.wereLocatesPresent]: yup
            .string()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.wereLocatesPresent]) return true;
                // required and has valid value. pass.
                if (value !== '0') return true;
                // default to fail.
                return false;
            })
            .nullable(),
    });

    const damagerDetailsValidationSchema: yup.ObjectSchema<any> = yup.object().shape({
        [claimFields.damagerContactName]: yup.string().when(claimFields.haveDamagerContactDetails, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damagerContactName]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.damagerEmailAddress]: yup.string().when(claimFields.haveDamagerContactDetails, {
            is: true,
            then: yup
                .string()
                .test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.damagerEmailAddress]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                })
                .test('isEmail', 'Invalid email address.', (value) => {
                    const isDamagerEmailAddressRequired = fieldRequirements[claimFields.damagerEmailAddress];
                    // used to validate email address.
                    const emailSchema = yup.string().email();
                    // not required and empty. pass.
                    if (!isDamagerEmailAddressRequired && !value) return true;
                    // valid. pass.
                    if (value && emailSchema.isValidSync(value)) return true;
                    // default to fail.
                    return false;
                }),
        }),
        [claimFields.damagerName]: yup
            .string()
            .when(claimFields.whoWasDamager, {
                is: true,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.damagerName]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            })
            .when(claimFields.whoWasDamager, {
                is: null,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.damagerName]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            }),
        [claimFields.damagerPhoneNumber]: yup.string().when(claimFields.haveDamagerContactDetails, {
            is: true,
            then: yup
                .string()
                .test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.damagerPhoneNumber]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                })
                .test('isValid', defaultRequired, (value) => {
                    // not required and empty. pass.
                    if (!fieldRequirements[claimFields.damagerPhoneNumber] && !value) return true;
                    // at least 10 digits. pass.
                    if (value && value.length >= 10) return true;
                    // default to fail.
                    return false;
                }),
        }),
        [claimFields.damagerStatement]: yup.string().when(claimFields.haveDamagerContactDetails, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damagerStatement]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.damagerIdentified]: yup.string().when(claimFields.whoWasDamager, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.damagerIdentified]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.haveDamagerContactDetails]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.haveDamagerContactDetails]) return true;
                // required and has valid value. pass.
                if (value !== null) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.opinionOnResponsibleParty]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.opinionOnResponsibleParty]) return true;
                // required and has valid value. pass.
                if (value !== null) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.responsibleForDamage]: yup
            .number()
            .nullable()
            .when(claimFields.opinionOnResponsibleParty, {
                is: true,
                then: yup.number().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.responsibleForDamage]) return true;
                    // required and has valid value. pass.
                    if (value && value !== damagerDetailsDefaultFormValues[claimFields.responsibleForDamage])
                        return true;
                    // default to fail.
                    return false;
                }),
            })
            .when(claimFields.opinionOnResponsibleParty, {
                is: null,
                then: yup.number().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.responsibleForDamage]) return true;
                    // required and has valid value. pass.
                    if (value && value !== damagerDetailsDefaultFormValues[claimFields.responsibleForDamage])
                        return true;
                    // default to fail.
                    return false;
                }),
            }),
        [claimFields.whoWasDamager]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.whoWasDamager]) return true;
                // required and has valid value. pass.
                if (value !== null) return true;
                // default to fail.
                return false;
            })
            .nullable(),
    });

    const repairDetailsValidationSchema: yup.ObjectSchema<any> = yup.object().shape({
        [claimFields.claimComments]: yup.string().when(claimFields.repairsDoneOnSite, {
            is: true,
            then: yup.string().test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.claimComments]) return true;
                // required and has value. pass.
                if (value) return true;
                // default to fail.
                return false;
            }),
        }),
        [claimFields.areFutureRepairsNeeded]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.areFutureRepairsNeeded]) return true;
                // required and has valid value. pass.
                if (value !== null) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        [claimFields.clientConstructionNumber]: yup
            .string()
            .when(claimFields.areFutureRepairsNeeded, {
                is: true,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.clientConstructionNumber]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            })
            .when(claimFields.areFutureRepairsNeeded, {
                is: null,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.clientConstructionNumber]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            }),
        [claimFields.isFreeFormRepairEntry]: yup
            .boolean()
            .when(claimFields.repairsDoneOnSite, {
                is: true,
                then: yup
                    .boolean()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.isFreeFormRepairEntry]) return true;
                        // required and has valid value. pass.
                        if (value !== null) return true;
                        // default to fail.
                        return false;
                    })
                    .nullable(),
            })
            .when(claimFields.repairsDoneOnSite, {
                is: null,
                then: yup
                    .boolean()
                    .test('isRequired', defaultRequired, (value) => {
                        // not required. pass.
                        if (!fieldRequirements[claimFields.isFreeFormRepairEntry]) return true;
                        // required and has valid value. pass.
                        if (value !== null) return true;
                        // default to fail.
                        return false;
                    })
                    .nullable(),
            })
            .nullable(),
        [claimFields.legacyClaimNo]: yup
            .string()
            .nullable()
            .when(claimFields.repairsDoneOnSite, {
                is: true,
                then: yup.string().test('isRequired', defaultRequired, (value) => {
                    // not required. pass.
                    if (!fieldRequirements[claimFields.legacyClaimNo]) return true;
                    // required and has value. pass.
                    if (value) return true;
                    // default to fail.
                    return false;
                }),
            }),
        overTime: yup
            .number()
            .nullable()
            .when(claimFields.areFutureRepairsNeeded, {
                is: true,
                then: yup.number().min(1, defaultRequired).nullable(),
            }),
        [claimFields.repairsDoneOnSite]: yup
            .boolean()
            .test('isRequired', defaultRequired, (value) => {
                // not required. pass.
                if (!fieldRequirements[claimFields.repairsDoneOnSite]) return true;
                // required and has valid value. pass.
                if (value !== null) return true;
                // default to fail.
                return false;
            })
            .nullable(),
        timeOnSite: yup
            .number()
            .nullable()
            .when(claimFields.areFutureRepairsNeeded, {
                is: true,
                then: yup.number().min(1, defaultRequired).nullable(),
            }),
    });

    const uploadFilesValidationScheme: yup.ObjectSchema<any> = yup.object().shape({});

    // Set the correct resolver based on the active step.
    let currentResolver: AnyObjectSchema = yup.object().shape({});
    switch (activeStep) {
        case 0:
            currentResolver = damageLocationValidationSchema;
            break;
        case 1:
            currentResolver = uploadFilesValidationScheme;
            break;
        case 2:
            currentResolver = damageDetailsValidationSchema;
            break;
        case 3:
            currentResolver = damagerDetailsValidationSchema;
            break;
        case 4:
            currentResolver = repairDetailsValidationSchema;
            break;
        case 5:
            currentResolver = yup.object().shape({});
            break;
    }

    const isLocationDetailsValid = async (formContext: UseFormReturn) => {
        return await formContext.trigger(
            [
                claimFields.claimNode,
                claimFields.damageStreetAddress,
                claimFields.damageCrossStreet,
                claimFields.damageCity,
                claimFields.damageState,
                claimFields.damageZipCode,
                claimFields.damageDate,
                claimFields.discoveryDate,
                claimFields.remedyId,
                claimFields.facilityNumber,
                claimFields.depthOfDamage,
            ],
            { shouldFocus: true }
        );
    };
    const isDamageDetailsValid = async (formContext: UseFormReturn) => {
        return await formContext.trigger(
            [
                claimFields.detailsOfDamageLoss,
                claimFields.estimatedSubsAffected,
                claimFields.outageDuration,
                claimFields.damageActivityType,
                claimFields.damageByType,
                claimFields.damageFacilityTypes,
                claimFields.wereLocatesPresent,
                claimFields.wereLocatesPresentUnknown,
                claimFields.locateAccuracy,
                claimFields.locateMarking,
                claimFields.locateMarkingFlags,
                claimFields.locateMarkingPaint,
                claimFields.locateMarkingStakes,
                claimFields.wasSpanReplaced,
                claimFields.spanFootage,
                claimFields.isSpanFootageUnknown,
                claimFields.cableInConduit,
                claimFields.cableSize,
            ],
            { shouldFocus: true }
        );
    };

    const isDamagerDetailsValid = async (formContext: UseFormReturn) => {
        return await formContext.trigger(
            [
                claimFields.opinionOnResponsibleParty,
                claimFields.responsibleForDamage,
                claimFields.whoWasDamager,
                claimFields.damagerName,
                claimFields.damagerIdentified,
                claimFields.haveDamagerContactDetails,
                claimFields.damagerContactName,
                claimFields.damagerPhoneNumber,
                claimFields.damagerEmailAddress,
                claimFields.damagerStatement,
            ],
            { shouldFocus: true }
        );
    };

    const isRepairDetailsValid = async (formContext: UseFormReturn) => {
        return await formContext.trigger(
            [
                claimFields.repairsDoneOnSite,
                claimFields.legacyClaimNo,
                claimFields.areFutureRepairsNeeded,
                claimFields.clientConstructionNumber,
                claimFields.isFreeFormRepairEntry,
            ],
            { shouldFocus: true }
        );
    };

    return {
        currentResolver,
        damageDetailsValidationSchema,
        damageLocationValidationSchema,
        damagerDetailsValidationSchema,
        fieldRequirements,
        isDamageDetailsValid,
        isDamagerDetailsValid,
        isLocationDetailsValid,
        isRepairDetailsValid,
        repairDetailsValidationSchema,
        uploadFilesValidationScheme,
    };
};