import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Popover, Transition } from '@headlessui/react';
import { resourceLinks } from '../../config';
import { Fragment } from 'react';
import clsx from 'clsx';

export const ResourceLinks = () => {
    const solutions = [
        { name: 'Reporting Damages Training Document', href: resourceLinks.reportingDamagesTrainingDocument },
        { name: 'Reporting Damages Training Video', href: resourceLinks.reportingDamagesTrainingVideo },
        { name: 'Pre-Dig Training Document', href: resourceLinks.preDigTrainingDocument },
        { name: 'Pre-Dig Training Video', href: resourceLinks.preDigTrainingVideo },
        { name: 'MRS Training Document', href: resourceLinks.mrsTrainingDocument },
        { name: 'MRS Training Video', href: resourceLinks.mrsTrainingVideo },
        { name: 'MRS Field Glossary', href: resourceLinks.mrsGlossary },
    ];

    return (
        <Popover className={'relative'}>
            {({ open }) => (
                <>
                    <Popover.Button
                        className={clsx(
                            'keyboard-focus inline-flex items-center gap-x-1 rounded-md border p-2 text-sm font-semibold leading-6',
                            'text-gray-900 outline-none dark:border-gray-500 dark:text-gray-100'
                        )}
                    >
                        <span className={'text-sm font-bold sm:text-lg lg:text-xl'}>Resources</span>
                        <ChevronDownIcon
                            className={clsx(
                                'mt-1 h-6 w-6',
                                open ? 'rotate-180 transform transition-transform duration-300' : 'duration-300'
                            )}
                            aria-hidden={'true'}
                        />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter={'transition ease-out duration-200'}
                        enterFrom={'opacity-0 translate-y-1'}
                        enterTo={'opacity-100 translate-y-0'}
                        leave={'transition ease-in duration-150'}
                        leaveFrom={'opacity-100 translate-y-0'}
                        leaveTo={'opacity-0 translate-y-1'}
                    >
                        <Popover.Panel
                            className={'absolute left-28 z-30 mt-1 flex w-screen max-w-min -translate-x-1/2 px-4'}
                        >
                            <div
                                className={clsx(
                                    'w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6',
                                    'text-gray-900 shadow-lg ring-1 ring-gray-900/5 dark:bg-gray-600'
                                )}
                            >
                                {solutions.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className={clsx(
                                            'block p-2 hover:text-indigo-600 dark:text-gray-100 hover:dark:text-indigo-300',
                                            'outline-none focus-visible:rounded-md focus-visible:ring-4 focus-visible:ring-brand-500 dark:focus-visible:ring-brand-100'
                                        )}
                                        target={'_blank'}
                                        referrerPolicy={'no-referrer'}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};