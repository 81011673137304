import type { FCC } from '../../../../types';

export const FormHeader: FCC = ({ children }) => {
    return (
        <div className={'flex flex-col'}>
            <h2
                className={
                    'min-w-[250px] text-xl font-semibold tracking-wide text-brand-500 dark:text-brand-100 sm:text-2xl'
                }
            >
                {children}
            </h2>
            <div className={'h-[2px] bg-gray-300 dark:bg-gray-500'} />
        </div>
    );
};