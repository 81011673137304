import { IconButton } from '../../../components';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import clsx from 'clsx';

import type { IMarketOffices, IOffice, IReportingTechCMOAssignmentViewModel } from '../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface IMarketOfficesProps {
    marketOffices: Array<IMarketOffices>;
    setMarketOffices: Dispatch<SetStateAction<Array<IMarketOffices>>>;
}

export const MarketOffices: FC<IMarketOfficesProps> = ({ marketOffices, setMarketOffices }) => {
    const formContext = useFormContext();

    useEffect(() => {
        const existingCMOAssignment: IReportingTechCMOAssignmentViewModel[] =
            formContext.getValues('techCMOAssignments');
        if (existingCMOAssignment?.length > 0) {
            existingCMOAssignment.forEach((cmo) => {
                const matchingMarket: IMarketOffices | undefined = marketOffices.find(
                    (mo: IMarketOffices) => mo.marketId.id === cmo.marketId.toString()
                );

                const matchingOffice: IMarketOffices | undefined = marketOffices.find(
                    (mo: IMarketOffices) =>
                        mo.marketId.id === cmo.marketId.toString() &&
                        mo.officeIds.find((x) => x.id === cmo.officeId.toString())
                );

                // Add office to existing market entry or create a new one.
                if (!matchingOffice) {
                    if (matchingMarket) {
                        matchingMarket.officeIds.push({ id: cmo.officeId.toString(), name: cmo.officeName });
                        setMarketOffices([...marketOffices]);
                    } else {
                        marketOffices.push({
                            marketId: { id: cmo.marketId.toString(), name: cmo.marketName },
                            officeIds: [{ id: cmo.officeId.toString(), name: cmo.officeName }],
                        });
                        setMarketOffices([...marketOffices]);
                    }
                }
            });
        }
    }, []);

    const onRemoveMarketOffice = (marketId: string, officeId: string) => {
        const marketOffice: IMarketOffices | undefined = marketOffices.find(
            (mo: IMarketOffices) => mo.marketId.id === marketId
        );
        if (marketOffice)
            marketOffice.officeIds = marketOffice?.officeIds?.filter((o: IOffice) => o.id !== officeId) ?? [];

        // Remove the market if we removed the last office.
        if (marketOffice?.officeIds?.length === 0)
            setMarketOffices([...marketOffices.filter((mo: IMarketOffices) => mo.marketId.id !== marketId)]);
        else setMarketOffices([...marketOffices]);
    };

    return (
        <div>
            <div className={'flex h-full w-full flex-col  items-center space-y-5'}>
                <div className={'flex w-full flex-col'}>
                    {/* SELECTED MARKET / OFFICES */}
                    <div className={'flex flex-row flex-wrap self-start whitespace-nowrap'}>
                        {marketOffices?.map((mo: IMarketOffices, marketIndex: number) =>
                            mo.officeIds.map((o: IOffice, officeIndex: number) => {
                                return (
                                    <div
                                        className={clsx(
                                            'mr-1 mt-1 flex flex-row rounded-md border border-gray-400',
                                            'bg-gray-200 p-1.5 text-sm font-medium text-gray-800 dark:text-gray-200',
                                            'dark:bg-gray-dark-500 sm:text-base'
                                        )}
                                        key={`${marketIndex}${officeIndex}`}
                                    >
                                        {mo.marketId.name} - {o.name}
                                        <IconButton
                                            className={'ml-1.5'}
                                            onPress={() => onRemoveMarketOffice(mo.marketId.id, o.id)}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};