import { Button } from '../components';
import axios from 'axios';

import type { FC } from 'react';

interface IErrorFallbackProps {
    error: Error;
    resetErrorBoundary: () => void;
}

interface IAxiosError {
    ErrorId: string;
    Messages: Array<string>;
}

export const ErrorFallback: FC<IErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    const data = axios.isAxiosError(error)
        ? (error.response?.data as IAxiosError)
        : { ErrorId: '', Messages: ['Unexpected Error'] };

    const errorMessage = data.Messages?.join(' ');

    return (
        <div
            className={
                'flex min-h-screen flex-col items-center justify-center bg-gradient-to-br from-brand-100 to-brand-500 px-2'
            }
            role={'alert'}
        >
            <p className={'mb-10 text-center text-2xl text-gray-200 sm:text-4xl'}>Oh no! Something went wrong.</p>
            <div className={'bg-gray-100 px-8 pt-8 sm:p-14'}>
                <div className={'text-center text-2xl font-medium text-red-500'}>
                    <p>{errorMessage}</p>
                </div>

                <div className={'flex w-full items-center justify-center'}>
                    <div className={'text-1xl my-16 max-w-md text-center font-medium text-gray-700'}>
                        <div>
                            If you continue to have difficulty logging in, please reach out to{' '}
                            <span className={'italic'}>Angi Meza</span> by phone at{' '}
                            <a className={'text-blue-300'} href={'tel:612-432-0366'}>
                                612-432-0366
                            </a>{' '}
                            or by email at{' '}
                            <a className={'text-blue-300'} href={'mailto:Angi_Meza@phoenixlosscontrol.com'}>
                                Angi_Meza@phoenixlosscontrol.com
                            </a>
                        </div>
                    </div>
                </div>
                <Button buttonProps={{ onPress: resetErrorBoundary }} className={'mb-10 w-full rounded-none sm:mb-0'}>
                    Return to login screen
                </Button>
            </div>
        </div>
    );
};