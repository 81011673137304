import { ForwardedRef, forwardRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FCCCN } from '../../../types';
import clsx from 'clsx';

interface IModalPanelProps {
    onAfterEnter?: () => void;
    onAfterLeave?: () => void;
    onBeforeEnter?: () => void;
    onBeforeLeave?: () => void;
}

export const ModalPanel: FCCCN<IModalPanelProps> = forwardRef(
    (
        { children, className, onAfterEnter, onAfterLeave, onBeforeEnter, onBeforeLeave },
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <div className={'fixed inset-0 overflow-y-auto'}>
                <div className={'flex min-h-full items-center justify-center p-0.5 text-center xs:p-2 sm:p-4'}>
                    <Transition.Child
                        afterEnter={onAfterEnter}
                        afterLeave={onAfterLeave}
                        as={Fragment}
                        beforeEnter={onBeforeEnter}
                        beforeLeave={onBeforeLeave}
                        enter={'ease-out duration-300'}
                        enterFrom={'opacity-0 scale-95'}
                        enterTo={'opacity-100 scale-100'}
                        leave={'ease-in duration-300'}
                        leaveFrom={'opacity-100 scale-100'}
                        leaveTo={'opacity-0 scale-95'}
                    >
                        <Dialog.Panel
                            className={clsx(
                                'w-full transform overflow-hidden rounded-2xl bg-white',
                                'p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-dark-300',
                                className
                            )}
                            ref={ref}
                        >
                            {children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        );
    }
);