import { useReportingTech } from '../../hooks';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';

export const UserMenuButton = () => {
    const { hasReportingTech, reportingTechInitials } = useReportingTech();

    return (
        <Menu.Button
            disabled={!hasReportingTech}
            className={
                'disabled:cursor-normal keyboard-focus inline-flex w-full items-center justify-center rounded-full bg-transparent outline-none disabled:opacity-50'
            }
        >
            <div
                className={clsx(
                    'flex h-10 w-10 select-none items-center justify-center rounded-full xxs:h-12 xxs:w-12',
                    'bg-brand-500 text-xl text-gray-200 dark:bg-brand-100 dark:text-gray-200 xxs:text-xl',
                    'hover:bg-brand-600 hover:dark:bg-brand-200'
                )}
            >
                {reportingTechInitials}
            </div>
        </Menu.Button>
    );
};