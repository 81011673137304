import { useLocation, useNavigate } from 'react-router-dom';
import { ResourceLinks } from './ResourceLinks';
import { MRSMenuLink } from './MRSMenuLink';
import { PLCMenuLink } from './PLCMenuLink';
import { appRoutes } from '../../config';
import { UserMenu } from '../userMenu';
import { Button } from '../buttons';

interface IMenuBarProps {
    isRegister?: boolean;
}

export const MenuBar = ({ isRegister }: IMenuBarProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const onSignIn = () => navigate(appRoutes.signIn);

    return (
        <div className={'relative flex w-full bg-white py-2 dark:border-gray-600 dark:bg-gray-dark-800'}>
            <div className={'mb-1 flex w-full flex-row items-center justify-between'}>
                {/* PLC LOGO */}
                <PLCMenuLink
                    className={'hidden md:block'}
                    isDisabled={isRegister || location.pathname === appRoutes.dashboard}
                />

                <div className={'flex w-full flex-row items-center justify-between xs:justify-end'}>
                    {/* RESOURCE LINKS */}
                    {!isRegister && <ResourceLinks />}

                    {/* MRS LOGO */}
                    <MRSMenuLink isDisabled={isRegister || location.pathname === appRoutes.dashboard} />

                    {/* DIVIDER */}
                    <div className={'-mt-4 hidden text-6xl text-gray-300 dark:text-gray-600 xs:block'}>
                        &nbsp;|&nbsp;
                    </div>

                    {/* SIGN IN */}
                    {isRegister && (
                        <div className={'mr-2 mt-1'}>
                            <Button buttonProps={{ onPress: onSignIn }}>Sign In</Button>
                        </div>
                    )}

                    {/* USER MENU */}
                    {!isRegister && <UserMenu />}
                </div>
            </div>
        </div>
    );
};