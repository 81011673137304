import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useFocusRing } from '@react-aria/focus';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';

import type { IListItem } from '../../../types';
import type { FC } from 'react';

interface IListBoxButtonProps {
    isDisabled?: boolean;
    isOpen?: boolean;
    isRequired?: boolean;
    selectedItem: IListItem;
    selectedItems: Array<IListItem>;
}

export const ListBoxButton: FC<IListBoxButtonProps> = ({
    isDisabled,
    isOpen,
    isRequired,
    selectedItem,
    selectedItems,
}) => {
    const { focusProps, isFocusVisible } = useFocusRing();

    const noValue = !selectedItem && selectedItems.length === 0;

    return (
        <Listbox.Button
            {...focusProps}
            className={clsx(
                'relative w-full py-2 px-3',
                'h-10 border-0 border-b-2 border-transparent text-left text-sm outline-none sm:text-base',
                isFocusVisible ? 'ring-4 ring-brand-500 dark:ring-brand-100' : '',
                isDisabled
                    ? 'cursor-not-allowed bg-gray-100 text-gray-200 dark:bg-gray-500 '
                    : 'cursor-default dark:text-gray-200',
                isRequired && selectedItem.id === '0' && selectedItems.length === 0
                    ? 'bg-red-100 dark:bg-red-200/80 dark:text-gray-600'
                    : 'bg-gray-200 dark:bg-gray-dark-200 dark:text-gray-200'
            )}
        >
            <div className={'flex w-full justify-between'}>
                {/* SELECTED ITEM */}
                <span className={'block truncate font-normal'}>{selectedItem.name}</span>

                {/* ICON */}
                <span className={'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'}>
                    {
                        <ChevronDownIcon
                            className={clsx(
                                isDisabled ? 'text-gray-300 dark:text-gray-400' : 'text-gray-600',
                                'h-6 w-6',
                                isOpen ? 'rotate-18d0 duration-300' : 'duration-300',
                                isRequired && selectedItem.id === '0' && selectedItems.length === 0
                                    ? ' dark:text-gray-600'
                                    : 'dark:text-gray-200'
                            )}
                        />
                    }
                </span>
            </div>
        </Listbox.Button>
    );
};