import { usePhoneNumber, useTimeConversion } from '../../hooks';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { InputClearButton } from '../buttons';
import { useTextField } from 'react-aria';
import { Checkbox } from './Checkbox';
import { Tooltip } from '../tooltip';
import { useRef } from 'react';
import clsx from 'clsx';

import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { AriaTextFieldOptions } from 'react-aria';
import type { FCCN } from '../../types';

interface IInputProps {
    checkboxOptions?: AriaCheckboxProps;
    inputOptions?: AriaTextFieldOptions<'input'>;
    tooltip?: string;
    type?: 'number' | 'phone' | 'text' | 'time' | 'zip';
}

export const Input: FCCN<IInputProps> = ({ checkboxOptions, className, inputOptions, tooltip, type = 'text' }) => {
    let inputRef = useRef<HTMLInputElement | null>(null);
    const name = inputOptions?.name ?? '';

    const formContext = useFormContext();
    const formValue = formContext.watch(name);

    let { ref: formRef } = formContext.register(name);

    const { getMinutesFromHHMMSS, toHHMMSS } = useTimeConversion();
    const { formatPhoneNumber } = usePhoneNumber({});

    const { labelProps, inputProps, errorMessageProps } = useTextField(
        {
            ...inputOptions,
            onBlur: (event) => {
                if (type === 'time') {
                    if (!formValue) return;
                    const seconds: number = Math.max(0, getMinutesFromHHMMSS(formValue));
                    const newTime: string = toHHMMSS(parseInt(seconds.toString(), 10));
                    formContext.setValue(name, seconds === 0 ? '' : newTime);
                }
                inputOptions?.onBlur?.(event);
            },
            onChange: (value: string) => {
                // Number input.
                if (type === 'number') {
                    const strippedNumber = value.replace(/\D/g, '');
                    formContext.setValue(name, strippedNumber);
                }
                // Phone number input.
                else if (type === 'phone') {
                    const strippedPhone = value.replace(/\D/g, '');
                    formContext.setValue(name, strippedPhone);
                }
                // Hour/Minute (time) input.
                else if (type === 'time') {
                    formContext.setValue(name, value);
                }
                // Zip code input.
                else if (type === 'zip') {
                    const strippedZipCode = value.replace(/\D/g, '');
                    if (value?.length > 5) return;
                    formContext.setValue(name, strippedZipCode);
                }
                // Other inputs.
                else formContext.setValue(name, value);

                if (errorMessage) formContext.clearErrors(name);
                inputOptions?.onChange?.(value);
            },
            isDisabled: inputOptions?.isDisabled,
        },
        inputRef
    );

    const errorMessage: string = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasCheckbox: boolean = checkboxOptions !== undefined;
    const hasError: boolean = errorMessage.length > 0;
    const isClearShowing: boolean = !inputOptions?.isDisabled && !inputOptions?.isReadOnly && formValue;

    const inputValue: string =
        type === 'phone'
            ? formValue !== 'Unknown'
                ? formatPhoneNumber(formValue, '')
                : formValue ?? ''
            : formValue ?? '';

    const hasValue = inputValue.length > 0;

    return (
        <div className={'flex w-full flex-col'}>
            {/* LABEL */}
            <label {...labelProps}>
                <Tooltip hasError={hasError} isRequired={inputOptions?.isRequired} tooltip={tooltip}>
                    {inputOptions?.label}
                </Tooltip>
            </label>

            {/* INPUT FIELD */}
            <div className={'flex flex-row items-center'}>
                {/* INPUT */}
                <div className={'relative w-full'}>
                    <input
                        {...inputProps}
                        className={clsx(
                            'input-primary h-10',
                            inputOptions?.isDisabled && 'cursor-not-allowed text-gray-400 dark:text-gray-400',
                            hasError && 'input-error',
                            inputOptions?.isRequired && !hasValue && 'bg-red-100 dark:bg-red-200/80',
                            className
                        )}
                        disabled={inputOptions?.isDisabled}
                        ref={(ref) => {
                            if (ref === null) return;
                            inputRef.current = ref;
                            formRef(ref);
                        }}
                        value={inputValue}
                    />

                    {/* CLEAR BUTTON */}
                    {isClearShowing && (
                        <InputClearButton inputRef={inputRef} name={name} onChange={inputOptions?.onChange} />
                    )}
                </div>

                {/* UNKNOWN CHECKBOX */}
                {hasCheckbox && (
                    <div className={'-mt-1 pl-2'}>
                        <Checkbox hasError={hasError} options={checkboxOptions}>
                            <div className={'font-semibold'}>Unknown</div>
                        </Checkbox>
                    </div>
                )}
            </div>

            {/* ERROR MESSAGE */}
            {hasError && <ErrorMessage ariaProps={errorMessageProps}>{errorMessage}</ErrorMessage>}
        </div>
    );
};