import { BackgroundOverlay, Modal, ModalPanel, ModalTitle } from './modal';
import { IModalToggle } from '../../hooks';
import { Button } from '../buttons';
import { FCCCN } from '../../types';

interface IConfirmModalProps {
    isLoading?: boolean;
    isStatic?: boolean; // Static means the caller is controlling the close after confirm operation.
    allowCancel?: boolean;
    message: string;
    modalToggle: IModalToggle;
    onConfirm?: () => void;
    title: string;
}

export const ConfirmModal: FCCCN<IConfirmModalProps> = ({
    className,
    isLoading,
    isStatic,
    allowCancel = true,
    message,
    onConfirm,
    modalToggle,
    title,
}) => {
    const onConfirmClick = () => {
        onConfirm?.();
        if (isStatic) return;
        modalToggle.close();
    };

    return (
        <Modal modalToggle={modalToggle} onClose={isLoading ? () => {} : undefined}>
            <BackgroundOverlay />
            <ModalPanel className={className || 'max-w-md'}>
                <ModalTitle>{title}</ModalTitle>
                <div className={'mt-2'}>
                    <p className={'text-gray-800 dark:text-gray-200'}>{message}</p>
                </div>

                <div className={'mt-4 flex justify-end space-x-2'}>
                    {allowCancel &&
                        <Button buttonProps={{ isDisabled: isLoading, onPress: modalToggle.close }} variant={'ghost'}>
                            Cancel
                        </Button>
                    }

                    <Button buttonProps={{ isDisabled: isLoading, onPress: onConfirmClick }} isBusy={isLoading}>
                        Confirm
                    </Button>
                </div>
            </ModalPanel>
        </Modal>
    );
};