import { XMarkIcon } from '@heroicons/react/24/solid';
import { useFocusRing } from '@react-aria/focus';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

import type { IModalToggle } from '../../hooks';
import type { FC } from 'react';

interface IPanelHeaderProps {
    panelToggle: IModalToggle;
}

export const PanelHeader: FC<IPanelHeaderProps> = ({ panelToggle }) => {
    const { focusProps, isFocusVisible } = useFocusRing();

    return (
        <div className={'bg-brand-500 px-4 pt-3 pb-5 dark:bg-brand-100 sm:px-6'}>
            <div className={'flex items-center justify-between'}>
                {/* TITLE */}
                <Dialog.Title className={'text-lg font-medium text-white'}>Update Contact Information</Dialog.Title>

                {/* CLOSE BUTTON */}
                <div className={'ml-3 flex h-7 items-center'}>
                    <button
                        {...focusProps}
                        type={'button'}
                        className={clsx(
                            'rounded-md bg-brand-500 text-gray-200 hover:text-white dark:bg-brand-100',
                            'focus:ring-2 focus:ring-gray-200',
                            isFocusVisible ? 'outline-none ring-2 ring-gray-300' : ''
                        )}
                        onClick={panelToggle.close}
                    >
                        <span className={'sr-only'}>Close update contact info panel</span>
                        <XMarkIcon className={'h-6 w-6'} aria-hidden={'true'} />
                    </button>
                </div>
            </div>

            {/* SUBTEXT */}
            <div className={'mt-1'}>
                <p className={'text-sm text-gray-300'}>Please keep this information up to date.</p>
            </div>
        </div>
    );
};