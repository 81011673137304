import { useToggle } from './useToggle';

export interface IModalToggle {
    close: () => void;
    isClosed: boolean;
    isOpen: boolean;
    open: () => void;
    toggle: () => void;
}

export const useModalToggle = (defaultValue: boolean = false): IModalToggle => {
    const toggle = useToggle(defaultValue);

    return {
        close: () => toggle.off(),
        isClosed: toggle.isOff,
        isOpen: toggle.isOn,
        open: () => toggle.on(),
        toggle: () => toggle.toggle(),
    };
};