import { damageDetailsDefaultFormValues } from '../DamageDetails';
import { FormHeader, FormSection } from '../form';
import { useValidation } from '../../../../hooks';
import { claimFields } from '../../../../config';
import { ListBox } from '../../../../components';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

import type { IClaim, IClaimInit, IDamageFacilityType, IListItem } from '../../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface IFacilitiesProps {
    claimInit?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const Facilities: FC<IFacilitiesProps> = ({ claimInit, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const [isAerial, setIsAerial] = useState<boolean>(false);

    // Sort facilities alphabetically.
    const facilityTypes =
        claimInit?.damageFacilityTypeList?.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())) ??
        [];

    // Check if all facilities are aerial when first rendered so we can set the starting isAerial value.
    useEffect(() => {
        if (!claimInit) return;
        if ('claimDamageFacilityTypeList' in claimInit) {
            const facilities: Array<IDamageFacilityType> = [];
            claimInit.claimDamageFacilityTypeList.forEach((item) => {
                const facility = claimInit.damageFacilityTypeList.find(
                    (f) => Number((f as IDamageFacilityType).id) === item
                );
                if (facility) facilities.push(facility as IDamageFacilityType);
            });
            setIsAerial(facilities.every((item) => item.damageFacilityClassificationTypeId === 1));
        }
    }, []);

    const onMultiChange = (items: Array<IListItem>) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageFacilityTypes']);

        // Set 'Is span replacement needed?' to 'Yes' when fiber facility types are selected.
        const fibers = items.filter((item) => item.name === 'Aerial Fiber' || item.name === 'Buried Fiber');
        if (fibers.length > 0) {
            formContext.setValue(claimFields.wasSpanReplaced, true);
            formContext.setValue(claimFields.isSpanReplacementNeeded, true);
            setSaveAndQuitChanges?.((prev) => [
                ...prev,
                claimFields.isSpanReplacementNeeded,
                claimFields.wasSpanReplaced,
                claimFields.cableInConduit,
                claimFields.cableSize,
                claimFields.spanFootage,
            ]);
            formContext.clearErrors(claimFields.isSpanReplacementNeeded);
            formContext.clearErrors(claimFields.wasSpanReplaced);
        }

        // Check if every item is an aerial facility type.
        const isAllAerial: boolean =
            items.length > 0 &&
            items.every((item) => (item as IDamageFacilityType).damageFacilityClassificationTypeId === 1);

        // Set locate field values when all the facility types are aerial.
        if (isAllAerial && !isAerial) {
            setIsAerial(true);
            setSaveAndQuitChanges?.((prev) => [
                ...prev,
                claimFields.wereLocatesPresent,
                claimFields.wereLocatesPresentUnknown,
                claimFields.locateAccuracy,
                claimFields.locateMarkingFlags,
                claimFields.locateMarkingPaint,
                claimFields.locateMarkingStakes,
                'IsMarkingUnknown',
            ]);
            formContext.setValue(claimFields.wereLocatesPresent, '3'); // 3 = NA - Not Underground
            formContext.setValue(
                claimFields.locateAccuracy,
                damageDetailsDefaultFormValues[claimFields.locateAccuracy]
            );
            formContext.setValue(claimFields.locateMarkingFlags, false);
            formContext.setValue(claimFields.locateMarkingPaint, false);
            formContext.setValue(claimFields.locateMarkingStakes, false);
            formContext.setValue('isMarkingUnknown', false);
        }
        // Was all aerial and now it isn't anymore. Reset locate field values.
        else if (!isAllAerial && isAerial) {
            setSaveAndQuitChanges?.((prev) => [
                ...prev,
                claimFields.wereLocatesPresent,
                claimFields.wereLocatesPresentUnknown,
                claimFields.locateAccuracy,
                claimFields.locateMarkingFlags,
                claimFields.locateMarkingPaint,
                claimFields.locateMarkingStakes,
                'IsMarkingUnknown',
            ]);
            setIsAerial(false);
            formContext.setValue(claimFields.wereLocatesPresent, 0);
            formContext.setValue(claimFields.wereLocatesPresentUnknown, null);
        }
    };

    return (
        <FormSection>
            <FormHeader>Facilities</FormHeader>

            <div className={'mt-4'}>
                <ListBox
                    isMultiple
                    isRequired={fieldRequirements.DamageFacilityTypes}
                    label={'Facilities'}
                    listItems={facilityTypes}
                    name={claimFields.damageFacilityTypes}
                    onMultiChange={onMultiChange}
                    placeholder={'Select all that apply'}
                    tooltip={'What type of facilities were damaged?'}
                />
            </div>
        </FormSection>
    );
};