import { DamageDescription, Locates, Facilities, SpanReplacement } from './damage-details';
import { claimFields } from '../../../config';
import { FormPage } from './form';
import { useEffect } from 'react';

import type { IClaim, IClaimInit, IDamageDetailsFormValueTypes } from '../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

export const damageDetailsDefaultFormValues: IDamageDetailsFormValueTypes = {
    [claimFields.cableInConduit]: null,
    [claimFields.cableSize]: '',
    [claimFields.damageActivityType]: null,
    [claimFields.damageByType]: null,
    [claimFields.detailsOfDamageLoss]: '',
    [claimFields.estimatedSubsAffected]: '',
    [claimFields.damageFacilityTypes]: [],
    isEstimatedSubsAffectedUnknown: false,
    [claimFields.isSpanReplacementNeeded]: null,
    [claimFields.locateAccuracy]: 0,
    [claimFields.locateMarkingFlags]: false,
    [claimFields.locateMarkingPaint]: false,
    [claimFields.locateMarkingStakes]: false,
    isMarkingUnknown: false,
    isOutageDurationUnknown: false,
    isSpanFootageReplacedUnknown: false,
    isSpanOtherMaterialUnknown: false,
    [claimFields.outageDuration]: '',
    [claimFields.spanFootage]: null,
    [claimFields.wereLocatesPresent]: null,
};

export const damageDetailsChangeList: Array<string> = [
    'CableInConduit',
    'CableSize',
    'DamageActivityTypeId',
    'DamageByTypeId',
    'DamageFacilityTypes',
    'DetailsofDamageLoss',
    'IsSpanReplacementNeeded',
    'LocateAccuracyId',
    'LocateMarkings',
    'OutageDuration',
    'SpanFootage',
    'SubAffected',
    claimFields.wasSpanReplaced,
    claimFields.wereLocatesPresent,
    claimFields.wereLocatesPresentNA,
    claimFields.wereLocatesPresentUnknown,
];

interface IDamageDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamageDetails: FC<IDamageDetailsProps> = ({ claim, setSaveAndQuitChanges }) => {
    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormPage>
            {/* DAMAGE DESCRIPTION */}
            <DamageDescription claimInit={claim} setSaveAndQuitChanges={setSaveAndQuitChanges} />

            {/* FACILITIES */}
            <Facilities claimInit={claim} setSaveAndQuitChanges={setSaveAndQuitChanges} />

            {/* LOCATES */}
            <Locates claimInit={claim} setSaveAndQuitChanges={setSaveAndQuitChanges} />

            {/* SPAN REPLACEMENT */}
            <SpanReplacement setSaveAndQuitChanges={setSaveAndQuitChanges} />
        </FormPage>
    );
};