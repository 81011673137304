import { UseFormReturn } from 'react-hook-form';
import { Step } from './Step';
import clsx from 'clsx';

import type { IStep } from '../../../../types';
import type { FC } from 'react';

interface IStepsProps {
    activeStep: number;
    farthestStep: number;
    formContext: UseFormReturn;
    isSaving?: boolean;
    saveClaim: (isSaveQuit?: boolean, isStepSave?: boolean, selectedStep?: number) => void;
    setStep: (step: number) => void;
    steps: Array<IStep>;
}

export const Steps: FC<IStepsProps> = ({
    activeStep,
    farthestStep,
    formContext,
    isSaving,
    saveClaim,
    setStep,
    steps,
}) => {
    return (
        <ol className={'mb-2 ml-6 flex w-full flex-row pb-1 sm:ml-16 md:ml-24'} role={'claim form steps list'}>
            {steps.map((step: IStep, index: number) => (
                <div className={'flex w-full items-center'} key={index}>
                    <Step
                        activeStep={activeStep}
                        farthestStep={farthestStep}
                        formContext={formContext}
                        index={index}
                        isSaving={isSaving}
                        saveClaim={saveClaim}
                        setStep={setStep}
                        step={step}
                    />
                    {index !== steps.length - 1 && (
                        <div className={clsx('h-1 w-full', index < farthestStep ? 'bg-emerald-600' : '')} />
                    )}
                </div>
            ))}
        </ol>
    );
};