import { CheckIcon } from '@heroicons/react/24/solid';
import { claimFields } from '../../../../config';
import { Spinner } from '../../../../components';
import { UseFormReturn } from 'react-hook-form';
import { useButton } from '@react-aria/button';
import { useRef } from 'react';
import clsx from 'clsx';

import type { FCC, IStep } from '../../../../types';
import type { FC } from 'react';

interface IStepProps {
    activeStep: number;
    farthestStep: number;
    formContext: UseFormReturn;
    index: number;
    isSaving?: boolean;
    saveClaim: (isSaveQuit?: boolean, isStepSave?: boolean, selectedStep?: number) => void;
    setStep: (step: number) => void;
    step: IStep;
}

export const Step: FC<IStepProps> = ({
    activeStep,
    farthestStep,
    formContext,
    index,
    isSaving,
    saveClaim,
    setStep,
    step,
}) => {
    const isActive = index === activeStep;
    const isValidated = index < farthestStep && index !== activeStep;
    const isInvalid = index >= farthestStep && index !== activeStep;
    const lastStep = index === farthestStep && index !== activeStep;

    const onClick = async () => {
        if (!isValidated && !lastStep) return;

        if (activeStep === 0 && farthestStep !== 0) {
            await formContext
                .trigger(
                    [
                        claimFields.claimNode,
                        claimFields.damageStreetAddress,
                        claimFields.damageCrossStreet,
                        claimFields.damageCity,
                        claimFields.damageState,
                        claimFields.damageZipCode,
                        claimFields.damageDate,
                        claimFields.discoveryDate,
                        claimFields.remedyId,
                        claimFields.facilityNumber,
                        claimFields.depthOfDamage,
                    ],
                    { shouldFocus: true }
                )
                .then((isValid) => (isValid ? saveClaim(false, true, index) : null));
        } else if (activeStep === 1) {
            setStep(index);
        } else if (activeStep === 2 && farthestStep !== 2) {
            await formContext
                .trigger(
                    [
                        claimFields.detailsOfDamageLoss,
                        claimFields.estimatedSubsAffected,
                        claimFields.outageDuration,
                        claimFields.damageActivityType,
                        claimFields.damageByType,
                        claimFields.damageFacilityTypes,
                        claimFields.wereLocatesPresent,
                        claimFields.wereLocatesPresentUnknown,
                        claimFields.locateAccuracy,
                        claimFields.locateMarking,
                        claimFields.locateMarkingFlags,
                        claimFields.locateMarkingPaint,
                        claimFields.locateMarkingStakes,
                        claimFields.wasSpanReplaced,
                        claimFields.spanFootage,
                        claimFields.isSpanFootageUnknown,
                        claimFields.cableInConduit,
                        claimFields.cableSize,
                    ],
                    { shouldFocus: true }
                )
                .then((isValid) => (isValid ? saveClaim(false, true, index) : null));
        } else if (activeStep === 2) {
            saveClaim(false, true, index);
        } else if (activeStep === 3 && farthestStep !== 3) {
            formContext
                .trigger(
                    [
                        claimFields.opinionOnResponsibleParty,
                        claimFields.responsibleForDamage,
                        claimFields.whoWasDamager,
                        claimFields.damagerName,
                        claimFields.damagerIdentified,
                        claimFields.haveDamagerContactDetails,
                        claimFields.damagerContactName,
                        claimFields.damagerPhoneNumber,
                        claimFields.damagerEmailAddress,
                        claimFields.damagerStatement,
                    ],
                    { shouldFocus: true }
                )
                .then((isValid) => (isValid ? saveClaim(false, true, index) : null));
        } else if (activeStep === 3) {
            saveClaim(false, true, index);
        } else if (activeStep === 4 && farthestStep !== 4) {
            formContext
                .trigger(
                    [
                        claimFields.repairsDoneOnSite,
                        claimFields.legacyClaimNo,
                        claimFields.areFutureRepairsNeeded,
                        claimFields.clientConstructionNumber,
                        claimFields.isFreeFormRepairEntry,
                    ],
                    { shouldFocus: true }
                )
                .then((isValid) => (isValid ? saveClaim(false, true, index) : null));
        } else if (activeStep === 4) {
            saveClaim(false, true, index);
        } else setStep(index);
    };

    const buttonRef = useRef<HTMLDivElement | null>(null);
    const { buttonProps } = useButton({ isDisabled: isSaving, onPress: onClick }, buttonRef);

    const stepNumber = index + 1;

    return (
        <li className={'relative'}>
            {/* VALIDATED */}
            {isValidated && (
                <div
                    {...buttonProps}
                    className={clsx(
                        'flex h-8 w-8 items-center justify-center rounded-full bg-emerald-600',
                        isSaving ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-emerald-500'
                    )}
                >
                    <CheckIcon aria-hidden={'true'} className={'h-5 w-5 text-white'} />
                    <span className={'sr-only'}>{step.label}</span>
                    <StyledLabel>
                        <ShortLabel>{step.shortLabel}</ShortLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <LongLabel>{step.label}</LongLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <NumberLabel>{stepNumber}</NumberLabel>
                    </StyledLabel>
                </div>
            )}

            {/* ACTIVE */}
            {isActive && (
                <div
                    aria-current={'step'}
                    className={
                        'flex h-8 w-8 items-center justify-center rounded-full border-2 border-emerald-600 bg-white dark:bg-gray-400'
                    }
                >
                    {!isSaving && <span className={'h-2.5 w-2.5 rounded-full bg-emerald-600'} aria-hidden={'true'} />}
                    {isSaving && <Spinner className={'h-6 w-7 text-emerald-600'} />}
                    <span className={'sr-only'}>{step.label}</span>
                    <StyledLabel>
                        <ShortLabel>{step.shortLabel}</ShortLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <LongLabel>{step.label}</LongLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <NumberLabel>{stepNumber}</NumberLabel>
                    </StyledLabel>
                </div>
            )}

            {/* INVALID */}
            {isInvalid && !lastStep && (
                <div
                    className={clsx(
                        'flex h-8 w-8 items-center justify-center',
                        'rounded-full  border-2 border-gray-300 bg-white',
                        'dark:border-gray-500 dark:bg-gray-400'
                    )}
                >
                    <span aria-hidden={'true'} className={'h-2.5 w-2.5 rounded-full bg-transparent'} />
                    <span className={'sr-only'}>{step.label}</span>
                    <StyledLabel>
                        <ShortLabel>{step.shortLabel}</ShortLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <LongLabel>{step.label}</LongLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <NumberLabel>{stepNumber}</NumberLabel>
                    </StyledLabel>
                </div>
            )}

            {/* FARTHEST STEP */}
            {lastStep && (
                <div
                    {...buttonProps}
                    className={clsx(
                        'flex h-8 w-8 items-center justify-center rounded-full bg-emerald-600',
                        isSaving ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-emerald-500'
                    )}
                >
                    <span aria-hidden={'true'} className={'h-2.5 w-2.5 rounded-full bg-transparent'} />
                    <span className={'sr-only'}>{step.label}</span>
                    <StyledLabel>
                        <ShortLabel>{step.shortLabel}</ShortLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <LongLabel>{step.label}</LongLabel>
                    </StyledLabel>
                    <StyledLabel>
                        <NumberLabel>{stepNumber}</NumberLabel>
                    </StyledLabel>
                </div>
            )}
        </li>
    );
};

const StyledLabel: FCC = ({ children }) => {
    return <div className={clsx('absolute whitespace-nowrap pt-14 font-semibold dark:text-gray-200')}>{children}</div>;
};

const ShortLabel: FCC = ({ children }) => {
    return <span className={'hidden sm:block lg:hidden'}>{children}</span>;
};

const LongLabel: FCC = ({ children }) => {
    return <span className={'hidden lg:block'}>{children}</span>;
};

const NumberLabel: FCC = ({ children }) => {
    return <span className={'block sm:hidden'}>{children}</span>;
};