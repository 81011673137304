import { Button, StyledTableCell, Table, TableContainer } from '../../../../components';
import { Cell, Column, Row, TableBody, TableHeader } from '@react-stately/table';
import { EmptyMessage } from './EmptyMessage';

import type { IUpdatePersonnelResponse } from '../../../../types';
import type { FC } from 'react';

interface IPersonnelTableProps {
    onRemove: (index: string) => void;
    personnel: Array<IUpdatePersonnelResponse>;
}

export const PersonnelTable: FC<IPersonnelTableProps> = ({ onRemove, personnel }) => {
    return (
        <TableContainer className={'w-full'}>
            <Table aria-label={'Added personnel types table'} selectionMode={'single'}>
                {/* HEADER */}
                <TableHeader>
                    <Column>
                        <StyledTableCell isHeader>Position</StyledTableCell>
                    </Column>
                    <Column>
                        <StyledTableCell isHeader isNumeric>
                            Time
                        </StyledTableCell>
                    </Column>
                    <Column>
                        <StyledTableCell isHeader isNumeric>
                            Overtime
                        </StyledTableCell>
                    </Column>
                    <Column>{''}</Column>
                </TableHeader>

                {/* BODY */}
                <TableBody>
                    {personnel.map((p, i) => (
                        <Row key={i}>
                            <Cell>
                                <StyledTableCell>{p.title}</StyledTableCell>
                            </Cell>
                            <Cell>
                                <StyledTableCell isNumeric>{p.regular}</StyledTableCell>
                            </Cell>
                            <Cell>
                                <StyledTableCell isNumeric>{p.overtime}</StyledTableCell>
                            </Cell>
                            <Cell>
                                <StyledTableCell isNumeric>
                                    <Button
                                        buttonProps={{ onPress: () => onRemove(p.itemId) }}
                                        size={'sm'}
                                        variant={'link'}
                                    >
                                        Remove
                                    </Button>
                                </StyledTableCell>
                            </Cell>
                        </Row>
                    ))}
                </TableBody>
            </Table>

            {/* EMPTY MESSAGE */}
            {personnel.length === 0 && <EmptyMessage>No Personnel Added</EmptyMessage>}
        </TableContainer>
    );
};