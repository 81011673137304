import type { IListItem } from '../../../types';
import type { FC } from 'react';

interface IListBoxMultiSelectedProps {
    onRemove: (id: string) => void;
    selectedItems: Array<IListItem>;
}

export const ListBoxMultiSelected: FC<IListBoxMultiSelectedProps> = ({ onRemove, selectedItems }) => {
    return (
        <div className={'mt-0.5 flex-wrap space-y-1 pb-1'}>
            {selectedItems.map((item: IListItem) => {
                return (
                    <span
                        className={
                            'mr-0.5 inline-flex items-center rounded-full bg-brand-500 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-200 dark:bg-brand-100 dark:text-gray-200'
                        }
                        key={item.id}
                    >
                        {item.name}
                        <button
                            className={
                                'ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full active:bg-red-600 active:outline-none active:dark:bg-brand-200'
                            }
                            onClick={() => onRemove(item.id)}
                            type={'button'}
                        >
                            <span className={'sr-only'}>Remove {item.name} option</span>
                            <svg className={'h-2 w-2'} fill={'none'} stroke={'currentColor'} viewBox={'0 0 8 8'}>
                                <path d={'M1 1l6 6m0-6L1 7'} strokeLinecap={'round'} strokeWidth={'1.5'} />
                            </svg>
                        </button>
                    </span>
                );
            })}
        </div>
    );
};