import { Button, ConfirmModal } from '../../../../components';
import { useModalToggle } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

import type { IToggle } from '../../../../hooks';
import type { IFile } from '../../../../types';
import type { FC } from 'react';
import { FileWithDescription } from '../UploadPhotosAndDocs';

interface IUploadedFilesProps {
    files: IFile[];
    isRemoving: IToggle;
    isUploading: IToggle;
    onRemove: (id: number) => void;
}

export const UploadedFiles: FC<IUploadedFilesProps> = ({ files, isRemoving, isUploading, onRemove }) => {
    const [fileToRemove, setFileToRemove] = useState<IFile | null>(null);

    const confirmRemoveToggle = useModalToggle();

    const onRemoveClick = (file: IFile) => {
        setFileToRemove(file);
        confirmRemoveToggle.open();
    };

    const onRemoveConfirmed = () => {
        if (fileToRemove === null) return;
        onRemove(fileToRemove.id);
    };

    return (
        <div className={'mt-2 flex flex-col space-y-2.5'}>
            {/* UPLOADED FILES */}
            {files.map((file, index) => {
                return (
                    <div
                        className={'spacing-x-2 items-center font-medium text-gray-800 dark:text-gray-200'}
                        key={index}
                    >
                        <Button
                            busyText={'Removing'}
                            buttonProps={{
                                isDisabled: isRemoving.isOn || isUploading.isOn,
                                onPress: () => onRemoveClick(file),
                            }}
                            isBusy={file.id === fileToRemove?.id ? isRemoving.isOn : false}
                            size={'xs'}
                            variant={'outline'}
                        >
                            <span className={'sr-only'}>Remove {file.displayName} option</span>
                            <span className={'text-xs'}>Remove</span>
                        </Button>
                        <span className={'mx-2'}>{file.displayName}</span>
                        <span className={'mr-2'}>{file.note && file.note.length > 0 ? '-' : ''}</span>
                        <span className={'mr-2'}>{file.note}</span>
                    </div>
                );
            })}

            {/* CONFIRM REMOVE FILE */}
            <ConfirmModal
                className={'max-w-lg'}
                message={`Are you sure you want to remove "${fileToRemove?.displayName}"?`}
                modalToggle={confirmRemoveToggle}
                onConfirm={onRemoveConfirmed}
                title={'Confirm Remove'}
            />
        </div>
    );
};