import { Checkbox, ErrorMessage, Tooltip } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';

interface ILocateMarkingProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const LocateMarkings: FC<ILocateMarkingProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const errorMessage = formContext.formState.errors[claimFields.locateMarkingFlags]?.message?.toString() ?? '';
    const hasError = errorMessage.length > 0;

    const isMarkingUnknown: boolean = formContext.watch('isMarkingUnknown');
    const isMarkingFlags: boolean = formContext.watch(claimFields.locateMarkingFlags);
    const isMarkingPaints: boolean = formContext.watch(claimFields.locateMarkingPaint);
    const isMarkingStakes: boolean = formContext.watch(claimFields.locateMarkingStakes);

    const onIsMarkingUnknownChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LocateMarkings']);
        formContext.setValue(claimFields.locateMarkingFlags, false);
        formContext.setValue(claimFields.locateMarkingPaint, false);
        formContext.setValue(claimFields.locateMarkingStakes, false);
        formContext.setValue('isMarkingUnknown', checked);
        if (checked) clearErrors();
    };

    const onMarkingFlagsChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LocateMarkings']);
        formContext.setValue(claimFields.locateMarkingFlags, checked);
        formContext.setValue('isMarkingUnknown', false);
        if (checked) clearErrors();
    };

    const onMarkingPaintsChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LocateMarkings']);
        formContext.setValue(claimFields.locateMarkingPaint, checked);
        formContext.setValue('isMarkingUnknown', false);
        if (checked) clearErrors();
    };

    const onMarkingStakesChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LocateMarkings']);
        formContext.setValue(claimFields.locateMarkingStakes, checked);
        formContext.setValue('isMarkingUnknown', false);
        if (checked) clearErrors();
    };

    const clearErrors = () => {
        formContext.clearErrors('isMarkingUnknown');
        formContext.clearErrors(claimFields.locateMarkingFlags);
        formContext.clearErrors(claimFields.locateMarkingPaint);
        formContext.clearErrors(claimFields.locateMarkingStakes);
    };

    const isMarkingEmpty = !isMarkingStakes && !isMarkingPaints && !isMarkingFlags && !isMarkingUnknown;

    return (
        <>
            {/* LOCATE MARKINGS */}
            <div className={'flex flex-col items-start'}>
                <label>
                    <Tooltip
                        hasError={hasError}
                        isRequired={fieldRequirements.LocateMarking}
                        tooltip={'How was the locate marked?'}
                    >
                        Locate Markings
                    </Tooltip>
                </label>

                {/* CHECKBOXES */}
                <div className={'flex flex-col'}>
                    <div className={'flex w-full space-x-4'}>
                        {/* FLAGS */}
                        <Checkbox
                            hasError={hasError}
                            options={{
                                'aria-label': 'Marking flags',
                                isSelected: isMarkingFlags,
                                name: claimFields.locateMarkingFlags,
                                onChange: onMarkingFlagsChange,
                            }}
                            showRequired={isMarkingEmpty}
                        >
                            Flags
                        </Checkbox>

                        {/* PAINT */}
                        <Checkbox
                            hasError={hasError}
                            options={{
                                'aria-label': 'Marking paints',
                                isSelected: isMarkingPaints,
                                name: claimFields.locateMarkingPaint,
                                onChange: onMarkingPaintsChange,
                            }}
                            showRequired={isMarkingEmpty}
                        >
                            Paint
                        </Checkbox>

                        {/* STAKES */}
                        <Checkbox
                            hasError={hasError}
                            options={{
                                'aria-label': 'Marking stakes',
                                isSelected: isMarkingStakes,
                                name: claimFields.locateMarkingStakes,
                                onChange: onMarkingStakesChange,
                            }}
                            showRequired={isMarkingEmpty}
                        >
                            Stakes
                        </Checkbox>

                        {/* UNKNOWN */}
                        <Checkbox
                            hasError={hasError}
                            options={{
                                'aria-label': 'Marking unknown',
                                isSelected: isMarkingUnknown,
                                name: 'locateMarkingUnknown',
                                onChange: onIsMarkingUnknownChange,
                            }}
                            showRequired={isMarkingEmpty}
                        >
                            Unknown
                        </Checkbox>
                    </div>

                    {/* ERROR MESSAGE */}
                    {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </div>
            </div>
        </>
    );
};
