import { Button, ConfirmModal, ControlledComboBox, Duration, Input } from '../../../../components';
import { useApi, useModalToggle, useReportingTech } from '../../../../hooks';
import useDebounce from '../../../../hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { PersonnelTable } from './PersonnelTable';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

import type {
    IGetPersonnelRequest,
    IGetPersonnelResponse,
    IUpdatePersonnelRequest,
    IUpdatePersonnelResponse,
} from '../../../../types';

export interface IPersonnelType {
    id: string;
    name: string;
}

export const Personnel = () => {
    const formContext = useFormContext();
    const { getPersonnel, postPersonnel } = useApi();
    const { reportingTech } = useReportingTech();

    const [idToRemove, setIdToRemove] = useState<string>();
    const [mappedPersonnel, setMappedPersonnel] = useState<Array<IPersonnelType>>([]);
    const [query, setQuery] = useState<string>();
    const [selectedPersonnel, setSelectedPersonnel] = useState<IPersonnelType | null>(null);

    const debouncedQuery = useDebounce(query, 400);

    const removeModal = useModalToggle();

    const allPersonnel: Array<IUpdatePersonnelResponse> = formContext.watch('allPersonnel');

    const hasPersonnel = (selectedPersonnel?.name.length ?? -1) <= 0;

    const onPersonnelQuerySuccess = (data: IGetPersonnelResponse) => {
        if (data === null || data?.results === null) return;
        // Have to map the fetched data because it comes back super goofy from the endpoint.
        setMappedPersonnel(
            data?.results?.map((result: string) => {
                const idIndex: number = result?.indexOf('^');
                if (idIndex === -1) return { id: '', name: '' };
                return {
                    id: result?.substring(idIndex + 1),
                    name: result?.substring(0, idIndex),
                } as IPersonnelType;
            })
        );
    };

    const { isLoading: isFetchingPersonnel, mutate: searchPersonnel } = useMutation({
        mutationFn: getPersonnel,
        onSuccess: onPersonnelQuerySuccess,
    });

    const onPersonnelMutationSuccess = (data: IUpdatePersonnelResponse | null) => {
        if (data === null || data?.name === null || data.name.length === 0) return;
        setSelectedPersonnel(null);
        setMappedPersonnel([]);
        formContext?.setValue('allPersonnel', [...formContext?.getValues('allPersonnel'), data]);
        formContext?.setValue('personnelOverTime', '0:00');
        formContext?.setValue('personnelRegularTime', '0:00');
    };

    const { isLoading: isUpdatingPersonnel, mutate: updatePersonnel } = useMutation({
        mutationFn: postPersonnel,
        onSuccess: onPersonnelMutationSuccess,
    });

    useEffect(() => {
        if (!query) return;
        searchPersonnel({
            clientId: reportingTech?.clientId?.toString() ?? '-1',
            marketId: formContext.getValues('marketId'),
            prefixText: query,
        } as IGetPersonnelRequest);
    }, [debouncedQuery]);

    const onPersonnelTypeChange = (search: string) => setQuery(search);

    const onPersonnelTypeSelect = (p: IPersonnelType | null) => setSelectedPersonnel(p);

    const onAddPersonnel = () => {
        const regular: Array<null | string> | undefined = formContext.getValues('personnelRegularTime')?.split(':');
        const overtime: Array<null | string> | undefined = formContext.getValues('personnelOverTime')?.split(':');

        const model: IUpdatePersonnelRequest = {
            action: 'save',
            claimId: formContext.getValues('claimId'),
            date: Date.now().toString(),
            id: selectedPersonnel?.id ?? '0',
            name: selectedPersonnel?.name ?? '',
            overtime: overtime ? `${overtime[0] ?? 0} hrs,${overtime[1] ?? 0} min` : '0 hrs,0 min',
            regular: regular ? `${regular[0] ?? 0} hrs,${regular[1] ?? 0} min` : '0 hrs,0 min',
        };

        updatePersonnel(model);
    };

    const onRemovePersonnel = (itemId: string) => {
        setIdToRemove(itemId);
        removeModal.open();
    };

    const onRemovePersonnelConfirm = () => {
        const filteredPersonnel = allPersonnel.filter((p) => p.itemId !== idToRemove);
        formContext?.setValue('allPersonnel', [...filteredPersonnel]);

        const model: IUpdatePersonnelRequest = {
            action: 'delete',
            claimId: formContext.getValues('claimId'),
            date: '',
            id: idToRemove ?? '-1',
            name: '',
            overtime: '',
            regular: '',
        };

        updatePersonnel(model);
    };

    return (
        <div className={'flex flex-col items-baseline space-y-2'}>
            {/* PERSONNEL COMBOBOX */}
            <ControlledComboBox
                debounce={debouncedQuery}
                displayKey={'name'}
                idKey={'id'}
                isLoading={isFetchingPersonnel}
                items={mappedPersonnel}
                label={'Personnel Type'}
                onChange={onPersonnelTypeChange}
                onSelect={onPersonnelTypeSelect}
                placeholder={'Start typing to see personnel types...'}
                selectedItem={selectedPersonnel}
                setSelectedItem={setSelectedPersonnel}
                tooltip={
                    'how many persons worked this repair?, i.e.,10/18/21 Paul Jones, OSP Tech, 1-hour\n' +
                    'regular time and 2 hours on overtime.'
                }
            />

            <div className={'flex w-full flex-col sm:flex-row sm:space-x-2'}>
                {/* REGULAR HOURS */}
                <Duration
                    label={'Time'}
                    name={'personnelRegularTime'}
                    tooltip={'How many non-overtime hours did the technician(s) work?'}
                />

                {/* OVERTIME HOURS */}
                <Duration
                    label={'Overtime'}
                    name={'personnelOverTime'}
                    tooltip={'How many overtime hours did the technician(s) work?'}
                />

                {/* ADD A PERSONNEL TYPE  */}
                <div className={'flex pt-2 sm:pt-0'}>
                    <Button
                        buttonProps={{ isDisabled: hasPersonnel, onPress: onAddPersonnel }}
                        className={'w-full place-self-end'}
                        isBusy={isUpdatingPersonnel}
                    >
                        Add
                    </Button>
                </div>
            </div>

            {/* PERSONNEL TABLE */}
            <PersonnelTable onRemove={onRemovePersonnel} personnel={allPersonnel} />

            {/* CONFIRM REMOVE MODAL */}
            <ConfirmModal
                message={'Are you sure you want to delete this personnel?'}
                modalToggle={removeModal}
                onConfirm={onRemovePersonnelConfirm}
                title={'Confirm Delete'}
            />
        </div>
    );
};