import { useFocusRing } from '@react-aria/focus';
import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';
import clsx from 'clsx';

import type { AriaButtonProps } from '@react-aria/button';
import type { FCC } from '../../../types';
interface ICalendarButtonProps {
    ariaButtonProps: AriaButtonProps<'button'>;
}

export const CalendarButton: FCC<ICalendarButtonProps> = ({ ariaButtonProps, children }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { buttonProps, isPressed } = useButton(ariaButtonProps, buttonRef);

    const { focusProps, isFocusVisible } = useFocusRing();

    return (
        <button
            {...mergeProps(buttonProps, focusProps)}
            className={clsx(
                'rounded-lg bg-gray-200 p-2 outline-none dark:bg-gray-600',
                ariaButtonProps.isDisabled ? 'text-gray-400' : 'hover:bg-gray-300 hover:dark:bg-gray-700 ',
                isFocusVisible && 'ring-4 ring-brand-500 dark:ring-brand-100',
                isPressed && 'active:bg-gray-300 active:dark:bg-gray-800'
            )}
            ref={buttonRef}
        >
            {children}
        </button>
    );
};