import { useMutation, useQuery } from '@tanstack/react-query';
import { ReportingTechContext } from '../context';
import { useApi, useToggle } from '../hooks';
import { useMsal } from '@azure/msal-react';
import { queryKeys } from '../config';

import type { FCC, IReportingTech } from '../types';

export const ReportingTechProvider: FCC = ({ children }) => {
    const { getReportingTech, updateMobileUser } = useApi();

    const loginToggle = useToggle();

    const onQuerySettled = (response: IReportingTech | null) => {
        if (!response) return;

        // Don't update a null LastLoginDate on MobileUser, null means it is a MRS1 transferred account and
        // LastLoginDate needs to stay null to trigger the profile information validation modal. We will update
        // LastLoginDate after they confirm valid profile information.
        if (loginToggle.isOff && response?.mobileUser && response.mobileUser?.lastLogin !== null) updateLastLogin();
        loginToggle.on();
    };

    const { data } = useQuery({
        onSettled: onQuerySettled,
        queryKey: [queryKeys.reportingTech],
        queryFn: getReportingTech,
        refetchOnWindowFocus: false,
    });

    const { mutate: updateLastLogin } = useMutation({
        mutationFn: updateMobileUser,
    });

    const isProfileConfirmed = data?.mobileUser?.lastLogin !== null;
    const reportingTech: IReportingTech | null = data ?? null;
    const reportingTechEmail = data?.emailAddress ?? '';
    const reportingTechInitials = data
        ? `${data?.firstName?.slice(0, 1).toUpperCase()}${data?.lastName?.slice(0, 1).toUpperCase()}`
        : '';
    const reportingTechName = data ? `${data?.firstName} ${data?.lastName}` : '';
    const reportingTechPhone = data ? data.phoneNumber.replace(/\D/g, '') : '';

    return (
        <ReportingTechContext.Provider
            value={{
                isProfileConfirmed,
                reportingTech,
                reportingTechEmail,
                reportingTechInitials,
                reportingTechName,
                reportingTechPhone,
            }}
        >
            {children}
        </ReportingTechContext.Provider>
    );
};