import { CheckIcon } from '@heroicons/react/20/solid';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';

import type { IListItem } from '../../../types';
import type { FC } from 'react';

interface IListBoxOptionsProps {
    addOptionsClassName?: string;
    isOpen?: boolean;
    isRequired?: boolean;
    listItems?: Array<IListItem>;
}

export const ListBoxOptions: FC<IListBoxOptionsProps> = ({
    addOptionsClassName,
    isOpen,
    isRequired,
    listItems = [],
}) => {
    const internalListItems = isRequired ? [...listItems] : [{ id: null, name: 'Select an option' }, ...listItems];

    return (
        <Listbox.Options
            className={clsx(
                'absolute max-h-80 bg-gray-50 focus:outline-none dark:bg-gray-600',
                'mt-1 w-full overflow-auto rounded-md py-1 text-sm shadow-md',
                isOpen ? 'z-20' : '',
                addOptionsClassName
            )}
        >
            {internalListItems?.map((listItem) => (
                <Listbox.Option
                    className={clsx(
                        'ui-active:bg-brand-500 ui-active:text-white ui-active:dark:bg-brand-100',
                        'relative cursor-pointer select-none py-2 pl-10 pr-4'
                    )}
                    key={listItem.id}
                    value={listItem}
                >
                    {({ selected }) => (
                        <>
                            {/* ITEM NAME */}
                            <span
                                className={clsx(
                                    'block truncate dark:text-gray-200',
                                    'font-normal ui-selected:font-normal'
                                )}
                            >
                                {listItem.name}
                            </span>

                            {/* SELECTED ITEM */}
                            {selected ? (
                                <span
                                    className={
                                        'absolute inset-y-0 left-0 flex items-center pl-3 text-amber-500 dark:text-amber-400'
                                    }
                                >
                                    <CheckIcon aria-hidden={'true'} className={'h-5 w-5'} />
                                </span>
                            ) : null}
                        </>
                    )}
                </Listbox.Option>
            ))}
        </Listbox.Options>
    );
};
