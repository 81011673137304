import { useIsAuthenticated } from '@azure/msal-react';
import { ProtectedRoutes } from './ProtectedRoutes';
import { PublicRoutes } from './PublicRoutes';
import { useRoutes } from 'react-router-dom';

import type { IRoute } from '../types';

export const AppRoutes = () => {
    const isAuthenticated = useIsAuthenticated();

    const routes: Array<IRoute> = isAuthenticated ? ProtectedRoutes : PublicRoutes;

    const element = useRoutes([...routes]);

    return <>{element}</>;
};