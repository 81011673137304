import { PublicClientApplication } from '@azure/msal-browser';
import { AppProvider, ColorModeProvider } from './providers';
import { OverlayContainer } from '@react-aria/overlays';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config';
import { AppRoutes } from './routes';
import * as Yup from 'yup';
import React from 'react';

import type { MessageParams } from 'yup/es/types';

const App = () => {
    const publicClientApplication = new PublicClientApplication(msalConfig);

    // Yup validation locale.
    Yup.setLocale({
        mixed: {
            required: (params: MessageParams) => `${params.path} is required.`,
        },
    });

    return (
        <ColorModeProvider>
            <MsalProvider instance={publicClientApplication}>
                <OverlayContainer>
                    <AppProvider>
                        <AppRoutes />
                    </AppProvider>
                </OverlayContainer>
            </MsalProvider>
        </ColorModeProvider>
    );
};
export default App;