import { FormFields, FormHeader, FormSection } from '../form';
import { Input, YesNoButton } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { Dispatch, FC, SetStateAction } from 'react';

interface IRepairsProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const Repairs: FC<IRepairsProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const wereRepairsDoneOnSite: boolean | null = formContext.watch(claimFields.repairsDoneOnSite);

    const onWereRepairsDoneOnSiteNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.legacyClaimNo,
            'ClaimComments',
            claimFields.repairsDoneOnSite,
        ]);
        formContext.setValue(claimFields.legacyClaimNo, '');
        formContext.setValue(claimFields.claimComments, '');
        formContext.clearErrors('');
    };

    const onWereRepairsDoneOnSiteYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.repairsDoneOnSite]);
    };

    const onReferenceNumberInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LegacyClaimNo', claimFields.repairsDoneOnSite]);
        formContext.setValue(claimFields.repairsDoneOnSite, true);
        formContext.clearErrors(claimFields.repairsDoneOnSite);
    };

    return (
        <FormSection>
            <FormHeader>Repairs</FormHeader>
            <FormFields>
                {/* WERE REPAIRS DONE ONSITE? */}
                <YesNoButton
                    isRequired
                    isSelected={wereRepairsDoneOnSite}
                    labelText={'Have onsite repairs been started/completed?'}
                    tooltip={'Have onsite repairs been started/completed, or do you have labor to report?'}
                    name={claimFields.repairsDoneOnSite}
                    onNoClick={onWereRepairsDoneOnSiteNoClick}
                    onYesClick={onWereRepairsDoneOnSiteYesClick}
                />

                {wereRepairsDoneOnSite !== false && (
                    <>
                        {/* REFERENCE */}
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.LegacyClaimNo,
                                label: 'Reference / Construction Job / Other Internal Job Reference #',
                                name: claimFields.legacyClaimNo,
                                onChange: onReferenceNumberInputChange,
                            }}
                            tooltip={
                                'If there is any other internal company\n' +
                                'reference number associated with this damage, such as a job number for the construction department\n' +
                                'to do a span replacement, enter it here.'
                            }
                        />
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};