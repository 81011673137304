import { PressEvent } from '@react-types/shared';
import { useButton } from '@react-aria/button';
import { MouseEvent, useRef } from 'react';
import { Menu } from '@headlessui/react';
import { FCC } from '../../types';
import clsx from 'clsx';

interface IUserMenuItemProps {
    isDisabled?: boolean;
    isStatic?: boolean;
    onClick?: (e: PressEvent) => void;
}

export const UserMenuItem: FCC<IUserMenuItemProps> = ({ children, isDisabled, isStatic, onClick }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { buttonProps } = useButton({ isDisabled: isDisabled, onPress: onClick }, buttonRef);

    const onClicked = (e: MouseEvent) => {
        if (isStatic) e.preventDefault();
    };

    if (onClick)
        return (
            <Menu.Item>
                {({ active }) => (
                    <button
                        {...buttonProps}
                        className={clsx(
                            'flex h-full w-full cursor-pointer px-4 py-2 text-left text-sm leading-5',
                            'outline-none focus:bg-brand-500 focus:text-white focus:dark:bg-brand-100',
                            active ? 'bg-brand-500 text-gray-200 dark:bg-brand-100' : '',
                            isDisabled ? 'cursor-not-allowed text-gray-400 opacity-50 dark:text-gray-200' : ''
                        )}
                        onClick={onClicked}
                        ref={buttonRef}
                    >
                        {children}
                    </button>
                )}
            </Menu.Item>
        );

    return (
        <Menu.Item>
            {({ active }) => (
                <div
                    className={clsx(
                        'flex h-full w-full cursor-pointer px-4 py-2 text-left text-sm leading-5',
                        'focus:bg-brand-500 focus:text-white focus:dark:bg-brand-100',
                        active ? 'bg-brand-500 text-white dark:bg-brand-100' : '',
                        isDisabled ? 'cursor-not-allowed text-gray-400 opacity-50 dark:text-gray-200' : ''
                    )}
                >
                    {children}
                </div>
            )}
        </Menu.Item>
    );
};