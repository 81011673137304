import { Address, Times, InternalIdentifications, DamageType } from './damage-location';
import { claimFields } from '../../../config';
import { FormPage } from './form';
import { useEffect } from 'react';

import type { IClaim, IClaimInit, IDamageLocationFormValueTypes } from '../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

export const damageLocationDefaultFormValues: IDamageLocationFormValueTypes = {
    [claimFields.claimHub]: '',
    [claimFields.claimNode]: '',
    [claimFields.damageStreetAddress]: '',
    [claimFields.damageCity]: '',
    [claimFields.damageCrossStreet]: '',
    [claimFields.damageDate]: null,
    [claimFields.damageLocationType]: null,
    [claimFields.damageZipCode]: '',
    [claimFields.damageState]: 0,
    damageTime: null,
    [claimFields.discoveryDate]: null,
    damageDiscoveryTime: null,
    [claimFields.depthOfDamage]: '',
    [claimFields.facilityNumber]: '',
    [claimFields.wasDamageAerial]: null,
    isDamageDateUnknown: false,
    [claimFields.wasDamageUnderground]: null,
    isDepthOfDamageUnknown: false,
    isDiscoveryDateSame: false,
    isFacilityNumberUnknown: false,
    isHubUnknown: false,
    isNodeUnknown: false,
    isOutageIdUnknown: false,
    [claimFields.remedyId]: null,
};

export const damageLocationChangeList: Array<string> = [
    'ClaimHub',
    'ClaimNode',
    'DamageAddress1',
    'DamageCity',
    'DamageDate',
    'DamageTime',
    'DamageDiscoveryDate',
    'DamageDiscoveryTime',
    'DamageCrossStreet',
    'DamageLocationTypeId',
    'DamagePostalCode',
    'DamageState',
    'DamageTimeStampUnknown',
    'DepthOfDamage',
    'FacilityNumber',
    'RemedyID',
    claimFields.wasDamageAerial,
    claimFields.wasDamageUnderground,
    claimFields.wereLocatesPresent,
    claimFields.wereLocatesPresentNA,
];

interface IDamageLocationProps {
    claim?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamageLocation: FC<IDamageLocationProps> = ({ claim, setSaveAndQuitChanges }) => {
    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormPage>
            {/* DAMAGE AND DISCOVERY TIMES */}
            <Times setSaveAndQuitChanges={setSaveAndQuitChanges} />

            {/* ADDRESS  */}
            <Address setSaveAndQuitChanges={setSaveAndQuitChanges} states={claim?.stateList ?? []} />

            {/* DAMAGE TYPE */}
            <DamageType
                locationTypes={claim?.damageLocationTypeList ?? []}
                setSaveAndQuitChanges={setSaveAndQuitChanges}
            />

            {/* INTERNAL IDENTIFICATIONS */}
            <InternalIdentifications setSaveAndQuitChanges={setSaveAndQuitChanges} />
        </FormPage>
    );
};