import { useDateSegment } from '@react-aria/datepicker';
import { useEffect, useRef } from 'react';
import clsx from 'clsx';

import type { DateFieldState, DateSegment as DateSeg } from '@react-stately/datepicker';
import type { IToggle } from '../../../hooks';
import type { FC } from 'react';

interface IDateSegmentProps {
    hasValue: IToggle;
    isDisabled?: boolean;
    segment: DateSeg;
    state: DateFieldState;
}

export const DateSegment: FC<IDateSegmentProps> = ({ hasValue, isDisabled, segment, state }) => {
    const segmentRef = useRef<HTMLDivElement>(null);
    const { segmentProps } = useDateSegment(segment, state, segmentRef);

    useEffect(() => {
        if (segmentProps['aria-valuetext'] !== undefined && segmentProps['aria-valuetext'] !== 'Empty') hasValue.on();
    }, [segmentProps]);

    return (
        <div
            {...segmentProps}
            className={clsx(
                'box-content rounded-md p-0.5 px-[2px] pr-1 text-right outline-none focus:bg-brand-500',
                'focus:text-white focus:dark:bg-brand-100',
                segment.isPlaceholder && segment.isEditable && !isDisabled && 'text-gray-900',
                isDisabled ? 'text-gray-300 dark:text-gray-500' : 'text-gray-600 dark:text-gray-200',
                state.isRequired && !state.value && 'dark:text-gray-800'
            )}
            ref={segmentRef}
        >
            {segment.text}
        </div>
    );
};
