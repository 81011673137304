import { createContext } from 'react';

interface IColorModeContext {
    disable: () => void;
    enable: () => void;
    isDarkMode: boolean;
    isLightMode: boolean;
    toggle: () => void;
}

export const ColorModeContext = createContext<IColorModeContext>({
    isDarkMode: false,
    isLightMode: true,
    disable: () => null,
    enable: () => null,
    toggle: () => null,
});
