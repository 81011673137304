import { useTableColumnHeader } from '@react-aria/table';
import { useRef } from 'react';
import clsx from 'clsx';

interface ITableColumnHeaderProps {
    column: any;
    state: any;
}

export const TableColumnHeader = ({ column, state }: ITableColumnHeaderProps) => {
    const thRef = useRef<HTMLTableCellElement>(null);
    const { columnHeaderProps } = useTableColumnHeader({ node: column }, state, thRef);

    return (
        <th
            {...columnHeaderProps}
            className={clsx(
                'sticky top-0 z-10 p-2 text-center text-xs font-semibold outline-none sm:text-sm',
                'bg-gray-600 uppercase tracking-wide text-gray-200 dark:bg-gray-700 dark:text-white sm:tracking-normal'
            )}
            colSpan={column.colSpan}
            ref={thRef}
        >
            {column.rendered}
            {/* SORT ICON */}
            {column.props.allowsSorting && (
                <span
                    aria-hidden={'true'}
                    className={clsx('px-0 py-1', state.sortDescriptor?.column === column.key ? 'visible' : 'invisible')}
                >
                    {state.sortDescriptor?.direction === 'ascending' ? '▲' : '▼'}
                </span>
            )}
        </th>
    );
};