import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { useTextField } from 'react-aria';
import { Tooltip } from '../tooltip';
import { useRef } from 'react';
import clsx from 'clsx';

import type { AriaTextFieldOptions } from 'react-aria';
import type { FCCN } from '../../types';

interface ITextAreaProps {
    options?: AriaTextFieldOptions<'input'>;
    tooltip?: string;
}

export const TextArea: FCCN<ITextAreaProps> = ({ className, options, tooltip }) => {
    const formContext = useFormContext();

    const formValue = formContext.watch(options?.name ?? '');
    const errorMessage = formContext.formState.errors[options?.name ?? 'unknown']?.message?.toString() ?? '';
    const hasError: boolean = errorMessage.length > 0;

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const { ref: formRef } = formContext.register(options?.name ?? 'textarea');

    const { errorMessageProps, labelProps, inputProps } = useTextField(
        {
            ...options,
            inputElementType: 'textarea',
            onChange: (value: string) => {
                options?.onChange?.(value);
                formContext.setValue(options?.name ?? '', value);
                formContext.clearErrors(options?.name ?? '');
            },
        },
        textAreaRef
    );

    return (
        <div className={'w-full'}>
            {/* LABEL */}
            <label {...labelProps}>
                <Tooltip hasError={hasError} isRequired={options?.isRequired} tooltip={tooltip}>
                    {options?.label}
                </Tooltip>
            </label>

            {/* INPUT */}
            <textarea
                {...inputProps}
                className={clsx(
                    'input-primary min-h-[6rem]',
                    hasError && 'input-error',
                    options?.isRequired && !formValue && 'bg-red-100 dark:bg-red-200/80',
                    className
                )}
                ref={(ref) => {
                    textAreaRef.current = ref;
                    formRef(ref);
                }}
                value={formValue ?? ''}
            />

            {/* ERROR MESSAGE */}
            {hasError && <ErrorMessage ariaProps={errorMessageProps}>{errorMessage}</ErrorMessage>}
        </div>
    );
};