import { useLayoutEffect, useRef, useState } from 'react';
import { useFocusRing } from '@react-aria/focus';
import { TooltipIcon } from './TooltipIcon';
import { usePress } from 'react-aria';
import clsx from 'clsx';

interface ITooltipButtonProps {
    isError?: boolean;
    tooltip?: string;
}

export const TooltipButton = ({ isError, tooltip }: ITooltipButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const iconRef = useRef<HTMLSpanElement | null>(null);
    const panelRef = useRef<HTMLDivElement | null>(null);

    const { pressProps } = usePress({
        onPress: (e) => {
            // Don't allow clicks when using hover (mouse/keyboard) tooltips
            if (e.pointerType === 'mouse') return;
            // Tapped the icon. If showing, hide. If hiding, show.
            setIsVisible((prev) => !prev);
        },
        ref: buttonRef,
    });

    const { isFocusVisible, focusProps } = useFocusRing();

    const [isVisible, setIsVisible] = useState(false);

    useLayoutEffect(() => {
        if (!isVisible) return;

        // Get the position of the right side of the icon.
        const parentLeft = panelRef.current?.parentElement?.getBoundingClientRect().left ?? 0;
        const parentWidth = panelRef.current?.parentElement?.getBoundingClientRect().width ?? 0;
        const parentRight = parentLeft + parentWidth;

        // Get the position of the right side of the panel relative to the right side of the icon.
        const panelWidth = panelRef.current?.getBoundingClientRect().width ?? 0;
        const panelRight = parentRight + panelWidth;

        // Width of the browser window.
        const screenWidth = window.screen.width;

        const tooltipOffset = screenWidth - panelRight;

        // Negative offset is offscreen.
        const isAnyShowingOffScreen = tooltipOffset < 0;

        if (isAnyShowingOffScreen) panelRef.current?.setAttribute('style', `left: ${tooltipOffset}px`);
    }, [isVisible]);

    return (
        <span className={'relative outline-none'} ref={iconRef}>
            {/* TOOLTIP ICON */}
            <button
                {...focusProps}
                {...pressProps}
                className={clsx(
                    isFocusVisible && 'rounded-full outline-none ring-4 ring-brand-500 dark:ring-brand-100',
                    'focus:outline-none'
                )}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                onBlur={(e) => {
                    focusProps?.onBlur?.(e);
                    setIsVisible(false);
                }}
                ref={buttonRef}
                role={'tooltip'}
                type={'button'}
            >
                <TooltipIcon isError={isError} />
            </button>

            {/* TOOLTIP PANEL */}
            <div
                className={clsx(
                    isVisible ? 'block' : 'hidden',
                    'absolute rounded-md bg-white text-sm text-black shadow-xl dark:bg-gray-700 dark:text-white',
                    'z-50 ml-1 min-w-[250px] max-w-[450px] border border-gray-200 p-2 focus:outline-none dark:border-gray-500'
                )}
                ref={panelRef}
            >
                {tooltip}
            </div>
        </span>
    );
};