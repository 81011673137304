import { useTableRow } from '@react-aria/table';
import { FCC } from '../../types';
import { useRef } from 'react';
import clsx from 'clsx';

interface ITableRowProps {
    item: any;
    state: any;
}

export const TableRow: FCC<ITableRowProps> = ({ children, item, state }) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const { rowProps } = useTableRow({ node: item }, state, ref);

    const isSelected = state.selectionManager.isSelected(item.key);

    return (
        <tr
            {...rowProps}
            className={clsx(
                'text-gray-800 dark:text-gray-200',
                isSelected
                    ? 'bg-brand-500 text-gray-100 dark:bg-brand-100 dark:text-gray-200'
                    : 'bg-white dark:bg-gray-dark-500',
                'outline-none focus-visible:-outline-offset-2 focus-visible:outline-brand-500'
            )}
            ref={ref}
        >
            {children}
        </tr>
    );
};