import { FCCCN } from '../../types';
import clsx from 'clsx';

interface IStyleTableCellProps {
    isHeader?: boolean;
    isNumeric?: boolean;
}

export const StyledSummaryTableCell: FCCCN<IStyleTableCellProps> = ({ children, className, isHeader, isNumeric }) => {
    if (isHeader)
        return (
            <th
                className={clsx(
                    'border py-0.5 px-2 font-medium dark:text-gray-100',
                    isNumeric ? 'text-end' : 'text-start',
                    className
                )}
            >
                {children}
            </th>
        );

    return (
        <td className={clsx('border py-0.5 px-2 dark:text-gray-100', isNumeric ? 'text-end' : 'text-start', className)}>
            {children}
        </td>
    );
};