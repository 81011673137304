import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

// TODO: Add Spinner

export const Loader = () => {
    return (
        <Suspense
            fallback={
                <div className={'flex h-full w-full items-center justify-center'}>
                    <></>
                </div>
            }
        >
            <Outlet />
        </Suspense>
    );
};
