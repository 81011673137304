import { useTableCell } from '@react-aria/table';
import { useRef } from 'react';
import clsx from 'clsx';

interface ITableCellProps {
    cell: any;
    state: any;
}

export const TableCell = ({ cell, state }: ITableCellProps) => {
    const ref = useRef<HTMLTableCellElement>(null);
    const { gridCellProps } = useTableCell({ node: cell }, state, ref);

    return (
        <td
            {...gridCellProps}
            className={clsx(
                'dark whitespace-nowrap text-sm dark:border-gray-600',
                'focus-visible:-outline-offset-2 focus-visible:outline-brand-500 focus-visible:dark:outline-brand-100'
            )}
            ref={ref}
        >
            {cell.rendered}
        </td>
    );
};