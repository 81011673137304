import { forwardRef, useImperativeHandle, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useButton } from '@react-aria/button';
import clsx from 'clsx';

import type { PressEvent } from '@react-types/shared';
import type { FCCCNFRH } from '../../types';
import type { ForwardedRef } from 'react';

interface IIconButtonProps {
    onPress?: (e: PressEvent) => void;
}

interface IIconButtonHandle {
    focus?: (options?: FocusOptions | undefined) => void;
    scrollIntoView?: (options?: boolean | ScrollIntoViewOptions) => void;
}

export const IconButton: FCCCNFRH<IIconButtonProps, IIconButtonHandle> = forwardRef(
    ({ children, className, onPress }, ref: ForwardedRef<HTMLButtonElement>) => {
        const buttonRef = useRef<HTMLButtonElement>(null);

        const { buttonProps } = useButton(
            {
                onPress: onPress,
            },
            buttonRef
        );

        useImperativeHandle(
            ref,
            () => {
                return {
                    focus(options?: FocusOptions) {
                        buttonRef.current?.focus(options);
                    },
                    scrollIntoView(options?: boolean | ScrollIntoViewOptions) {
                        buttonRef.current?.scrollIntoView(options);
                    },
                } as HTMLButtonElement;
            },
            [buttonRef, ref]
        );

        return (
            <button
                {...buttonProps}
                className={clsx(
                    'flex cursor-pointer items-center text-gray-500 outline-none active:scale-95 dark:text-gray-200',
                    'keyboard-focus focus-visible:rounded-sm hover:text-gray-400 hover:dark:text-gray-400',
                    className
                )}
                ref={ref}
            >
                {children || <XMarkIcon className={'h-6 w-6'} />}
            </button>
        );
    }
);