import { FormFields, FormHeader, FormSection } from '../form';
import { UndergroundDamage } from './UndergroundDamage';
import { useValidation } from '../../../../hooks';
import { ListBox } from '../../../../components';
import { claimFields } from '../../../../config';
import { AerialDamage } from './AerialDamage';

import type { Dispatch, FC, SetStateAction } from 'react';
import type { ILocationType } from '../../../../types';

interface IDamageTypeProps {
    locationTypes: Array<ILocationType>;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamageType: FC<IDamageTypeProps> = ({ locationTypes, setSaveAndQuitChanges }) => {
    const { fieldRequirements } = useValidation({});

    return (
        <FormSection>
            <FormHeader>Damage Type</FormHeader>

            <FormFields>
                {/* LOCATION TYPE */}
                <ListBox
                    isRequired={fieldRequirements.DamageLocationType}
                    label={'Location'}
                    listItems={locationTypes}
                    name={claimFields.damageLocationType}
                    onSingleChange={(_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageLocationTypeId'])}
                    tooltip={
                        'What is the location type? For example, did the damage occur in the right of way,\n' +
                        'under roadway, etc. ?'
                    }
                />

                {/* AERIAL DAMAGE */}
                <AerialDamage setSaveAndQuitChanges={setSaveAndQuitChanges} />

                {/* UNDERGROUND DAMAGE */}
                <UndergroundDamage setSaveAndQuitChanges={setSaveAndQuitChanges} />
            </FormFields>
        </FormSection>
    );
};