import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

import type { FCCCN } from '../types';

export const Warning: FCCCN = ({ children, className }) => {
    return (
        <div
            className={clsx(
                'flex w-full items-center border border-gray-300 bg-orange-200 p-1 text-sm font-medium dark:border-gray-500 dark:bg-[#3e3d3c] dark:text-gray-100 sm:text-base',
                className
            )}
        >
            <div>
                <ExclamationCircleIcon className={'mr-2 h-7 w-7 text-orange-600 dark:text-[#fcd28d]'} />
            </div>
            {children}
        </div>
    );
};