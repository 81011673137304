import { useOverlay, useModal, DismissButton } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';

import type { AriaDialogProps } from '@react-aria/dialog';
import type { FCC } from '../../../types';

interface IPopoverProps {
    dialog: AriaDialogProps;
    isOpen: boolean;
    onClose: () => void;
}

export const Popover: FCC<IPopoverProps> = ({ children, dialog, isOpen, onClose }) => {
    const popoverRef = useRef<HTMLDivElement>(null);

    const { overlayProps } = useOverlay(
        {
            isDismissable: true,
            isOpen,
            onClose,
            shouldCloseOnBlur: true,
        },
        popoverRef
    );
    const { modalProps } = useModal();
    const { dialogProps } = useDialog(dialog, popoverRef);

    return (
        <FocusScope autoFocus contain>
            <div
                {...mergeProps(overlayProps, modalProps, dialogProps)}
                className={
                    'absolute left-0 top-1 z-30 rounded-md border border-gray-300 bg-white p-1 shadow-lg dark:border-gray-500 dark:bg-gray-dark-500'
                }
                ref={popoverRef}
            >
                {children}
                <DismissButton onDismiss={onClose} />
            </div>
        </FocusScope>
    );
};