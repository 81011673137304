import { Button, ConfirmModal } from '../../../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModalToggle } from '../../../../hooks';
import { FormProvider } from 'react-hook-form';
import { appRoutes } from '../../../../config';
import clsx from 'clsx';

import type { UseFormReturn } from 'react-hook-form';
import type { IToggle } from '../../../../hooks';
import type { IStep } from '../../../../types';
import type { FC } from 'react';

export interface IButtonBarProps {
    activeStep: number;
    disableButtonToggle: IToggle;
    formContext: UseFormReturn;
    isSaving: boolean;
    isSavingAndQuiting: boolean;
    onComplete: () => void;
    onNext: () => void;
    onSaveAndQuit: () => void;
    prevStep: () => void;
    steps: Array<IStep>;
}

export const ButtonBar: FC<IButtonBarProps> = ({
    activeStep,
    disableButtonToggle,
    formContext,
    isSaving,
    isSavingAndQuiting,
    onComplete,
    onNext,
    onSaveAndQuit,
    prevStep,
    steps,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const onNextClick = () => {
        // Validate the current step before moving to the next.
        formContext.trigger().then((isValid: boolean) => {
            // Not valid.
            if (!isValid) {
                const formErrors = formContext.formState.errors;
                // Find the error with the highest position in the form (if possible, nested inputs will have 0 for offsetTop).
                let highestError: HTMLElement | null = null;
                let index = '';
                Object.keys(formErrors).forEach((key) => {
                    // Get the element.
                    const formElement = formErrors[key]?.ref as HTMLElement;
                    // Check offset from the top.
                    if (formElement && (!highestError || formElement.offsetTop > highestError.offsetTop)) {
                        highestError = formElement;
                        index = key;
                    }
                });

                // Scroll and focus the errored field (if possible).
                if (index) {
                    const formElement = formErrors[index]?.ref as HTMLElement;
                    if (formElement.scrollIntoView) formElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    if (formElement.focus) formElement.focus();
                }
            } else if (activeStep !== steps.length - 1) onNext(); // Not the last step.
            else confirmSubmitModalToggle.open(); // Last step.
        });
    };

    const onCancelClick = () => {
        if (location.pathname === appRoutes.dashboard) navigate(appRoutes.dashboard);
        else confirmDashboardNavigateModalToggle.open();
    };

    const onCancelConfirmed = () => navigate(appRoutes.dashboard);

    const confirmSubmitModalToggle = useModalToggle();
    const confirmSaveAndQuitModalToggle = useModalToggle();
    const confirmDashboardNavigateModalToggle = useModalToggle();

    const isDisabled: boolean = isSaving || isSavingAndQuiting || disableButtonToggle.isOn;

    return (
        <FormProvider {...formContext}>
            <div className={'p-5'}>
                <div className={'flex flex-col items-center'}>
                    <div className={'grid gap-2 xxs:grid-cols-2 sm:grid-cols-4'}>
                        {/* CANCEL */}
                        <Button
                            buttonProps={{
                                'aria-label': 'cancel claim entry',
                                isDisabled: isDisabled,
                                onPress: onCancelClick,
                            }}
                            variant={'ghost'}
                            className={'w-36'}
                        >
                            Exit
                        </Button>

                        {/* SAVE AND QUIT */}
                        <Button
                            buttonProps={{
                                'aria-label': 'save and quit',
                                isDisabled: isDisabled,
                                onPress: confirmSaveAndQuitModalToggle.open,
                            }}
                            isBusy={isSavingAndQuiting}
                            variant={'ghost'}
                            className={'w-36'}
                        >
                            Save & Quit
                        </Button>

                        {/* PREV BUTTON */}
                        <Button
                            buttonProps={{
                                'aria-label': 'previous step',
                                isDisabled: activeStep === 0 || isDisabled,
                                onPress: prevStep,
                            }}
                            className={'group w-36'}
                            variant={'outline'}
                        >
                            <div className={'flex w-full flex-row items-center transition duration-200 ease-in-out'}>
                                {activeStep !== 0 && (
                                    <span
                                        aria-hidden={'true'}
                                        className={clsx(
                                            '-mt-0.5 inline-block translate-x-0 transition-transform duration-200 ease-in-out',
                                            !isDisabled && activeStep !== 0 && 'group-hover:-translate-x-1'
                                        )}
                                    >
                                        &lt;
                                    </span>
                                )}
                                &nbsp;Previous
                            </div>
                        </Button>

                        {/* NEXT/FINISH BUTTON */}
                        <Button
                            buttonProps={{
                                'aria-label': activeStep === steps.length - 1 ? 'complete claim' : 'next step',
                                isDisabled: isDisabled,
                                onPress: onNextClick,
                            }}
                            className={'group w-36'}
                            isBusy={isSaving}
                        >
                            <div
                                className={
                                    'flex w-full flex-row items-center justify-center transition duration-200 ease-in-out'
                                }
                            >
                                {activeStep === steps.length - 1 ? 'Complete' : 'Next'}&nbsp;
                                {activeStep !== steps.length - 1 && (
                                    <span
                                        aria-hidden={'true'}
                                        className={clsx(
                                            '-mt-0.5 inline-block translate-x-0 transition-transform duration-200 ease-in-out',
                                            { 'group-hover:translate-x-1': !isDisabled }
                                        )}
                                    >
                                        &gt;
                                    </span>
                                )}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>

            {/* CONFIRM SUBMIT CLAIM */}
            <ConfirmModal
                message={'Are you sure you want to submit this claim?'}
                modalToggle={confirmSubmitModalToggle}
                onConfirm={onComplete}
                title={'Confirm Submit'}
            />

            {/* CONFIRM SAVE AND QUIT */}
            <ConfirmModal
                message={'Are you sure you want to save this claim and return to the dashboard?'}
                modalToggle={confirmSaveAndQuitModalToggle}
                onConfirm={onSaveAndQuit}
                title={'Confirm Save & Quit'}
            />

            {/* CONFIRM CANCEL */}
            <ConfirmModal
                message={'Are you sure you want to return to the dashboard?'}
                modalToggle={confirmDashboardNavigateModalToggle}
                onConfirm={onCancelConfirmed}
                title={'Return to Dashboard?'}
            />
        </FormProvider>
    );
};