import { accessTokenRequest, apiEndpoints, appRoutes } from '../config';
import { useErrorHandler } from 'react-error-boundary';
import { useErrorHandling } from './useErrorHandling';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

import type { AxiosInstance, AxiosResponse } from 'axios';
import type { AccountInfo } from '@azure/msal-browser';
import type {
    IClaim,
    IClaimInit,
    IRegisterReportingTechRequest,
    IRegisterReportingTechResponse,
    IReportingTech,
    ISaveClaimRequest,
    ISaveClaimResponse,
    ITechDashboardResponse,
    IUploadClaimFilesResponse,
    IUpdatePersonnelRequest,
    IUpdatePersonnelResponse,
    IUpdateMaterialRequest,
    IUpdateMaterialResponse,
    IUpdateReportingTechRequest,
    IUpdateReportingTechResponse,
    IUpdateVehicleRequest,
    IUpdateVehicleResponse,
    IVerifyReferenceNumberRequest,
    IVerifyReferenceNumberResponse,
    IVerifyTechEmailAddressRequest,
    IVerifyTechEmailAddressResponse,
    IGetPersonnelRequest,
    IGetPersonnelResponse,
    IGetMaterialResponse,
    IGetMaterialRequest,
    IGetVehicleResponse,
    IGetVehicleRequest,
    IGetOfficesResponse,
    IGetMarketsResponse,
    IGetClientsResponse,
    IDeleteClaimFileResponse,
    IDeleteClaimFileRequest,
    IUpdateMobileUserResponse,
    IClaimFieldConfig,
    ISearchRequest,
    IReportingTechSupervisor,
} from '../types';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { ConstructionCoordinatorsResponse } from '../types/response/constructionCoordinatorsResponse';

interface IUseApiReturn {
    deleteClaimFile: (data: IDeleteClaimFileRequest) => Promise<IDeleteClaimFileResponse | null>;
    getAllVisibleClients: () => Promise<IGetClientsResponse | null>;
    getClaim: (claimId: string, signal?: AbortSignal) => Promise<IClaim | null>;
    getClaimFieldConfig: (id: number, signal?: AbortSignal) => Promise<Array<IClaimFieldConfig> | null>;
    getClaimInit: (marketId: string, officeId: string, signal?: AbortSignal) => Promise<IClaimInit | null>;
    getClientByDomain: (domain: string, signal?: AbortSignal) => Promise<IGetClientsResponse | null>;
    getConstructionCoordinatorSearch: (
        clientId: number,
        searchText: string,
        signal?: AbortSignal
    ) => Promise<ConstructionCoordinatorsResponse>;
    getMarketsByClientId: (clientId: string, signal?: AbortSignal) => Promise<IGetMarketsResponse | null>;
    getMaterial: (data: IGetMaterialRequest) => Promise<IGetMaterialResponse | null>;
    getOfficesByMarketId: (marketId: string, signal?: AbortSignal) => Promise<IGetOfficesResponse | null>;
    getPersonnel: (data: IGetPersonnelRequest) => Promise<IGetPersonnelResponse | null>;
    getReportingTech: () => Promise<IReportingTech | null>;
    getReportingTechDashboard: (signal?: AbortSignal) => Promise<ITechDashboardResponse | null>;
    getReportingTechSupervisorSearch: (
        data: ISearchRequest,
        signal?: AbortSignal
    ) => Promise<Array<IReportingTechSupervisor> | null>;
    getVehicle: (data: IGetVehicleRequest) => Promise<IGetVehicleResponse | null>;
    postClaim: (data: ISaveClaimRequest) => Promise<ISaveClaimResponse | null>;
    postPersonnel: (data: IUpdatePersonnelRequest) => Promise<IUpdatePersonnelResponse | null>;
    postMaterial: (data: IUpdateMaterialRequest) => Promise<IUpdateMaterialResponse | null>;
    postVehicle: (data: IUpdateVehicleRequest) => Promise<IUpdateVehicleResponse | null>;
    postReportingTech: (data: IUpdateReportingTechRequest) => Promise<IUpdateReportingTechResponse | null>;
    registerReportingTech: (data: IRegisterReportingTechRequest) => Promise<IRegisterReportingTechResponse | null>;
    tokenRequest: ITokenRequest;
    updateMobileUser: () => Promise<IUpdateMobileUserResponse | null>;
    uploadClaimFiles: (data: FormData) => Promise<IUploadClaimFilesResponse | null>;
    verifyTechEmailAddress: (data: IVerifyTechEmailAddressRequest) => Promise<IVerifyTechEmailAddressResponse | null>;
    verifyTechReferenceNumber: (data: IVerifyReferenceNumberRequest) => Promise<IVerifyReferenceNumberResponse | null>;
}

interface ITokenRequest {
    account: AccountInfo;
    forceRefresh: boolean;
    scopes: string[];
}

const api: AxiosInstance = axios.create({
    baseURL: apiEndpoints.baseURL,
});

export const useApi = (): IUseApiReturn => {
    const { accounts, instance } = useMsal();
    const { handleApiError } = useErrorHandling();
    const handleError = useErrorHandler();

    const tokenRequest = {
        ...accessTokenRequest,
        account: accounts[0],
    };

    const requestToken = async (): Promise<string | undefined> => {
        let token;

        try {
            const tokenResponse = await instance.acquireTokenSilent(tokenRequest);
            token = tokenResponse.accessToken;
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                await instance
                    .acquireTokenPopup(accessTokenRequest)
                    .then((tokenResponse) => {
                        token = tokenResponse.accessToken;
                    })
                    .catch(() => {
                        instance.logoutRedirect({ postLogoutRedirectUri: appRoutes.signIn }).catch(handleApiError);
                    });
            } else handleError(err as Error);
        }

        return token;
    };

    const deleteClaimFile = async (data: IDeleteClaimFileRequest): Promise<IDeleteClaimFileResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IDeleteClaimFileResponse | null> = await api.post(
                apiEndpoints.deleteClaimFile,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getAllVisibleClients = async (): Promise<IGetClientsResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IGetClientsResponse | null> = await api.get(apiEndpoints.getAllClients);
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getClaim = async (claimId: string, signal?: AbortSignal): Promise<IClaim | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IClaim | null> = await api.get(apiEndpoints.getClaim(claimId), {
                headers: { Authorization: `Bearer ${token}` },
                signal,
            });
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getClaimFieldConfig = async (id: number, signal?: AbortSignal): Promise<Array<IClaimFieldConfig> | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<Array<IClaimFieldConfig> | null> = await api.get(
                apiEndpoints.getClaimFieldConfig(id),
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal,
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getClaimInit = async (
        marketId: string,
        officeId: string,
        signal?: AbortSignal
    ): Promise<IClaimInit | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IClaimInit | null> = await api.get(
                apiEndpoints.getClaimInit(marketId, officeId),
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal,
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getClientByDomain = async (domain: string, signal?: AbortSignal): Promise<IGetClientsResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IGetClientsResponse | null> = await api.get(
                apiEndpoints.getClientDomain(domain),
                { signal }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getConstructionCoordinatorSearch = async (
        clientId: number,
        searchText: string,
        signal?: AbortSignal
    ): Promise<ConstructionCoordinatorsResponse> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<ConstructionCoordinatorsResponse> = await api.get(
                apiEndpoints.getConstructionCoordinatorSearch(clientId, searchText),
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal,
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return { constructionCoordinators: [] };
        }
    };

    const getMarketsByClientId = async (
        clientId: string,
        signal?: AbortSignal
    ): Promise<IGetMarketsResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IGetMarketsResponse | null> = await api.get(
                apiEndpoints.getMarketsByClientId(clientId),
                { signal }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getMaterial = async (data: IGetMaterialRequest): Promise<IGetMaterialResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IGetMaterialResponse | null> = await api.post(
                apiEndpoints.getMaterialSearch,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getOfficesByMarketId = async (
        marketId: string,
        signal?: AbortSignal
    ): Promise<IGetOfficesResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IGetOfficesResponse | null> = await api.get(
                apiEndpoints.getOfficesByMarketId(marketId),
                { signal }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getPersonnel = async (data: IGetPersonnelRequest): Promise<IGetPersonnelResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IGetPersonnelResponse | null> = await api.post(
                apiEndpoints.getLaborSearch,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getReportingTech = async (): Promise<IReportingTech | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IReportingTech | null> = await api.get(apiEndpoints.getReportingTech, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return apiResponse.data;
        } catch (err) {
            handleError(err as Error);
            return null;
        }
    };

    const getReportingTechDashboard = async (signal?: AbortSignal): Promise<ITechDashboardResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<ITechDashboardResponse | null> = await api.get(
                apiEndpoints.getTechDashboard,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    signal,
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getReportingTechSupervisorSearch = async (
        data: ISearchRequest,
        signal?: AbortSignal
    ): Promise<Array<IReportingTechSupervisor> | null> => {
        try {
            const apiResponse: AxiosResponse<Array<IReportingTechSupervisor> | null> = await api.get(
                apiEndpoints.getReportingTechSupervisorSearch(data.clientId, data.prefixText),
                { signal }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const getVehicle = async (data: IGetVehicleRequest): Promise<IGetVehicleResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IGetVehicleResponse | null> = await api.post(
                apiEndpoints.getVehicleSearch,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const postClaim = async (data: ISaveClaimRequest): Promise<ISaveClaimResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<ISaveClaimResponse | null> = await api.post(
                apiEndpoints.postSaveClaim,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const postPersonnel = async (data: IUpdatePersonnelRequest): Promise<IUpdatePersonnelResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUpdatePersonnelResponse | null> = await api.post(
                apiEndpoints.postLabor,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const postMaterial = async (data: IUpdateMaterialRequest): Promise<IUpdateMaterialResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUpdateMaterialResponse | null> = await api.post(
                apiEndpoints.postMaterial,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const postReportingTech = async (
        data: IUpdateReportingTechRequest
    ): Promise<IUpdateReportingTechResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUpdateReportingTechResponse | null> = await api.post(
                apiEndpoints.postReportingTech,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const postVehicle = async (data: IUpdateVehicleRequest): Promise<IUpdateVehicleResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUpdateVehicleResponse | null> = await api.post(
                apiEndpoints.postVehicle,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const registerReportingTech = async (
        data: IRegisterReportingTechRequest
    ): Promise<IRegisterReportingTechResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IRegisterReportingTechResponse | null> = await api.post(
                apiEndpoints.registerReportingTech,
                data
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const updateMobileUser = async (): Promise<IUpdateMobileUserResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUpdateMobileUserResponse | null> = await api.post(
                apiEndpoints.updateMobileUser,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const uploadClaimFiles = async (data: FormData): Promise<IUploadClaimFilesResponse | null> => {
        try {
            const token = await requestToken();
            const apiResponse: AxiosResponse<IUploadClaimFilesResponse | null> = await api.post(
                apiEndpoints.uploadClaimFiles,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: '*/*',
                    },
                }
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const verifyTechEmailAddress = async (
        data: IVerifyTechEmailAddressRequest
    ): Promise<IVerifyTechEmailAddressResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IVerifyTechEmailAddressResponse | null> = await api.post(
                apiEndpoints.verifyTechEmailAddress,
                data
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    const verifyTechReferenceNumber = async (
        data: IVerifyReferenceNumberRequest
    ): Promise<IVerifyReferenceNumberResponse | null> => {
        try {
            const apiResponse: AxiosResponse<IVerifyReferenceNumberResponse | null> = await api.post(
                apiEndpoints.verifyTechReferenceNumber,
                data
            );
            return apiResponse.data;
        } catch (err) {
            handleApiError(err as Error);
            return null;
        }
    };

    return {
        deleteClaimFile,
        getAllVisibleClients,
        getClaim,
        getClaimFieldConfig,
        getClaimInit,
        getClientByDomain,
        getConstructionCoordinatorSearch,
        getPersonnel,
        getMarketsByClientId,
        getMaterial,
        getOfficesByMarketId,
        getReportingTech,
        getReportingTechDashboard,
        getReportingTechSupervisorSearch,
        getVehicle,
        postClaim,
        postPersonnel,
        postMaterial,
        postVehicle,
        postReportingTech,
        registerReportingTech,
        tokenRequest,
        updateMobileUser,
        uploadClaimFiles,
        verifyTechEmailAddress,
        verifyTechReferenceNumber,
    };
};