import { Transition } from '@headlessui/react';
import { ITransitionBaseProps } from './index';

interface IExpandVTransitionProps extends ITransitionBaseProps {}

export const ExpandVTransition = ({ children, isOpen }: IExpandVTransitionProps) => {
    return (
        <Transition
            as={'div'}
            appear
            className={'z-10'}
            enter={'transform duration-300'}
            enterFrom={'scale-y-0'}
            enterTo={'scale-y-100'}
            leave={'transform duration-200 ease-in-out'}
            leaveFrom={'scale-y-100'}
            leaveTo={'scale-y-0'}
            show={isOpen}
        >
            {children}
        </Transition>
    );
};
