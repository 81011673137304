import { useReportingTech } from '../../hooks';

export const EmailDisplay = () => {
    const { reportingTechEmail } = useReportingTech();

    return (
        <div className={'pt-1 pb-2'}>
            <div className={'flex flex-col whitespace-nowrap px-4 pt-1'}>
                <p className={'text-sm font-semibold leading-5 opacity-50'}>Signed in as</p>
                <p
                    className={
                        'truncate text-base font-semibold leading-5 text-gray-800 ui-active:text-gray-200 dark:text-white'
                    }
                >
                    {reportingTechEmail}
                </p>
            </div>
        </div>
    );
};