import clsx from 'clsx';

import type { FCCCN } from '../../../../types';

interface IFormFieldsProps {
    replaceClassName?: string;
}

export const FormFields: FCCCN<IFormFieldsProps> = ({ children, className, replaceClassName }) => {
    return (
        <div className={clsx(replaceClassName || 'mt-6 flex w-full flex-col justify-center space-y-4', className)}>
            {children}
        </div>
    );
};