import { useApi, useErrorHandling } from '../../../hooks';
import { useMutation } from '@tanstack/react-query';
import { PasswordWarning } from './PasswordWarning';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../../components';
import { AxiosError } from 'axios';

import type { IVerifyReferenceNumberResponse, IVerifyTechEmailAddressResponse } from '../../../types';
import type { IToggle } from '../../../hooks';
import type { FC } from 'react';

interface ITechnicianFieldsProps {
    clientId: number;
    setIsUniqueEmail: IToggle;
    setIsUserNameUnique: IToggle;
}

export const TechnicianFields: FC<ITechnicianFieldsProps> = ({ clientId, setIsUniqueEmail, setIsUserNameUnique }) => {
    const formContext = useFormContext();
    const { verifyTechEmailAddress, verifyTechReferenceNumber } = useApi();
    const { handleApiError } = useErrorHandling();

    const verifyReferenceNumberMutation = useMutation({
        mutationFn: verifyTechReferenceNumber,
        onSuccess: (response: IVerifyReferenceNumberResponse | null) => {
            if (response === null || response?.reportingTech?.length === 0) {
                setIsUserNameUnique.on();
                formContext.clearErrors('techReferenceNumber');
            } else {
                if (
                    formContext.getValues('isExistingReportingTech') === true &&
                    response?.reportingTech?.find(
                        (x) => x.emailAddress === formContext.getValues('existingReportingTechEmailAddress')
                    )?.techReferenceNumber !== formContext.getValues('existingReportingTechReferenceNumber')
                ) {
                    setIsUserNameUnique.off();
                    formContext.setError('techReferenceNumber', {
                        message: 'This user name is already taken.',
                    });
                } else {
                    setIsUserNameUnique.on();
                    formContext.clearErrors('techReferenceNumber');
                }
            }
        },
    });

    const verifyTechEmailAddressMutation = useMutation({
        mutationFn: verifyTechEmailAddress,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: (response: IVerifyTechEmailAddressResponse | null) => {
            if (response?.reportingTech === null) {
                setIsUniqueEmail.on();
                formContext.clearErrors('techEmailAddress');
            } else {
                if (
                    formContext.getValues('isExistingReportingTech') === true &&
                    response?.reportingTech.emailAddress !== formContext.getValues('existingReportingTechEmailAddress')
                ) {
                    setIsUniqueEmail.off();
                    formContext.setError('techEmailAddress', {
                        message: 'An account with this email address already exists.',
                    });
                } else {
                    setIsUniqueEmail.on();
                    formContext.clearErrors('techEmailAddress');
                }
            }
        },
    });

    const onBlurEmailAddress = () => {
        const email: string = formContext.getValues('techEmailAddress');
        if (!email || email?.trim().length === 0) return;
        verifyTechEmailAddressMutation.mutate({
            clientId: 0,
            marketId: 0,
            prefixText: email,
        });
    };

    const onBlurReferenceNumber = () => {
        const referenceNumber: string = formContext.getValues('techReferenceNumber');
        if (!referenceNumber || referenceNumber?.trim()?.length === 0) return;
        verifyReferenceNumberMutation.mutate({
            clientId: clientId,
            marketId: 0,
            prefixText: referenceNumber,
        });
    };

    return (
        <div className={'flex flex-col'}>
            {/* TECHNICIAN DETAILS */}
            <div className={'my-6 self-center text-3xl font-medium uppercase text-brand-100'}>Technician Details</div>
            <div className={'flex flex-col space-y-3'}>
                <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                    {/* TECH FIRST NAME */}
                    <Input
                        inputOptions={{
                            autoComplete: 'first-name',
                            isRequired: true,
                            label: 'First Name',
                            name: 'techFirstName',
                        }}
                        tooltip={"The technician's first name."}
                    />

                    {/* TECH LAST NAME */}
                    <Input
                        inputOptions={{
                            autoComplete: 'last-name',
                            isRequired: true,
                            label: 'Last Name',
                            name: 'techLastName',
                        }}
                        tooltip={"The technician's last name."}
                    />
                </div>

                <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                    {/* TECH REFERENCE NUMBER / USER NAME */}
                    <Input
                        inputOptions={{
                            isRequired: true,
                            label: 'User Name',
                            name: 'techReferenceNumber',
                            onBlur: onBlurReferenceNumber,
                        }}
                        tooltip={'A unique user name for the account.'}
                    />

                    {/* TECH PHONE NUMBER */}
                    <Input
                        inputOptions={{
                            isRequired: true,
                            label: 'Phone Number',
                            name: 'techPhoneNumber',
                        }}
                        tooltip={'A phone number for the technician.'}
                        type={'phone'}
                    />
                </div>

                {formContext.getValues('isExistingB2CLogin') === false && (
                    <>
                        <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                            {/* TECH EMAIL ADDRESS */}
                            <Input
                                inputOptions={{
                                    isRequired: true,
                                    label: 'Email Address',
                                    name: 'techEmailAddress',
                                    onBlur: onBlurEmailAddress,
                                }}
                                tooltip={'An email address for the technician.'}
                            />

                            {/* TECH CONFIRM EMAIL ADDRESS */}
                            <Input
                                inputOptions={{
                                    isRequired: true,
                                    label: 'Confirm Email Address',
                                    name: 'techConfirmEmailAddress',
                                }}
                                tooltip={'Confirm the email address for the technician.'}
                            />
                        </div>

                        <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                            {/* TECH PASSWORD */}
                            <Input
                                inputOptions={{
                                    isRequired: true,
                                    label: 'Password',
                                    name: 'password',
                                    type: 'password',
                                }}
                                tooltip={
                                    'A password for the technician. Must be at least 8 characters. Must contain at least one capital letter, one lowercase letter, one number and one special character.'
                                }
                            />

                            {/* TECH CONFIRM PASSWORD */}
                            <Input
                                inputOptions={{
                                    isRequired: true,
                                    label: 'Confirm Password',
                                    name: 'confirmPassword',
                                    type: 'password',
                                }}
                                tooltip={
                                    'A password for the technician. Must be at least 8 characters. Must contain at least one capital letter, one lowercase letter, one number and one special character.'
                                }
                            />
                        </div>

                        {/* PASSWORD FORMAT WARNING */}
                        <PasswordWarning />
                    </>
                )}
            </div>
        </div>
    );
};
