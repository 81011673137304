import { lazyImport } from '../utils';
import type { IRoute } from '../types';

const { AuthRoutes } = lazyImport(() => import('../features/auth'), 'AuthRoutes');

export const PublicRoutes: Array<IRoute> = [
    {
        path: '*',
        element: <AuthRoutes />,
    },
];