import {
    useApi,
    useReportingTech,
    useToggle,
    useModalToggle,
    IModalToggle,
    usePrevious,
    useErrorHandling,
} from '../../hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { SupervisorFields } from './SupervisorFields';
import { TechnicianFields } from './TechnicianFields';
import { MarketOffices } from './MarketOffices';
import { mapMarketOffices } from '../../utils';
import { PanelButtons } from './PanelButtons';
import { PanelHeader } from './PanelHeader';
import { appRoutes, queryKeys } from '../../config';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import type { FC } from 'react';
import type {
    IMarketOffice,
    IMarketOffices,
    IOffice,
    IUpdateReportingTechRequest,
    IUpdateReportingTechResponse,
    IValidationError,
} from '../../types';
import { useMsal } from '@azure/msal-react';
import { ConfirmModal } from '../modals';
import { SchemaLike } from 'yup/lib/types';

const contactInfoFormValidationSchema: yup.ObjectSchema<any> = yup.object().shape({
    techEmailAddress: yup
        .string()
        .nullable()
        .required('An email address is required.')
        .email('A valid email address is required.'),
    techFirstName: yup.string().nullable().required('A first name is required.'),
    techLastName: yup.string().nullable().required('A last name is required.'),
    techPhoneNumber: yup
        .string()
        .nullable()
        .required('A phone number is required.')
        .min(10, 'A valid phone number is required.'),
    confirmPassword: yup
        .string()
        .when(['techEmailAddressChanged'], {
            is: true,
            then: yup
                .string()
                .oneOf([yup.ref('password'), null], 'The passwords do not match.')
                .required('A confirm password is required.'),
        })
        .when(['techEmailAddressChanged'], {
            is: false,
            then: yup.string().optional(),
        }),
    password: yup
        .string()
        .nullable()
        .when(['techEmailAddressChanged'], {
            is: true,
            then: yup
                .string()
                .required('A valid password is required.')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,25}/,
                    'Password does not match the required format.'
                ),
        })
        .when(['techEmailAddressChanged'], {
            is: false,
            then: yup.string().optional(),
        }),
    techSupervisorEmailAddress: yup
        .string()
        .nullable()
        .required('An email address is required.')
        .email('A valid email address is required.'),
    techSupervisorFirstName: yup.string().nullable().required('A first name is required.'),
    techSupervisorLastName: yup.string().nullable().required('A last name is required.'),
    techSupervisorPhoneNumber: yup
        .string()
        .nullable()
        .required('A phone number is required.')
        .min(10, 'A valid phone number is required.'),
});

export interface IContactInfoFormValueTypes {
    techEmailAddress: string;
    techFirstName: string;
    techId: number;
    techLastName: string;
    techPhoneNumber: string;
    password?: string;
    confirmPassword?: string;
    techSupervisorEmailAddress: string;
    techSupervisorFirstName: string;
    techSupervisorLastName: string;
    techSupervisorPhoneNumber: string;
    techEmailAddressChanged?: boolean;
}

interface IContactInfoPanelProps {
    panelToggle: IModalToggle;
}

export const ContactInfoPanel: FC<IContactInfoPanelProps> = ({ panelToggle }) => {
    const { postReportingTech } = useApi();
    const queryClient = useQueryClient();
    const { reportingTech } = useReportingTech();
    const { handleApiError } = useErrorHandling();

    const [marketOffices, setMarketOffices] = useState<Array<IMarketOffices>>([]);
    const isEmailUnique = useToggle(true);
    const confirmModal = useModalToggle();
    const confirmSignOut = useModalToggle();
    const previousIsOpen = usePrevious<boolean>(confirmModal.isOpen);
    const { instance } = useMsal();

    const contactInfoStartingFormValues: IContactInfoFormValueTypes = {
        techEmailAddressChanged: false,
        techEmailAddress: reportingTech?.emailAddress ?? '',
        techFirstName: reportingTech?.firstName ?? '',
        techId: reportingTech?.techReferenceNumber ?? -1,
        techLastName: reportingTech?.lastName ?? '',
        techPhoneNumber: reportingTech?.phoneNumber?.replace(/\D/g, '') ?? '',
        techSupervisorEmailAddress: reportingTech?.reportingTechSupervisor?.emailAddress ?? '',
        techSupervisorFirstName: reportingTech?.reportingTechSupervisor?.firstName ?? '',
        techSupervisorLastName: reportingTech?.reportingTechSupervisor?.lastName ?? '',
        techSupervisorPhoneNumber: reportingTech?.reportingTechSupervisor?.phoneNumber?.replace(/\D/g, '') ?? '',
    };

    const formContext = useForm({
        defaultValues: contactInfoStartingFormValues as Record<string, any>,
        resolver: yupResolver(contactInfoFormValidationSchema),
    });

    const onReportingTechMutationSuccess = async (data: IUpdateReportingTechResponse | null) => {
        confirmModal.close();
        if (!data?.success) {
            data?.validationErrorList.forEach((error: IValidationError) => toast.error(error?.message));
            return;
        }
        if (data?.requireSignOut) {
            // await signOut();
            confirmSignOut.open();
            return;
        }
        await queryClient.invalidateQueries({ queryKey: [queryKeys.reportingTech] });
        toast.success('Contact Information Updated Successfully!');
        panelToggle.close();
    };

    const reportingTechMutation = useMutation({
        mutationFn: postReportingTech,
        onSuccess: onReportingTechMutationSuccess,
    });

    const signOut = async () =>
        instance.logoutRedirect({ postLogoutRedirectUri: appRoutes.signIn }).catch(handleApiError);

    // Reset the form data when the panel is first opened otherwise it will appear blank.
    useEffect(() => {
        if (panelToggle.isOpen && !previousIsOpen) {
            formContext.reset(contactInfoStartingFormValues);
            setMarketOffices(mapMarketOffices(reportingTech?.techCMOAssignments ?? []));
        }
    }, [panelToggle.isOpen]);

    const onUpdateContactInfoConfirmed = async (): Promise<void> => {
        if (isEmailUnique.isOff) return;

        const values = formContext.getValues() as IContactInfoFormValueTypes;

        const model: IUpdateReportingTechRequest = {
            marketOffices: marketOffices.map((mo: IMarketOffices) => {
                return { marketId: mo.marketId.id, officeIds: mo.officeIds.map((o: IOffice) => o.id) } as IMarketOffice;
            }),
            techEmailAddress: values?.techEmailAddress ?? '',
            techFirstName: values?.techFirstName ?? '',
            techLastName: values?.techLastName ?? '',
            techPhoneNumber: values?.techPhoneNumber ?? '',
            password: values?.password ?? '',
            confirmPassword: values?.confirmPassword ?? '',
            techSupervisorEmailAddress: values?.techSupervisorEmailAddress ?? '',
            techSupervisorFirstName: values?.techSupervisorFirstName ?? '',
            techSupervisorId: reportingTech?.reportingTechSupervisor?.reportingTechSupervisorId?.toString() ?? '0',
            techSupervisorLastName: values?.techSupervisorLastName ?? '',
            techSupervisorPhoneNumber: values?.techSupervisorPhoneNumber ?? '',
        };
        reportingTechMutation.mutate(model);
    };

    const onConfirm = () => {
        const values = formContext.getValues() as IContactInfoFormValueTypes;
        const originalTechEmailAddress = contactInfoStartingFormValues.techEmailAddress;
        const currentTechEmailAddress = values.techEmailAddress;

        formContext.setValue('techEmailAddressChanged', originalTechEmailAddress !== currentTechEmailAddress);

        formContext.trigger().then((isValid: boolean) => {
            if (!isValid) return;
            confirmModal.open();
        });
    };

    return (
        <Transition.Root appear as={Fragment} show={panelToggle.isOpen}>
            <Dialog as={'div'} className={'relative z-50'} onClose={panelToggle.close}>
                <div className={'fixed inset-0 overflow-hidden'}>
                    <div className={'absolute inset-0 overflow-hidden'}>
                        <div className={'pointer-events-none fixed inset-y-0 right-0 flex max-w-full'}>
                            <Transition.Child
                                as={Fragment}
                                enter={'transform transition ease-in-out duration-500'}
                                enterFrom={'translate-x-full'}
                                enterTo={'translate-x-0'}
                                leave={'transform transition ease-in-out duration-500'}
                                leaveFrom={'translate-x-0'}
                                leaveTo={'translate-x-full'}
                            >
                                <Dialog.Panel className={'pointer-events-auto w-screen max-w-md'}>
                                    <FormProvider {...formContext}>
                                        <form
                                            className={
                                                'flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl dark:divide-gray-600 dark:bg-gray-800'
                                            }
                                        >
                                            <div className={'h-0 flex-1 overflow-y-auto'}>
                                                {/* HEADER */}
                                                <PanelHeader panelToggle={panelToggle} />

                                                {/* CONTENT */}
                                                <div className={'my-5 px-3'}>
                                                    <div className={'flex w-full flex-col bg-white dark:bg-gray-800'}>
                                                        {/* MARKETS & OFFICES */}
                                                        <MarketOffices
                                                            marketOffices={marketOffices}
                                                            setMarketOffices={setMarketOffices}
                                                        />

                                                        {/* TECHNICIAN FIELDS */}
                                                        <TechnicianFields uniqueEmailToggle={isEmailUnique} />

                                                        {/* SUPERVISOR FIELDS */}
                                                        <SupervisorFields />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* BUTTONS */}
                                            <PanelButtons
                                                isSaveDisabled={!isEmailUnique}
                                                isSaving={reportingTechMutation.isLoading}
                                                modalToggle={confirmModal}
                                                onConfirm={onConfirm}
                                                onUpdate={onUpdateContactInfoConfirmed}
                                                panelToggle={panelToggle}
                                            />
                                        </form>
                                    </FormProvider>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>

                {/* CONFIRM SIGN OUT */}
                <ConfirmModal
                    message={'Your login info has changed. You will be redirected to sign in again.'}
                    modalToggle={confirmSignOut}
                    onConfirm={signOut}
                    isStatic
                    allowCancel={false}
                    title={'Sign In Required'}
                />
            </Dialog>
        </Transition.Root>
    );
};
