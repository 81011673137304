import { useTableHeaderRow } from '@react-aria/table';
import { FCC } from '../../types';
import { useRef } from 'react';

interface ITableHeaderRowProps {
    item: any;
    state: any;
}

export const TableHeaderRow: FCC<ITableHeaderRowProps> = ({ children, item, state }) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

    return (
        <tr {...rowProps} ref={ref}>
            {children}
        </tr>
    );
};