import { Button, ConfirmModal, ControlledComboBox, Input } from '../../../../components';
import { useApi, useModalToggle, useReportingTech } from '../../../../hooks';
import useDebounce from '../../../../hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { MaterialTable } from './MaterialTable';
import { useEffect, useState } from 'react';

import type {
    IGetMaterialRequest,
    IGetMaterialResponse,
    IUpdateMaterialRequest,
    IUpdateMaterialResponse,
} from '../../../../types';

export interface IMaterialType {
    id: string;
    name: string;
}

export const Materials = () => {
    const formContext = useFormContext();
    const { getMaterial, postMaterial } = useApi();
    const { reportingTech } = useReportingTech();

    const [idToRemove, setIdToRemove] = useState<number>();
    const [mappedMaterials, setMappedMaterials] = useState<Array<IMaterialType>>([]);
    const [query, setQuery] = useState<string>();
    const [selectedMaterial, setSelectedMaterial] = useState<IMaterialType | null>(null);

    const debouncedQuery = useDebounce(query, 400);

    const removeModal = useModalToggle();

    const allMaterials: Array<IUpdateMaterialResponse> = formContext.watch('allMaterials');

    const hasMaterial = (selectedMaterial?.name.length ?? -1) <= 0;

    const onMaterialQuerySuccess = (data: IGetMaterialResponse) => {
        if (data === null || data?.results === null) return;
        // Have to map the fetched data because it comes back super goofy from the endpoint.
        setMappedMaterials(
            data?.results?.map((result: string) => {
                const idIndex: number = result?.indexOf('^');
                if (idIndex === -1) return { id: 'unknown', name: 'unknown' };
                return { id: result?.substring(idIndex + 1), name: result?.substring(0, idIndex) } as IMaterialType;
            })
        );
    };

    const { isLoading: isFetchingMaterials, mutate: searchMaterials } = useMutation({
        mutationFn: getMaterial,
        onSuccess: onMaterialQuerySuccess,
    });

    const onMaterialMutationSuccess = (data: IUpdateMaterialResponse | null) => {
        if (data === null || data?.name === null || data.name.length === 0) return;
        setSelectedMaterial(null);
        setMappedMaterials([]);
        formContext.setValue('allMaterials', [...formContext?.getValues('allMaterials'), data]);
        formContext.setValue('materialQuantity', '');
    };

    const materialMutation = useMutation({
        mutationFn: postMaterial,
        onSuccess: onMaterialMutationSuccess,
    });

    // debounce th materials search.
    useEffect(() => {
        if (!query) return;
        searchMaterials({
            clientId: reportingTech?.clientId?.toString() ?? '-1',
            marketId: formContext.getValues('marketId'),
            prefixText: query,
        } as IGetMaterialRequest);
    }, [debouncedQuery]);

    const onMaterialChange = (search: string) => setQuery(search);

    const onMaterialSelect = (m: IMaterialType | null) => setSelectedMaterial(m);

    const onAddMaterial = () => {
        const model: IUpdateMaterialRequest = {
            action: 'save',
            claimId: formContext.getValues('claimId'),
            id: Number(selectedMaterial?.id ?? 0),
            materialName: selectedMaterial?.name ?? '',
            qty:
                formContext.getValues('materialQuantity')?.length !== 0 ? formContext.getValues('materialQuantity') : 0,
        };

        materialMutation.mutate(model);
    };

    const onRemoveMaterial = (itemId: number) => {
        setIdToRemove(itemId);
        removeModal.open();
    };

    const onRemoveMaterialConfirm = () => {
        const filteredMaterials = allMaterials.filter((a) => a.itemId !== idToRemove);

        formContext?.setValue('allMaterials', [...filteredMaterials]);

        const model: IUpdateMaterialRequest = {
            action: 'delete',
            claimId: formContext.getValues('claimId'),
            id: idToRemove ?? 0,
            materialName: '',
            qty: 0,
        };
        materialMutation.mutate(model);
    };

    return (
        <div className={'flex flex-col space-y-2'}>
            <div className={'flex w-full flex-col sm:flex-row sm:space-x-2'}>
                {/* MATERIAL TYPE */}
                <ControlledComboBox
                    debounce={debouncedQuery}
                    displayKey={'name'}
                    idKey={'id'}
                    isLoading={isFetchingMaterials}
                    items={mappedMaterials}
                    label={'Material Type'}
                    onChange={onMaterialChange}
                    onSelect={onMaterialSelect}
                    placeholder={'Start typing to see material types...'}
                    selectedItem={selectedMaterial}
                    setSelectedItem={setSelectedMaterial}
                    tooltip={
                        'What materials did you use to make the repair?, e.g., 200’ of RG11 cable, 2 RG11\n' +
                        "connectors, 2 Pin-F's"
                    }
                />

                {/* QUANTITY  */}
                <Input
                    inputOptions={{
                        label: 'Length / Quantity',
                        name: 'materialQuantity',
                        value: formContext.getValues('materialQuantity'),
                    }}
                    tooltip={'The total amount of the material used.'}
                    type={'number'}
                />

                {/* ADD A MATERIAL TYPE  */}
                <div className={'flex pt-2 sm:pt-0'}>
                    <Button
                        buttonProps={{ isDisabled: hasMaterial, onPress: onAddMaterial }}
                        className={'place-self-end'}
                        isBusy={materialMutation.isLoading}
                    >
                        Add
                    </Button>
                </div>
            </div>

            {/* MATERIALS TABLE  */}
            <MaterialTable materials={allMaterials} onRemove={onRemoveMaterial} />

            {/* CONFIRM REMOVE MODAL */}
            <ConfirmModal
                message={'Are you sure you want to delete this material?'}
                modalToggle={removeModal}
                onConfirm={onRemoveMaterialConfirm}
                title={'Confirm Delete'}
            />
        </div>
    );
};