import { CalendarDateTime } from '@internationalized/date';

import type { DateValue } from '@internationalized/date';

export const parseDate = (date: string, time: string): DateValue | undefined => {
    if (typeof date === 'object' || !date) return undefined;

    if (date?.indexOf('/') !== -1) {
        const dateParts = date?.split('/');

        let timeParts = [0, 0];
        if (time?.indexOf(':') !== -1) {
            const splitTime = time.split(' ');
            const hourMinute = splitTime[0].split(':');
            timeParts[0] = Number(hourMinute[0]);
            timeParts[1] = Number(hourMinute[1]);

            // adjust for PM.
            const period = splitTime[1];
            if (period === 'PM' && timeParts[0] !== 12) {
                timeParts[0] += 12;
            }
        }

        return new CalendarDateTime(
            'AD',
            Number(dateParts[2]),
            Number(dateParts[0]),
            Number(dateParts[1]),
            timeParts[0],
            timeParts[1],
            0,
            0
        );
    }

    return undefined;
};