import { Input, YesNoButton } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';

interface IAerialDamageProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const AerialDamage: FC<IAerialDamageProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const isDamageAerial: boolean | null = formContext.watch(claimFields.wasDamageAerial);

    const onAerialDamageNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'FacilityNumber', claimFields.wasDamageAerial]);
        formContext.setValue(claimFields.facilityNumber, '');
        formContext.clearErrors(claimFields.wasDamageAerial);
        formContext.clearErrors(claimFields.facilityNumber);
    };

    const onFacilityNumberChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'FacilityNumber', claimFields.wasDamageAerial]);
        formContext.setValue(claimFields.wasDamageAerial, true);
        formContext.clearErrors(claimFields.wasDamageAerial);
        formContext.clearErrors(claimFields.facilityNumber);
    };

    return (
        <div className={'flex w-full flex-col space-y-1'}>
            {/* WAS AERIAL? */}
            <YesNoButton
                isRequired
                isSelected={isDamageAerial}
                labelText={'Were above ground facilities damaged?'}
                name={claimFields.wasDamageAerial}
                onNoClick={onAerialDamageNoClick}
                onYesClick={() => setSaveAndQuitChanges?.((prev) => [...prev, claimFields.wasDamageAerial])}
                tooltip={'Did any of the damage occur above ground?'}
            />

            {/* PED POLE AMP (FACILITY NUMBER) */}
            {isDamageAerial !== false && (
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.FacilityNumber,
                        label: 'Ped / Pole / Amp',
                        name: claimFields.facilityNumber,
                        onChange: onFacilityNumberChange,
                    }}
                    tooltip={
                        'Does the pedestal, or AMP damaged have a number/name assigned? Does the pole have a number attached?'
                    }
                />
            )}
        </div>
    );
};