import { FormFields, FormHeader, FormSection } from '../form';
import { Personnel } from './Personnel';
import { Materials } from './Materials';
import { Vehicles } from './Vehicles';

export const LaborDetails = () => {
    return (
        <>
            <FormSection>
                <FormHeader>Personnel</FormHeader>
                <FormFields className={'space-y-10'}>
                    <Personnel />
                </FormFields>
            </FormSection>
            <FormSection>
                <FormHeader>Materials</FormHeader>
                <FormFields className={'space-y-10'}>
                    <Materials />
                </FormFields>
            </FormSection>
            <FormSection>
                <FormHeader>Vehicles</FormHeader>
                <FormFields className={'space-y-10'}>
                    <Vehicles />
                </FormFields>
            </FormSection>
        </>
    );
};