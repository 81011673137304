import { Duration, Input, ListBox, TextArea } from '../../../../components';
import { FormFields, FormHeader, FormSection } from '../form';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { IClaim, IClaimInit } from '../../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface IDamageDescriptionProps {
    claimInit?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamageDescription: FC<IDamageDescriptionProps> = ({ claimInit, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const onDetailsOfDamageLossChange = (newValue: string) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DetailsofDamageLoss']);
        formContext.setValue(claimFields.detailsOfDamageLoss, newValue);
        formContext.clearErrors(claimFields.detailsOfDamageLoss);
    };

    return (
        <FormSection>
            <FormHeader>Damage Description</FormHeader>

            <FormFields>
                {/* DESCRIPTION */}
                <TextArea
                    options={{
                        isRequired: fieldRequirements.DetailsOfDamageLoss,
                        label: 'Damage Description / General Commentary',
                        name: claimFields.detailsOfDamageLoss,
                        onChange: onDetailsOfDamageLossChange,
                    }}
                    tooltip={
                        'Enter details such as: What was damaged?\n' +
                        'How was it damaged? Scope of work\n' +
                        'being done when the damage occurred? Did you mark the damage? Do you have any comments on locate markings if they were present? If underground and not exposed,\n' +
                        'how many feet is the damage from a particular landmark? Any police report numbers? Any other comments?'
                    }
                />

                {/* ESTIMATED SUBS AFFECTED */}
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.EstimatedSubsAffected,
                        label: 'Estimated # of Subs Affected',
                        name: claimFields.estimatedSubsAffected,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'SubAffected']),
                    }}
                    tooltip={'How many subscribers were affected by the damage?'}
                    type={'number'}
                />

                {/* OUTAGE DURATION */}
                <Duration
                    isRequired={fieldRequirements.OutageDuration}
                    label={'Outage Duration'}
                    name={claimFields.outageDuration}
                    onChange={(_) => setSaveAndQuitChanges?.((prev) => [...prev, 'OutageDuration'])}
                    tooltip={'How long were the affected customers out of service?'}
                />

                {/* ACTIVITY TYPE */}
                <ListBox
                    isRequired={fieldRequirements.DamageActivityType}
                    label={'Activity Type'}
                    listItems={claimInit?.damageActivityTypeList ?? []}
                    name={claimFields.damageActivityType}
                    onSingleChange={(_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageActivityTypeId'])}
                    tooltip={'What was the activity that caused the damage?'}
                />

                {/* DAMAGE BY */}
                <ListBox
                    isRequired={fieldRequirements.DamageByType}
                    label={'Damage By'}
                    listItems={claimInit?.damageByTypeList ?? []}
                    name={claimFields.damageByType}
                    onSingleChange={(_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageByTypeId'])}
                    tooltip={'What caused the damage?'}
                />
            </FormFields>
        </FormSection>
    );
};