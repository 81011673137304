import { usePhoneNumber } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import {
    SummaryGrid,
    SummaryGroup,
    SummaryHeader,
    SummaryRow,
    SummarySection,
    SummaryText,
} from '../../../../components';

import type { IClaim, IClaimInit } from '../../../../types';
import type { FC } from 'react';

interface IDamagerDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setStep: (step: number) => void;
}

export const DamagerDetailsSummary: FC<IDamagerDetailsProps> = ({ claim, setStep }) => {
    const formContext = useFormContext();

    const { formatPhoneNumber } = usePhoneNumber({});

    // DAMAGER KNOWN
    const hasOpinion: string = formContext.getValues(claimFields.opinionOnResponsibleParty) ? 'Yes' : 'No';
    const billableParty: string =
        claim?.billablePartyTypeList?.find((b) => b.id === formContext.getValues(claimFields.responsibleForDamage))
            ?.name ?? 'N/A';

    // DAMAGER KNOWN
    const haveDamagerDetails = formContext.getValues(claimFields.haveDamagerContactDetails) ? 'Yes' : 'No';
    const isDamagerKnown: string = formContext.getValues(claimFields.whoWasDamager) ? 'Yes' : 'No';
    const damagerName: string =
        formContext.getValues(claimFields.damagerName)?.length !== 0
            ? formContext.getValues(claimFields.damagerName)
            : 'N/A';
    const damagerContactName: string =
        formContext.getValues(claimFields.damagerContactName)?.length !== 0
            ? formContext.getValues(claimFields.damagerContactName)
            : 'N/A';
    const phoneNumber: string = formContext.getValues(claimFields.damagerPhoneNumber) ?? '';
    const formattedPhoneNumber: string = phoneNumber.length > 0 ? formatPhoneNumber(phoneNumber) : 'N/A';
    const damagerEmailAddress: string = formContext.getValues(claimFields.damagerEmailAddress) ?? '';
    const damagerEmailAddressDisplay: string = damagerEmailAddress.length > 0 ? damagerEmailAddress : 'N/A';
    const howWasDamagerIdentified: string =
        formContext.getValues(claimFields.damagerIdentified)?.length !== 0
            ? formContext.getValues(claimFields.damagerIdentified)
            : 'N/A';
    const damagerStatement: string =
        formContext.getValues(claimFields.damagerStatement)?.length !== 0
            ? formContext.getValues(claimFields.damagerStatement)
            : 'N/A';

    // Return to damager details step
    const onStepClick = () => setStep(3);

    return (
        <div className={'flex flex-col'}>
            <SummaryHeader isLink onLink={onStepClick}>
                Damager Details
            </SummaryHeader>

            <SummaryGroup>
                <SummarySection>
                    <SummaryGrid>
                        {/* DAMAGER OPINION */}
                        <SummaryRow hideColon label={'Do you have an opinion on who was responsible for the damage?'}>
                            {hasOpinion}
                        </SummaryRow>
                        <SummaryRow hideColon label={'Who was responsible for the damage?'}>
                            {billableParty}
                        </SummaryRow>

                        {/* DAMAGER KNOWN */}
                        <SummaryRow hideColon label={'Do you know who the damager was?'}>
                            {isDamagerKnown}
                        </SummaryRow>
                        <SummaryRow label={'Damager Name'}>{damagerName}</SummaryRow>
                        <SummaryText
                            hideColon
                            label={'How was the damager identified?'}
                            value={howWasDamagerIdentified}
                        />
                    </SummaryGrid>
                </SummarySection>

                {/* CONTACT DETAILS */}
                <SummarySection>
                    <SummaryGrid>
                        <SummaryRow hideColon label={'Do you have contact details or a statement from the damager?'}>
                            {haveDamagerDetails}
                        </SummaryRow>
                        <SummaryRow label={'Damager Contact Name'}>{damagerContactName}</SummaryRow>
                        <SummaryRow label={'Damager Phone Number'}>{formattedPhoneNumber}</SummaryRow>
                        <SummaryRow label={'Damager Email Address'}>{damagerEmailAddressDisplay}</SummaryRow>
                        <SummaryText label={'Damager Statement'} value={damagerStatement} />
                    </SummaryGrid>
                </SummarySection>
            </SummaryGroup>
        </div>
    );
};