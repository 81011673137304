import { Fragment, useEffect, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { usePrevious, useToggle } from '../../hooks';
import { TooltipButton } from '../tooltip';
import clsx from 'clsx';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface IComboBoxProps<T> {
    debounce?: string;
    displayKey: string;
    idKey: string;
    isLoading?: boolean;
    items?: Array<T>;
    label?: string;
    onChange?: (value: string) => void;
    onSelect?: (value: T | null) => void;
    placeholder?: string;
    selectedItem: null | T;
    setSelectedItem: Dispatch<SetStateAction<null | T>>;
    tooltip?: string;
}

export const ControlledComboBox = <T,>({
    debounce,
    displayKey,
    idKey,
    isLoading,
    items = [],
    label,
    onChange,
    onSelect,
    placeholder,
    selectedItem,
    setSelectedItem,
    tooltip,
}: IComboBoxProps<T>) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [query, setQuery] = useState('');

    const isLoadingFinished = useToggle();
    const previousIsLoading = usePrevious(isLoading);

    const onChangeInternal = (event: ChangeEvent<HTMLInputElement>) => {
        const text: string | undefined = event.target.value;
        setQuery(text);
        onChange?.(text);
        if (!text) isLoadingFinished.off();
    };

    const onSelectedInternal = (value: T | null) => {
        setSelectedItem(value);
        onSelect?.(value);
        isLoadingFinished.off();
    };

    const filteredItems: Array<T> =
        query === ''
            ? []
            : items?.filter((item: any) => {
                  return item?.[displayKey]?.toLowerCase().includes(query.toLowerCase());
              });

    const hasNoResults: boolean = filteredItems.length === 0 && query !== '';

    const message: string = isLoading
        ? 'Loading...'
        : !isLoadingFinished
        ? query.length === 0
            ? ''
            : 'Loading...'
        : hasNoResults
        ? 'Nothing Found'
        : '';

    useEffect(() => {
        if (previousIsLoading && !isLoading) isLoadingFinished.on();
        if (isLoading && !previousIsLoading) isLoadingFinished.off();
    }, [isLoading]);

    return (
        <div className={'flex w-full flex-col'}>
            <div className={'flex'}>
                {/* TOOLTIP */}
                <div className={'mr-0.5 block text-sm text-gray-700 dark:text-gray-300 sm:text-base'}>
                    <TooltipButton tooltip={tooltip} />
                </div>
                {/* LABEL */}
                <span className={'text-sm font-semibold text-gray-800 dark:text-gray-200 sm:text-base'}>{label}</span>
            </div>

            <Combobox nullable onChange={onSelectedInternal} value={selectedItem}>
                <div className={'relative mt-0.5'}>
                    <div>
                        {/* INPUT FIELD */}
                        <Combobox.Input
                            className={'input-primary h-10'}
                            displayValue={(item: Record<string, any>) => item?.[displayKey]}
                            onChange={onChangeInternal}
                            placeholder={placeholder}
                            ref={inputRef}
                        />
                    </div>
                    <Transition
                        afterLeave={() => setQuery('')}
                        as={Fragment}
                        leave={'transition ease-in duration-100'}
                        leaveFrom={'opacity-100'}
                        leaveTo={'opacity-0'}
                    >
                        <Combobox.Options
                            className={clsx(
                                'absolute mt-1 max-h-80 bg-gray-50 focus:outline-none dark:bg-gray-600',
                                'w-full overflow-auto rounded-md py-1 text-base shadow-md sm:text-sm',
                                'ui-open:z-20'
                            )}
                        >
                            {/* LOADING MESSAGE */}
                            {message && (
                                <div
                                    className={
                                        'relative cursor-default select-none py-2 px-4 text-gray-700 dark:text-gray-300'
                                    }
                                >
                                    {message}
                                </div>
                            )}

                            {/* RESULTS */}
                            {filteredItems.map((item: any) => (
                                <Combobox.Option
                                    className={clsx(
                                        'ui-active:bg-brand-500 ui-active:dark:bg-brand-100',
                                        'relative cursor-pointer select-none py-2 pl-10 pr-4 ui-active:text-white'
                                    )}
                                    key={item?.[idKey]}
                                    value={item}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={clsx(
                                                    'block truncate dark:text-gray-200',
                                                    'font-normal ui-selected:font-normal'
                                                )}
                                            >
                                                {item?.[displayKey]}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={
                                                        'absolute inset-y-0 left-0 flex items-center pl-3 text-amber-500 dark:text-amber-400'
                                                    }
                                                >
                                                    <CheckIcon aria-hidden={'true'} className={'h-5 w-5'} />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
};