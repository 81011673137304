import { createContext } from 'react';

import type { IClaimFieldConfig } from '../types';

export interface IClaimFieldContext {
    claimFieldConfig: Array<IClaimFieldConfig> | null;
    setClaimFieldConfig: (claimFieldConfig: Array<IClaimFieldConfig>) => void;
}

export const ClaimConfigContext = createContext<IClaimFieldContext>({
    claimFieldConfig: null,
    setClaimFieldConfig: () => {},
});