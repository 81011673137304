import { useTableRowGroup } from '@react-aria/table';
import { FCC } from '../../types';
import clsx from 'clsx';

interface ITableRowGroupProps {
    type: any;
}

export const TableRowGroup: FCC<ITableRowGroupProps> = ({ children, type }) => {
    const { rowGroupProps } = useTableRowGroup();

    // TABLE HEAD
    if (type === 'thead')
        return (
            <thead {...rowGroupProps} className={'bg-gray-50'}>
                {children}
            </thead>
        );

    // TABLE BODY
    return (
        <tbody {...rowGroupProps} className={clsx('divide-y divide-gray-300 dark:divide-gray-600')}>
            {children}
        </tbody>
    );
};