import { useCalendarCell } from '@react-aria/calendar';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';
import clsx from 'clsx';

import type { CalendarState } from '@react-stately/calendar';
import type { CalendarDate } from '@internationalized/date';
import type { FC } from 'react';

interface ICalendarCellProps {
    date: CalendarDate;
    state: CalendarState;
}

export const CalendarCell: FC<ICalendarCellProps> = ({ date, state }) => {
    const ref = useRef<HTMLDivElement>(null);

    const { focusProps, isFocusVisible } = useFocusRing();

    const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate, isInvalid } =
        useCalendarCell({ date }, state, ref);

    return (
        <td {...cellProps} className={clsx('relative py-0.5', isFocusVisible ? 'z-10' : 'z-0')}>
            <div
                {...mergeProps(buttonProps, focusProps)}
                ref={ref}
                className={clsx(
                    'group h-10 w-10 focus:outline-none dark:text-white',
                    'rounded-full focus:ring-2 focus:ring-brand-600 focus:group-focus:z-10 focus:dark:ring-brand-200',
                    isOutsideVisibleRange ? 'invisible' : 'visible',
                    isSelected && isInvalid && 'bg-red-300',
                    isSelected && !isInvalid && 'rounded-xl bg-brand-500 text-white dark:bg-brand-100'
                )}
            >
                <div
                    className={clsx(
                        'flex h-full w-full cursor-pointer items-center justify-center rounded-full',
                        isDisabled && !isInvalid && 'text-gray-400',
                        isFocusVisible && 'ring-2 ring-brand-600 group-focus:z-10 dark:ring-brand-200',
                        !isSelected && !isDisabled && 'hover:bg-brand-500 hover:text-white hover:dark:bg-brand-100'
                    )}
                >
                    {formattedDate}
                </div>
            </div>
        </td>
    );
};