import { Button, StyledTableCell, Table, TableContainer } from '../../../../components';
import { Cell, Column, Row, TableBody, TableHeader } from '@react-stately/table';
import { EmptyMessage } from './EmptyMessage';

import type { IUpdateVehicleResponse } from '../../../../types';
import type { FC } from 'react';

interface IVehiclesTableProps {
    onRemove: (itemId: number) => void;
    vehicles: Array<IUpdateVehicleResponse>;
}

export const VehicleTable: FC<IVehiclesTableProps> = ({ onRemove, vehicles }) => {
    return (
        <TableContainer className={'w-full'}>
            <Table aria-label={'Added personnel types table'} selectionMode={'single'}>
                {/* HEADER */}
                <TableHeader>
                    <Column>
                        <StyledTableCell isHeader>Vehicle</StyledTableCell>
                    </Column>
                    <Column>
                        <StyledTableCell isHeader isNumeric>
                            Time
                        </StyledTableCell>
                    </Column>
                    <Column>{''}</Column>
                </TableHeader>
                {/* BODY */}
                <TableBody>
                    {vehicles.map((vehicle, i) => (
                        <Row key={i}>
                            <Cell>
                                <StyledTableCell>{vehicle.title}</StyledTableCell>
                            </Cell>
                            <Cell>
                                <StyledTableCell isNumeric>{vehicle.regular}</StyledTableCell>
                            </Cell>
                            <Cell>
                                <StyledTableCell isNumeric>
                                    <Button
                                        buttonProps={{ onPress: () => onRemove(vehicle.itemId) }}
                                        size={'sm'}
                                        variant={'link'}
                                    >
                                        Remove
                                    </Button>
                                </StyledTableCell>
                            </Cell>
                        </Row>
                    ))}
                </TableBody>
            </Table>

            {/* EMPTY MESSAGE */}
            {vehicles.length === 0 && <EmptyMessage>No Vehicles Added</EmptyMessage>}
        </TableContainer>
    );
};