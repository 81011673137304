import { Dialog, Transition } from '@headlessui/react';
import { IModalToggle } from '../../../hooks';
import { FCC } from '../../../types';
import { Fragment } from 'react';

interface IModalProps {
    modalToggle: IModalToggle;
    onClose?: () => void; // override the default close behavior
}

export const Modal: FCC<IModalProps> = ({ children, modalToggle, onClose }) => {
    return (
        <Transition appear as={Fragment} show={modalToggle.isOpen}>
            <Dialog className={'relative z-50'} onClose={onClose || modalToggle.close} open={modalToggle.isOpen}>
                {children}
            </Dialog>
        </Transition>
    );
};