export const useTimeConversion = (): {
    getMinutesFromHHMMSS: (value: string) => number;
    toHHMMSS: (totalMinutes: number) => string;
} => {
    const toHHMMSS = (totalMinutes: number): string => {
        const hours: number = Math.floor(totalMinutes / 3600);
        const minutes: number = Math.floor(totalMinutes / 60) % 60;
        const seconds: number = totalMinutes % 60;

        return [hours, minutes, seconds]
            .map((val: number) => (val < 10 ? `0${val}` : val))
            .filter((val: number | string, index: number) => val !== '00' || index > 0)
            .join(':')
            .replace(/^0/, '');
    };

    const getMinutesFromHHMMSS = (value: string) => {
        const [hourString, minutesString, secondsString]: string[] = value?.split(':');

        const hours: number = Number(hourString);
        const minutes: number = Number(minutesString);
        const seconds: number = Number(secondsString);

        let totalMinutes: number = 0;
        if (!isNaN(hours) && isNaN(minutes) && isNaN(seconds)) totalMinutes = hours;
        else if (!isNaN(hours) && !isNaN(minutes) && isNaN(seconds)) totalMinutes = hours * 60 + minutes;
        else if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) totalMinutes = hours * 60 * 60 + minutes * 60;
        return totalMinutes;
    };

    return { getMinutesFromHHMMSS, toHHMMSS };
};
