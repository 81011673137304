import { SummaryGrid, SummaryGroup, SummaryHeader, SummaryRow, SummarySection } from '../../../../components';
import { CalendarDateTime } from '@internationalized/date';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { IClaim, IClaimInit } from '../../../../types';
import type { FC } from 'react';

interface ILocationDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setStep: (step: number) => void;
}

export const LocationDetailsSummary: FC<ILocationDetailsProps> = ({ claim, setStep }) => {
    const formContext = useFormContext();

    // TIMES
    const damageDateValue = formContext.getValues(claimFields.damageDate) as CalendarDateTime;
    const damageDate = damageDateValue ? `${damageDateValue.month}-${damageDateValue.day}-${damageDateValue.year}` : '';
    const damageTimeHour =
        damageDateValue?.hour === 0
            ? 12
            : damageDateValue?.hour > 12
            ? damageDateValue?.hour - 12
            : damageDateValue?.hour ?? 0;
    const damageTimeAMPM = damageDateValue?.hour >= 12 ? 'PM' : 'AM';
    const damageTime = damageDateValue
        ? `${damageTimeHour}:${
              damageDateValue.minute <= 9 ? `0${damageDateValue.minute}` : damageDateValue.minute
          } ${damageTimeAMPM}`
        : '';

    const discoveryDateValue = formContext.getValues(claimFields.discoveryDate) as CalendarDateTime;
    const discoveryDate = discoveryDateValue
        ? `${discoveryDateValue.month}-${discoveryDateValue.day}-${discoveryDateValue.year}`
        : '';
    const discoveryTimeHour =
        discoveryDateValue?.hour === 0
            ? 12
            : discoveryDateValue?.hour > 12
            ? discoveryDateValue?.hour - 12
            : discoveryDateValue?.hour ?? 0;
    const discoveryTimeAMPM = discoveryDateValue?.hour >= 12 ? 'PM' : 'AM';
    const discoveryTime = discoveryDateValue
        ? `${discoveryTimeHour}:${
              discoveryDateValue.minute <= 9 ? `0${discoveryDateValue.minute}` : discoveryDateValue.minute
          } ${discoveryTimeAMPM}`
        : '';
    const isDamageDateUnknown: boolean = formContext.getValues('isDamageDateUnknown') ?? false;

    // ADDRESS
    const streetAddress: string = formContext.getValues(claimFields.damageStreetAddress);
    const crossStreet: string = formContext.getValues(claimFields.damageCrossStreet) ?? '';
    const crossStreetDisplay = crossStreet.length > 0 ? crossStreet : 'N/A';
    const city: string = formContext.getValues(claimFields.damageCity);
    const stateName: string =
        claim?.stateList.find((s) => s.id === formContext.getValues(claimFields.damageState))?.fullName ??
        formContext.getValues(claimFields.damageState)?.name ??
        'N/A';
    const zipcode: string = formContext.getValues(claimFields.damageZipCode);

    // DAMAGE TYPES
    const locationType: string =
        claim?.damageLocationTypeList?.find((l) => l.id === formContext.getValues(claimFields.damageLocationType))
            ?.name ?? 'N/A';
    const isDamageAerial: string = formContext.getValues(claimFields.wasDamageAerial) ? 'Yes' : 'No';
    const pedPoleAmp: string =
        formContext.getValues(claimFields.facilityNumber)?.length > 0
            ? formContext.getValues(claimFields.facilityNumber)
            : 'N/A';
    const isDamageUnderground: string = formContext.getValues(claimFields.wasDamageUnderground) ? 'Yes' : 'No';
    const depthOfDamage: string = formContext.getValues(claimFields.depthOfDamage) ?? '';
    const depthOfDamageDisplay = depthOfDamage.length > 0 ? depthOfDamage : 'N/A';

    // INTERNAL IDENTIFICATIONS
    const claimHub: string = formContext.getValues(claimFields.claimHub) ?? '';
    const claimHubDisplay = claimHub.length > 0 ? claimHub : 'N/A';
    const claimNode: string = formContext.getValues(claimFields.claimNode) ?? '';
    const remedyId: string = formContext.getValues(claimFields.remedyId) ?? '';
    const remedyIdDisplay = remedyId.length > 0 ? remedyId : 'N/A';

    // Return to the damage location step.
    const onStepClick = () => setStep(0);

    return (
        <div className={'flex flex-col'}>
            {/* STEP LINK */}
            <SummaryHeader isLink onLink={onStepClick}>
                Location Details
            </SummaryHeader>

            <SummaryGroup>
                <SummarySection>
                    <SummaryGrid>
                        {/* TIMES */}
                        <SummaryRow label={'Damage Date'}>{isDamageDateUnknown ? 'Unknown' : damageDate}</SummaryRow>
                        <SummaryRow label={'Damage Time'}>{isDamageDateUnknown ? 'Unknown' : damageTime}</SummaryRow>
                        <SummaryRow label={'Discovery Date'}>{discoveryDate}</SummaryRow>
                        <SummaryRow label={'Discovery Time'}>{discoveryTime}</SummaryRow>

                        {/* ADDRESS */}
                        <SummaryRow label={'Street Address'}>{streetAddress}</SummaryRow>
                        <SummaryRow label={'Cross Street'}>{crossStreetDisplay}</SummaryRow>
                        <SummaryRow label={'City'}>{city}</SummaryRow>
                        <SummaryRow label={'State'}>{stateName}</SummaryRow>
                        <SummaryRow label={'Zip Code'}>{zipcode}</SummaryRow>
                    </SummaryGrid>
                </SummarySection>

                <SummarySection>
                    <SummaryGrid>
                        {/* DAMAGE TYPES */}
                        <SummaryRow label={'Location Type'}>{locationType}</SummaryRow>
                        <SummaryRow hideColon label={'Were above ground facilities damaged?'}>
                            {isDamageAerial}
                        </SummaryRow>
                        <SummaryRow label={'Ped - Pole - Amp'}>{pedPoleAmp}</SummaryRow>
                        <SummaryRow hideColon label={'Were underground facilities damaged?'}>
                            {isDamageUnderground}
                        </SummaryRow>
                        <SummaryRow label={'Depth of damage'}>{depthOfDamageDisplay}</SummaryRow>

                        {/* INTERNAL IDENTIFICATIONS */}
                        <SummaryRow label={'Hub'}>{claimHubDisplay}</SummaryRow>
                        <SummaryRow label={'Node'}>{claimNode}</SummaryRow>
                        <SummaryRow label={'Outage Id'}>{remedyIdDisplay}</SummaryRow>
                    </SummaryGrid>
                </SummarySection>
            </SummaryGroup>
        </div>
    );
};