import { FCCCN } from '../../types';
import clsx from 'clsx';

interface IStyledTableCellProps {
    isHeader?: boolean;
    isConditionalNumeric?: boolean;
    isNumeric?: boolean;
}

export const StyledTableCell: FCCCN<IStyledTableCellProps> = ({
    children,
    className,
    isConditionalNumeric,
    isHeader,
    isNumeric,
}) => {
    return (
        <div
            className={clsx(
                'ml-1 py-1',
                'text-left font-semibold',
                isHeader ? 'tracking wide uppercase' : 'px-2',
                isNumeric ? 'text-end' : '',
                isConditionalNumeric ? 'md:text-end' : '',
                className
            )}
        >
            {children}
        </div>
    );
};