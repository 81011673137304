interface IRoutes {
    completeClaim: (claimId: number) => string;
    dashboard: string;
    newClaim: (marketId: string, officeId: string) => string;
    openClaim: (claimId: number) => string;
    register: string;
    signIn: string;
}

export const appRoutes: IRoutes = {
    dashboard: '/',
    newClaim: (marketId: string, officeId: string) => `/claim/new/${marketId}/${officeId}`,
    openClaim: (claimId: number) => `/claim/open/${claimId}`,
    completeClaim: (claimId: number) => `/claim/complete/${claimId}`,
    register: '/register',
    signIn: '/',
};