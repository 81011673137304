import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { InputClearButton } from '../buttons';
import { useEffect, useRef } from 'react';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { ChangeEvent, FC, MouseEvent } from 'react';

interface IDurationProps {
    checkboxOptions?: AriaCheckboxProps;
    isDisabled?: boolean;
    isRequired?: boolean;
    label: string;
    name: string;
    onChange?: (value: string) => void;
    tooltip: string;
}

export const Duration: FC<IDurationProps> = ({
    checkboxOptions,
    isDisabled,
    isRequired,
    label,
    name,
    onChange,
    tooltip,
}) => {
    const durationRef = useRef<HTMLDivElement>(null);
    const hourRef = useRef<HTMLInputElement>(null);
    const minuteRef = useRef<HTMLInputElement>(null);

    const formContext = useFormContext();
    const formValue = formContext.watch(name);

    const formHour = formValue?.includes(':') ? formValue?.split(':')[0] ?? '0' : '0';
    const formMinute = formValue?.includes(':') ? formValue?.split(':')[1] ?? '00' : '00';

    const errorMessage = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasError = errorMessage.length > 0;

    const hourText = formHour === '1' ? 'hour' : 'hours';
    const minuteText = formMinute === '01' || formMinute === '1' ? 'minute' : 'minutes';

    const isClearShowing =
        !isDisabled && formValue !== '0:00' && formValue !== '' && formValue !== undefined && formValue !== null;

    // Resize the inputs when their values change
    useEffect(() => resizeInputs(), [formHour, formMinute]);

    // Resize the inputs to the width of the text
    const resizeInputs = () => {
        if (hourRef.current) {
            const textWidth = formHour.length > 1 ? formHour.length + 0.5 : 2;
            hourRef.current.style.width = `${textWidth}rem`;
        }
        if (minuteRef.current) {
            const textWidth = formMinute.length > 1 ? formMinute.length + 0.5 : 2;
            minuteRef.current.style.width = `${textWidth}rem`;
        }
    };

    // Hour field changed.
    const onHourChange = (e: ChangeEvent<HTMLInputElement>) => {
        // No longer than 5 digits.
        if (e.target.value.length > 5) return;
        // No characters allowed
        if (e.target.value.length > 0 && e.target.value.match(/\D/g)) return;

        formContext.setValue(name, `${e.target.value}:${formMinute}`);

        // Run provided onChange.
        onChange?.(e.target.value);
    };

    // Minute field changed.
    const onMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
        // No longer than 3 digits.
        if (e.target.value.length > 3) return;
        // No characters allowed
        if (e.target.value.length > 0 && e.target.value.match(/\D/g)) return;

        formContext.setValue(name, `${formHour}:${e.target.value}`);

        // Run provided onChange.
        onChange?.(e.target.value);
    };

    // hour field blurred.
    const onHourBlur = (e: ChangeEvent<HTMLInputElement>) => {
        // Multiple 0 values get set to 0
        // Empty hour values get set to 0.
        if (formHour.trim().length === 0 || Number(formHour) === 0) formContext.setValue(name, `${0}:${formMinute}`);
    };

    // Minute field blurred.
    const onMinuteBlur = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove any leading zeros.
        const noZerosValue = e.target.value?.replace(/^0+/, '');

        // Add leading zero to 0-9.
        if (noZerosValue?.length === 1) formContext.setValue(name, `${formHour}:0${noZerosValue}`);
        // Replace empty minute with 00.
        else if (noZerosValue?.length === 0) formContext.setValue(name, `${formHour}:00`);
        else formContext.setValue(name, `${formHour}:${noZerosValue}`);

        // If invalid shows an error message.
        if (Number(formMinute) > 59 || Number(formMinute) < 0)
            formContext.setError(name, { type: 'manual', message: 'Minutes must be between 0 and 59.' });
        else formContext.clearErrors(name);
    };

    // Select the hour on user focus.
    const onHourFocus = () => hourRef.current?.select();

    // Select the minute on user focus.
    const onMinuteFocus = () => minuteRef.current?.select();

    // Focus the hour input on containing div click.
    const onDurationClick = () => hourRef.current?.focus();

    // Hour + button click.
    const onHourPlusClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onHourChange({ target: { value: `${Number(formHour) + 1}` } } as ChangeEvent<HTMLInputElement>);
    };

    // Hour - button click.
    const onHourMinusClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onHourChange({ target: { value: `${Number(formHour) - 1}` } } as ChangeEvent<HTMLInputElement>);
    };

    // Minute + button click.
    const onMinutePlusClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const minuteNumber = Number(formMinute) + 1 ?? 0;
        if (minuteNumber <= 59) {
            onMinuteChange({ target: { value: `${minuteNumber}` } } as ChangeEvent<HTMLInputElement>);
            onMinuteBlur({ target: { value: `${minuteNumber}` } } as ChangeEvent<HTMLInputElement>);
            if (minuteNumber >= 0) formContext.clearErrors(name);
        }
    };

    // Minute - button click.
    const onMinuteMinusClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const minuteNumber = Number(formMinute) - 1 ?? 0;
        if (minuteNumber >= 0) {
            onMinuteChange({ target: { value: `${minuteNumber}` } } as ChangeEvent<HTMLInputElement>);
            onMinuteBlur({ target: { value: `${minuteNumber}` } } as ChangeEvent<HTMLInputElement>);
            if (minuteNumber <= 59) formContext.clearErrors(name);
        }
    };

    // Clear button click.
    const onClear = () => {
        formContext.clearErrors(name);
        formContext.setValue(name, '0:00');
        hourRef.current?.select();
        onChange?.('');
    };

    return (
        <div className={'flex w-full flex-col'}>
            {/* LABEL */}
            <label>
                {/* TOOLTIP */}
                <Tooltip hasError={hasError} isRequired={isRequired} tooltip={tooltip}>
                    {label}
                </Tooltip>
            </label>

            {/* DURATION FIELD */}
            <div className={'flex flex-row items-center'}>
                <div
                    className={clsx(
                        'flex w-full bg-gray-200 text-sm dark:bg-gray-600 sm:text-base',
                        'relative h-10 items-center py-1.5 pl-2 text-black dark:text-gray-200',
                        'focus-within:border-brand-500 focus-within:ring-0 focus-within:dark:border-brand-100',
                        isDisabled && 'cursor-not-allowed text-gray-400 dark:text-gray-400',
                        hasError ? 'input-error' : 'border-0 border-b-4 border-transparent '
                    )}
                    onClick={onDurationClick}
                    ref={durationRef}
                >
                    <div className={'mt-1 flex space-x-1'}>
                        {/* HOUR */}
                        <div className={'flex'}>
                            <SpinnerButton icon={'minus'} onClick={onHourMinusClick} />
                            <input
                                className={clsx(
                                    'w-full border-gray-400 bg-gray-300 px-2 py-0.5 text-center text-base dark:bg-gray-500',
                                    'focus:bg-brand-500 focus:text-white focus:outline-none focus:dark:bg-brand-100',
                                    isDisabled && 'cursor-not-allowed'
                                )}
                                disabled={isDisabled}
                                onBlur={onHourBlur}
                                onChange={onHourChange}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={onHourFocus}
                                ref={hourRef}
                                value={formHour}
                            />
                            <SpinnerButton icon={'plus'} onClick={onHourPlusClick} />
                            <div className={'mt-1 hidden pr-2 pl-1.5 xxxs:block md:mt-0.5'}>{hourText}</div>
                        </div>

                        <div className={'mt-0.5 block xxxs:mt-0 xxxs:hidden'}>:</div>

                        {/* MINUTE */}
                        <div className={'flex'}>
                            <SpinnerButton icon={'minus'} onClick={onMinuteMinusClick} />
                            <input
                                className={clsx(
                                    'w-full border-gray-400 bg-gray-300 px-2 py-0.5 text-center text-base dark:bg-gray-500',
                                    'focus:bg-brand-500 focus:text-white focus:outline-none focus:dark:bg-brand-100',
                                    isDisabled && 'cursor-not-allowed'
                                )}
                                disabled={isDisabled}
                                onBlur={onMinuteBlur}
                                onChange={onMinuteChange}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={onMinuteFocus}
                                ref={minuteRef}
                                value={formMinute}
                            />
                            <SpinnerButton icon={'plus'} onClick={onMinutePlusClick} />
                            <div className={'mt-1 hidden pl-1.5 xxxs:block md:mt-0.5'}>{minuteText}</div>
                        </div>
                    </div>

                    {/* CLEAR BUTTON */}
                    {isClearShowing && <InputClearButton inputRef={hourRef} name={name} onChange={onClear} />}
                </div>
            </div>

            {/* ERROR MESSAGE */}
            {hasError && <ErrorMessage ariaProps={{}}>{errorMessage}</ErrorMessage>}
        </div>
    );
};

interface SpinnerButtonProps {
    icon: 'plus' | 'minus';
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const SpinnerButton = ({ icon, onClick }: SpinnerButtonProps) => {
    return (
        <button
            className={clsx(
                'outline-none focus-visible:ring-2 focus-visible:ring-brand-500 focus-visible:dark:ring-brand-100'
            )}
            onClick={onClick}
            type={'button'}
        >
            {icon === 'minus' && <MinusIcon className={'h-7 w-5 rounded-l-md border border-gray-400/50'} />}
            {icon === 'plus' && <PlusIcon className={'h-7 w-5 rounded-r-md border border-gray-400/50'} />}
        </button>
    );
};