import { Loader } from '../components';
import { lazyImport } from '../utils';

import type { IRoute } from '../types';

const { DashboardRoutes } = lazyImport(() => import('../features/dashboard'), 'DashboardRoutes');
const { ClaimRoutes } = lazyImport(() => import('../features/claim'), 'ClaimRoutes');

export const ProtectedRoutes: Array<IRoute> = [
    {
        path: '/',
        element: <Loader />,
        children: [
            {
                path: '/',
                element: <DashboardRoutes />,
            },
            {
                path: '/claim/*',
                element: <ClaimRoutes />,
            },
        ],
    },
];