import { useDateFieldState } from '@react-stately/datepicker';
import { createCalendar } from '@internationalized/date';
import { useDateField } from '@react-aria/datepicker';
import { useFormContext } from 'react-hook-form';
import { useLocale } from '@react-aria/i18n';
import { DateSegment } from './DateSegment';
import { useRef } from 'react';
import clsx from 'clsx';

import type { DateSegment as DateSegmentState } from '@react-stately/datepicker';
import type { AriaDatePickerProps } from '@react-aria/datepicker';
import type { DateValue } from '@internationalized/date';
import type { IToggle } from '../../../hooks';
import type { FC } from 'react';

interface IDateFieldProps {
    dateFieldProps: AriaDatePickerProps<DateValue>;
    hasValue: IToggle;
    name: string;
}

export const DateField: FC<IDateFieldProps> = ({ dateFieldProps, hasValue, name }) => {
    const fieldRef = useRef<HTMLDivElement | null>(null);
    const { locale } = useLocale();

    const { register } = useFormContext();
    let { ref: formRef } = register(name);

    const state = useDateFieldState({
        ...dateFieldProps,
        locale,
        createCalendar,
    });

    const { fieldProps } = useDateField({ ...dateFieldProps }, state, fieldRef);

    return (
        <div
            {...fieldProps}
            className={clsx(
                'input-primary flex h-10 pt-1.5 pl-2',
                state.isRequired && !state.value && 'bg-red-100 dark:bg-red-200/80',
                state.isDisabled && 'cursor-not-allowed'
            )}
            ref={(ref) => {
                if (ref === null) return;
                fieldRef.current = ref;
                formRef(ref);
            }}
        >
            {state.segments.map((segment: DateSegmentState, index: number) => (
                <DateSegment
                    hasValue={hasValue}
                    isDisabled={state.isDisabled}
                    key={index}
                    segment={segment}
                    state={state}
                />
            ))}
        </div>
    );
};