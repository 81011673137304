import { XMarkIcon } from '@heroicons/react/20/solid';
import { useFormContext } from 'react-hook-form';
import { useFocusRing } from '@react-aria/focus';
import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';
import clsx from 'clsx';

import type { FC, RefObject } from 'react';

interface IInputClearButtonProps {
    inputRef: RefObject<HTMLInputElement>;
    name: string;
    onChange?: (value: string) => void;
}

export const InputClearButton: FC<IInputClearButtonProps> = ({ inputRef, name, onChange }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const formContext = useFormContext();

    const { focusProps, isFocusVisible } = useFocusRing();

    const { buttonProps } = useButton(
        {
            onPress: () => {
                formContext.setValue(name, '');
                inputRef.current?.focus();
                onChange?.('');
            },
        },
        buttonRef
    );

    return (
        <button
            {...mergeProps(buttonProps, focusProps)}
            className={clsx(
                'absolute right-2 top-2 flex cursor-pointer items-center text-gray-500 outline-none',
                'focus:rounded-sm hover:text-gray-400 active:scale-90 dark:text-gray-200 hover:dark:text-gray-400',
                isFocusVisible &&
                    'keyboard-focus focus-visible:ring-offset-gray-200 focus-visible:dark:ring-offset-gray-600'
            )}
        >
            <XMarkIcon className={'h-6 w-6'} />
        </button>
    );
};