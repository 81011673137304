import { Button, ConfirmModal, ControlledComboBox, Duration, Input } from '../../../../components';
import { useApi, useModalToggle, useReportingTech } from '../../../../hooks';
import useDebounce from '../../../../hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { VehicleTable } from './VehicleTable';
import { useEffect, useState } from 'react';

import type { IPersonnelType } from './Personnel';
import type {
    IGetVehicleRequest,
    IGetVehicleResponse,
    IUpdateVehicleRequest,
    IUpdateVehicleResponse,
} from '../../../../types';

export interface IVehicleType {
    id: string;
    name: string;
}

export const Vehicles = () => {
    const formContext = useFormContext();
    const { getVehicle, postVehicle } = useApi();
    const { reportingTech } = useReportingTech();

    const [idToRemove, setIdToRemove] = useState<number>(0);
    const [mappedVehicles, setMappedVehicles] = useState<Array<IPersonnelType>>([]);
    const [query, setQuery] = useState<string>();
    const [selectedVehicle, setSelectedVehicle] = useState<IVehicleType | null>(null);

    const debouncedQuery = useDebounce(query, 400);

    const removeModal = useModalToggle();

    const allVehicles: Array<IUpdateVehicleResponse> = formContext.watch('allVehicles');

    const hasVehicle = (selectedVehicle?.name.length ?? -1) <= 0;

    const onVehicleQuerySuccess = (data: IGetVehicleResponse) => {
        if (data === null || data?.results === null) return;
        // Have to map the fetched data because it comes back super goofy from the endpoint.
        setMappedVehicles(
            data?.results?.map((result: string) => {
                const idIndex: number = result?.indexOf('^');
                if (idIndex === -1) return { id: '', name: '' };
                return { id: result.substring(idIndex + 1), name: result.substring(0, idIndex) } as IVehicleType;
            })
        );
    };

    const { isLoading: isFetchingVehicles, mutate: searchVehicles } = useMutation({
        mutationFn: getVehicle,
        onSuccess: onVehicleQuerySuccess,
    });

    const onVehicleMutationSuccess = (data: IUpdateVehicleResponse | null) => {
        if (data === null || data.date === '1/1/0001') return;
        setSelectedVehicle(null);
        setMappedVehicles([]);
        formContext?.setValue('allVehicles', [...formContext?.getValues('allVehicles'), data]);
        formContext?.setValue('vehicleRegularTime', '');
    };

    const { isLoading: isUpdatingVehicles, mutate: updateVehicle } = useMutation({
        mutationFn: postVehicle,
        onSuccess: onVehicleMutationSuccess,
    });

    useEffect(() => {
        if (!query) return;
        searchVehicles({
            clientId: reportingTech?.clientId?.toString() ?? '-1',
            marketId: formContext.getValues('marketId'),
            prefixText: query,
        } as IGetVehicleRequest);
    }, [debouncedQuery]);

    const onVehicleChange = (search: string) => setQuery(search);

    const onVehicleSelect = (v: IVehicleType | null) => setSelectedVehicle(v);

    const onAddVehicle = () => {
        const hours: Array<null | string> | undefined = formContext.getValues('vehicleRegularTime')?.split(':');

        const model: IUpdateVehicleRequest = {
            action: 'save',
            claimId: formContext.getValues('claimId'),
            date: '2/2/2022',
            hours: hours ? `${hours[0] ?? 0} hrs,${hours[1] ?? 0} min` : '0 hrs,0 min',
            id: Number(selectedVehicle?.id ?? 0),
        };

        updateVehicle(model);
    };

    const onRemoveVehicle = (itemId: number) => {
        setIdToRemove(itemId);
        removeModal.open();
    };

    const onRemoveVehicleConfirm = () => {
        const filteredVehicles = allVehicles.filter((v) => v.itemId !== idToRemove);

        formContext?.setValue('allVehicles', [...filteredVehicles]);

        const model: IUpdateVehicleRequest = {
            action: 'delete',
            claimId: formContext.getValues('claimId'),
            date: '',
            hours: '',
            id: idToRemove,
        };

        updateVehicle(model);
    };

    return (
        <div className={'flex flex-col items-baseline space-y-2'}>
            <div className={'flex w-full flex-col sm:flex-row sm:space-x-2'}>
                {/* VEHICLE TYPE */}
                <ControlledComboBox
                    debounce={debouncedQuery}
                    displayKey={'name'}
                    idKey={'id'}
                    isLoading={isFetchingVehicles}
                    items={mappedVehicles}
                    label={'Vehicle Type'}
                    onChange={onVehicleChange}
                    onSelect={onVehicleSelect}
                    placeholder={'Start typing to see vehicle types...'}
                    selectedItem={selectedVehicle}
                    setSelectedItem={setSelectedVehicle}
                    tooltip={'What vehicles were used when the repairs were completed?'}
                />

                {/* REGULAR HOURS */}
                <Duration label={'Time'} name={'vehicleRegularTime'} tooltip={'How many hours was the vehicle used?'} />

                {/* ADD A VEHICLE TYPE  */}
                <div className={'flex pt-2 sm:pt-0'}>
                    <Button
                        buttonProps={{ isDisabled: hasVehicle, onPress: onAddVehicle }}
                        className={'place-self-end'}
                        isBusy={isUpdatingVehicles}
                    >
                        Add
                    </Button>
                </div>
            </div>

            {/* ADDED VEHICLE TYPES  */}
            <VehicleTable onRemove={onRemoveVehicle} vehicles={allVehicles} />

            {/* CONFIRM REMOVE MODAL */}
            <ConfirmModal
                message={'Are you sure you want to delete this vehicle?'}
                modalToggle={removeModal}
                onConfirm={onRemoveVehicleConfirm}
                title={'Confirm Delete'}
            />
        </div>
    );
};