import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

export interface IApiError {
    Messages?: string[] | null;
    statusCode?: number;
}

type useErrorHandlingReturn = {
    handleApiError: (err: Error | AxiosError, message?: string) => void;
};

export const useErrorHandling = (): useErrorHandlingReturn => {
    const isAxiosError = (err: Error | AxiosError): boolean => {
        return (err as AxiosError).response !== undefined;
    };

    const handleApiError = (err: Error | AxiosError, message?: string): void => {
        const usedMessage: string = message || err.message;
        try {
            if (!isAxiosError(err)) return;

            const axiosError: AxiosError = err as AxiosError;
            const apiError: IApiError = axiosError?.response?.data as IApiError;

            toast.error(apiError?.Messages?.join(', ') ?? usedMessage);
        } catch (err) {
            toast.error(usedMessage);
        }
    };

    return { handleApiError };
};