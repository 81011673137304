import { ClaimConfigContext } from '../context';
import { useState } from 'react';

import type { FCC, IClaimFieldConfig } from '../types';

export const ClaimFieldConfigProvider: FCC = ({ children }) => {
    const [claimConfig, setClaimConfig] = useState<Array<IClaimFieldConfig> | null>(null);

    return (
        <ClaimConfigContext.Provider value={{ claimFieldConfig: claimConfig, setClaimFieldConfig: setClaimConfig }}>
            {children}
        </ClaimConfigContext.Provider>
    );
};