import { TooltipButton } from './TooltipButton';
import clsx from 'clsx';

import type { FCC } from '../../types';

interface ITooltipProps {
    hasError?: boolean;
    isRequired?: boolean;
    tooltip?: string;
}

export const Tooltip: FCC<ITooltipProps> = ({ children, hasError, isRequired, tooltip }) => {
    return (
        <div className={'flex'}>
            {/* TOOLTIP */}
            <div className={'mr-0.5 mt-0.5'}>
                <TooltipButton isError={hasError} tooltip={tooltip} />
            </div>

            {/* LABEL */}
            <div>
                <span
                    className={clsx(
                        'text-sm font-semibold sm:text-base',
                        hasError ? 'text-red-500' : 'text-gray-800 dark:text-gray-200',
                        isRequired && 'bg-transparent bg-[url(../assets/required.svg)] bg-right-top bg-no-repeat pr-6 '
                    )}
                >
                    {children}
                </span>
            </div>
        </div>
    );
};