interface IUsePhoneNumberProps {
    number?: null | number | string;
}

type usePhoneNumberReturn = {
    formatPhoneNumber: (phone: string, prevPhone?: string) => string;
    isValid: boolean;
    phoneNumber: null | string;
};

export const usePhoneNumber = ({ number }: IUsePhoneNumberProps): usePhoneNumberReturn => {
    let isValid: boolean = true;
    let phoneNumber: null | string = null;

    // No number, invalid.
    if (number === null || number === undefined) isValid = false;

    // Parse phone number.
    const parsedPhoneNumber: string = number?.toString().replace(/\D/g, '') ?? '';

    // Not 10 digits, invalid.
    if (parsedPhoneNumber.length !== 10) isValid = false;

    if (isValid) {
        const areaCode: string = `(${parsedPhoneNumber?.substring(0, 3)})`;
        const exchangeCode: string = `${parsedPhoneNumber?.substring(3, 6)}`;
        const subscriberCode: string = `${parsedPhoneNumber?.substring(6, 10)}`;

        phoneNumber = `${areaCode} ${exchangeCode}-${subscriberCode}`;
    }

    const formatPhoneNumber = (phone: string, prevPhone?: string): string => {
        const areaCode = phone?.substring(0, 3);
        const prefix = phone?.substring(3, 6);
        const lineNumber = phone?.substring(6, 10);
        const ext = phone?.substring(10, 18);
        const newPhone = areaCode + prefix + lineNumber + ext;

        // Helper for backspacing the last digit of the area code and the first digit of the extension.
        const strippedPrevPhone = prevPhone?.replace(/\D/g, '') ?? '';
        const isRemovingAreaCode = strippedPrevPhone?.length === 4 && newPhone.length === 3;
        const isRemovingExtension = strippedPrevPhone?.length === 11 && newPhone.length === 10;

        // Return the properly formatted phone number based on which parts the user has completed.
        if (phone?.length >= 10 && isRemovingExtension) return `(${areaCode})-${prefix}-${lineNumber}`;
        else if (phone?.length >= 11) return `(${areaCode})-${prefix}-${lineNumber} ext. ${ext}`;
        else if (phone?.length >= 7) return `(${areaCode})-${prefix}-${lineNumber}`;
        else if (phone?.length >= 4) return `(${areaCode})-${prefix}`;
        else if (phone?.length >= 3 && isRemovingAreaCode) return `(${areaCode}`;
        else if (phone?.length >= 3) return `(${areaCode})`;
        else if (phone?.length >= 1) return `(${areaCode}`;
        return '';
    };

    return { formatPhoneNumber, isValid, phoneNumber };
};