import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useTableSelectionCheckbox } from 'react-aria';
import { useCheckbox } from '@react-aria/checkbox';
import { useTableCell } from '@react-aria/table';
import { useToggleState } from 'react-stately';
import { useRef } from 'react';
import clsx from 'clsx';

interface ITableCheckboxCellProps {
    cell: any;
    state: any;
}

export const TableCheckboxCell = ({ cell, state }: ITableCheckboxCellProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const tdRef = useRef<HTMLTableCellElement>(null);

    const { checkboxProps } = useTableSelectionCheckbox({ key: cell.parentKey }, state);
    const { gridCellProps } = useTableCell({ node: cell }, state, inputRef);
    const { inputProps } = useCheckbox(checkboxProps, useToggleState(checkboxProps), inputRef);

    const checkboxStyle = clsx(
        checkboxProps.isSelected && 'bg-brand-500 dark:bg-brand-100 bg-white dark:bg-[#4a5568]',
        'focus-visible:border-2 focus-visible:border-brand-500 focus-visible:dark:border-brand-100 focus-visible:shadow-outline',
        'h-5 mr-2 mt-1 w-5 flex flex-shrink-0 justify-center items-center outline-none',
        'text-white cursor-pointer rounded transition ease-in-out duration-300'
    );

    return (
        <td {...gridCellProps} ref={tdRef}>
            {/* Aria Input */}
            <VisuallyHidden>
                <input {...inputProps} ref={inputRef} />
            </VisuallyHidden>

            {/* Checkbox */}
            <label aria-hidden={'true'} className="group flex items-center">
                <div className={checkboxStyle}>
                    <svg className={'h-3 w-3 stroke-current '} viewBox={'0 0 18 18'}>
                        <polyline
                            className={'transition-all duration-300'}
                            fill={'none'}
                            points={'1 9 7 14 15 4'}
                            strokeDasharray={22}
                            strokeDashoffset={checkboxProps.isSelected ? 44 : 66}
                            strokeWidth={3}
                        />
                    </svg>
                </div>
            </label>
        </td>
    );
};