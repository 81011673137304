import type { FCC } from '../../types';

interface ISummaryRowProps {
    hideColon?: boolean;
    label?: string;
}

export const SummaryRow: FCC<ISummaryRowProps> = ({ children, hideColon, label }) => {
    const usedLabel = label ? `${label}${!hideColon ? ':' : ''}` : '';

    return (
        <>
            <div className={'font-bold dark:text-gray-200'}>{usedLabel}&nbsp;</div>
            <div className={'flex flex-col-reverse break-all pl-1 dark:text-gray-300'}>{children}</div>
        </>
    );
};