import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ErrorMessage, Tooltip } from '../../../../components';
import { useErrorHandling } from '../../../../hooks';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { mergeProps } from '@react-aria/utils';
import { useTextField } from 'react-aria';
import clsx from 'clsx';

import type { AriaTextFieldOptions } from 'react-aria';
import type { IState } from '../../../../types';

interface IAddressAutocompleteProps {
    options?: AriaTextFieldOptions<'input'>;
    stateList: Array<IState>;
    tooltip?: string;
}

export const AddressAutocomplete = ({ stateList, options, tooltip }: IAddressAutocompleteProps) => {
    const formContext = useFormContext();
    const formValue = formContext.watch(options?.name ?? '');
    const { handleApiError } = useErrorHandling();

    const name = options?.name ?? '';

    const inputRef = useRef<HTMLInputElement>(null);

    const [address, setAddress] = useState<string>(formValue ?? '');
    const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });

    useEffect(() => {
        if (!formValue) {
            setAddress('');
            return;
        }
        setAddress(formValue);
    }, [formValue]);

    const { labelProps, inputProps, errorMessageProps } = useTextField({ ...options }, inputRef);

    const onGeocode = async (results: Array<google.maps.GeocoderResult>): Promise<void> => {
        const addressComponents: Array<google.maps.GeocoderAddressComponent> = results[0].address_components;

        // City.
        const city =
            addressComponents.find((a: google.maps.GeocoderAddressComponent) =>
                a.types.some((type: string) => type === 'locality')
            )?.long_name ?? '';
        formContext.setValue(claimFields.damageCity, city);
        formContext.clearErrors(claimFields.damageCity);

        // State.
        const state = addressComponents.find((a: google.maps.GeocoderAddressComponent) =>
            a.types.some((type: string) => type === 'administrative_area_level_1')
        );
        const stateId: string =
            stateList.find(
                (s: IState) =>
                    s.name?.toUpperCase() === state?.long_name.toUpperCase() ||
                    s.name?.toUpperCase() === state?.short_name.toUpperCase()
            )?.id ?? '0';
        formContext.setValue(claimFields.damageState, Number(stateId));
        formContext.clearErrors(claimFields.damageState);

        // Zip.
        const zip: string =
            addressComponents.find((a: google.maps.GeocoderAddressComponent) =>
                a.types.some((type: string) => type === 'postal_code')
            )?.short_name ?? '';
        formContext.setValue(claimFields.damageZipCode, zip);
        formContext.clearErrors(claimFields.damageZipCode);

        // Lat/Lng.
        setLatLng(await getLatLng(results[0]));
    };

    const onAddressSelect = (newAddress: string, _placeId?: string, suggestion?: any) => {
        const street: string = suggestion?.formattedSuggestion?.mainText;
        setAddress(street);
        formContext.setValue(claimFields.damageStreetAddress, street);
        formContext.clearErrors(claimFields.damageStreetAddress);
        geocodeByAddress(newAddress).then(onGeocode).catch(handleApiError);
    };

    const errorMessage = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasError: boolean = errorMessage.length > 0;
    return (
        <PlacesAutocomplete
            highlightFirstSuggestion
            onChange={setAddress}
            onSelect={onAddressSelect}
            searchOptions={{ types: ['address'] }}
            value={address}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className={'w-full'}>
                    {/* LABEL */}
                    <label {...labelProps} className={'block'}>
                        <Tooltip hasError={hasError} isRequired={options?.isRequired} tooltip={tooltip}>
                            {options?.label}
                        </Tooltip>
                    </label>

                    <div className={'relative w-full'}>
                        <input
                            {...mergeProps(
                                getInputProps({
                                    className: clsx(
                                        'input-primary h-10',
                                        hasError && 'input-error',
                                        !formValue && 'bg-red-100 dark:bg-red-200 dark:text-gray-600'
                                    ),
                                }),
                                inputProps
                            )}
                            ref={inputRef}
                            value={address}
                        />

                        {/* SUGGESTIONS */}
                        {suggestions.length > 0 && (
                            <div
                                className={
                                    'absolute left-0 top-[44px] z-30 rounded-md border border-gray-300 bg-white shadow-lg dark:border-gray-500 dark:bg-gray-dark-500'
                                }
                            >
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        {...getSuggestionItemProps(suggestion)}
                                        className={clsx(
                                            'w-full cursor-pointer p-1 pl-2',
                                            suggestion.active
                                                ? 'bg-brand-500 font-bold text-gray-200 dark:bg-brand-100'
                                                : 'text-gray-800 dark:text-gray-200'
                                        )}
                                        key={index}
                                    >
                                        {suggestion.description}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* ERROR MESSAGE */}
                    {hasError && <ErrorMessage ariaProps={errorMessageProps}>{errorMessage}</ErrorMessage>}
                </div>
            )}
        </PlacesAutocomplete>
    );
};
