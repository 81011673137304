import { damageDetailsDefaultFormValues } from '../DamageDetails';
import { FormFields, FormHeader, FormSection } from '../form';
import { LocateMarkings } from './LocateMarkings';
import { useValidation } from '../../../../hooks';
import { ListBox } from '../../../../components';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { useEffect, useMemo } from 'react';

import type { IClaim, IClaimInit, IListItem } from '../../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface ILocatesProps {
    claimInit?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const Locates: FC<ILocatesProps> = ({ claimInit, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const showLocateAccuracy: boolean = formContext.getValues(claimFields.wereLocatesPresent) === '1';

    const wereLocatesPresentOptions: Array<IListItem> = [
        { id: '0', name: 'Select an option' },
        { id: '1', name: 'Yes' },
        { id: '2', name: 'No' },
        { id: '3', name: 'NA - No Underground Damage' },
        { id: '4', name: 'Unknown' },
    ];

    // Filter out inactive and non-required locates.
    const locateAccuracies = useMemo(
        () =>
            claimInit?.locateAccuracyList
                ?.filter((a) => a.active && a.isSelectableInMrs)
                .map((a) => {
                    return { id: a.id, name: a.label } as IListItem;
                })
                .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase())) ?? [],
        [claimInit]
    );

    // Include the previously selected locate accuracy if it's not in the list.
    // This allows for compatability with claims started in MRS1/CTS1.
    useEffect(() => {
        // Narrow type to IClaim
        if (claimInit && 'locateAccuracyId' in claimInit) {
            const accuracy = claimInit.locateAccuracyList.find((a) => Number(a.id) === claimInit.locateAccuracyId);
            const alreadyExists =
                locateAccuracies.find((a) => Number(a.id) === claimInit.locateAccuracyId) !== undefined;
            if (accuracy && !alreadyExists)
                locateAccuracies.push({ id: accuracy.id, name: accuracy.label } as IListItem);
        } else {
        }
    }, [claimInit]);

    const onLocateAccuracyChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'LocateAccuracyId']);
        formContext.clearErrors(claimFields.locateAccuracy);
    };

    const onWereLocatesPresentChange = (item: IListItem) => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.wereLocatesPresent,
            claimFields.wereLocatesPresentNA,
            claimFields.wereLocatesPresentUnknown,
        ]);

        // Clear the locate accuracy and marking fields if the answer is no, NA, or unknown.
        if (item.id === '2' || item.id === '3' || item.id === '4') {
            formContext.setValue(
                claimFields.locateAccuracy,
                damageDetailsDefaultFormValues[claimFields.locateAccuracy]
            );
            formContext.setValue(claimFields.locateMarkingFlags, false);
            formContext.setValue(claimFields.locateMarkingPaint, false);
            formContext.setValue(claimFields.locateMarkingStakes, false);
            formContext.setValue('isMarkingUnknown', false);
            formContext.clearErrors(claimFields.locateAccuracy);
            formContext.clearErrors('isMarkingUnknown');
            formContext.clearErrors(claimFields.locateMarkingFlags);
            formContext.clearErrors(claimFields.locateMarkingPaint);
            formContext.clearErrors(claimFields.locateMarkingStakes);
        }

        formContext.setValue(claimFields.wereLocatesPresent, item.id);
        formContext.setValue(claimFields.wereLocatesPresentNA, item.id === '3' ? true : null);
        formContext.setValue(claimFields.wereLocatesPresentUnknown, item.id === '4' ? true : null);
        formContext.clearErrors(claimFields.wereLocatesPresent);
    };

    return (
        <FormSection>
            <FormHeader>Locates</FormHeader>

            <FormFields>
                {/* WERE LOCATES PRESENT? */}
                <ListBox
                    isRequired={fieldRequirements.WereLocatesPresent}
                    label={'Were Locates Present?'}
                    listItems={wereLocatesPresentOptions}
                    name={claimFields.wereLocatesPresent}
                    onSingleChange={onWereLocatesPresentChange}
                    tooltip={'Were locates present at the damage location?'}
                />

                {showLocateAccuracy && (
                    <>
                        {/* LOCATE ACCURACY */}
                        <ListBox
                            isRequired={fieldRequirements.LocateAccuracy}
                            label={'Locate Accuracy'}
                            listItems={locateAccuracies}
                            name={claimFields.locateAccuracy}
                            onSingleChange={onLocateAccuracyChange}
                            tooltip={'Note if locates were visible and accurate or not.'}
                        />

                        {/* LOCATE MARKINGS */}
                        <LocateMarkings setSaveAndQuitChanges={setSaveAndQuitChanges} />
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};