interface IApiEndpoints {
    baseURL: string;
    deleteClaimFile: string;
    getAllClients: string;
    getClaim: (id: string) => string;
    getClaimFieldConfig: (id: number) => string;
    getClaimInit: (marketId: string, officeId: string) => string;
    getConstructionCoordinatorSearch: (clientId: number, searchText: string) => string;
    getTechDashboard: string;
    getClientDomain: (domain: string) => string;
    getLaborSearch: string;
    getMarketsByClientId: (clientId: string) => string;
    getMaterialSearch: string;
    getOfficesByMarketId: (marketId: string) => string;
    getReportingTech: string;
    getReportingTechSupervisorSearch: (clientId: string, search: string) => string;
    getReportingTechByEmail: string;
    getVehicleSearch: string;
    postLabor: string;
    postMaterial: string;
    postReportingTech: string;
    postSaveClaim: string;
    postVehicle: string;
    registerReportingTech: string;
    updateMobileUser: string;
    uploadClaimFiles: string;
    verifyTechEmailAddress: string;
    verifyTechReferenceNumber: string;
}

export const apiEndpoints: IApiEndpoints = {
    baseURL: process.env.REACT_APP_TRACKERS_API_URL ?? '',
    deleteClaimFile: '/ClaimFiles/Delete',
    getAllClients: '/Clients/AllVisibleClients',
    getClaim: (id: string) => `/Claims/${id}`,
    getClaimFieldConfig: (id: number) => `/Claims/ClaimFieldConfig/${id}`,
    getClaimInit: (marketId: string, officeId: string) => `/Claims/ClaimInit/${marketId}/${officeId}`,
    getConstructionCoordinatorSearch: (clientId: number, searchText: string) =>
        `/ConstructionCoordinators/Search/${clientId}/${searchText}`,
    getTechDashboard: '/Claims/Dashboard',
    getClientDomain: (domain: string) => `/Clients/DomainSearch/${domain}`,
    getLaborSearch: '/Labors/Search',
    getMarketsByClientId: (clientId: string) => `/Clients/${clientId}/Markets`,
    getMaterialSearch: '/Materials/Search',
    getOfficesByMarketId: (marketId: string) => `/Clients/${marketId}/Offices`,
    getReportingTech: '/ReportingTechs',
    getReportingTechByEmail: '/ReportingTechs/GetReportingTechByEmail',
    getReportingTechSupervisorSearch: (clientId: string, search: string) =>
        `/ReportingTechs/SupervisorSearch?ClientId=${clientId}&PrefixText=${search}`,
    getVehicleSearch: '/Vehicles/Search',
    postLabor: '/Labors',
    postMaterial: '/Materials',
    postReportingTech: '/ReportingTechs',
    postSaveClaim: '/Claims',
    postVehicle: '/Vehicles',
    registerReportingTech: '/Users/register',
    updateMobileUser: '/reportingTechs/updateMobileUser',
    uploadClaimFiles: '/ClaimFiles',
    verifyTechEmailAddress: 'ReportingTechs/VerifyEmailAddress',
    verifyTechReferenceNumber: 'ReportingTechs/VerifyTechReferenceNumber',
};