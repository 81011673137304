import { ColorModeContext } from '../context';
import { useEffect, useState } from 'react';

import type { FCC } from '../types';

export const ColorModeProvider: FCC = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

    const darkModeLocalStorageName = 'mrs_dark_mode';

    // Set the initial dark mode based on property in local storage.
    useEffect(() => {
        const isDark = localStorage.getItem(darkModeLocalStorageName) === 'true';
        isDark ? enableDarkMode() : disableDarkMode();
    }, []);

    // Enable dark mode.
    const enableDarkMode = () => {
        setIsDarkMode(true);
        document.querySelector('html')?.classList.add('dark');
        localStorage.setItem(darkModeLocalStorageName, 'true');
    };

    // Disable dark mode.
    const disableDarkMode = () => {
        setIsDarkMode(false);
        document.querySelector('html')?.classList.remove('dark');
        localStorage.setItem(darkModeLocalStorageName, 'false');
    };

    return (
        <ColorModeContext.Provider
            value={{
                isDarkMode: isDarkMode,
                isLightMode: !isDarkMode,
                disable: () => disableDarkMode(),
                enable: () => enableDarkMode(),
                toggle: () => (isDarkMode ? disableDarkMode() : enableDarkMode()),
            }}
        >
            {children}
        </ColorModeContext.Provider>
    );
};