import { useApi, useReportingTech } from '../../hooks';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { Button, IconButton } from '../buttons';
import { ListBox } from '../listbox';
import { useState } from 'react';
import clsx from 'clsx';

import type { IListItem, IMarketOffices, IOffice } from '../../types';
import type { Dispatch, SetStateAction } from 'react';

interface IMarketOfficesProps {
    marketOffices: Array<IMarketOffices>;
    setMarketOffices: Dispatch<SetStateAction<Array<IMarketOffices>>>;
}

export const MarketOffices = ({ marketOffices, setMarketOffices }: IMarketOfficesProps) => {
    const { getMarketsByClientId, getOfficesByMarketId } = useApi();
    const { reportingTech } = useReportingTech();
    const formContext = useFormContext();

    const [market, setMarket] = useState<IListItem>();
    const [office, setOffice] = useState<IListItem>();

    const { data: marketData, refetch: refetchMarkets } = useQuery({
        enabled: Number(reportingTech?.clientId) > 0,
        queryFn: () => getMarketsByClientId(reportingTech?.clientId?.toString() ?? ''),
        queryKey: ['markets'],
        refetchOnWindowFocus: false,
    });

    const officesQuery = useQuery({
        enabled: Number(market?.id) > 0,
        queryFn: () => getOfficesByMarketId(market?.id ?? '-1'),
        queryKey: ['offices'],
        refetchOnWindowFocus: false,
    });

    const onMarketChange = (newMarket: IListItem) => {
        setOffice(undefined);
        setMarket(newMarket);
        formContext.setValue('offices', undefined);
        refetchMarkets().then(() => officesQuery.refetch());
    };

    const onRemoveMarketOffice = (marketId: string, officeId: string) => {
        const marketOffice: IMarketOffices | undefined = marketOffices.find(
            (mo: IMarketOffices) => mo.marketId.id === marketId
        );
        if (marketOffice)
            marketOffice.officeIds = marketOffice?.officeIds?.filter((o: IOffice) => o.id !== officeId) ?? [];

        // Remove the market if we removed the last office.
        if (marketOffice?.officeIds?.length === 0)
            setMarketOffices([...marketOffices.filter((mo: IMarketOffices) => mo.marketId.id !== marketId)]);
        else setMarketOffices([...marketOffices]);
    };

    const onAddMarketOffice = (office: IListItem) => {
        if (!market?.id || !office?.id) return;

        // Don't add the same office twice.
        const isOfficeAlreadyAdded: boolean =
            marketOffices?.find((mo: IMarketOffices) => {
                return mo.officeIds?.find((o: IOffice) => o.id === office.id) !== undefined;
            }) !== undefined;
        if (isOfficeAlreadyAdded) {
            setTimeout(() => {
                formContext.setValue('offices', null);
            }, 100);
            return;
        }

        const matchingMarket: IMarketOffices | undefined = marketOffices?.find(
            (mo: IMarketOffices) => mo.marketId.id === market.id
        );

        // Add office to existing market entry or create a new one.
        if (matchingMarket) {
            matchingMarket.officeIds.push(office);
            setMarketOffices([...marketOffices]);
        } else {
            marketOffices.push({
                marketId: market,
                officeIds: [office],
            });
            setMarketOffices([...marketOffices]);
        }

        setTimeout(() => {
            formContext.setValue('offices', null);
        }, 100);
    };

    return (
        <div className={'mb-3'}>
            <h1
                className={clsx(
                    'text-2xl font-semibold text-brand-500 dark:text-brand-100',
                    'min-w-[250px] border-b border-gray-200 pb-2 dark:border-gray-700'
                )}
            >
                {reportingTech?.clientName}
            </h1>

            <div className={'flex h-full w-full flex-col  items-center space-y-5'}>
                <div className={'flex w-full flex-col'}>
                    {/* SELECTED MARKET / OFFICES */}
                    <div className={'flex flex-row flex-wrap self-start'}>
                        {marketOffices?.map((mo: IMarketOffices, marketIndex: number) =>
                            mo.officeIds.map((o: IOffice, officeIndex: number) => {
                                return (
                                    <div
                                        className={clsx(
                                            'mr-1 mt-1 flex flex-row rounded-md border border-gray-400',
                                            'bg-gray-200 p-1.5 text-sm font-medium text-gray-800 dark:text-gray-200',
                                            'dark:bg-gray-dark-500 sm:text-base'
                                        )}
                                        key={`${marketIndex}${officeIndex}`}
                                    >
                                        {mo.marketId.name} - {o.name}
                                        <IconButton
                                            className={'ml-1.5'}
                                            onPress={() => onRemoveMarketOffice(mo.marketId.id, o.id)}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </div>

                    {/* MARKET & OFFICES */}
                    <div className={'mt-2 flex w-full flex-col space-y-4'}>
                        <ListBox
                            label={'Markets'}
                            listItems={marketData?.markets ?? []}
                            name={'markets'}
                            onSingleChange={onMarketChange}
                            tooltip={'Select a market.'}
                        />
                        <ListBox
                            label={'Offices'}
                            listItems={officesQuery.data?.offices ?? []}
                            name={'offices'}
                            onSingleChange={onAddMarketOffice}
                            tooltip={'Select an office.'}
                        />

                        {/* ADD BUTTON */}
                        {/*<div className={'mr-1 self-end'}>*/}
                        {/*    <Button buttonProps={{ onPress: onAddMarketOffice }} size={'sm'}>*/}
                        {/*        Add*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};