import type { IMarket, IMarketOffices, IOffice, IReportingTechCMOAssignmentViewModel } from '../types';

export const mapMarketOffices = (
    cmoAssignments: Array<IReportingTechCMOAssignmentViewModel>
): Array<IMarketOffices> => {
    const mappedMarketOffices: Array<IMarketOffices> = [];

    cmoAssignments.forEach((cmo: IReportingTechCMOAssignmentViewModel) => {
        const mappedMarketOffice: IMarketOffices | undefined = mappedMarketOffices?.find(
            (mmo: IMarketOffices) => Number(mmo.marketId.id) === cmo.marketId
        );
        if (mappedMarketOffice !== undefined) {
            mappedMarketOffice?.officeIds.push({ id: cmo.officeId?.toString(), name: cmo.officeName } as IOffice);
        } else {
            mappedMarketOffices.push({
                marketId: { id: cmo.marketId?.toString(), name: cmo.marketName } as IMarket,
                officeIds: [{ id: cmo.officeId?.toString(), name: cmo.officeName } as IOffice],
            } as IMarketOffices);
        }
    });

    return mappedMarketOffices;
};