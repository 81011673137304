import { Button } from '../buttons';

import type { FCC } from '../../types';

interface ISummaryHeaderProps {
    isLink?: boolean;
    onLink?: () => void;
}

export const SummaryHeader: FCC<ISummaryHeaderProps> = ({ children, isLink, onLink }) => {
    if (isLink)
        return (
            <div className={'mb-1'}>
                <Button buttonProps={{ onPress: onLink }} variant={'link'}>
                    <div className={'text-base font-medium'}>{children}</div>
                </Button>
            </div>
        );

    return <div className={'mb-1 text-base font-medium uppercase text-brand-500 dark:text-brand-100'}>{children}</div>;
};