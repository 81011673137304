import { DamagerKnown, DamagerOpinion } from './damager-details';
import { claimFields } from '../../../config';
import { FormPage } from './form';
import { useEffect } from 'react';

import type { IClaim, IClaimInit, IDamagerDetailsFormValueTypes } from '../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

export const damagerDetailsDefaultFormValues: IDamagerDetailsFormValueTypes = {
    [claimFields.damagerContactName]: '',
    [claimFields.damagerEmailAddress]: '',
    [claimFields.damagerName]: '',
    [claimFields.damagerPhoneNumber]: '',
    [claimFields.damagerStatement]: '',
    [claimFields.damagerIdentified]: '',
    [claimFields.haveDamagerContactDetails]: null,
    isDamagerEmailAddressUnknown: false,
    isDamagerPhoneNumberUnknown: false,
    [claimFields.opinionOnResponsibleParty]: false,
    [claimFields.responsibleForDamage]: 0,
    [claimFields.whoWasDamager]: null,
};

export const damagerDetailsChangeList: Array<string> = [
    'DamagerContactName',
    'DamagerEmail',
    'DamagerIdentificationComments',
    'DamagerName',
    'DamagerPhone',
    'DamagerStatement',
    claimFields.haveDamagerContactDetails,
    claimFields.opinionOnResponsibleParty,
    claimFields.whoWasDamager,
];

interface IDamagerDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamagerDetails: FC<IDamagerDetailsProps> = ({ claim, setSaveAndQuitChanges }) => {
    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormPage>
            {/* DAMAGER OPINION */}
            <DamagerOpinion
                billableParties={claim?.billablePartyTypeList ?? []}
                setSaveAndQuitChanges={setSaveAndQuitChanges}
            />

            {/* DAMAGER KNOWN */}
            <DamagerKnown setSaveAndQuitChanges={setSaveAndQuitChanges} />
        </FormPage>
    );
};