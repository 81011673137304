import { FutureRepairs, LaborDetails, Repairs } from './repair-details';
import { TextArea, Warning, YesNoButton } from '../../../components';
import { useFormContext } from 'react-hook-form';
import { useValidation } from '../../../hooks';
import { claimFields } from '../../../config';
import { useEffect } from 'react';
import { FormPage } from './form';

import type { IClaim, IClaimInit, IRepairDetailsFormValueTypes } from '../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

export const repairDetailsDefaultFormValues: IRepairDetailsFormValueTypes = {
    allMaterials: [],
    allPersonnel: [],
    allVehicles: [],
    [claimFields.areFutureRepairsNeeded]: null,
    [claimFields.claimComments]: '',
    [claimFields.clientConstructionNumber]: '',
    isClientConstructionNumberUnknown: false,
    isFreeFormRepairEntry: null,
    [claimFields.legacyClaimNo]: '',
    material: null,
    materialQuantity: '',
    materials: [],
    overtime: null,
    personnel: null,
    personnels: [],
    [claimFields.repairsDoneOnSite]: null,
    timeOnSite: null,
    vehicle: null,
    vehicleQuantity: '',
    vehicles: [],
};

export const repairDetailsChangesList: Array<string> = [
    'LegacyClaimNo',
    'ClaimComments',
    'RemedyPrismID',
    claimFields.areFutureRepairsNeeded,
    claimFields.repairsDoneOnSite,
    claimFields.isFreeFormRepairEntry,
];

interface IRepairDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const RepairDetails: FC<IRepairDetailsProps> = ({ claim, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const wereRepairsDoneOnSite: boolean | null = formContext.watch(claimFields.repairsDoneOnSite);
    const isFreeFormRepairEntry: boolean | null = formContext.watch(claimFields.isFreeFormRepairEntry);

    const { fieldRequirements } = useValidation({});

    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onAdditionalCommentsInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'ClaimComments', claimFields.repairsDoneOnSite]);
        formContext.setValue(claimFields.repairsDoneOnSite, true);
        formContext.clearErrors(claimFields.repairsDoneOnSite);
    };

    const onRepairDetailsInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            'ClaimComments',
            claimFields.isFreeFormRepairEntry,
            claimFields.repairsDoneOnSite,
        ]);
        formContext.setValue(claimFields.isFreeFormRepairEntry, false);
        formContext.setValue(claimFields.repairsDoneOnSite, true);
        formContext.clearErrors(claimFields.isFreeFormRepairEntry);
    };

    return (
        <FormPage>
            {/* REPAIRS */}
            <Repairs setSaveAndQuitChanges={setSaveAndQuitChanges} />

            {/* LABOR DETAILS */}
            {wereRepairsDoneOnSite !== false && (
                <>
                    <YesNoButton
                        isRequired
                        isSelected={isFreeFormRepairEntry}
                        labelText={
                            'Would you like to enter repair details as free text, or would you like to use drop downs to choose specific labor, materials, and vehicle types?'
                        }
                        tooltip={'How would you like to enter repairs details?'}
                        name={claimFields.isFreeFormRepairEntry}
                        onNoClick={() => {
                            setSaveAndQuitChanges?.((prev) => [
                                ...prev,
                                claimFields.repairsDoneOnSite,
                                claimFields.isFreeFormRepairEntry,
                            ]);
                            formContext.setValue(claimFields.repairsDoneOnSite, true);
                        }}
                        onYesClick={() => {
                            setSaveAndQuitChanges?.((prev) => [
                                ...prev,
                                claimFields.repairsDoneOnSite,
                                claimFields.isFreeFormRepairEntry,
                            ]);
                            formContext.setValue(claimFields.repairsDoneOnSite, true);
                        }}
                        noText={'Text'}
                        yesText={'Fields'}
                    />

                    {/* LABOR DETAILS */}
                    {isFreeFormRepairEntry && <LaborDetails />}

                    {/* REPAIR DETAILS */}
                    {isFreeFormRepairEntry === false && (
                        <>
                            <Warning>
                                {
                                    'Please provide details for onsite repairs. Please include notes, if applicable, on what personnel were onsite and for how long (e.g. two fiber techs for 2 hours), what materials and in what quantities they were used (e.g. 100 ft of Rg-11 cable), and what vehicles were onsite and for how long (e.g. one bucket truck for two hours).'
                                }
                            </Warning>
                            <TextArea
                                className={'h-40'}
                                options={{
                                    isRequired: fieldRequirements.ClaimComments,
                                    label: 'Repair Details',
                                    name: claimFields.claimComments,
                                    onChange: onRepairDetailsInputChange,
                                }}
                                tooltip={'Enter repair details.'}
                            />
                        </>
                    )}

                    {/* Additional Comments */}
                    {isFreeFormRepairEntry && (
                        <TextArea
                            options={{
                                isRequired: fieldRequirements.ClaimComments,
                                label: 'Additional Repair Comments',
                                name: claimFields.claimComments,
                                onChange: onAdditionalCommentsInputChange,
                            }}
                            tooltip={'Do you have anything else to add about the repairs?'}
                        />
                    )}
                </>
            )}

            {/* FUTURE REPAIRS */}
            <FutureRepairs
                altName={claim?.clientConstructionNumberAltName}
                setSaveAndQuitChanges={setSaveAndQuitChanges}
            />
        </FormPage>
    );
};