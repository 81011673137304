import { LoadingOverlay, MenuBar, Warning } from '../components';
import { InteractionStatus } from '@azure/msal-browser';
import { useReportingTech } from '../hooks';
import { useMsal } from '@azure/msal-react';
import { resourceLinks } from '../config';

import type { FCC } from '../types';

export const PrivateLayout: FCC = ({ children }) => {
    const { hasReportingTech } = useReportingTech();
    const { inProgress } = useMsal();

    return (
        <div className={'flex flex-col items-center justify-center'}>
            {/* MENU BAR */}
            <MenuBar />

            {/* PHONE/EMAIL INFORMATION */}
            <Warning className={'w-full'}>
                <p className={'whitespace-normal text-sm sm:text-base'}>
                    If you have any questions or would prefer to report damages by phone, please call:
                    <span
                        className={
                            'ml-1 whitespace-nowrap underline hover:text-gray-600 hover:opacity-75 dark:hover:text-gray-200'
                        }
                    >
                        <a href={`tel:${resourceLinks.reportDamagesByPhone}`}>{resourceLinks.reportDamagesByPhone}</a>
                    </span>
                    .
                </p>
            </Warning>

            {/* DASHBOARD */}
            {hasReportingTech && (
                <div className={'flex w-full max-w-screen-2xl flex-col'}>
                    {inProgress === InteractionStatus.None && (
                        <div className={'min-h-screen w-full bg-gray-100 dark:bg-gray-dark-800'}>{children}</div>
                    )}
                </div>
            )}

            {/* LOADING OVERLAY */}
            {!hasReportingTech && (
                <div className={'h-full w-full'}>
                    <LoadingOverlay />
                </div>
            )}
        </div>
    );
};