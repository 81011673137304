import { FCCCN } from '../../types';
import clsx from 'clsx';

export const TableContainer: FCCCN = ({ children, className }) => {
    return (
        <div
            className={clsx(
                'scrollbar-primary max-h-96 overflow-auto border-2 border-gray-200 dark:border-gray-600',
                className
            )}
        >
            {children}
        </div>
    );
};