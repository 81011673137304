import { BackgroundOverlay, Button, FileUpload, Modal, ModalPanel, ModalTitle } from '../../../components';
import { FormFields, FormHeader, FormPage, FormSection } from './form';
import { useApi, useModalToggle, useToggle } from '../../../hooks';
import { UploadedFiles } from './upload-photos-docs';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import type { IToggle } from '../../../hooks';
import type {
    IDeleteClaimFileRequest,
    IDeleteClaimFileResponse,
    IFile,
    IUploadClaimFilesResponse,
    IUploadFilesFormValueTypes,
} from '../../../types';
import { type Params, useParams } from 'react-router-dom';

export const uploadFilesDefaultFormValues: IUploadFilesFormValueTypes = {
    files: [],
};

interface IUploadPhotosAndDocProps {
    disableButtonsToggle: IToggle;
}

export interface FileWithDescription {
    file: File;
    description: string;
}

export const UploadPhotosAndDocs = ({ disableButtonsToggle }: IUploadPhotosAndDocProps) => {
    const formContext = useFormContext();
    const { claimId: urlClaimId } = useParams<Params>();
    const { deleteClaimFile, uploadClaimFiles } = useApi();

    const [uploadingFiles, setUploadingFiles] = useState<FileWithDescription[]>([]);
    const [fileToAddDescription, setFileToAddDescription] = useState<FileWithDescription>();

    const [description, setDescription] = useState('');

    const isUploading = useToggle(false);
    const isRemoving = useToggle(false);

    const fileUploadModal = useModalToggle();
    const fileUploadDescription = useModalToggle();

    const uploadedFiles = formContext.watch('files');

    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onUploadFilesMutationSuccess = (response: IUploadClaimFilesResponse | null, variables: FormData) => {
        if (!response) return;
        else if (!response.success) {
            toast.error('Error uploading. Please try again.');
            return;
        }

        const files: Array<IFile> = formContext.getValues('files');
        formContext.setValue('files', [
            ...files,
            ...response.fileData.map((file) => {
                return {
                    id: file.id,
                    displayName: file.name,
                    name: file.name,
                    note: uploadingFiles.find((f) => f.file.name === file.name)?.description ?? '',
                };
            }),
        ]);
        toast.success('File(s) Uploaded Successfully!');
    };

    const uploadFilesMutation = useMutation({
        mutationFn: uploadClaimFiles,
        onSuccess: onUploadFilesMutationSuccess,
        onSettled: () => {
            isUploading.off();
            disableButtonsToggle.off();
            setUploadingFiles([]);
        },
    });

    const onFileAccepted = (newFiles: File[]) => {
        // check for duplicates
        const files: Array<IFile> = formContext.getValues('files');
        const duplicates: Array<File> = newFiles.filter((file) => files.some((f) => f.displayName === file.name));
        if (duplicates.length > 0) {
            const fileMessage = duplicates.length > 1 ? 'files have' : 'file has';
            const removeMessage = duplicates.length > 1 ? 'them' : 'it';
            toast.error(
                <>
                    <div className={'mb-1'}>{`The following ${fileMessage} already been uploaded:`}</div>
                    <ul className={'ml-5'}>
                        {duplicates.map((file) => (
                            <li className={'list-disc'} key={file.name}>
                                {file.name}
                            </li>
                        ))}
                    </ul>
                </>,
                { duration: 5000 }
            );
            return;
        }

        setUploadingFiles(newFiles.map((file) => ({ file, description: '' })));
        fileUploadModal.open();
    };

    const onFilesUploaded = () => {
        let claimId = formContext.getValues('claimId') ?? urlClaimId;
        if (claimId === undefined || claimId < 0) return;

        const formData = new FormData();
        formData.set('claimId', claimId);
        uploadingFiles.forEach((file) => {
            formData.append('files', file.file);
            formData.append('notes', file.description.trim().length > 0 ? file.description : '  ');
        });
        isUploading.on();
        disableButtonsToggle.on();
        uploadFilesMutation.mutate(formData);
        fileUploadModal.close();
    };

    const onDeleteFileMutationSuccess = (data: IDeleteClaimFileResponse | null) => {
        if (!data || !data.success) return;

        const files: Array<IFile> = formContext.getValues('files');
        const filteredFiles: Array<IFile> = files.filter((f: IFile) => f.id !== data?.fileId) ?? [];
        formContext.setValue('files', [...filteredFiles]);
        toast.success('File Removed Successfully!');
    };

    const deleteFileMutation = useMutation({
        mutationFn: deleteClaimFile,
        onSuccess: onDeleteFileMutationSuccess,
        onSettled: () => {
            isRemoving.off();
            disableButtonsToggle.off();
        },
    });

    const onFileRemove = (id: number) => {
        const model: IDeleteClaimFileRequest = { fileId: id };
        isRemoving.on();
        disableButtonsToggle.on();
        deleteFileMutation.mutate(model);
    };

    return (
        <FormPage>
            <FormSection>
                <FormHeader>Upload Photos & Documents</FormHeader>
                <FormFields>
                    {/* FILE UPLOADER */}
                    <FileUpload
                        isBusy={isUploading.isOn}
                        isDisabled={isUploading.isOn || isRemoving.isOn}
                        isMultiple
                        onFileAccepted={onFileAccepted}
                    />
                </FormFields>
                {/* UPLOADED FILES */}
                <UploadedFiles
                    files={uploadedFiles ?? []}
                    isRemoving={isRemoving}
                    isUploading={isUploading}
                    onRemove={onFileRemove}
                />
            </FormSection>

            {/* FILE DESCRIPTION */}
            <Modal modalToggle={fileUploadModal}>
                <BackgroundOverlay />
                <ModalPanel className={'max-w-2xl text-gray-800 dark:text-gray-200'}>
                    <ModalTitle>{'File Upload'}</ModalTitle>
                    <div className={'my-4'}>
                        {uploadingFiles.map((file) => (
                            <div className={'mb-2 border p-2 dark:border-gray-400'} key={file?.file.name}>
                                <div className={'flex flex-col'}>
                                    <div>
                                        File Name:<span className={'ml-2'}>{file?.file.name}</span>
                                    </div>
                                    {file.description && <div>File Description: {file.description}</div>}
                                </div>
                                <Button
                                    buttonProps={{
                                        onPress: () => {
                                            setFileToAddDescription(file);
                                            setDescription(file.description ?? '');
                                            fileUploadDescription.open();
                                        },
                                    }}
                                    className={'mr-2'}
                                    size={'xs'}
                                >
                                    {file.description ? 'Update' : 'Add'} Description
                                </Button>
                            </div>
                        ))}
                    </div>

                    <Modal modalToggle={fileUploadDescription}>
                        <BackgroundOverlay />
                        <ModalPanel className={'max-w-lg text-gray-800 dark:text-gray-200'}>
                            <ModalTitle>{'File Description'}</ModalTitle>
                            <textarea
                                className={'input-primary mt-4 h-32'}
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                            />

                            <div className={'mt-4 flex justify-end space-x-2'}>
                                <Button
                                    buttonProps={{
                                        isDisabled: isUploading.isOn,
                                        onPress: () => {
                                            fileUploadDescription.close();
                                            setFileToAddDescription(undefined);
                                        },
                                    }}
                                    variant={'ghost'}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    buttonProps={{
                                        onPress: () => {
                                            fileUploadDescription.close();
                                            setUploadingFiles((prev) => {
                                                setDescription('');
                                                if (fileToAddDescription === undefined) return prev;
                                                else {
                                                    prev[
                                                        prev.findIndex(
                                                            (f) => f.file.name === fileToAddDescription.file.name
                                                        )
                                                    ].description = description;
                                                    return prev;
                                                }
                                            });
                                            setFileToAddDescription(undefined);
                                        },
                                    }}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </ModalPanel>
                    </Modal>

                    <div className={'mt-4 flex justify-end space-x-2'}>
                        <Button
                            buttonProps={{
                                isDisabled: isUploading.isOn,
                                onPress: () => {
                                    fileUploadModal.close();
                                    setUploadingFiles([]);
                                },
                            }}
                            variant={'ghost'}
                        >
                            Cancel
                        </Button>

                        <Button buttonProps={{ onPress: onFilesUploaded }} isBusy={isUploading.isOn}>
                            Confirm
                        </Button>
                    </div>
                </ModalPanel>
            </Modal>
        </FormPage>
    );
};