import { StyledSummaryTableCell, SummaryHeader } from '../../../../components';
import { useFormContext } from 'react-hook-form';

import type { IUpdateMaterialResponse } from '../../../../types';
import type { FC } from 'react';

interface IMaterialsProps {
    setStep: (step: number) => void;
}

export const MaterialSummary: FC<IMaterialsProps> = ({ setStep }) => {
    const formContext = useFormContext();

    const materials: Array<IUpdateMaterialResponse> = formContext.getValues('allMaterials');

    const onStepClick = () => setStep(4);

    return (
        <div>
            <SummaryHeader isLink onLink={onStepClick}>
                Materials
            </SummaryHeader>

            {/* NO MATERIAL MESSAGE */}
            {materials?.length === 0 && (
                <span className={'font-medium dark:text-gray-200'}>No materials were added to this claim.</span>
            )}

            {/* MATERIAL TABLE */}
            {materials?.length !== 0 && (
                <table className={'mt-1'}>
                    <thead>
                        <tr>
                            <StyledSummaryTableCell isHeader>Name</StyledSummaryTableCell>
                            <StyledSummaryTableCell isHeader isNumeric>
                                Length / Quantity
                            </StyledSummaryTableCell>
                        </tr>
                    </thead>
                    <tbody>
                        {materials.map((m) => {
                            return (
                                <tr key={m.itemId}>
                                    <StyledSummaryTableCell>{m.name}</StyledSummaryTableCell>
                                    <StyledSummaryTableCell isNumeric>{m.qty}</StyledSummaryTableCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};