import { TableCheckboxCell } from './TableCheckboxCell';
import { TableColumnHeader } from './TableColumnHeader';
import { useTableState } from '@react-stately/table';
import { TableHeaderRow } from './TableHeaderRow';
import { TableRowGroup } from './TableRowGroup';
import { useTable } from '@react-aria/table';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';
import { useRef } from 'react';

export const Table = (props: any) => {
    const ref = useRef<HTMLTableElement>(null);
    const state = useTableState({
        ...props,
        selectionMode: 'none',
        showSelectionCheckboxes: props?.selectionMode === 'multiple' ?? 'single',
    });
    const { gridProps } = useTable(props, state, ref);

    return (
        <table {...gridProps} className={'w-full'} ref={ref}>
            {/* HEAD */}
            <TableRowGroup type={'thead'}>
                {state.collection.headerRows.map((headerRow) => (
                    <TableHeaderRow item={headerRow} key={headerRow.key} state={state}>
                        {/* COLUMN HEADER */}
                        {[...headerRow.childNodes].map((column) => (
                            <TableColumnHeader column={column} key={column.key} state={state} />
                        ))}
                    </TableHeaderRow>
                ))}
            </TableRowGroup>

            {/* BODY */}
            <TableRowGroup type={'tbody'}>
                {[...state.collection.body.childNodes].map((row) => (
                    <TableRow item={row} key={row.key} state={state}>
                        {/* ROWS */}
                        {[...row.childNodes].map((cell) =>
                            cell.props.isSelectionCell ? (
                                <TableCheckboxCell cell={cell} key={cell.key} state={state} />
                            ) : (
                                <TableCell cell={cell} key={cell.key} state={state} />
                            )
                        )}
                    </TableRow>
                ))}
            </TableRowGroup>
        </table>
    );
};