import { useEffect, useState } from 'react';

// const [value, setValue] = useState<number>(0);
// const debouncedValue = useDebounce(value, 800);
// useEffect(() => {
//    code here will fire when 800ms have passed since last change to value
// }, [debouncedValue])

const useDebounce = <T>(value: T, delay?: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;