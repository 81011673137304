import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useCalendarState } from '@react-stately/calendar';
import { createCalendar } from '@internationalized/date';
import { useCalendar } from '@react-aria/calendar';
import { CalendarButton } from './CalendarButton';
import { CalendarGrid } from './CalendarGrid';
import { useLocale } from '@react-aria/i18n';
import { useRef } from 'react';

import type { CalendarProps } from '@react-aria/calendar';
import type { DateValue } from '@internationalized/date';
import type { FC } from 'react';

interface ICalendarProps {
    ariaCalendarProps: CalendarProps<DateValue>;
}

export const Calendar: FC<ICalendarProps> = ({ ariaCalendarProps }) => {
    const calendarRef = useRef<HTMLDivElement>(null);

    const { locale } = useLocale();

    const state = useCalendarState({
        ...ariaCalendarProps,
        locale,
        createCalendar,
    });

    const { calendarProps, prevButtonProps, nextButtonProps, title: monthName } = useCalendar(ariaCalendarProps, state);

    return (
        <div {...calendarProps} className={'inline-block text-gray-800 text-black dark:text-white'} ref={calendarRef}>
            <div className={'flex items-center justify-between pb-4'}>
                {/* DECREMENT MONTH */}
                <CalendarButton ariaButtonProps={prevButtonProps}>
                    <ChevronLeftIcon className={'h-6 w-6'} />
                </CalendarButton>

                {/* MONTH NAME */}
                <h2 className={'ml-2 flex text-xl font-bold '}>{monthName}</h2>

                {/* INCREMENT MONTH */}
                <CalendarButton ariaButtonProps={nextButtonProps}>
                    <ChevronRightIcon className={'h-6 w-6'} />
                </CalendarButton>
            </div>
            {/* CALENDAR DATES */}
            <CalendarGrid state={state} />
        </div>
    );
};