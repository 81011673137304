import { Calendar, DateField, FieldButton, Popover, ResetButton, TimeField } from './components';
import { CalendarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useDatePickerState } from '@react-stately/datepicker';
import { FadeAndScaleTransition } from '../transitions';
import { useDatePicker } from '@react-aria/datepicker';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { useEffect, useRef } from 'react';
import { useToggle } from '../../hooks';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

import type { DatePickerStateOptions } from 'react-stately';
import type { TimeValue } from '@react-types/datepicker';

interface IDatePickerProps {
    customErrorMessage?: string;
    datePickerProps?: DatePickerStateOptions;
    name: string;
    onReset?: () => void;
    timeFieldLabel?: string;
    tooltip?: string;
}

export const DatePicker = ({
    customErrorMessage,
    datePickerProps = {},
    name,
    onReset,
    timeFieldLabel = 'Date & Time',
    tooltip,
}: IDatePickerProps) => {
    const datepickerRef = useRef<HTMLDivElement>(null);

    const formContext = useFormContext();

    const hasValue = useToggle(false);

    const state = useDatePickerState(datePickerProps);

    const { buttonProps, calendarProps, dialogProps, errorMessageProps, fieldProps, groupProps, labelProps } =
        useDatePicker(
            {
                ...datePickerProps,
                'aria-label': datePickerProps?.label?.toString(),
                hideTimeZone: true,
            },
            state,
            datepickerRef
        );

    const errorMessage = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasError = state.validationState === 'invalid' || errorMessage.length > 0;

    // Set the time to midnight if there is no time.
    // This allows a user to select just the hour/minute and not the period (am/pm) and still have it work correctly.
    useEffect(() => {
        if (state.timeValue !== null) return;
        state.setTimeValue({ hour: 0, minute: 0, second: 0 } as TimeValue);
    }, [state.timeValue]);

    const onClose = () => state.setOpen(false);

    const onTimeChange = (newTime: TimeValue) => {
        state.setTimeValue(newTime);
        formContext.setValue(timeFieldLabel, newTime);
    };

    const onResetInternal = () => {
        onReset?.();
        hasValue.off();
    };

    return (
        <div className={'relative flex w-full flex-col text-left'}>
            {/* LABEL */}
            <label {...labelProps} className={'block text-sm font-medium'}>
                <Tooltip hasError={hasError} isRequired={datePickerProps?.isRequired} tooltip={tooltip}>
                    {datePickerProps?.label}
                </Tooltip>
            </label>

            {/* INPUT */}
            <div {...groupProps} className={clsx('inline-flex w-full', hasError && 'input-error')} ref={datepickerRef}>
                {/* DATE FIELD */}
                <DateField dateFieldProps={fieldProps} hasValue={hasValue} name={name} />

                {/* CLEAR BUTTON */}
                {hasValue.isOn && (
                    <ResetButton
                        ariaProps={{
                            ...buttonProps,
                            isDisabled: datePickerProps?.isDisabled,
                            onPress: onResetInternal,
                        }}
                    >
                        <XMarkIcon className={'h-6 w-6'} />
                    </ResetButton>
                )}

                {/* CALENDAR ICON */}
                <FieldButton
                    ariaProps={{ ...buttonProps, isDisabled: datePickerProps?.isDisabled }}
                    hasValue={state.value !== undefined && state.value !== null}
                    isRequired={datePickerProps?.isRequired}
                >
                    <CalendarIcon className={'h-6 w-6'} />
                </FieldButton>
            </div>

            {/* CALENDAR POP-OUT WINDOW */}
            <FadeAndScaleTransition className={'z-20'} isOpen={state.isOpen}>
                <Popover dialog={dialogProps} isOpen={state.isOpen} onClose={onClose}>
                    {/* DATES */}
                    <Calendar ariaCalendarProps={calendarProps} />

                    {/* TIMES */}
                    <TimeField
                        hasValue={hasValue}
                        label={timeFieldLabel || 'Time'}
                        name={timeFieldLabel}
                        onChange={onTimeChange}
                        value={state.timeValue}
                    />
                </Popover>
            </FadeAndScaleTransition>

            {/* ERROR MESSAGE */}
            {errorMessage && (
                <ErrorMessage ariaProps={errorMessageProps}>
                    {errorMessage}
                    &nbsp;
                </ErrorMessage>
            )}

            {/* INVALID DATE MESSAGE */}
            {state.validationState === 'invalid' && (
                <ErrorMessage ariaProps={errorMessageProps}>{customErrorMessage}</ErrorMessage>
            )}
        </div>
    );
};
