import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi, useErrorHandling } from '../../../hooks';
import { TechnicianFields } from './TechnicianFields';
import { SupervisorFields } from './SupervisorFields';
import { Button, ListBox } from '../../../components';
import { useFormContext } from 'react-hook-form';
import { MarketOffices } from './MarketOffices';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import type { Dispatch, FC, SetStateAction } from 'react';
import type { IToggle } from '../../../hooks';
import type {
    IClient,
    IListItem,
    IMarketOffices,
    IOffice,
    IVerifyReferenceNumberResponse,
    IVerifyTechEmailAddressResponse,
} from '../../../types';

interface IRegisterFormProps {
    client: IClient;
    isSaving: boolean;
    isValidMarketOffice?: boolean;
    marketOffices: Array<IMarketOffices>;
    onRegister: () => Promise<void>;
    setIsUniqueEmail: IToggle;
    setIsUserNameUnique: IToggle;
    setIsValidMarketOffice: IToggle;
    setMarketOffices: Dispatch<SetStateAction<Array<IMarketOffices>>>;
}

export const RegisterForm: FC<IRegisterFormProps> = ({
    client,
    isSaving,
    isValidMarketOffice,
    marketOffices,
    onRegister,
    setIsUniqueEmail,
    setIsUserNameUnique,
    setIsValidMarketOffice,
    setMarketOffices,
}) => {
    const formContext = useFormContext();
    const { getMarketsByClientId, getOfficesByMarketId, verifyTechEmailAddress, verifyTechReferenceNumber } = useApi();
    const { handleApiError } = useErrorHandling();

    const [market, setMarket] = useState<IListItem>();
    const [markets, setMarkets] = useState<Array<IListItem> | null>([]);
    const [office, setOffice] = useState<IListItem>();
    const [offices, setOffices] = useState<Array<IListItem> | null>([]);

    const verifyReferenceNumberMutation = useMutation({
        mutationFn: verifyTechReferenceNumber,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: (response: IVerifyReferenceNumberResponse | null) => {
            if (response === null || response?.reportingTech?.length === 0) {
                setIsUserNameUnique.on();
                formContext.clearErrors('techReferenceNumber');
            } else {
                setIsUserNameUnique.off();
                formContext.setError('techReferenceNumber', {
                    message: 'This user name is already taken.',
                });
            }
        },
    });

    const verifyTechEmailAddressMutation = useMutation({
        mutationFn: verifyTechEmailAddress,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: (response: IVerifyTechEmailAddressResponse | null) => {
            if (response?.reportingTech === null) {
                setIsUniqueEmail.on();
                formContext.clearErrors('techEmailAddress');
            } else {
                if (
                    formContext.getValues('isExistingReportingTech') === true &&
                    response?.reportingTech.emailAddress !== formContext.getValues('existingReportingTechEmailAddress')
                ) {
                    setIsUniqueEmail.off();
                    formContext.setError('techEmailAddress', {
                        message: 'An account with this email address already exists.',
                    });
                } else {
                    setIsUniqueEmail.on();
                    formContext.clearErrors('techEmailAddress');
                }
            }
        },
    });

    const marketsQuery = useQuery({
        enabled: Number(client.id) > 0,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: (response: { markets: Array<IListItem> | null }) => setMarkets(response.markets ?? []),
        queryFn: () => getMarketsByClientId(client.id),
        queryKey: ['markets'],
        refetchOnWindowFocus: false,
    });

    const officesQuery = useQuery({
        enabled: Number(market?.id) > 0,
        onError: (error: Error | AxiosError) => handleApiError(error),
        onSuccess: (response: { offices: Array<IListItem> | null }) => setOffices(response.offices ?? []),
        queryFn: () => getOfficesByMarketId(market?.id ?? '-1'),
        queryKey: ['offices'],
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        officesQuery.refetch().then();
    }, [market]);

    useEffect(() => {
        marketsQuery.refetch().then();
    }, [client]);

    useEffect(() => {
        setTimeout(() => {
            formContext.setValue('offices', null);
        }, 100);
    }, [office]);

    const onAddMarketOffice = (office: IListItem) => {
        if (!market?.id || !office?.id) return;

        setOffice(office);
        setIsValidMarketOffice.on();

        // Don't add the same office twice.
        const isOfficeAlreadyAdded: boolean =
            marketOffices.find((mo: IMarketOffices) => {
                return mo.officeIds.find((o: IOffice) => o.id === office.id) !== undefined;
            }) !== undefined;
        if (isOfficeAlreadyAdded) return;

        const matchingMarket: IMarketOffices | undefined = marketOffices.find(
            (mo: IMarketOffices) => mo.marketId.id === market.id
        );

        // Add office to existing market entry or create a new one.
        if (matchingMarket) {
            matchingMarket.officeIds.push(office);
            setMarketOffices([...marketOffices]);
        } else {
            marketOffices.push({
                marketId: market,
                officeIds: [office],
            });
            setMarketOffices([...marketOffices]);
        }
    };

    const onMarketChange = (newMarket: IListItem) => {
        formContext.setValue('offices', undefined);
        setMarket(newMarket);
    };

    return (
        <div className={'flex w-full flex-col items-center justify-center'}>
            <div className={'flex w-[25rem] flex-col sm:w-[40rem] lg:w-[56rem]'}>
                <div className={'flex w-full flex-col px-4'}>
                    {/* CLIENT DETAILS */}
                    <div className={'my-6 self-center text-3xl font-medium text-brand-100'}>
                        CLIENT - {client?.name}
                    </div>

                    {/* MARKET / OFFICES */}
                    <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                        <ListBox
                            isRequired
                            label={'Markets'}
                            listItems={markets ?? []}
                            name={'markets'}
                            onSingleChange={onMarketChange}
                            placeholder={'Add a market'}
                            tooltip={'Select a market to see the offices.'}
                        />
                        <ListBox
                            isRequired
                            label={'Offices'}
                            listItems={offices ?? []}
                            name={'offices'}
                            onSingleChange={onAddMarketOffice}
                            placeholder={'Add an office'}
                            tooltip={'Select the office you want to be able to submit claims for.'}
                        />
                    </div>

                    {/* ERROR MESSAGE */}
                    {(!isValidMarketOffice || marketOffices.length === 0) && (
                        <div className={'font-medium text-red-500 dark:text-red-600'}>
                            You must select at least one market/office combination.
                        </div>
                    )}

                    {/* SELECTED MARKET / OFFICES */}
                    <MarketOffices marketOffices={marketOffices} setMarketOffices={setMarketOffices} />

                    {/* TECHNICIAN DETAILS */}
                    <TechnicianFields
                        clientId={Number(client.id)}
                        setIsUniqueEmail={setIsUniqueEmail}
                        setIsUserNameUnique={setIsUserNameUnique}
                    />

                    {/* SUPERVISOR DETAILS */}
                    <SupervisorFields clientId={client.id} />

                    {/* REGISTER */}
                    <div className={'my-10 flex w-full'}>
                        <Button
                            busyText={'Registering'}
                            buttonProps={{ isDisabled: isSaving, onPress: onRegister }}
                            className={'flex w-full justify-center'}
                            isBusy={isSaving}
                        >
                            <div className={'w-full'}>Register</div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};