import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ErrorFallback, PrivateLayout, PublicLayout } from '../layout';
import { ClaimFieldConfigProvider } from './ClaimFieldConfigProvider';
import { ReportingTechProvider } from './ReportingTechProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { TailwindToaster } from '../components';

import type { FCC } from '../types';

export const AppProvider: FCC = ({ children }) => {
    const { instance } = useMsal();

    const onError = async () => await instance.logout();

    return (
        <Router>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onError}>
                <AuthenticatedTemplate>
                    <ReportingTechProvider>
                        <ClaimFieldConfigProvider>
                            <PrivateLayout>{children}</PrivateLayout>
                        </ClaimFieldConfigProvider>
                    </ReportingTechProvider>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <PublicLayout>{children}</PublicLayout>
                </UnauthenticatedTemplate>
                <TailwindToaster />
            </ErrorBoundary>
        </Router>
    );
};