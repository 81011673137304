import { SummaryHeader } from '../../../../components';
import { useFormContext } from 'react-hook-form';

import type { IFile } from '../../../../types';
import type { FC } from 'react';

interface ILocationDetailsProps {
    setStep: (step: number) => void;
}

export const PhotosAndDocumentsSummary: FC<ILocationDetailsProps> = ({ setStep }) => {
    const formContext = useFormContext();

    const files: Array<IFile> = formContext.getValues('files') ?? [];

    const onStepClick = () => setStep(1);

    return (
        <div className={'flex w-full flex-col'}>
            {/* STEP LINK */}
            <SummaryHeader isLink onLink={onStepClick}>
                Photos & Documents
            </SummaryHeader>

            {/* NO FILES MESSAGE */}
            {files?.length === 0 && (
                <span className={'font-medium dark:text-gray-200'}>No files were added to this claim.</span>
            )}

            {/* FILES */}
            <div className={'flex flex-col'}>
                {files?.map((f) => (
                    <div className={'mt-0.5'} key={f.id}>
                        <span className={'font-bold'}>{f.displayName}</span>
                        {f.note && <span> - {f.note}</span>}
                    </div>
                ))}
            </div>
        </div>
    );
};