import { useState } from 'react';

interface IUseStepsProps {
    initialStep?: number;
    totalSteps?: number;
}

export const useSteps = ({ initialStep = 0, totalSteps = 0 }: IUseStepsProps) => {
    const [activeStep, setActiveStep] = useState<number>(initialStep);

    const nextStep = (): void => {
        if (totalSteps !== undefined) setActiveStep((prev) => Math.min(totalSteps, prev + 1));
        else setActiveStep((prev: number) => prev + 1);
    };

    const prevStep = (): void => setActiveStep((prev) => Math.max(0, prev - 1));

    const reset = (): void => setActiveStep(initialStep);

    const setStep = (step: number): void => setActiveStep(step > totalSteps ? totalSteps : step < 0 ? 0 : step);

    return { activeStep, nextStep, prevStep, reset, setStep };
};