import { useApi, useReportingTech } from '../../hooks';
import { useMutation } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { Input } from '../inputs';

import type { IVerifyTechEmailAddressResponse } from '../../types';
import type { IToggle } from '../../hooks';
import { FC, useEffect, useState } from 'react';
import { PasswordWarning } from '../../features/auth/components';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

interface ITechnicianFieldsProps {
    uniqueEmailToggle: IToggle;
}

export const TechnicianFields: FC<ITechnicianFieldsProps> = ({ uniqueEmailToggle }) => {
    const formContext = useFormContext();
    const { reportingTech } = useReportingTech();
    const { verifyTechEmailAddress } = useApi();
    const [originalTechEmailAddress, setOriginalTechEmailAddress] = useState<string | null>(null);
    const [techEmailAddressChanged, setTechEmailAddressChanged] = useState<boolean>(false);

    useEffect(() => {
        setOriginalTechEmailAddress(formContext.getValues('techEmailAddress'));
    }, []);

    const onVerifyEmailAddress = (response: IVerifyTechEmailAddressResponse | null) => {
        if (response?.reportingTech === null) {
            uniqueEmailToggle.on();
            formContext.clearErrors('techEmailAddress');
        } else {
            if (
                reportingTech?.emailAddress?.trim().toUpperCase() ===
                formContext.getValues('techEmailAddress')?.trim()?.toUpperCase()
            )
                return;
            uniqueEmailToggle.off();
            formContext.setError('techEmailAddress', {
                message: 'An account with this email address already exists.',
            });
        }
    };

    const { mutate: verifyEmail } = useMutation({
        mutationFn: verifyTechEmailAddress,
        onSuccess: onVerifyEmailAddress,
    });

    const onBlurEmailAddress = () => {
        const email: string = formContext.getValues('techEmailAddress');
        setTechEmailAddressChanged(email !== originalTechEmailAddress);
        if (!email || email?.trim().length === 0) return;
        verifyEmail({
            clientId: -1,
            marketId: -1,
            prefixText: email,
        });
    };

    return (
        <div>
            <h1 className={'min-w-[250px] border-b pb-2 text-2xl font-semibold text-brand-500 dark:text-brand-100'}>
                Technician
            </h1>

            <div className={'flex w-full flex-col items-center space-y-3 sm:mt-3'}>
                {/* TECH ID */}
                <Input
                    className={'text-gray-400'}
                    inputOptions={{
                        isDisabled: true,
                        label: 'Username',
                        name: 'techId',
                    }}
                    tooltip={
                        'The unique tech username that was chosen when the account was created. This cannot be changed.'
                    }
                />

                {/* FIRST NAME */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'First Name',
                        name: 'techFirstName',
                    }}
                    tooltip={'The first name of the technician.'}
                />

                {/* LAST NAME */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'Last Name',
                        name: 'techLastName',
                    }}
                    tooltip={'The last name of the technician.'}
                />

                {/* PHONE NUMBER */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'Phone Number',
                        name: 'techPhoneNumber',
                    }}
                    tooltip={'A phone number that the technician can be reached at.'}
                    type={'phone'}
                />

                {/* EMAIL ADDRESS */}
                <Input
                    inputOptions={{
                        autoComplete: 'email',
                        isRequired: true,
                        label: 'Email Address',
                        name: 'techEmailAddress',
                        onBlur: onBlurEmailAddress,
                    }}
                    tooltip={'An email address that the technician can be reached at.'}
                />

                {techEmailAddressChanged && (
                    <>
                        <div
                            className={'flex flex-row items-center bg-orange-100 p-1.5 text-gray-900 dark:bg-orange-600 dark:text-gray-100'}>
                            <ExclamationTriangleIcon className={'mr-1 h-5 w-5 flex-shrink-0'} />
                            Please reenter your password or create a new one. You will be signed out and will need to sign in
                            again with your updated login information.
                        </div>

                        {/* PASSWORD */}
                        <Input
                            inputOptions={{
                                isRequired: true,
                                label: 'Password',
                                name: 'password',
                                type: 'password',
                            }}
                            tooltip={
                                'A password for the technician. Must be at least 8 characters. Must contain at least one capital letter, one lowercase letter, one number and one special character.'
                            }
                        />

                        {/* CONFIRM PASSWORD */}
                        <Input
                            inputOptions={{
                                isRequired: true,
                                label: 'Confirm Password',
                                name: 'confirmPassword',
                                type: 'password',
                            }}
                            tooltip={
                                'A password for the technician. Must be at least 8 characters. Must contain at least one capital letter, one lowercase letter, one number and one special character.'
                            }
                        />

                        {/* PASSWORD FORMAT WARNING */}
                        <PasswordWarning />
                    </>
                )}
            </div>
        </div>
    );
};