import { resolveValue, Toast, Toaster, ToastIcon } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import React from 'react';
import clsx from 'clsx';

export const TailwindToaster = () => {
    return (
        <Toaster
            toastOptions={{
                error: { duration: 5000, iconTheme: { primary: 'white', secondary: 'black' }, position: 'top-center' },
                success: {
                    duration: 3000,
                    iconTheme: { primary: 'white', secondary: 'black' },
                    position: 'bottom-center',
                },
            }}
        >
            {(toast: Toast) => (
                <Transition
                    appear
                    className={clsx(
                        'flex transform rounded bg-gray-200 p-4 shadow-lg dark:bg-gray-dark-200 dark:text-gray-200',
                        toast.type === 'error' &&
                            'bg-red-600 font-medium text-white dark:bg-red-500 dark:text-gray-800',
                        toast.type === 'success' && 'bg-emerald-700 text-white dark:bg-emerald-500'
                    )}
                    enter={'transition-all duration-150'}
                    enterFrom={'opacity-0 scale-50'}
                    enterTo={'opacity-100 scale-100'}
                    leave={'transition-all duration-150'}
                    leaveFrom={'opacity-100 scale-100'}
                    leaveTo={'opacity-0 scale-75'}
                    show={toast.visible}
                >
                    <ToastIcon toast={toast} />
                    <p className={'px-2 text-white'}>{resolveValue(toast.message, toast)}</p>
                </Transition>
            )}
        </Toaster>
    );
};