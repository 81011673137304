import { ListBox, YesNoButton } from '../../../../components';
import { FormFields, FormHeader, FormSection } from '../form';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';
import type { IListItem } from '../../../../types';

interface IDamagerOpinionProps {
    billableParties: Array<IListItem>;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const DamagerOpinion: FC<IDamagerOpinionProps> = ({ billableParties, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const hasOpinion: boolean | null = formContext.watch(claimFields.opinionOnResponsibleParty);

    const onHasOpinionNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.opinionOnResponsibleParty]);
        formContext.setValue(claimFields.responsibleForDamage, 0);
        formContext.clearErrors(claimFields.responsibleForDamage);
    };

    const onHasOpinionYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.opinionOnResponsibleParty]);
        formContext.setValue(claimFields.opinionOnResponsibleParty, true);
        formContext.clearErrors(claimFields.opinionOnResponsibleParty);
    };

    const onResponsibleForDamageChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.opinionOnResponsibleParty]);
        formContext.setValue(claimFields.opinionOnResponsibleParty, true);
        formContext.clearErrors(claimFields.opinionOnResponsibleParty);
        formContext.clearErrors(claimFields.responsibleForDamage);
    };

    return (
        <FormSection>
            <FormHeader>Damager Opinion</FormHeader>

            <FormFields>
                {/* HAS OPINION? */}
                <YesNoButton
                    isSelected={hasOpinion}
                    labelText={'Do you have an opinion on who was responsible for the damage?'}
                    name={claimFields.opinionOnResponsibleParty}
                    onNoClick={onHasOpinionNoClick}
                    onYesClick={onHasOpinionYesClick}
                    tooltip={'Do you think you know who may have been responsible for the damage?'}
                />

                {hasOpinion !== false && (
                    <>
                        {/* RESPONSIBLE PARTY */}
                        <ListBox
                            isRequired={fieldRequirements.ResponsibleForDamage}
                            label={'Who was responsible for the damage?'}
                            listItems={billableParties}
                            name={claimFields.responsibleForDamage}
                            onSingleChange={onResponsibleForDamageChange}
                            tooltip={'Who do you think is responsible for the damage?'}
                        />
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};