import { FormFields, FormHeader, FormSection } from '../form';
import { Input, Tooltip, YesNoButton } from '../../../../components';
import { useApi, useReportingTech, useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { Dispatch, FC, Fragment, SetStateAction, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon } from '@heroicons/react/24/solid';

interface IFutureRepairsProps {
    altName?: string;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const FutureRepairs: FC<IFutureRepairsProps> = ({ altName, setSaveAndQuitChanges }) => {
    const formContext = useFormContext();
    const { getConstructionCoordinatorSearch } = useApi();
    const { reportingTech } = useReportingTech();

    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldRequirements } = useValidation({});

    const isClientConstructionNumberUnknown: boolean = formContext.watch('isClientConstructionNumberUnknown') ?? false;
    const areFutureRepairsNeeded: boolean | null = formContext.watch(claimFields.areFutureRepairsNeeded);

    const [coordinatorSearchText, setCoordinatorSearchText] = useState('');
    const coordinator = formContext.watch('constructionCoordinatorName');

    const {
        data,
        isFetching,
        refetch: search,
    } = useQuery({
        enabled: coordinatorSearchText.length > 2,
        queryFn: ({ signal }) =>
            getConstructionCoordinatorSearch(reportingTech?.clientId ?? 0, coordinatorSearchText, signal),
        queryKey: ['coordinatorSearch', coordinatorSearchText],
        refetchOnWindowFocus: false,
    });

    const onAreFutureRepairsNeededNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'RemedyPrismID', claimFields.areFutureRepairsNeeded]);
        formContext.setValue(claimFields.clientConstructionNumber, '');
        formContext.setValue('isClientConstructionNumberUnknown', false);
        formContext.clearErrors(claimFields.clientConstructionNumber);
        formContext.setValue('constructionCoordinatorEmail', '');
        formContext.setValue('constructionCoordinatorId', null);
        formContext.setValue('constructionCoordinatorName', '');
        formContext.setValue('constructionCoordinatorPhone', '');
        formContext.clearErrors(claimFields.areFutureRepairsNeeded);
    };

    const onAreFutureRepairsNeededYesClick = () => {
        formContext.setValue(
            'constructionCoordinatorEmail',
            formContext.getValues('constructionCoordinatorDefaultEmail') ?? ''
        );
        formContext.setValue(
            'constructionCoordinatorId',
            formContext.getValues('constructionCoordinatorDefaultId') ?? null
        );
        formContext.setValue(
            'constructionCoordinatorName',
            formContext.getValues('constructionCoordinatorDefaultName') ?? ''
        );
        formContext.setValue(
            'constructionCoordinatorPhone',
            formContext.getValues('constructionCoordinatorDefaultPhone') ?? ''
        );
    };

    const onInputChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'RemedyPrismID', claimFields.areFutureRepairsNeeded]);
        formContext?.setValue(claimFields.areFutureRepairsNeeded, true);
        formContext?.clearErrors(claimFields.areFutureRepairsNeeded);
    };

    const onIsClientConstructionNumberUnknownChange = (checked: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'RemedyPrismID', claimFields.areFutureRepairsNeeded]);
        formContext.setValue(claimFields.clientConstructionNumber, checked ? 'Unknown' : '');
        formContext?.setValue(claimFields.areFutureRepairsNeeded, true);
        formContext.setValue('isClientConstructionNumberUnknown', checked);
        formContext.clearErrors('isClientConstructionNumberUnknown');
        formContext.clearErrors(claimFields.clientConstructionNumber);
        formContext?.clearErrors(claimFields.areFutureRepairsNeeded);
    };

    const onSelectedInternal = (value: number) => {
        const selectedCoordinator = data?.constructionCoordinators?.find((x) => x.id === value);

        if (selectedCoordinator) {
            formContext.setValue('constructionCoordinatorId', selectedCoordinator?.id);
            formContext.setValue('constructionCoordinatorEmail', selectedCoordinator?.email);
            formContext.setValue('constructionCoordinatorPhone', selectedCoordinator?.phone);
            formContext.setValue(
                'constructionCoordinatorName',
                selectedCoordinator?.firstName + ' ' + selectedCoordinator?.lastName
            );
        } else {
            formContext.setValue('constructionCoordinatorId', null);
            formContext.setValue('constructionCoordinatorEmail', '');
            formContext.setValue('constructionCoordinatorPhone', '');
            formContext.setValue('constructionCoordinatorName', coordinatorSearchText);
        }
    };

    return (
        <FormSection>
            <FormHeader>Future Repairs</FormHeader>
            <FormFields>
                {/* ARE FUTURE REPAIRS NEEDED? */}
                <YesNoButton
                    isRequired
                    isSelected={areFutureRepairsNeeded}
                    labelText={'Is a future span replacement or any other repairs needed?'}
                    name={claimFields.areFutureRepairsNeeded}
                    onNoClick={onAreFutureRepairsNeededNoClick}
                    onYesClick={onAreFutureRepairsNeededYesClick}
                    tooltip={'Will future repairs be needed at the damage location?'}
                />

                {/* CLIENT CONSTRUCTION #  */}
                {areFutureRepairsNeeded !== false && (
                    <>
                        <Input
                            checkboxOptions={{
                                'aria-label': 'client construction number unknown',
                                isSelected: isClientConstructionNumberUnknown,
                                onChange: onIsClientConstructionNumberUnknownChange,
                            }}
                            inputOptions={{
                                isDisabled: isClientConstructionNumberUnknown,
                                isRequired: fieldRequirements.ClientConstructionNumber,
                                label: altName || 'Client Construction #',
                                name: claimFields.clientConstructionNumber,
                                onChange: onInputChange,
                            }}
                            tooltip={'Your company’s construction tracking number, if available.'}
                        />

                        {areFutureRepairsNeeded === true && (
                            <>
                                {/* CONSTRUCTION COORDINATOR */}
                                <div className={'flex w-full flex-col'}>
                                    <label>
                                        <Tooltip tooltip={'The construction coordinator for the claim.'}>
                                            {'Construction Coordinator'}
                                        </Tooltip>
                                    </label>

                                    <Combobox
                                        name={claimFields.constructionCoordinator}
                                        onChange={onSelectedInternal}
                                        value={formContext.getValues('constructionCoordinatorName') ?? ''}
                                    >
                                        <div className={'relative mt-0.5'}>
                                            <div>
                                                {/* INPUT FIELD */}
                                                <Combobox.Input
                                                    className={'input-primary h-10'}
                                                    displayValue={(item: string) => item}
                                                    onChange={(e) => {
                                                        setCoordinatorSearchText(e.target.value ?? '');
                                                        if (!e.target.value) {
                                                            formContext.setValue('constructionCoordinatorId', null);
                                                            formContext.setValue('constructionCoordinatorName', '');
                                                            formContext.setValue('constructionCoordinatorEmail', '');
                                                            formContext.setValue('constructionCoordinatorPhone', '');
                                                        }
                                                    }}
                                                    ref={inputRef}
                                                />
                                            </div>
                                            <Transition
                                                afterLeave={() => setCoordinatorSearchText('')}
                                                as={Fragment}
                                                leave={'transition ease-in duration-100'}
                                                leaveFrom={'opacity-100'}
                                                leaveTo={'opacity-0'}
                                            >
                                                <Combobox.Options
                                                    className={clsx(
                                                        'absolute mt-1 max-h-80 bg-gray-50 focus:outline-none dark:bg-gray-600',
                                                        'w-full overflow-auto rounded-md py-1 text-base shadow-md sm:text-sm',
                                                        'ui-open:z-20'
                                                    )}
                                                >
                                                    {isFetching && (
                                                        <div
                                                            className={
                                                                'relative select-none py-2 pl-10 pr-4 dark:text-gray-200'
                                                            }
                                                        >
                                                            Loading...
                                                        </div>
                                                    )}
                                                    {coordinatorSearchText.length > 0 && (
                                                        <Combobox.Option
                                                            className={clsx(
                                                                'ui-active:bg-brand-500 dark:text-gray-200 ui-active:dark:bg-brand-100',
                                                                'relative cursor-pointer select-none py-2 pl-10 pr-4 ui-active:text-white'
                                                            )}
                                                            value={coordinatorSearchText}
                                                        >
                                                            {'Create a new construction coordinator - "' +
                                                                coordinatorSearchText}
                                                            {'"'}
                                                        </Combobox.Option>
                                                    )}
                                                    {/* RESULTS */}
                                                    {data?.constructionCoordinators?.map((item) => (
                                                        <Combobox.Option
                                                            className={clsx(
                                                                'ui-active:bg-brand-500 ui-active:dark:bg-brand-100',
                                                                'relative cursor-pointer select-none py-2 pl-10 pr-4 ui-active:text-white'
                                                            )}
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <span
                                                                        className={clsx(
                                                                            'block truncate dark:text-gray-200',
                                                                            'font-normal ui-selected:font-normal'
                                                                        )}
                                                                    >
                                                                        {item.firstName +
                                                                            ' ' +
                                                                            item.lastName +
                                                                            ' - ' +
                                                                            item.email}
                                                                    </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={
                                                                                'absolute inset-y-0 left-0 flex items-center pl-3 text-amber-500 dark:text-amber-400'
                                                                            }
                                                                        >
                                                                            <CheckIcon
                                                                                aria-hidden={'true'}
                                                                                className={'h-5 w-5'}
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>

                                {coordinator && (
                                    <div className={'flex gap-4'}>
                                        <Input
                                            inputOptions={{
                                                label: 'Construction Coordinator Email Address',
                                                name: 'constructionCoordinatorEmail',
                                            }}
                                            tooltip={'Email address for the construction coordinator'}
                                        />
                                        <Input
                                            inputOptions={{
                                                label: 'Construction Coordinator Phone Number',
                                                name: 'constructionCoordinatorPhone',
                                            }}
                                            tooltip={'Phone number for the construction coordinator'}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </FormFields>
        </FormSection>
    );
};