import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFocusRing } from '@react-aria/focus';
import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import { ErrorMessage } from '../ErrorMessage';
import { useLabel } from 'react-aria';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

import type { FC } from 'react';

interface IYesNoButtonProps {
    isRequired?: boolean;
    isSelected?: boolean | null;
    labelText?: null | string;
    name: string;
    noText?: string;
    onChange?: (checked: boolean) => void;
    onNoClick?: () => void;
    onYesClick?: () => void;
    tooltip?: string;
    yesText?: string;
}

export const YesNoButton: FC<IYesNoButtonProps> = ({
    isRequired,
    isSelected = null,
    labelText,
    name,
    noText = 'NO',
    onNoClick,
    onYesClick,
    tooltip,
    yesText = 'YES',
}) => {
    const formContext = useFormContext();
    const errorMessage = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasError: boolean = errorMessage.length > 0;

    const fieldRef = useRef<HTMLDivElement | null>(null);
    const { ref: formRef } = formContext.register(name);

    const [value, setValue] = useState<boolean | null>(isSelected);

    const { isFocusVisible: isNoFocus, focusProps: noFocusProps } = useFocusRing();
    const { isFocusVisible: isYesFocus, focusProps: yesFocusProps } = useFocusRing();

    const onNoButtonClick = () => {
        formContext.setValue(name, false);
        setValue(false);
        onNoClick?.();
    };

    const onYesButtonClick = () => {
        formContext.setValue(name, true);
        formContext.clearErrors(name);
        setValue(true);
        onYesClick?.();
    };

    const yesButtonRef = useRef<HTMLButtonElement>(null);

    const { buttonProps: yesButtonProps } = useButton({ onPress: onYesButtonClick }, yesButtonRef);
    const noButtonRef = useRef<HTMLButtonElement>(null);

    const { buttonProps: noButtonProps } = useButton({ onPress: onNoButtonClick }, noButtonRef);
    const { labelProps } = useLabel({ label: `${name} yes or no selection.` });

    useEffect(() => {
        formContext.setValue(name, isSelected);
        formContext.clearErrors(name);
        setValue(isSelected);
    }, [isSelected]);

    return (
        <div
            className={'flex flex-col'}
            ref={(ref) => {
                fieldRef.current = ref;
                formRef(ref);
            }}
        >
            <div className={'flex w-full flex-row flex-wrap items-center sm:space-x-2'}>
                {/* LABEL */}
                <label {...labelProps}>
                    <Tooltip hasError={hasError} isRequired={isRequired} tooltip={tooltip}>
                        {labelText}
                    </Tooltip>
                </label>

                {/* BUTTONS */}
                <div className={'space-x-1.5'}>
                    {/* NO */}
                    <button
                        {...mergeProps(noButtonProps, noFocusProps)}
                        className={clsx(
                            'inline-flex items-center rounded-l-md border-2 px-1 text-base font-medium outline-none transition-all duration-200 sm:text-lg',
                            value === null && 'bg-red-100 dark:bg-red-200/80',
                            value === null && isRequired
                                ? 'dark:text-gray-600 hover:dark:text-gray-200'
                                : 'dark:text-gray-200',
                            value === false
                                ? 'bg-brand-500 text-gray-200 hover:bg-brand-600 dark:border-brand-100 dark:bg-brand-100 hover:dark:bg-brand-200'
                                : 'border-gray-300 hover:bg-gray-200 dark:border-gray-600 hover:dark:bg-gray-600',
                            isNoFocus && 'keyboard-focus',
                            hasError && 'border-red-500 text-red-500 dark:border-red-600 dark:text-red-600'
                        )}
                        onClick={onNoButtonClick}
                    >
                        {noText}
                    </button>

                    {/* YES */}
                    <button
                        {...mergeProps(yesFocusProps, yesButtonProps)}
                        className={clsx(
                            'inline-flex items-center rounded-r-md border-2 px-1 text-base font-medium outline-none transition-all duration-200 dark:text-gray-200 sm:text-lg',
                            value === null && 'bg-red-100 dark:bg-red-200/80',
                            value === null && isRequired
                                ? 'dark:text-gray-600 hover:dark:text-gray-200'
                                : 'dark:text-gray-200',
                            value
                                ? 'bg-brand-500 text-gray-200 hover:bg-brand-600 dark:border-brand-100 dark:bg-brand-100 hover:dark:bg-brand-200'
                                : 'border-gray-300 hover:bg-gray-200 dark:border-gray-600 hover:dark:bg-gray-600',
                            isYesFocus && 'keyboard-focus',
                            hasError && 'border-red-500 text-red-500 dark:border-red-600 dark:text-red-600'
                        )}
                        onClick={onYesButtonClick}
                    >
                        {yesText}
                    </button>
                </div>
            </div>

            {/* ERROR MESSAGE */}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
};