import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useCheckbox } from '@react-aria/checkbox';
import { useFormContext } from 'react-hook-form';
import { Transition } from '@headlessui/react';
import { useFocusRing } from 'react-aria';
import { useRef } from 'react';
import clsx from 'clsx';

import type { AriaCheckboxProps } from '@react-types/checkbox';
import type { FCC } from '../../types';

interface ICheckboxProps {
    hasError?: boolean;
    options?: AriaCheckboxProps;
    showRequired?: boolean;
}

export const Checkbox: FCC<ICheckboxProps> = ({ children, hasError, options, showRequired }) => {
    const checkboxInputRef = useRef<HTMLInputElement | null>(null);

    const formContext = useFormContext();

    const { ref: formRef } = formContext.register(options?.name ?? 'checkbox');

    const checkboxState = useToggleState(options);

    const { inputProps } = useCheckbox(
        { ...options, isDisabled: options?.isDisabled },
        checkboxState,
        checkboxInputRef
    );

    const { isFocusVisible, focusProps } = useFocusRing();

    return (
        <div className={'flex flex-row items-center'}>
            <div>
                <label className={clsx('flex flex-row items-center', options?.isDisabled && 'cursor-not-allowed ')}>
                    {/* ARIA */}
                    <VisuallyHidden>
                        <input {...inputProps} {...focusProps} ref={checkboxInputRef} />
                    </VisuallyHidden>

                    {/* CHECKBOX */}
                    <div
                        aria-hidden={'true'}
                        className={clsx(
                            'mr-2 mt-1 flex h-5 w-5 flex-shrink-0 cursor-pointer items-center justify-center',
                            'border-0 dark:text-gray-300',
                            checkboxState.isSelected && 'text-gray-800 dark:text-gray-200',
                            isFocusVisible && 'ring-4 ring-brand-500 dark:ring-brand-100',
                            hasError && 'ring-2 ring-red-500',
                            options?.isDisabled && 'cursor-not-allowed',
                            showRequired ? 'bg-red-100 dark:bg-red-200' : 'bg-gray-200 dark:bg-gray-600 '
                        )}
                        ref={formRef}
                    >
                        {/* CHECKMARK ANIMATION */}
                        <Transition
                            as={'div'}
                            className={'z-10'}
                            enter={'transform duration-300'}
                            enterFrom={'scale-x-0'}
                            enterTo={'scale-x-100'}
                            leave={'transform duration-300 ease-in-out'}
                            leaveFrom={'scale-x-100'}
                            leaveTo={'scale-x-0'}
                            show={checkboxState.isSelected ?? false}
                        >
                            {/* CHECKMARK */}
                            <svg
                                className={'h-4 w-4'}
                                fill={'none'}
                                stroke={'currentColor'}
                                strokeWidth={'3.5'}
                                viewBox={'0 0 24 24'}
                            >
                                <path d={'M4.5 12.75l6 6 9-13.5'} strokeLinecap={'round'} strokeLinejoin={'round'} />
                            </svg>
                        </Transition>
                    </div>

                    {/* LABEL */}
                    <span
                        className={clsx(
                            'mt-1 cursor-pointer dark:text-gray-100',
                            options?.isDisabled && 'cursor-not-allowed text-gray-300 dark:text-gray-500',
                            hasError ? 'text-red-500 dark:text-red-500' : 'text-gray-700'
                        )}
                    >
                        {children}
                    </span>
                </label>
            </div>
        </div>
    );
};
