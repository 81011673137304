import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export const PasswordWarning = () => {
    return (
        <div className={'flex flex-row items-center bg-red-500 p-1.5 text-white dark:bg-red-300 dark:text-gray-100'}>
            <ExclamationCircleIcon className={'mr-1 h-5 w-5 flex-shrink-0'} />
            Password must be at least 8 characters and contain at least one capital letter, one lowercase letter, one
            number, and one special character.
        </div>
    );
};