import { SummaryGrid, SummaryHeader, SummaryRow, SummarySection, SummaryText } from '../../../../components';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { IClaim, IClaimInit } from '../../../../types';
import type { FC } from 'react';

interface IRepairDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setStep: (step: number) => void;
}

export const RepairDetailsSummary: FC<IRepairDetailsProps> = ({ claim, setStep }) => {
    const formContext = useFormContext();

    const isFreeFormRepairEntry: boolean | null = formContext.watch(claimFields.isFreeFormRepairEntry);

    // REPAIRS DONE ON SITE
    const wereRepairsDoneOnSite: string = formContext.getValues(claimFields.repairsDoneOnSite) ? 'Yes' : 'No';
    const additionalCommentsLabel = isFreeFormRepairEntry ? 'Additional Repair Comments' : 'Repair Details';
    const additionalComments: string =
        formContext.getValues(claimFields.claimComments)?.length !== 0
            ? formContext.getValues(claimFields.claimComments)
            : 'N/A';
    const legacyClaimNo: string =
        formContext.getValues(claimFields.legacyClaimNo)?.length !== 0
            ? formContext.getValues(claimFields.legacyClaimNo)
            : 'N/A';

    // CONSTRUCTION COORDINATOR
    const constructionCoordinatorName: string =
        formContext.getValues('constructionCoordinatorName')?.length !== 0
            ? formContext.getValues('constructionCoordinatorName')
            : 'N/A';
    const constructionCoordinatorPhone: string =
        formContext.getValues('constructionCoordinatorPhone')?.length !== 0
            ? formContext.getValues('constructionCoordinatorPhone')
            : 'N/A';
    const constructionCoordinatorEmail: string =
        formContext.getValues('constructionCoordinatorEmail')?.length !== 0
            ? formContext.getValues('constructionCoordinatorEmail')
            : 'N/A';

    // FUTURE REPAIRS
    const areFutureRepairsNeeded: string = formContext.getValues(claimFields.areFutureRepairsNeeded) ? 'Yes' : 'No';
    const clientConstructionName =
        claim?.clientConstructionNumberAltName?.length !== 0
            ? claim?.clientConstructionNumberAltName
            : 'Client Construction #:';
    const clientConstructionNumber: string =
        formContext.getValues(claimFields.clientConstructionNumber)?.length !== 0
            ? formContext.getValues(claimFields.clientConstructionNumber)
            : 'N/A';

    // Return to the repair details step
    const onStepClick = () => setStep(4);

    return (
        <div className={'flex flex-col'}>
            <SummaryHeader isLink onLink={onStepClick}>
                Repair Details
            </SummaryHeader>

            {/* REPAIRS */}
            <SummarySection>
                <SummaryGrid>
                    <SummaryRow hideColon label={'Have onsite repairs been started/completed?'}>
                        {wereRepairsDoneOnSite}
                    </SummaryRow>
                    <SummaryRow label={'Reference / Construction Job / Other Internal Job Reference #'}>
                        {legacyClaimNo}
                    </SummaryRow>
                </SummaryGrid>
            </SummarySection>

            {/* FUTURE REPAIRS */}
            <SummarySection>
                <SummaryGrid>
                    <SummaryRow hideColon label={'Are future repairs needed?'}>
                        {areFutureRepairsNeeded}
                    </SummaryRow>
                    <SummaryRow label={clientConstructionName}>{clientConstructionNumber}</SummaryRow>
                    <SummaryRow label={'Construction Coordinator Name'}>
                        {constructionCoordinatorName ?? 'N/A'}
                    </SummaryRow>
                    <SummaryRow label={'Construction Coordinator Email'}>
                        {constructionCoordinatorEmail ?? 'N/A'}
                    </SummaryRow>
                    <SummaryRow label={'Construction Coordinator Phone'}>
                        {constructionCoordinatorPhone ?? 'N/A'}
                    </SummaryRow>
                    <SummaryText label={additionalCommentsLabel} value={additionalComments} />
                </SummaryGrid>
            </SummarySection>
        </div>
    );
};