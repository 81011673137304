import { ListBoxButton, ListBoxMultiSelected, ListBoxOptions } from './components';
import { ExpandVTransition } from '../transitions';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';
import { Listbox } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Tooltip } from '../tooltip';
import clsx from 'clsx';

import type { IListItem } from '../../types';
import type { FC } from 'react';

interface IListBoxProps {
    addOptionsClassName?: string;
    hideTooltip?: boolean;
    isDisabled?: boolean;
    isMultiple?: boolean;
    isRequired?: boolean;
    label?: string;
    listItems: Array<IListItem>;
    name: string;
    onMultiChange?: (items: Array<IListItem>) => void;
    onSingleChange?: (item: IListItem) => void;
    placeholder?: string;
    tooltip?: string;
}

export const ListBox: FC<IListBoxProps> = ({
    addOptionsClassName,
    hideTooltip,
    isDisabled,
    isMultiple,
    isRequired,
    label,
    listItems,
    name = '',
    onMultiChange,
    onSingleChange,
    placeholder = 'Select an option',
    tooltip,
}) => {
    const formContext = useFormContext();
    const formValue = formContext.watch(name);
    const errorMessage = formContext.formState.errors[name]?.message?.toString() ?? '';
    const hasError = errorMessage.length > 0;

    const { ref } = formContext.register(name);

    const [selectedItem, setSelectedItem] = useState<IListItem>({ id: '0', name: placeholder });
    const [selectedItems, setSelectedItems] = useState<Array<IListItem>>([]);

    const onChange = (item: IListItem) => {
        setSelectedItem(item);
        formContext.setValue(name, item.id);
        if (hasError) formContext.clearErrors(name);
        onSingleChange?.(item);
    };

    const onChangeMultiple = (items: Array<IListItem>) => {
        setSelectedItems(items);
        const mappedItems: Array<string> = items.map((item: IListItem) => item.id);
        formContext.setValue(name, mappedItems);
        if (hasError) formContext.clearErrors(name);
        onMultiChange?.(items);
    };

    const onRemove = (id: string) => {
        const filteredItems: Array<IListItem> | undefined = selectedItems.filter(
            (item: IListItem) => Number(item.id) !== Number(id)
        );
        setSelectedItems(filteredItems);
        const mappedItems: Array<string> = filteredItems.map((item: IListItem) => item.id);
        formContext.setValue(name, mappedItems);
        onMultiChange?.(filteredItems);
    };

    useEffect(() => {
        if (!formValue) {
            setSelectedItem({ id: '0', name: placeholder });
            return;
        }
        if (isMultiple) {
            const items: Array<IListItem> = [];
            formValue?.forEach((facilityId: number) => {
                const item: IListItem | undefined = listItems.find((f: IListItem) => Number(f.id) === facilityId);
                if (item) items.push(item);
            });
            setSelectedItems(items);
        } else {
            const newItem: IListItem | undefined = listItems?.find((item: IListItem) => item.id === formValue);
            if (newItem) setSelectedItem(newItem);
        }
    }, [formValue]);

    return (
        <div className={'flex w-full flex-col'} ref={ref}>
            {!hideTooltip && (
                <Tooltip hasError={hasError} isRequired={isRequired} tooltip={tooltip}>
                    {label}
                </Tooltip>
            )}

            <Listbox
                as={'div'}
                className={clsx(
                    'relative flex w-full flex-col focus-within:border-brand-500',
                    'ui-open:z-30 ui-not-open:z-0 ui-not-open:duration-300 dark:focus-within:border-brand-100',
                    hasError && 'input-error'
                )}
                disabled={isDisabled}
                multiple={isMultiple}
                onChange={isMultiple ? onChangeMultiple : onChange}
                value={isMultiple ? selectedItems : selectedItem}
            >
                {({ open }) => (
                    <>
                        {/*OPEN/CLOSE BUTTON */}
                        <ListBoxButton
                            isDisabled={isDisabled}
                            isOpen={open}
                            isRequired={isRequired}
                            selectedItem={selectedItem}
                            selectedItems={selectedItems}
                        />

                        {/* OPTIONS */}
                        <ExpandVTransition>
                            <ListBoxOptions
                                addOptionsClassName={addOptionsClassName}
                                isRequired={isRequired}
                                listItems={listItems}
                            />
                        </ExpandVTransition>
                    </>
                )}
            </Listbox>

            {/* ERROR MESSAGE */}
            {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}

            {/* MULTI SELECTED */}
            {isMultiple && <ListBoxMultiSelected onRemove={onRemove} selectedItems={selectedItems} />}
        </div>
    );
};
