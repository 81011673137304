import type { IClaimFields, IFieldRequirements } from '../types';

export const claimFields: IClaimFields = {
    additionalComments: 'AdditionalComments',
    areFutureRepairsNeeded: 'AreFutureRepairsNeeded',
    cableInConduit: 'CableInConduit',
    cableSize: 'CableSize',
    claimComments: 'ClaimComments',
    claimHub: 'ClaimHub',
    claimNode: 'ClaimNode',
    clientConstructionNumber: 'ClientConstructionNumber',
    constructionCoordinator: 'ConstructionCoordinator',
    damageActivityType: 'DamageActivityType',
    damageByType: 'DamageByType',
    damageCity: 'DamageCity',
    damageCrossStreet: 'DamageCrossStreet',
    damageDate: 'DamageDate',
    damageFacilityTypes: 'DamageFacilityTypes',
    damageLocationType: 'DamageLocationType',
    damageState: 'DamageState',
    damageStreetAddress: 'DamageStreetAddress',
    damageZipCode: 'DamageZipCode',
    damagerContactName: 'DamagerContactName',
    damagerEmailAddress: 'DamagerEmailAddress',
    damagerIdentified: 'DamagerIdentified',
    damagerName: 'DamagerName',
    damagerPhoneNumber: 'DamagerPhoneNumber',
    damagerStatement: 'DamagerStatement',
    depthOfDamage: 'DepthOfDamage',
    detailsOfDamageLoss: 'DetailsOfDamageLoss',
    discoveryDate: 'DiscoveryDate',
    estimatedSubsAffected: 'EstimatedSubsAffected',
    facilityNumber: 'FacilityNumber',
    haveDamagerContactDetails: 'HaveDamagerContactDetails',
    isEstimatedSubsUnknown: 'IsEstimatedSubsUnknown',
    isFreeFormRepairEntry: 'IsFreeFormRepairEntry',
    isOutageDurationUnknown: 'IsOutageDurationUnknown',
    isSpanFootageUnknown: 'IsSpanFootageUnknown',
    isSpanReplacementNeeded: 'IsSpanReplacementNeeded',
    legacyClaimNo: 'LegacyClaimNo',
    locateAccuracy: 'LocateAccuracy',
    locateCompany: 'LocateCompany',
    locateMarking: 'LocateMarking',
    locateMarkingFlags: 'LocateMarkingFlags',
    locateMarkingPaint: 'LocateMarkingPaint',
    locateMarkingStakes: 'LocateMarkingStakes',
    material: 'Material',
    opinionOnResponsibleParty: 'OpinionOnResponsibleParty',
    outageDuration: 'OutageDuration',
    personnel: 'Personnel',
    remedyId: 'RemedyId',
    repairsDoneOnSite: 'RepairsDoneOnSite',
    responsibleForDamage: 'ResponsibleForDamage',
    spanFootage: 'SpanFootage',
    vehicle: 'Vehicle',
    wasDamageAerial: 'WasDamageAerial',
    wasDamageUnderground: 'WasDamageUnderground',
    wasSpanReplaced: 'WasSpanReplaced',
    wereLocatesPresent: 'WereLocatesPresent',
    wereLocatesPresentNA: 'WereLocatesPresentNA',
    wereLocatesPresentUnknown: 'WereLocatesPresentUnknown',
    whoWasDamager: 'WhoWasDamager',
};

export const defaultFieldRequirements: IFieldRequirements = {
    AdditionalComments: false,
    AreFutureRepairsNeeded: true,
    ClaimComments: false,
    CableInConduit: true,
    CableSize: true,
    ClaimHub: true,
    ClaimNode: false,
    ClientConstructionNumber: false,
    DamageCity: true,
    DamageActivityType: false,
    DamageByType: false,
    DamageCrossStreet: false,
    DamageDate: true,
    DamageFacilityTypes: true,
    DamageLocationType: false,
    DamageState: true,
    DamageStreetAddress: true,
    DamageZipCode: true,
    DamagerContactName: false,
    DamagerEmailAddress: false,
    DamagerIdentified: true,
    DamagerName: true,
    DamagerPhoneNumber: false,
    DamagerStatement: false,
    DepthOfDamage: false,
    DetailsOfDamageLoss: true,
    DiscoveryDate: true,
    EstimatedSubsAffected: false,
    FacilityNumber: false,
    HaveDamagerContactDetails: true,
    IsFreeFormRepairEntry: true,
    IsSpanReplacementNeeded: true,
    LegacyClaimNo: false,
    LocateAccuracy: false,
    LocateCompany: true,
    LocateMarking: true,
    Material: false,
    OpinionOnResponsibleParty: true,
    OutageDuration: false,
    Personnel: false,
    RemedyId: false,
    RepairsDoneOnSite: true,
    ResponsibleForDamage: true,
    SpanFootage: true,
    Vehicle: false,
    WasDamageAerial: true,
    WasDamageUnderground: true,
    WasSpanReplaced: true,
    WereLocatesPresent: true,
    WhoWasDamager: true,
};