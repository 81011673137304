import { ReportingTechContext } from '../context';
import { useContext } from 'react';

import type { IReportingTech } from '../types';

export type IUseReportingTechReturn = {
    hasReportingTech: boolean;
    isProfileConfirmed: boolean;
    reportingTech: IReportingTech | null;
    reportingTechEmail: string;
    reportingTechInitials: string;
    reportingTechName: string;
};

export const useReportingTech = (): IUseReportingTechReturn => {
    const { isProfileConfirmed, reportingTech, reportingTechEmail, reportingTechInitials, reportingTechName } =
        useContext(ReportingTechContext);
    const hasReportingTech: boolean = reportingTech !== null;
    return {
        hasReportingTech,
        isProfileConfirmed,
        reportingTech,
        reportingTechEmail,
        reportingTechInitials,
        reportingTechName,
    };
};