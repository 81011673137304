import { useFormContext } from 'react-hook-form';
import { TextArea } from '../../../components';
import { useValidation } from '../../../hooks';
import { claimFields } from '../../../config';
import { useEffect } from 'react';
import {
    DamageDetailsSummary,
    DamagerDetailsSummary,
    LocationDetailsSummary,
    MaterialSummary,
    PersonnelSummary,
    PhotosAndDocumentsSummary,
    RepairDetailsSummary,
    VehicleSummary,
} from './summary-details';

import type { IClaim, IClaimInit } from '../../../types';
import type { Dispatch, SetStateAction } from 'react';
import type { FC } from 'react';

interface ISummaryDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
    setStep: (step: number) => void;
}

export const SummaryDetails: FC<ISummaryDetailsProps> = ({ claim, setSaveAndQuitChanges, setStep }) => {
    const formContext = useFormContext();
    const { fieldRequirements } = useValidation({});

    // scroll to the top of the screen on initial render.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onAdditionalCommentsInputChange = (value: string) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'ClaimComments']);
        formContext.setValue(claimFields.additionalComments, value);
    };

    return (
        <div className={'flex flex-col space-y-4 text-sm text-gray-700 dark:text-white sm:ml-2'}>
            {/* Additional Comments */}
            <TextArea
                options={{
                    autoFocus: true,
                    isRequired: fieldRequirements.AdditionalComments,
                    label: 'Additional Comments',
                    name: claimFields.additionalComments,
                    onChange: onAdditionalCommentsInputChange,
                }}
                tooltip={'Do you have any additional comments about the claim?'}
            />

            <hr className={'border-gray-400 pb-2 dark:border-gray-500'} />

            <LocationDetailsSummary claim={claim} setStep={setStep} />
            <DamageDetailsSummary claim={claim} setStep={setStep} />
            <DamagerDetailsSummary claim={claim} setStep={setStep} />
            <RepairDetailsSummary claim={claim} setStep={setStep} />
            <PersonnelSummary setStep={setStep} />
            <MaterialSummary setStep={setStep} />
            <VehicleSummary setStep={setStep} />
            <PhotosAndDocumentsSummary setStep={setStep} />
        </div>
    );
};