import { ConfirmModal } from '../modals';
import { Button } from '../buttons';

import type { IModalToggle } from '../../hooks';
import type { FC } from 'react';

interface IPanelButtonsProps {
    isSaveDisabled?: boolean;
    isSaving: boolean;
    modalToggle: IModalToggle;
    onConfirm: () => void;
    onUpdate: () => Promise<void>;
    panelToggle: IModalToggle;
}

export const PanelButtons: FC<IPanelButtonsProps> = ({
    isSaveDisabled,
    isSaving,
    modalToggle,
    onConfirm,
    onUpdate,
    panelToggle,
}) => {
    return (
        <div>
            <div
                className={
                    'float-right flex flex-shrink-0 flex-col-reverse items-center justify-between px-4 py-4 xxs:flex-row xxs:items-start'
                }
            >
                <div className={'space-x-3 py-1 xxs:py-0'}>
                    <Button buttonProps={{ onPress: panelToggle.close }} variant={'outline'}>
                        Cancel
                    </Button>
                    <Button buttonProps={{ isDisabled: isSaveDisabled, onPress: onConfirm }}>Update</Button>
                </div>
            </div>

            {/* CONFIRM UPDATE */}
            <ConfirmModal
                message={'Are you sure you want to update the contact info?'}
                modalToggle={modalToggle}
                onConfirm={onUpdate}
                isLoading={isSaving}
                isStatic
                title={'Confirm Update'}
            />
        </div>
    );
};