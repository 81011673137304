import { Input, YesNoButton } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { Dispatch, FC, SetStateAction } from 'react';

interface IUndergroundDamageProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const UndergroundDamage: FC<IUndergroundDamageProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const isDamageUnderground: boolean | null = formContext.watch(claimFields.wasDamageUnderground);

    const onUndergroundDamageNoClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.depthOfDamage,
            claimFields.wereLocatesPresent,
            claimFields.cableInConduit,
            claimFields.wereLocatesPresentNA,
            claimFields.wasDamageUnderground,
        ]);
        formContext.setValue(claimFields.depthOfDamage, '');
        formContext.clearErrors(claimFields.depthOfDamage);
        formContext.clearErrors(claimFields.wasDamageUnderground);
        // If there is no underground damage, set the underground related question in span replacement to false.
        formContext.setValue(claimFields.cableInConduit, false);
        formContext.setValue(claimFields.wereLocatesPresent, '3'); // 3 = NA - Not Underground
        formContext.setValue(claimFields.wereLocatesPresentNA, true);
    };

    const onUndergroundDamageYesClick = () => {
        setSaveAndQuitChanges?.((prev) => [
            ...prev,
            claimFields.cableInConduit,
            claimFields.depthOfDamage,
            claimFields.wereLocatesPresent,
            claimFields.wasDamageUnderground,
        ]);
        formContext.clearErrors(claimFields.wasDamageUnderground);
        // If there is underground damage, set the underground related questions.
        formContext.setValue(claimFields.cableInConduit, null);
        formContext.setValue(claimFields.wereLocatesPresent, null);
        formContext.setValue(claimFields.wereLocatesPresentNA, null);
        formContext.setValue(claimFields.wereLocatesPresentUnknown, null);
    };

    const onDepthOfDamageChange = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, claimFields.depthOfDamage, claimFields.wasDamageUnderground]);
        formContext.setValue(claimFields.wasDamageUnderground, true);
        formContext.clearErrors(claimFields.wasDamageUnderground);
        formContext.clearErrors(claimFields.depthOfDamage);
    };

    return (
        <div className={'flex w-full flex-col space-y-1'}>
            {/* WAS UNDERGROUND? */}
            <YesNoButton
                isRequired
                isSelected={isDamageUnderground}
                labelText={'Were underground facilities damaged?'}
                name={claimFields.wasDamageUnderground}
                onNoClick={onUndergroundDamageNoClick}
                onYesClick={onUndergroundDamageYesClick}
                tooltip={'Did any of the damage occur below ground?'}
            />

            {/* DEPTH OF DAMAGE */}
            {isDamageUnderground !== false && (
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.DepthOfDamage,
                        label: 'Depth of Damage',
                        name: claimFields.depthOfDamage,
                        onChange: onDepthOfDamageChange,
                    }}
                    tooltip={'How far down was the cable buried at the damage site?'}
                />
            )}
        </div>
    );
};