import { ErrorMessage, Input, Tooltip, TooltipButton } from '../../../components';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Fragment, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { useApi } from '../../../hooks';
import clsx from 'clsx';

import type { ChangeEvent } from 'react';
import type { FC } from 'react';
import { IReportingTechSupervisor } from '../../../types';

interface ISupervisorFieldsProps {
    clientId: string;
}

export const SupervisorFields: FC<ISupervisorFieldsProps> = ({ clientId }) => {
    const formContext = useFormContext();
    const { getReportingTechSupervisorSearch } = useApi();

    const inputRef = useRef<HTMLInputElement>(null);

    const [searchText, setSearchText] = useState<string>('');

    const {
        data,
        isFetching,
        refetch: search,
    } = useQuery({
        enabled: searchText.length > 2,
        queryFn: ({ signal }) =>
            getReportingTechSupervisorSearch({ clientId: clientId, prefixText: searchText }, signal),
        queryKey: ['supervisorSearch', searchText],
        refetchOnWindowFocus: false,
    });

    const onChangeInternal = (event: ChangeEvent<HTMLInputElement>) => {
        const text: string | undefined = event.target.value;
        setSearchText(text);
    };

    const onSelectedInternal = (value: number) => {
        const reportingTechSupervisor = data?.find((x) => x.reportingTechSupervisorId == value);

        if (reportingTechSupervisor !== undefined) {
            formContext.setValue('supervisorEmailAddress', reportingTechSupervisor.emailAddress);
            formContext.setValue('supervisorFirstName', reportingTechSupervisor.firstName);
            formContext.setValue('supervisorLastName', reportingTechSupervisor.lastName);
            formContext.setValue('supervisorPhoneNumber', reportingTechSupervisor.phoneNumber);
        } else {
            formContext.setValue('supervisorEmailAddress', searchText);
        }

        formContext.trigger('supervisorEmailAddress').then();
    };

    const errorMessage: string = formContext.formState.errors['supervisorEmailAddress']?.message?.toString() ?? '';
    const hasError: boolean = errorMessage.length > 0;

    const hasSupervisorEmail =
        formContext.getValues('supervisorEmailAddress') !== undefined &&
        formContext.getValues('supervisorEmailAddress') !== '';

    return (
        <div className={'flex flex-col'}>
            <div className={'my-6 self-center text-3xl font-medium uppercase text-brand-100'}>Supervisor Details</div>
            <div className={'flex flex-col space-y-3'}>
                <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                    {/* SUPERVISOR FIRST NAME */}
                    <Input
                        inputOptions={{
                            autoComplete: 'first-name',
                            isRequired: true,
                            label: 'First Name',
                            name: 'supervisorFirstName',
                        }}
                        tooltip={"The supervisor's first name."}
                    />

                    {/* SUPERVISOR LAST NAME */}
                    <Input
                        inputOptions={{
                            autoComplete: 'last-name',
                            isRequired: true,
                            label: 'Last Name',
                            name: 'supervisorLastName',
                        }}
                        tooltip={"The supervisor's last name."}
                    />
                </div>

                <div className={'flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4'}>
                    {/* SUPERVISOR PHONE NUMBER */}
                    <Input
                        inputOptions={{
                            autoComplete: 'phone',
                            isRequired: true,
                            label: 'Phone Number',
                            name: 'supervisorPhoneNumber',
                        }}
                        tooltip={'A phone number for the supervisor.'}
                        type={'phone'}
                    />

                    {/* SUPERVISOR EMAIL ADDRESS */}
                    <div className={'flex w-full flex-col'}>
                        {/* TOOLTIP */}
                        <label>
                            <Tooltip hasError={hasError} isRequired tooltip={"The supervisor's email address"}>
                                {'Supervisor Email Address'}
                            </Tooltip>
                        </label>

                        <Combobox
                            name={'supervisorEmailAddress'}
                            onChange={onSelectedInternal}
                            value={formContext.getValues('supervisorEmailAddress')}
                        >
                            <div className={'relative mt-0.5'}>
                                <div>
                                    {/* INPUT FIELD */}
                                    <Combobox.Input
                                        className={clsx(
                                            'input-primary h-10',
                                            hasError && 'input-error',
                                            !hasSupervisorEmail && 'bg-red-100 dark:bg-red-400'
                                        )}
                                        displayValue={(item: string) => item}
                                        onChange={onChangeInternal}
                                        ref={inputRef}
                                    />
                                </div>
                                <Transition
                                    afterLeave={() => setSearchText('')}
                                    as={Fragment}
                                    leave={'transition ease-in duration-100'}
                                    leaveFrom={'opacity-100'}
                                    leaveTo={'opacity-0'}
                                >
                                    <Combobox.Options
                                        className={clsx(
                                            'absolute mt-1 max-h-80 bg-gray-50 focus:outline-none dark:bg-gray-600',
                                            'w-full overflow-auto rounded-md py-1 text-base shadow-md sm:text-sm',
                                            'ui-open:z-20'
                                        )}
                                    >
                                        {isFetching && (
                                            <div className={'relative select-none py-2 pl-10 pr-4 dark:text-gray-200'}>
                                                Loading...
                                            </div>
                                        )}
                                        {searchText.length > 0 && (
                                            <Combobox.Option
                                                className={clsx(
                                                    'ui-active:bg-brand-500 dark:text-gray-200 ui-active:dark:bg-brand-100',
                                                    'relative cursor-pointer select-none py-2 pl-10 pr-4 ui-active:text-white'
                                                )}
                                                value={searchText}
                                            >
                                                {searchText}
                                            </Combobox.Option>
                                        )}
                                        {/* RESULTS */}
                                        {data?.map((item) => (
                                            <Combobox.Option
                                                className={clsx(
                                                    'ui-active:bg-brand-500 ui-active:dark:bg-brand-100',
                                                    'relative cursor-pointer select-none py-2 pl-10 pr-4 ui-active:text-white'
                                                )}
                                                key={item.reportingTechSupervisorId}
                                                value={item.reportingTechSupervisorId}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={clsx(
                                                                'block truncate dark:text-gray-200',
                                                                'font-normal ui-selected:font-normal'
                                                            )}
                                                        >
                                                            {item.emailAddress}
                                                        </span>
                                                        {selected ? (
                                                            <span
                                                                className={
                                                                    'absolute inset-y-0 left-0 flex items-center pl-3 text-amber-500 dark:text-amber-400'
                                                                }
                                                            >
                                                                <CheckIcon aria-hidden={'true'} className={'h-5 w-5'} />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                </Transition>
                            </div>
                        </Combobox>

                        {/* ERROR MESSAGE */}
                        {hasError && <ErrorMessage ariaProps={{}}>{errorMessage}</ErrorMessage>}
                    </div>
                </div>
            </div>
        </div>
    );
};
