import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import {
    SummaryGrid,
    SummaryGroup,
    SummaryHeader,
    SummaryRow,
    SummarySection,
    SummaryText,
} from '../../../../components';

import type { IClaim, IClaimInit } from '../../../../types';
import type { FC } from 'react';

interface IDamageDetailsProps {
    claim?: IClaimInit | IClaim | null;
    setStep: (step: number) => void;
}

export const DamageDetailsSummary: FC<IDamageDetailsProps> = ({ claim, setStep }) => {
    const formContext = useFormContext();

    // DAMAGE DESCRIPTION
    const detailsOfDamageLoss: string = formContext.getValues(claimFields.detailsOfDamageLoss);
    const subAffected: number = formContext.getValues(claimFields.estimatedSubsAffected) ?? '';
    const subAffectedDisplay = subAffected ? subAffected : 'N/A';
    const outageDuration: string = formContext.getValues(claimFields.outageDuration) ?? '';
    const outageDurationDisplay = outageDuration.length > 0 ? outageDuration : 'N/A';
    const activityType: string =
        claim?.damageActivityTypeList?.find((a) => a.id === formContext.getValues(claimFields.damageActivityType))
            ?.name ?? 'N/A';
    const damageBy: string =
        claim?.damageByTypeList?.find((a) => a.id === formContext.getValues(claimFields.damageByType))?.name ?? 'N/A';

    // FACILITIES
    const facilities: string = formContext
        .getValues(claimFields.damageFacilityTypes)
        ?.map((f: number) => claim?.damageFacilityTypeList.find((ft) => Number(ft.id) === f)?.name ?? '')
        ?.join(', ');

    // LOCATES
    const locateAccuracy: string =
        claim?.locateAccuracyList?.find((c) => c.id === formContext.getValues(claimFields.locateAccuracy))?.label ??
        'N/A';
    const locateMarkingFlags: string = formContext.getValues(claimFields.locateMarkingFlags) ? 'Flags, ' : '';
    const locateMarkingPaint: string = formContext.getValues(claimFields.locateMarkingPaint) ? 'Paint, ' : '';
    const locateMarkingStakes: string = formContext.getValues(claimFields.locateMarkingStakes) ? 'Stakes' : '';
    const locateMarkingUnknown: string = formContext.getValues('isMarkingUnknown') ? 'Unknown' : '';
    const locateMarkings: string = locateMarkingFlags + locateMarkingPaint + locateMarkingStakes + locateMarkingUnknown;
    const locateMarkingDisplay: string = locateMarkings.length > 0 ? locateMarkings : 'N/A';
    const isLocatesHidden: boolean = formContext.getValues('isLocatesHidden') ?? false;
    const hasLocateDetails: string = !isLocatesHidden
        ? formContext.getValues(claimFields.wereLocatesPresent)
            ? 'Yes'
            : 'No'
        : 'N/A';

    // SPAN REPLACEMENT
    const isSpanReplacement: string = formContext.getValues(claimFields.isSpanReplacementNeeded) ? 'Yes' : 'No';
    const isSpanUgInConduit: string = formContext.getValues(claimFields.wasDamageUnderground)
        ? formContext.getValues(claimFields.cableInConduit)
            ? 'Yes'
            : 'No'
        : 'N/A';
    const otherMaterial: string =
        formContext.getValues(claimFields.cableSize)?.length > 0 ? formContext.getValues(claimFields.cableSize) : 'N/A';
    const spanFootage: string = formContext.getValues(claimFields.spanFootage) ?? '';
    const spanFootageDisplay = spanFootage.length > 0 ? spanFootage : 'N/A';

    // Return to damage details step
    const onStepClick = () => setStep(2);

    return (
        <div className={'flex flex-col'}>
            {/* STEP LINK */}
            <SummaryHeader isLink onLink={onStepClick}>
                Damage Details
            </SummaryHeader>

            <SummaryGroup>
                <SummarySection>
                    <SummaryGrid>
                        {/* DESCRIPTION */}
                        <SummaryText label={'Damage Description / General Commentary'} value={detailsOfDamageLoss} />
                        <SummaryRow label={'Estimated # Of Subs Affected'}>{subAffectedDisplay}</SummaryRow>
                        <SummaryRow label={'Outage Duration'}>{outageDurationDisplay}</SummaryRow>
                        <SummaryRow label={'Activity Type'}>{activityType}</SummaryRow>
                        <SummaryRow label={'Damage By'}>{damageBy}</SummaryRow>

                        {/* FACILITIES */}
                        <SummaryRow label={'Facilities'}>{facilities}</SummaryRow>
                    </SummaryGrid>
                </SummarySection>

                <SummarySection>
                    <SummaryGrid>
                        {/* LOCATES */}
                        <SummaryRow hideColon label={'Were Locates Present?'}>
                            {hasLocateDetails}
                        </SummaryRow>
                        <SummaryRow label={'Locate Accuracy'}>{locateAccuracy}</SummaryRow>
                        <SummaryRow label={'Locate Markings'}>{locateMarkingDisplay}</SummaryRow>

                        {/* SPAN REPLACEMENT */}
                        <SummaryRow hideColon label={'Was span replacement needed or was fiber damaged?'}>
                            {isSpanReplacement}
                        </SummaryRow>
                        <SummaryRow hideColon label={'Was u/g cable in conduit?'}>
                            {isSpanUgInConduit}
                        </SummaryRow>
                        <SummaryRow label={'Span Footage Damaged/Replaced'}>{spanFootageDisplay}</SummaryRow>
                        <SummaryRow label={'Cable Size / Fiber Count / Other Material'}>{otherMaterial}</SummaryRow>
                    </SummaryGrid>
                </SummarySection>
            </SummaryGroup>
        </div>
    );
};