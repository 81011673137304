interface IResourceLinks {
    mrsGlossary: string;
    mrsTrainingDocument: string;
    mrsTrainingVideo: string;
    preDigTrainingDocument: string;
    preDigTrainingVideo: string;
    reportDamagesByEmail: string;
    reportDamagesByPhone: string;
    reportingDamagesTrainingDocument: string;
    reportingDamagesTrainingVideo: string;
}

export const resourceLinks: IResourceLinks = {
    mrsGlossary: 'https://phoenixmrsprod.blob.core.windows.net/training/MRS%20Fields%20Glossary.pdf',
    mrsTrainingDocument:
        'https://phoenixmrsprod.blob.core.windows.net/training/Phoenix%20Loss%20Control%20-%20Guide%20To%20MRS.pdf',
    mrsTrainingVideo: 'https://www.youtube.com/watch?v=KuDd7u7dOjU&t=14s',
    preDigTrainingDocument:
        'https://phoenixmrsprod.blob.core.windows.net/training/Phoenix%20Loss%20Control%20-%20Pre-Dig%20Training.pdf',
    preDigTrainingVideo: 'https://www.youtube.com/watch?v=H6DbvEfiKAc',
    reportDamagesByEmail: 'dispatch@phoenixlosscontrol.com',
    reportDamagesByPhone: '888-749-5677',
    reportingDamagesTrainingDocument:
        'https://phoenixmrsprod.blob.core.windows.net/training/Phoenix%20Loss%20Control%20-%20Reporting%20Damages%20Training.pdf',
    reportingDamagesTrainingVideo: 'https://www.youtube.com/watch?v=etupM5jNBCA',
};