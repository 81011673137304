import { FormFields, FormHeader, FormSection } from '../form';
import { AddressAutocomplete } from './AddressAutocomplete';
import { Input, ListBox } from '../../../../components';
import { useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';

import type { IListItem, IState } from '../../../../types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface IAddressProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
    states: Array<IState>;
}

export const Address: FC<IAddressProps> = ({ setSaveAndQuitChanges, states }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    // Remove stateId 0, it is an empty placeholder.
    const filteredStates: Array<IListItem> = states
        ?.map((state) => {
            return { id: state.id, name: state.fullName ?? state.name ?? state.id };
        })
        .filter((state: IState) => Number(state.id) !== 0);

    return (
        <FormSection>
            <FormHeader>Damage Address</FormHeader>

            <FormFields>
                {/* STREET ADDRESS AUTOCOMPLETE */}
                <AddressAutocomplete
                    stateList={filteredStates}
                    options={{
                        isRequired: fieldRequirements.DamageStreetAddress,
                        label: 'Street Address',
                        name: claimFields.damageStreetAddress,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageAddress1']),
                    }}
                    tooltip={
                        'What is the street address of the damage location? This is not to be confused by the affected customer address. If in a rural or new build area, what are the GPS coordinates?'
                    }
                />

                {/* CROSS STREET */}
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.DamageCrossStreet,
                        label: 'Cross Street',
                        name: claimFields.damageCrossStreet,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageCrossStreet']),
                    }}
                    tooltip={'What is the closest cross street to the damage address or intersection?'}
                />

                {/* CITY */}
                <Input
                    inputOptions={{
                        isRequired: fieldRequirements.DamageCity,
                        label: 'City',
                        name: claimFields.damageCity,
                        onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageCity']),
                    }}
                    tooltip={'In what city did the damage occur?'}
                />

                <div
                    className={
                        'flex w-full flex-col items-baseline space-x-0 space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0'
                    }
                >
                    {/* STATE */}
                    <div className={'w-full'}>
                        <ListBox
                            isRequired={fieldRequirements.DamageState}
                            label={'State'}
                            listItems={filteredStates}
                            name={claimFields.damageState}
                            onSingleChange={(_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamageState'])}
                            placeholder={'Select a state'}
                            tooltip={'In what state did the damage occur?'}
                        />
                    </div>

                    {/* ZIPCODE */}
                    <div className={'mt-1 w-full'}>
                        <Input
                            inputOptions={{
                                isRequired: fieldRequirements.DamageZipCode,
                                label: 'Zip Code',
                                name: claimFields.damageZipCode,
                                onChange: (_) => setSaveAndQuitChanges?.((prev) => [...prev, 'DamagePostalCode']),
                            }}
                            tooltip={'What is the damage locations postal zip code?'}
                            type={'zip'}
                        />
                    </div>
                </div>
            </FormFields>
        </FormSection>
    );
};