import { getWeeksInMonth, endOfMonth } from '@internationalized/date';
import { useCalendarGrid } from '@react-aria/calendar';
import { CalendarCell } from './CalendarCell';
import { useLocale } from '@react-aria/i18n';

import type { CalendarState } from '@react-stately/calendar';
import type { FC } from 'react';

interface ICalendarGridProps {
    state: CalendarState;
}

export const CalendarGrid: FC<ICalendarGridProps> = ({ state }) => {
    const { locale } = useLocale();

    const startDate = state.visibleRange.start.add({});
    const endDate = endOfMonth(startDate);

    const { gridProps, headerProps, weekDays } = useCalendarGrid(
        {
            startDate,
            endDate,
        },
        state
    );

    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    const weeks = Array.apply(null, Array(weeksInMonth)).map((_: number, index: number) => index);

    return (
        <table {...gridProps} cellPadding={'0'} className={'flex-1'}>
            {/* DAY NAMES */}
            <thead {...headerProps} className={'text-gray-600'}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th className={'text-center text-black dark:text-white'} key={index}>
                            {day}
                        </th>
                    ))}
                </tr>
            </thead>

            {/* CALENDAR DAYS */}
            <tbody>
                {weeks.map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex)
                            .map((date, index) =>
                                date ? <CalendarCell date={date} key={index} state={state} /> : <td key={index} />
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};