import { CalendarDateTime } from '@internationalized/date';
import { parseDate } from './datetime-utils';
import { claimFields } from '../config';
import {
    damageDetailsDefaultFormValues,
    damageLocationDefaultFormValues,
    damagerDetailsDefaultFormValues,
} from '../features/claim/components';

import type { UseFormReturn } from 'react-hook-form';
import type {
    Claim,
    IClaim,
    IClaimInit,
    IDamageFacilityType,
    IFile,
    IReportingTech,
    ISaveClaimRequest,
    IUpdateMaterialResponse,
    IUpdatePersonnelResponse,
    IUpdateVehicleResponse,
} from '../types';

export const loadClaim = (formContext: UseFormReturn, claim: IClaim | null) => {
    if (claim === null) return;

    const outageId: null | string = claim.remedyId?.length > 0 ? claim.remedyId : '';
    const postalCode: string = claim.damagePostalCode?.replace(/\D/g, '')?.slice(0, 5) ?? '';

    formContext.setValue('marketId', claim.marketId);
    formContext.setValue('officeId', claim.officeId);
    formContext.setValue('requiredFieldConfigurationTypeId', claim.requiredFieldConfigurationTypeId);

    // Damage Location - Times
    formContext.setValue(
        claimFields.damageDate,
        claim.damageDate?.length !== 0 ? parseDate(claim.damageDate, claim.damageTime) : null
    );
    formContext.setValue(
        claimFields.discoveryDate,
        claim.damageDiscoveryDate?.length !== 0 ? parseDate(claim.damageDiscoveryDate, claim.damageDiscoveryTime) : null
    );
    formContext.setValue('isDamageDateUnknown', claim.damageTimeStampUnknown);

    const isDiscoveryDateSameAsDamageDate = claim.damageDate !== '' && claim.damageDate === claim.damageDiscoveryDate;
    const isDiscoveryTimeSameAsDamageDate = claim.damageTime !== '' && claim.damageTime === claim.damageDiscoveryTime;
    if (isDiscoveryDateSameAsDamageDate && isDiscoveryTimeSameAsDamageDate && !claim.damageTimeStampUnknown) {
        formContext.setValue('isDiscoveryDateSame', true);
    }

    // Damage Location - Address
    formContext.setValue(claimFields.damageStreetAddress, claim.damageAddress ?? '');
    formContext.setValue(claimFields.damageCrossStreet, claim.damageCrossStreet ?? '');
    formContext.setValue(claimFields.damageCity, claim.damageCity ?? '');
    formContext.setValue(
        claimFields.damageState,
        claim.damageStateId ?? damageLocationDefaultFormValues[claimFields.damageState]
    );
    formContext.setValue(claimFields.damageZipCode, postalCode);

    // Damage Location - Damage Type
    formContext.setValue(
        claimFields.damageLocationType,
        claim.damageLocationTypeId ?? damageLocationDefaultFormValues[claimFields.damageLocationType]
    );
    formContext.setValue(claimFields.facilityNumber, claim.facilityNumber ?? '');
    formContext.setValue(claimFields.depthOfDamage, claim.depthOfDamage ?? '');
    formContext.setValue(claimFields.wasDamageAerial, claim.wasDamageAerial);
    formContext.setValue(claimFields.wasDamageUnderground, claim.wasDamageUnderground);

    // Damage Location - Internal Identifications
    formContext.setValue(claimFields.claimNode, claim.claimNode ?? '');
    formContext.setValue(claimFields.claimHub, claim.claimHub ?? '');
    formContext.setValue(claimFields.remedyId, outageId ?? '');
    formContext.setValue('isNodeUnknown', claim.claimNode === 'Unknown');

    // Upload Photos
    formContext.setValue(
        'files',
        claim.claimFileCabinetList.map((c) => {
            return {
                displayName: c.displayName,
                id: c.id,
                name: c.filename,
                note: c.note,
            } as IFile;
        })
    );

    // Damage Details - Damage Description
    formContext.setValue(claimFields.detailsOfDamageLoss, claim.detailsofDamageLoss ?? '');
    formContext.setValue(
        claimFields.estimatedSubsAffected,
        claim.isEstimatedSubsUnknown ? 'Unknown' : claim.subAffected ?? ''
    );
    formContext.setValue(
        claimFields.outageDuration,
        claim.isOutageDurationUnknown ? 'Unknown' : claim.outageDuration ?? '0:00'
    );
    formContext.setValue(
        claimFields.damageActivityType,
        claim.damageActivityTypeId ?? damageDetailsDefaultFormValues[claimFields.damageActivityType]
    );
    formContext.setValue(
        claimFields.damageByType,
        claim.damageByTypeId ?? damageDetailsDefaultFormValues[claimFields.damageByType]
    );
    formContext.setValue(claimFields.isEstimatedSubsUnknown, claim?.isEstimatedSubsUnknown);
    formContext.setValue(claimFields.isOutageDurationUnknown, claim?.isOutageDurationUnknown);

    // Damage Details - Facilities
    formContext.setValue(claimFields.damageFacilityTypes, claim.claimDamageFacilityTypeList ?? []);

    // Check for all aerial types.
    const facilities: Array<IDamageFacilityType> = claim?.claimDamageFacilityTypeList.map(
        (facilityType) =>
            claim?.damageFacilityTypeList.find(
                (damageFacilityType) => Number(damageFacilityType.id) === facilityType
            )! as IDamageFacilityType
    );
    const isAerialFacilities =
        facilities.length > 0 && facilities.every((facility) => facility.damageFacilityClassificationTypeId === 1);

    formContext.setValue(
        claimFields.wereLocatesPresent,
        claim?.wereLocatesPresent
            ? '1'
            : claim?.wereLocatesPresentNA
            ? '3'
            : claim?.wereLocatesPresentUnknown
            ? '4'
            : claim?.wereLocatesPresent === false
            ? '2'
            : '0'
    );
    formContext.setValue(claimFields.wereLocatesPresentNA, claim?.wereLocatesPresentNA);
    formContext.setValue(claimFields.wereLocatesPresentUnknown, claim?.wereLocatesPresent);

    // Damage Details - Locates
    formContext.setValue(
        claimFields.locateAccuracy,
        claim.locateAccuracyId ?? damageDetailsDefaultFormValues[claimFields.locateAccuracy]
    );
    formContext.setValue(claimFields.locateMarkingFlags, claim.locateMarkingsFlags);
    formContext.setValue(claimFields.locateMarkingPaint, claim.locateMarkingsPaint);
    formContext.setValue(claimFields.locateMarkingStakes, claim.locateMarkingsStakes);
    formContext.setValue(
        'isMarkingUnknown',
        (claim.locateCompanyId !== null || claim.locateAccuracyId !== null) &&
            !claim.locateMarkingsFlags &&
            !claim.locateMarkingsPaint &&
            !claim.locateMarkingsStakes
    );

    // Damage Details - Span Replacement
    formContext.setValue(claimFields.isSpanReplacementNeeded, claim.isSpanReplacementNeeded ?? null);
    formContext.setValue(claimFields.wasSpanReplaced, claim.wasSpanReplaced ?? null);
    formContext.setValue(claimFields.cableInConduit, claim.cableInConduit ?? null);
    formContext.setValue(
        claimFields.spanFootage,
        claim.isSpanFootageUnknown ? 'Unknown' : claim.spanFootage?.toString() ?? ''
    );
    formContext.setValue(claimFields.cableSize, claim.cableSize ?? '');
    formContext.setValue(claimFields.isSpanFootageUnknown, claim.isSpanFootageUnknown);
    formContext.setValue('isSpanOtherMaterialUnknown', claim.cableSize === 'Unknown');

    // Damager Details - Damager Opinion
    formContext.setValue(
        claimFields.responsibleForDamage,
        claim.billablePartyTypeId ?? damagerDetailsDefaultFormValues[claimFields.responsibleForDamage]
    );
    formContext.setValue(claimFields.opinionOnResponsibleParty, claim.opinionOnResponsibleParty);

    // Damager Details - Damager Known
    formContext.setValue(claimFields.damagerName, claim.damagerName ?? '');
    formContext.setValue(claimFields.damagerContactName, claim.damagerContactName ?? '');
    formContext.setValue(claimFields.damagerPhoneNumber, claim.damagerPhone ?? '');
    formContext.setValue(claimFields.damagerEmailAddress, claim.damagerEmail ?? '');
    formContext.setValue(claimFields.damagerIdentified, claim.damagerIdentificationComments ?? '');
    formContext.setValue(claimFields.damagerStatement, claim.damagerStatement ?? '');
    formContext.setValue(claimFields.haveDamagerContactDetails, claim.haveDamagerContactDetails);
    formContext.setValue(claimFields.whoWasDamager, claim.whoWasDamager);

    // Repair Details - Repairs
    formContext.setValue(claimFields.legacyClaimNo, claim.legacyClaimNo ?? '');
    formContext.setValue(claimFields.claimComments, claim.claimComments ?? '');
    formContext.setValue(claimFields.repairsDoneOnSite, claim.repairsDoneOnSite);

    // Repair Details - Labor Details
    formContext.setValue(
        'allPersonnel',
        claim.claimLaborList?.map((l) => {
            return {
                date: l.workDate,
                itemId: l.id?.toString(),
                name: l.name,
                overtime: l.overtime,
                regular: l.regularTime,
                success: '1',
                title: l.position,
            } as IUpdatePersonnelResponse;
        })
    );
    formContext.setValue(
        'allMaterials',
        claim.claimMaterialCostList?.map((m) => {
            return {
                itemId: m.claimMaterialCostId,
                name: m.name,
                qty: m.qty,
                success: '1',
            } as IUpdateMaterialResponse;
        })
    );
    formContext.setValue(
        'allVehicles',
        claim.claimVehicleCostList?.map((v) => {
            return {
                date: v.date,
                itemId: v.id,
                regular: v.regularTime,
                success: '1',
                title: v.title,
            } as IUpdateVehicleResponse;
        })
    );

    // Repair Details - Future Repairs
    formContext.setValue(claimFields.clientConstructionNumber, claim.remedyPrismId ?? '');
    formContext.setValue('isClientConstructionNumberUnknown', claim.remedyPrismId === 'Unknown');
    formContext.setValue(claimFields.areFutureRepairsNeeded, claim.areFutureRepairsNeeded);
    formContext.setValue(claimFields.isFreeFormRepairEntry, claim.isFreeFormRepairEntry);

    if (claim.areFutureRepairsNeeded) {
        formContext.setValue('constructionCoordinatorId', claim.constructionCoordinatorId ?? null);
        formContext.setValue('constructionCoordinatorName', claim.constructionCoordinatorName ?? '');
        formContext.setValue('constructionCoordinatorPhone', claim.constructionCoordinatorPhone ?? '');
        formContext.setValue('constructionCoordinatorEmail', claim.constructionCoordinatorEmail ?? '');
        formContext.setValue('constructionCoordinatorDefaultId', claim.constructionCoordinatorId ?? null);
        formContext.setValue('constructionCoordinatorDefaultName', claim.constructionCoordinatorName ?? '');
        formContext.setValue('constructionCoordinatorDefaultPhone', claim.constructionCoordinatorPhone ?? '');
        formContext.setValue('constructionCoordinatorDefaultEmail', claim.constructionCoordinatorEmail ?? '');
    } else {
        formContext.setValue('constructionCoordinatorDefaultId', claim.constructionCoordinatorId ?? null);
        formContext.setValue('constructionCoordinatorDefaultName', claim.constructionCoordinatorName ?? '');
        formContext.setValue('constructionCoordinatorDefaultPhone', claim.constructionCoordinatorPhone ?? '');
        formContext.setValue('constructionCoordinatorDefaultEmail', claim.constructionCoordinatorEmail ?? '');
    }

    // Summary
    formContext.setValue(claimFields.additionalComments, claim.claimComments ?? '');
};

export const buildClaim = (
    formContext: UseFormReturn,
    completedStep: number,
    currentChangedInputList: Array<string>,
    claimInitData: IClaimInit,
    claimId: null | number,
    reportingTech: IReportingTech | null
): ISaveClaimRequest => {
    const utcOffsetInMinutes = new Date().getTimezoneOffset();
    const utcOffsetInHours = utcOffsetInMinutes / 60;
    const utcOffset = utcOffsetInMinutes < 0 ? utcOffsetInHours : Number(`-${utcOffsetInHours}`);

    const formValues: any = formContext.getValues();

    // Remove duplicates
    const changedInputList = [...new Set(currentChangedInputList)];

    const claimComments: string = formContext.getValues(claimFields.claimComments) ?? '';
    const additionalComments: string = formContext.getValues(claimFields.additionalComments) ?? '';
    const commentsAreTheSame = claimComments.toLowerCase() === additionalComments.toLowerCase();

    // If the comments are the same only use one of them, otherwise use both
    const comments = commentsAreTheSame ? claimComments : `${claimComments} ${additionalComments}`;

    const damageDateValue = formContext.getValues(claimFields.damageDate) as CalendarDateTime;
    const damageDate = damageDateValue
        ? `${damageDateValue.month}-${damageDateValue.day}-${damageDateValue.year}`
        : null;
    const damageTime = damageDateValue ? `${damageDateValue.hour}:${damageDateValue.minute}` : null;

    const discoveryDateValue = formContext.getValues(claimFields.discoveryDate) as CalendarDateTime;
    const discoveryDate = discoveryDateValue
        ? `${discoveryDateValue.month}-${discoveryDateValue.day}-${discoveryDateValue.year}`
        : null;
    const discoveryTime = discoveryDateValue ? `${discoveryDateValue.hour}:${discoveryDateValue.minute}` : '';

    const outageDuration =
        formContext.getValues(claimFields.outageDuration) === 'Unknown'
            ? '0:00'
            : formContext.getValues(claimFields.outageDuration);

    const responsible: number = formContext.getValues(claimFields.responsibleForDamage);
    const billablePartyId: number | null =
        responsible !== damagerDetailsDefaultFormValues[claimFields.responsibleForDamage] ? responsible : null;

    const wereLocatesPresent = formContext.getValues(claimFields.wereLocatesPresent);
    const wereLocatesPresentNA = formContext.getValues(claimFields.wereLocatesPresentNA ?? null);
    const wereLocatesPresentUnknown = formContext.getValues(claimFields.wereLocatesPresentUnknown ?? null);
    const wereLocatesPresentValue = wereLocatesPresent === '1' ? true : wereLocatesPresent === '2' ? false : null;

    return {
        areFutureRepairsNeeded: formContext.getValues(claimFields.areFutureRepairsNeeded),
        cableInConduit: formContext.getValues(claimFields.cableInConduit),
        cableSize: formContext.getValues(claimFields.cableSize),
        changedInputList: changedInputList,
        claimComments: comments ?? '',
        claimHub: formContext.getValues(claimFields.claimHub),
        claimId: claimId,
        claimNode: formContext.getValues(claimFields.claimNode),
        clientId: reportingTech?.clientId,
        completeMobileSubmission: false,
        constructionCoordinatorEmail: formContext.getValues('constructionCoordinatorEmail') ?? '',
        constructionCoordinatorId: formContext.getValues('constructionCoordinatorId') ?? null,
        constructionCoordinatorName: formContext.getValues('constructionCoordinatorName') ?? '',
        constructionCoordinatorPhone: formContext.getValues('constructionCoordinatorPhone') ?? '',
        contractorAddress1: '', // Doesn't exist in schema anymore.
        contractorAddress2: '', // Doesn't exist in schema anymore.
        contractorCity: '', // Doesn't exist in schema anymore.
        contractorContactName: '', // Doesn't exist in schema anymore.
        contractorEmail: '', // Doesn't exist in schema anymore.
        contractorName: '', // Doesn't exist in schema anymore.
        contractorPhone: '', // Doesn't exist in schema anymore.
        contractorPostalCode: '', // Doesn't exist in schema anymore.
        contractorState: '', // Doesn't exist in schema anymore.
        damageActivityTypeId: formContext.getValues(claimFields.damageActivityType),
        damageByTypeId: formContext.getValues(claimFields.damageByType),
        damageFacilityTypes: formContext.getValues(claimFields.damageFacilityTypes) ?? [],
        damageLocationTypeId: formContext.getValues(claimFields.damageLocationType),
        damageAddress1: formContext.getValues(claimFields.damageStreetAddress),
        damageAddress2: '', // Doesn't exist in schema anymore.
        damageCity: formContext.getValues(claimFields.damageCity),
        damageCounty: '', // Doesn't exist in schema anymore.
        damageCrossStreet: formContext.getValues(claimFields.damageCrossStreet),
        damageDate: damageDate,
        damageTime: damageTime ?? '',
        damageDiscoveryDate: discoveryDate,
        damageDiscoveryTime: discoveryTime ?? '',
        damagePhoneNumber: '', // Doesn't exist in schema anymore.
        damagePostalCode: formContext.getValues(claimFields.damageZipCode),
        damageState: formContext.getValues(claimFields.damageState)?.toString(),
        damageTimeStampUnknown: formValues.isDamageDateUnknown,
        damagerAddress1: '', // Doesn't exist in schema anymore.
        damagerAddress2: '', // Doesn't exist in schema anymore.
        damagerAddressCity: '', // Doesn't exist in schema anymore.
        damagerAddressCounty: '', // Doesn't exist in schema anymore.
        damagerAddressPostal: '', // Doesn't exist in schema anymore.
        damagerAddressStateId: null, // Doesn't exist in schema anymore.
        damagerContactName: formContext.getValues(claimFields.damagerContactName),
        damagerId: null, // Comes back in save claim response when damager info is changed.
        damagerIdentificationComments: formContext.getValues(claimFields.damagerIdentified),
        damagerEmail: formContext.getValues(claimFields.damagerEmailAddress),
        damagerName: formContext.getValues(claimFields.damagerName),
        damagerPhone: formContext.getValues(claimFields.damagerPhoneNumber),
        damagerStatement: formContext.getValues(claimFields.damagerStatement),
        depthOfDamage: formContext.getValues(claimFields.depthOfDamage),
        detailsofDamageLoss: formContext.getValues(claimFields.detailsOfDamageLoss),
        facilityNumber: formContext.getValues(claimFields.facilityNumber),
        haveDamagerContactDetails: formContext.getValues(claimFields.haveDamagerContactDetails),
        hitKit: false, // Doesn't exist in schema anymore.
        isAutoSave: true,
        isEstimatedSubsUnknown: formContext.getValues(claimFields.isEstimatedSubsUnknown),
        isFreeFormRepairEntry: formContext.getValues(claimFields.isFreeFormRepairEntry),
        isMobileSubmission: true,
        isOutageDurationUnknown: formContext.getValues(claimFields.isOutageDurationUnknown),
        isSpanFootageUnknown: formContext.getValues(claimFields.isSpanFootageUnknown),
        isSpanReplacementNeeded: formContext.getValues(claimFields.isSpanReplacementNeeded) ?? false,
        legacyClaimNo: formContext.getValues(claimFields.legacyClaimNo),
        locateAccuracyId: formContext.getValues(claimFields.locateAccuracy),
        locateCompanyId: null, // Doesn't exist in schema anymore.
        locateMarkingsFlags: formContext.getValues(claimFields.locateMarkingFlags),
        locateMarkingsPaint: formContext.getValues(claimFields.locateMarkingPaint),
        locateMarkingsStakes: formContext.getValues(claimFields.locateMarkingStakes),
        locateTicket: '', // Doesn't exist in schema anymore.
        locateWorkDoneFor: '', // Doesn't exist in schema anymore.
        marketId: formValues.marketId,
        noDamager: false, // Doesn't exist in schema anymore.
        officeId: formValues.officeId,
        onCallSupervisorEmail: '', // Doesn't exist in schema anymore.
        onCallSupervisorName: '', // Doesn't exist in schema anymore.
        onCallSupervisorPhone: '', // Doesn't exist in schema anymore.
        opinionOnResponsibleParty: formContext.getValues(claimFields.opinionOnResponsibleParty),
        outageDuration: outageDuration,
        remedyId: formContext.getValues(claimFields.remedyId) ?? '',
        remedyPrismID: formContext.getValues(claimFields.clientConstructionNumber) ?? '',
        repairsDoneOnSite: formContext.getValues(claimFields.repairsDoneOnSite),
        repairTypeId: null,
        reportingTechBillablePartyTypeId: billablePartyId,
        reportingTechId: claimInitData?.reportingTechId,
        reportingTechResponseDate: '', // Doesn't exist in schema anymore.
        reportingTechResponseTime: '', // Doesn't exist in schema anymore.
        requestFieldInvestigatorOnSite: false, // Doesn't exist in schema anymore.
        requiredFieldConfigurationTypeId: formContext.getValues('requiredFieldConfigurationTypeId'),
        sceneComments: '', // Doesn't exist in schema anymore.
        spanFootage: formContext.getValues(claimFields.spanFootage)?.toString() ?? '',
        subAffected: formContext.getValues(claimFields.estimatedSubsAffected)?.toString(),
        techEmail: claimInitData?.techEmail ?? '',
        techFirstName: claimInitData?.techFirstName ?? '',
        techId: claimInitData?.techId ?? '',
        techLastName: claimInitData?.techLastName ?? '',
        techPhone: claimInitData?.techPhone ?? '',
        techSupervisor: claimInitData?.techSupervisor ?? '',
        techSupervisorPhone: claimInitData?.techSupervisorPhone ?? '',
        techSupervisorEmail: claimInitData?.techSupervisorEmail ?? '',
        utcOffset,
        wasDamageAerial: formContext.getValues(claimFields.wasDamageAerial),
        wasDamageUnderground: formContext.getValues(claimFields.wasDamageUnderground),
        wasSpanReplaced: formContext.getValues(claimFields.wasSpanReplaced),
        wereLocatesPresent: !wereLocatesPresentNA && !wereLocatesPresentUnknown ? wereLocatesPresentValue : null,
        wereLocatesPresentNA: wereLocatesPresentNA,
        wereLocatesPresentUnknown: wereLocatesPresentUnknown,
        whoWasDamager: formContext.getValues(claimFields.whoWasDamager),
    };
};

export const convertClaim = (oldClaim: IClaim) => {
    const claim: Claim & {
        ClientConstructionNumber: string;
        DamageFacilityTypes: string[];
        DamageState: string;
        DamageStreetAddress: string;
        DamageZipCode: string;
        DetailsOfDamageLoss: string;
        DiscoveryDate: string;
        ResponsibleForDamage: number | null;
    } = {
        AreFutureRepairsNeeded: oldClaim.areFutureRepairsNeeded,
        BillablePartyTypeId: oldClaim.billablePartyTypeId,
        BillablePartyTypeList: oldClaim.billablePartyTypeList,
        CableInConduit: oldClaim.cableInConduit,
        CableSize: oldClaim.cableSize,
        ClaimComments: oldClaim.claimComments,
        ClaimDamageFacilityTypeList: oldClaim.claimDamageFacilityTypeList,
        ClaimFileCabinetList: oldClaim.claimFileCabinetList,
        ClaimHub: oldClaim.claimHub,
        ClaimId: oldClaim.claimId,
        ClaimLaborList: oldClaim.claimLaborList,
        ClaimManager: oldClaim.claimManager,
        ClaimManagerEmail: oldClaim.claimManagerEmail,
        ClaimManagerPhone: oldClaim.claimManagerPhone,
        ClaimManagerUserId: oldClaim.claimManagerUserId,
        ClaimMaterialCostList: oldClaim.claimMaterialCostList,
        ClaimNode: oldClaim.claimNode,
        ClaimStatusId: oldClaim.claimStatusId,
        ClaimVehicleCostList: oldClaim.claimVehicleCostList,
        ClientConstructionNumber: oldClaim.remedyPrismId ?? '',
        ClientConstructionNumberAltName: oldClaim.clientConstructionNumberAltName,
        ClientId: oldClaim.clientId,
        ClientName: oldClaim.clientName,
        ConstructionCoordinatorName: oldClaim.constructionCoordinatorName ?? '',
        ConstructionCoordinatorId: oldClaim.constructionCoordinatorId ?? null,
        ConstructionCoordinatorEmail: oldClaim.constructionCoordinatorEmail ?? '',
        ConstructionCoordinatorPhone: oldClaim.constructionCoordinatorPhone ?? '',
        ContractorAddress1: oldClaim.contractorAddress1,
        ContractorAddress2: oldClaim.contractorAddress2,
        ContractorCity: oldClaim.contractorCity,
        ContractorContactName: oldClaim.contractorContactName,
        ContractorEmail: oldClaim.contractorEmail,
        ContractorName: oldClaim.contractorName,
        ContractorPhone: oldClaim.contractorPhone,
        ContractorPostalCode: oldClaim.contractorPostalCode,
        ContractorState: oldClaim.contractorState,
        DamageActivityTypeId: oldClaim.damageActivityTypeId,
        DamageActivityTypeList: oldClaim.damageActivityTypeList,
        DamageAddress: oldClaim.damageAddress,
        DamageByTypeId: oldClaim.damageByTypeId,
        DamageByTypeList: oldClaim.damageByTypeList,
        DamageCity: oldClaim.damageCity,
        DamageClaimNo: oldClaim.damageClaimNo,
        DamageCounty: oldClaim.damageCounty,
        DamageCrossStreet: oldClaim.damageCrossStreet,
        DamageDate: oldClaim.damageDate,
        DamageDiscoveryDate: oldClaim.damageDiscoveryDate,
        DamageDiscoveryTime: oldClaim.damageDiscoveryTime,
        DamageFacilityTypeList: oldClaim.damageFacilityTypeList,
        DamageFacilityTypes: oldClaim.damageFacilityTypeList.map((dft) => dft.id),
        DamageLocationTypeId: oldClaim.damageLocationTypeId,
        DamageLocationTypeList: oldClaim.damageLocationTypeList,
        DamagePhoneNumber: oldClaim.damagePhoneNumber,
        DamagePostalCode: oldClaim.damagePostalCode,
        DamageState: oldClaim.damageStateId,
        DamageStateId: oldClaim.damageStateId,
        DamageStreetAddress: oldClaim.damageAddress,
        DamageTime: oldClaim.damageTime,
        DamageTimeStampUnknown: oldClaim.damageTimeStampUnknown,
        DamageZipCode: oldClaim.damagePostalCode,
        DamagerAddress1: oldClaim.damagerAddress1,
        DamagerAddress2: oldClaim.damagerAddress2,
        DamagerAddressCity: oldClaim.damagerAddressCity,
        DamagerAddressCounty: oldClaim.damagerAddressCounty,
        DamagerAddressPostal: oldClaim.damagerAddressPostal,
        DamagerAddressStateId: oldClaim.damagerAddressStateId,
        DamagerContactName: oldClaim.damagerContactName,
        DamagerEmail: oldClaim.damagerEmail,
        DamagerId: oldClaim.damagerId,
        DamagerIdentificationComments: oldClaim.damagerIdentificationComments,
        DamagerName: oldClaim.damagerName,
        DamagerPhone: oldClaim.damagerPhone,
        DamagerStatement: oldClaim.damagerStatement,
        DepthOfDamage: oldClaim.depthOfDamage,
        DetailsofDamageLoss: oldClaim.detailsofDamageLoss,
        DetailsOfDamageLoss: oldClaim.detailsofDamageLoss,
        DiscoveryDate: oldClaim.damageDiscoveryDate,
        FacilityNumber: oldClaim.facilityNumber,
        FirstInvoiceDate: oldClaim.firstInvoiceDate,
        HaveDamagerContactDetails: oldClaim.haveDamagerContactDetails,
        HitKit: oldClaim.hitKit,
        IsEstimatedSubsUnknown: oldClaim.isEstimatedSubsUnknown,
        IsFreeFormRepairEntry: oldClaim.isFreeFormRepairEntry,
        IsOutageDurationUnknown: oldClaim.isOutageDurationUnknown,
        IsSpanFootageUnknown: oldClaim.isSpanFootageUnknown,
        IsSpanReplacementNeeded: oldClaim.isSpanReplacementNeeded,
        LegacyClaimNo: oldClaim.legacyClaimNo,
        LocateAccuracyId: oldClaim.locateAccuracyId,
        LocateAccuracyList: oldClaim.locateAccuracyList,
        LocateCompanyId: oldClaim.locateCompanyId,
        LocateCompanyList: oldClaim.locateCompanyList,
        LocateMarkingsFlags: oldClaim.locateMarkingsFlags,
        LocateMarkingsPaint: oldClaim.locateMarkingsPaint,
        LocateMarkingsStakes: oldClaim.locateMarkingsStakes,
        LocateTicket: oldClaim.locateTicket,
        MarketId: oldClaim.marketId,
        MarketIsTRP: oldClaim.marketIsTRP,
        MarketName: oldClaim.marketName,
        NoDamager: oldClaim.noDamager,
        OfficeId: oldClaim.officeId,
        OfficeName: oldClaim.officeName,
        OnCallSupervisor: oldClaim.onCallSupervisor,
        OnCallSupervisorEmail: oldClaim.onCallSupervisorEmail,
        OnCallSupervisorPhone: oldClaim.onCallSupervisorPhone,
        OpinionOnResponsibleParty: oldClaim.opinionOnResponsibleParty,
        OutageDuration: oldClaim.outageDuration,
        RemedyId: oldClaim.remedyId,
        RemedyPrismId: oldClaim.remedyPrismId,
        RepairTypeId: oldClaim.repairTypeId,
        RepairsDoneOnSite: oldClaim.repairsDoneOnSite,
        ReportingTechId: oldClaim.reportingTechId,
        RequestFieldInvestigatorOnSite: oldClaim.requestFieldInvestigatorOnSite,
        RequiredFieldConfigurationTypeId: oldClaim.requiredFieldConfigurationTypeId,
        ResponsibleForDamage: oldClaim.billablePartyTypeId,
        SceneComments: oldClaim.sceneComments,
        SpanFootage: oldClaim.spanFootage,
        StateList: oldClaim.stateList,
        SubAffected: oldClaim.subAffected,
        TechEmail: oldClaim.techEmail,
        TechFirstName: oldClaim.techFirstName,
        TechId: oldClaim.techId,
        TechLastName: oldClaim.techLastName,
        TechPhone: oldClaim.techPhone,
        TechResponseDate: oldClaim.techResponseDate,
        TechResponseTime: oldClaim.techResponseTime,
        TechSupervisor: oldClaim.techSupervisor,
        TechSupervisorEmail: oldClaim.techSupervisorEmail,
        TechSupervisorPhone: oldClaim.techSupervisorPhone,
        WasDamageAerial: oldClaim.wasDamageAerial,
        WasDamageUnderground: oldClaim.wasDamageUnderground,
        WasSpanReplaced: oldClaim.wasSpanReplaced,
        WereLocatesPresent: oldClaim.wereLocatesPresent,
        WereLocatesPresentNA: oldClaim.wereLocatesPresentNA,
        WereLocatesPresentUnknown: oldClaim.wereLocatesPresentUnknown,
        WhoWasDamager: oldClaim.whoWasDamager,
    };

    return claim;
};

export const hasDamageDetails = (claim: Claim) => {
    return !!(
        claim.CableSize.length !== 0 ||
        claim.DamageActivityTypeId !== null ||
        claim.DamageByTypeId !== null ||
        claim.DetailsofDamageLoss.length !== 0 ||
        (claim?.SubAffected !== null && claim.SubAffected?.length !== 0) ||
        claim.ClaimDamageFacilityTypeList.length !== 0 ||
        claim.LocateAccuracyId !== null ||
        claim.LocateMarkingsFlags ||
        claim.LocateMarkingsPaint ||
        claim.LocateMarkingsStakes ||
        claim.OutageDuration !== '0:00' ||
        claim.SpanFootage !== null ||
        claim.WereLocatesPresent !== null ||
        claim.WereLocatesPresentNA ||
        claim.WereLocatesPresentUnknown
    );
};