import { StyledSummaryTableCell, SummaryHeader } from '../../../../components';
import { useFormContext } from 'react-hook-form';

import type { IUpdatePersonnelResponse } from '../../../../types';
import type { FC } from 'react';

interface IPersonnelProps {
    setStep: (step: number) => void;
}

export const PersonnelSummary: FC<IPersonnelProps> = ({ setStep }) => {
    const formContext = useFormContext();

    const personnel: Array<IUpdatePersonnelResponse> = formContext.getValues('allPersonnel');

    const onStepClick = () => setStep(4);

    return (
        <div>
            <SummaryHeader isLink onLink={onStepClick}>
                Personnel
            </SummaryHeader>

            {/* NO PERSONNEL MESSAGE */}
            {personnel?.length === 0 && (
                <span className={'font-medium dark:text-gray-200'}>No personnel were added to this claim.</span>
            )}

            {/* PERSONNEL TABLE */}
            {personnel?.length !== 0 && (
                <table className={'mt-1'}>
                    <thead>
                        <tr>
                            <StyledSummaryTableCell isHeader>Title</StyledSummaryTableCell>
                            <StyledSummaryTableCell isHeader isNumeric>
                                Regular
                            </StyledSummaryTableCell>
                            <StyledSummaryTableCell isHeader isNumeric>
                                Overtime
                            </StyledSummaryTableCell>
                        </tr>
                    </thead>
                    <tbody>
                        {personnel.map((p) => {
                            return (
                                <tr key={p.itemId}>
                                    <StyledSummaryTableCell>{p.title}</StyledSummaryTableCell>
                                    <StyledSummaryTableCell isNumeric>{p.regular}</StyledSummaryTableCell>
                                    <StyledSummaryTableCell isNumeric>{p.overtime}</StyledSummaryTableCell>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};