import { Input } from '../inputs';
import clsx from 'clsx';

export const SupervisorFields = () => {
    return (
        <div className={'mt-4'}>
            <h1
                className={clsx(
                    'text-2xl font-semibold text-brand-500 dark:text-brand-100',
                    'min-w-[250px] border-b border-gray-200 pb-2 dark:border-gray-700'
                )}
            >
                Supervisor
            </h1>

            <div className={'flex h-full w-full flex-col items-center space-y-3 sm:mt-3'}>
                {/* FIRST NAME */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'First Name',
                        name: 'techSupervisorFirstName',
                    }}
                    tooltip={"The first name of the technician\'s supervisor."}
                />

                {/* LAST NAME */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'Last Name',
                        name: 'techSupervisorLastName',
                    }}
                    tooltip={"The last name of the technician\'s supervisor."}
                />

                {/* PHONE NUMBER */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'Phone Number',
                        name: 'techSupervisorPhoneNumber',
                    }}
                    tooltip={"A phone number the technician\'s supervisor can be reached at."}
                    type={'phone'}
                />

                {/* EMAIL ADDRESS */}
                <Input
                    inputOptions={{
                        isRequired: true,
                        label: 'Email Address',
                        name: 'techSupervisorEmailAddress',
                    }}
                    tooltip={"An email address the technician\'s supervisor can be reached at."}
                />
            </div>
        </div>
    );
};
