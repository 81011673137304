import { ForwardedRef, forwardRef } from 'react';
import { Dialog } from '@headlessui/react';
import { FCC } from '../../../types';

export const ModalTitle: FCC = forwardRef(({ children }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <Dialog.Title className={'text-2xl font-medium leading-6 text-gray-800 dark:text-gray-200'} ref={ref}>
            {children}
        </Dialog.Title>
    );
});