import { createContext } from 'react';

import type { IReportingTech } from '../types';

interface IReportingTechContext {
    isProfileConfirmed: boolean;
    reportingTech: IReportingTech | null;
    reportingTechEmail: string;
    reportingTechInitials: string;
    reportingTechName: string;
    reportingTechPhone: string;
}

export const ReportingTechContext = createContext<IReportingTechContext>({
    isProfileConfirmed: false,
    reportingTech: null,
    reportingTechEmail: '',
    reportingTechInitials: '',
    reportingTechName: '',
    reportingTechPhone: '',
});