import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useButton } from '@react-aria/button';
import { Spinner } from '../Spinner';
import clsx from 'clsx';

import type { AriaButtonProps } from 'react-aria';
import type { FCCCNFRH } from '../../types';
import type { ForwardedRef } from 'react';

interface IButtonProps {
    busyText?: string;
    buttonProps?: AriaButtonProps<'button'>;
    isBusy?: boolean;
    isDisabledWhileBusy?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    variant?: 'primary' | 'outline' | 'ghost' | 'link';
}

interface IButtonHandle {
    focus?: (options?: FocusOptions | undefined) => void;
    scrollIntoView?: (options?: boolean | ScrollIntoViewOptions) => void;
    scrollWidth?: number;
}

export const Button: FCCCNFRH<IButtonProps, IButtonHandle> = forwardRef(
    (
        {
            busyText = 'Saving',
            buttonProps,
            children,
            className,
            isBusy,
            isDisabledWhileBusy = true,
            size = 'md',
            variant = 'primary',
        },
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        const buttonRef = useRef<HTMLButtonElement>(null);

        const { buttonProps: ariaProps } = useButton(
            {
                ...buttonProps,
                isDisabled: buttonProps?.isDisabled || (isDisabledWhileBusy && isBusy),
            },
            buttonRef
        );

        const { isFocusVisible, focusProps } = useFocusRing();

        useImperativeHandle(
            ref,
            () => {
                return {
                    focus(options?: FocusOptions) {
                        buttonRef.current?.focus(options);
                    },
                    scrollIntoView(arg?: boolean | ScrollIntoViewOptions) {
                        buttonRef.current?.scrollIntoView(arg);
                    },
                    scrollWidth: buttonRef.current?.scrollWidth,
                } as HTMLButtonElement;
            },
            [buttonRef]
        );

        const variants = {
            primary: 'bg-brand-500 text-gray-200 hover:bg-brand-600 dark:bg-brand-100 hover:dark:bg-brand-200',
            outline:
                'border border-gray-300 hover:border-transparent hover:bg-gray-200 dark:border-gray-700 dark:text-gray-200 hover:dark:bg-gray-dark-500',
            ghost: 'hover:bg-gray-200 hover:dark:bg-gray-700 dark:text-gray-200',
            link: 'text-base text-brand-500 underline hover:opacity-75 dark:text-brand-100 dark:hover:text-brand-200',
        };

        const sizes = {
            xs: 'px-2 py-1 text-sm',
            sm: 'px-4 py-2 text-sm',
            md: 'px-4 py-2 text-base',
            lg: 'px-6 py-3 text-base',
        };

        return (
            <button
                {...mergeProps(ariaProps, focusProps)}
                className={clsx(
                    'inline-flex rounded-md font-medium outline-none active:scale-95',
                    'disabled:cursor-not-allowed disabled:opacity-50 disabled:active:scale-100',
                    isFocusVisible &&
                        'ring-4 ring-brand-500 ring-offset-2 ring-offset-white dark:ring-brand-100 dark:ring-offset-gray-dark-900',
                    variant !== 'link' && sizes[size],
                    variants[variant],
                    className
                )}
                ref={buttonRef}
            >
                <div className={'flex w-full flex-row items-center justify-center text-center uppercase'}>
                    {/* BUSY SPINNER */}
                    {isBusy && (
                        <Spinner
                            className={clsx(
                                size === 'sm' || size === 'xs' ? 'h-5 w-5' : 'h-6 w-6',
                                variant === 'outline' && 'text-black'
                            )}
                        >
                            {busyText}
                        </Spinner>
                    )}

                    {/* CHILDREN */}
                    {!isBusy && children}
                </div>
            </button>
        );
    }
);