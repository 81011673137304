import { useTimeFieldState } from '@react-stately/datepicker';
import { useTimeField } from '@react-aria/datepicker';
import { useLocale } from '@react-aria/i18n';
import { DateSegment } from './DateSegment';
import { useRef } from 'react';
import clsx from 'clsx';

import type { DateSegment as DateSegmentState } from '@react-stately/datepicker';
import type { TimeValue } from '@react-types/datepicker';
import type { IToggle } from '../../../hooks';
import type { FC } from 'react';

interface ITimeFieldProps {
    hasValue: IToggle;
    label?: string;
    name?: string;
    onChange: (newTime: TimeValue) => void;
    value: TimeValue;
}

export const TimeField: FC<ITimeFieldProps> = ({ hasValue, label, name, onChange, value }) => {
    const timeInputRef = useRef<HTMLDivElement>(null);

    const { locale } = useLocale();

    const state = useTimeFieldState({
        label: label,
        locale,
        onChange: onChange,
        value: value,
        isRequired: false,
    });

    const { fieldProps, labelProps } = useTimeField(
        { label: label, onChange: onChange, value: value },
        state,
        timeInputRef
    );

    return (
        <div {...fieldProps}>
            {/* LABEL */}
            <label {...labelProps} className={'font-bold dark:text-gray-200'}>
                {label}
            </label>
            <div
                className={clsx(
                    'flex border-0 border-b-4 border-transparent bg-gray-200 dark:bg-gray-600',
                    'w-full text-sm focus-within:border-brand-500 focus-within:ring-0 focus-within:dark:border-brand-100 sm:text-base',
                    'py-[6px] pl-2 text-black dark:text-gray-200'
                )}
                ref={timeInputRef}
            >
                {/* TIME SEGMENTS */}
                {state.segments.map((segment: DateSegmentState, index: number) => (
                    <DateSegment hasValue={hasValue} key={index} segment={segment} state={state} />
                ))}
            </div>
        </div>
    );
};