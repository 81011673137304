import { SummaryRow } from './SummaryRow';

interface SummaryTextProps {
    hideColon?: boolean;
    label: string;
    value: string;
}

export const SummaryText = ({ hideColon, label, value = '' }: SummaryTextProps) => {
    const minLength = 75;

    const labelDisplay = hideColon ? label : `${label}:`;

    return (
        <>
            {value.length > minLength && (
                <>
                    <div className={'col-span-2 font-bold dark:text-gray-200'}>{labelDisplay}</div>
                    <div className={'col-span-2 pb-1 dark:text-gray-300'}>{value}</div>
                </>
            )}
            {value.length <= minLength && (
                <SummaryRow hideColon={hideColon} label={label}>
                    {value}
                </SummaryRow>
            )}
        </>
    );
};