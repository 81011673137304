import { FormFields, FormHeader, FormSection } from '../form';
import { Checkbox, DatePicker } from '../../../../components';
import { useToggle, useValidation } from '../../../../hooks';
import { useFormContext } from 'react-hook-form';
import { claimFields } from '../../../../config';
import { useState } from 'react';

import type { Dispatch, FC, SetStateAction } from 'react';
import type { DateValue } from '@internationalized/date';

interface ITimesProps {
    setSaveAndQuitChanges?: Dispatch<SetStateAction<Array<string>>>;
}

export const Times: FC<ITimesProps> = ({ setSaveAndQuitChanges }) => {
    const formContext = useFormContext();

    const { fieldRequirements } = useValidation({});

    const resetDamageDate = useToggle(false);
    const resetDiscoveryDate = useToggle(false);

    const damageDate = formContext.watch(claimFields.damageDate);
    const discoveryDate = formContext.watch(claimFields.discoveryDate);

    const isDamageDateUnknown: boolean = formContext.watch('isDamageDateUnknown') ?? false;
    const isDiscoveryDateSame: boolean = formContext.watch('isDiscoveryDateSame') ?? false;

    // Holds the previous date so that when 'damage date is unknown'
    // is unchecked, we can set the date back to the previous date.
    const [prevDamageDate, setPreviousDamageDate] = useState<DateValue>(damageDate);

    const onDamageDateChange = (newDate: DateValue) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDate']);
        formContext.setValue(claimFields.damageDate, newDate);
        formContext.setValue('isDamageDateUnknown', false);
        formContext.clearErrors(claimFields.damageDate);

        if (isDiscoveryDateSame) formContext.setValue(claimFields.discoveryDate, newDate);
    };

    const onDiscoveryDateChange = (newDate: DateValue) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDiscoveryDate']);
        formContext.setValue(claimFields.discoveryDate, newDate);
        formContext.setValue('isDiscoveryDateSame', false);
        formContext.clearErrors(claimFields.discoveryDate);
    };

    const onIsDamageDateUnknownChange = (isSelected: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDate']);
        formContext.setValue('isDamageDateUnknown', isSelected);
        formContext.clearErrors(claimFields.damageDate);

        if (isSelected) {
            formContext.setValue(claimFields.damageDate, null);
            setPreviousDamageDate(damageDate);
            formContext.clearErrors(claimFields.damageDate);
            formContext.clearErrors(claimFields.discoveryDate);
            // Disables the 'discovery date is same' checkbox when damage date is unknown.
            formContext.setValue('isDiscoveryDateSame', false);
        } else {
            // Reset form damage date if the 'damage date is unknown' box is unchecked.
            formContext.setValue(claimFields.damageDate, prevDamageDate);
        }
    };

    const onIsDiscoverySameChange = (isSelected: boolean) => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDiscoveryDate']);
        formContext.setValue('isDiscoveryDateSame', isSelected);
        formContext.clearErrors('damageDiscoveryDate');

        // Sets the discovery date to the damage when the 'discovery date is same as damage date' box is checked.
        if (isSelected) formContext.setValue(claimFields.discoveryDate, formContext.getValues(claimFields.damageDate));
    };

    const onResetDamageDate = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDate']);
        formContext.setValue(claimFields.damageDate, null);
        if (isDiscoveryDateSame) formContext.setValue(claimFields.discoveryDate, null);
        resetDamageDate.toggle();
    };

    const onResetDiscoveryDate = () => {
        setSaveAndQuitChanges?.((prev) => [...prev, 'DamageDiscoveryDate']);
        formContext.setValue(claimFields.discoveryDate, null);
        resetDiscoveryDate.toggle();
    };

    return (
        <FormSection>
            <FormHeader>Dates & Times</FormHeader>

            <FormFields
                replaceClassName={
                    'flex flex-col sm:flex-row justify-center mt-6 space-y-4 sm:space-y-0 sm:space-x-4 w-full'
                }
            >
                {/* DAMAGE DATE */}
                <div className={'flex w-full flex-col'}>
                    {/* DATEPICKER */}
                    {!isDamageDateUnknown && (
                        <DatePicker
                            customErrorMessage={'The damage date cannot be after the discovery date.'}
                            datePickerProps={{
                                defaultValue: damageDate,
                                granularity: 'minute',
                                isDisabled: isDamageDateUnknown,
                                isRequired: fieldRequirements.DamageDate,
                                label: 'Damage Date',
                                maxValue: isDamageDateUnknown ? undefined : discoveryDate,
                                onChange: onDamageDateChange,
                                shouldCloseOnSelect: false,
                            }}
                            key={resetDamageDate.isOn ? 'reset' : 'normal'}
                            name={claimFields.damageDate}
                            onReset={onResetDamageDate}
                            timeFieldLabel={'Damage Time'}
                            tooltip={'The date and time that the damage occurred.'}
                        />
                    )}
                    {isDamageDateUnknown && (
                        <DatePicker
                            customErrorMessage={'The damage date cannot be after the discovery date.'}
                            datePickerProps={{
                                granularity: 'minute',
                                isDisabled: true,
                                isRequired: fieldRequirements.DamageDate,
                                label: 'Damage Date',
                                maxValue: isDamageDateUnknown ? undefined : discoveryDate,
                                onChange: onDamageDateChange,
                                shouldCloseOnSelect: false,
                            }}
                            name={claimFields.damageDate}
                            timeFieldLabel={'Damage Time'}
                            tooltip={'The date and time that the damage occurred.'}
                        />
                    )}

                    {/* CHECKBOX */}
                    <Checkbox
                        options={{
                            'aria-label': 'Damage date and time',
                            isSelected: isDamageDateUnknown,
                            onChange: onIsDamageDateUnknownChange,
                        }}
                    >
                        <div className={'font-semibold'}>Damage date is unknown</div>
                    </Checkbox>
                </div>

                {/* DISCOVERY DATE */}
                <div className={'flex w-full flex-col'}>
                    {/* DATEPICKER */}
                    {!isDiscoveryDateSame && (
                        <DatePicker
                            customErrorMessage={'The discovery date cannot be before the damage date.'}
                            datePickerProps={{
                                defaultValue: discoveryDate,
                                granularity: 'minute',
                                isDisabled: isDiscoveryDateSame,
                                isRequired: fieldRequirements.DiscoveryDate,
                                label: 'Discovery Date',
                                minValue: damageDate,
                                onChange: onDiscoveryDateChange,
                                shouldCloseOnSelect: false,
                            }}
                            key={resetDiscoveryDate.isOn ? 'reset' : 'normal'}
                            name={claimFields.discoveryDate}
                            onReset={onResetDiscoveryDate}
                            timeFieldLabel={'Discovery Time'}
                            tooltip={'The date and time that the damage was discovered.'}
                        />
                    )}

                    {isDiscoveryDateSame && (
                        <DatePicker
                            customErrorMessage={'The discovery date cannot be before the damage date.'}
                            datePickerProps={{
                                granularity: 'minute',
                                isDisabled: true,
                                isRequired: fieldRequirements.DiscoveryDate,
                                label: 'Discovery Date',
                                value: damageDate,
                            }}
                            name={'fakeDamageDiscoveryDate'}
                            tooltip={'The date and time that the damage was discovered.'}
                        />
                    )}

                    {/* CHECKBOX */}
                    <Checkbox
                        options={{
                            'aria-label': 'Discovery date and time',
                            isDisabled: isDamageDateUnknown,
                            isSelected: isDiscoveryDateSame,
                            name: 'isDiscoveryDateSame',
                            onChange: onIsDiscoverySameChange,
                        }}
                    >
                        <div className={'font-semibold'}>Discovery date is the same as the damage date</div>
                    </Checkbox>
                </div>
            </FormFields>
        </FormSection>
    );
};