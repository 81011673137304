import { DOMAttributes } from 'react';
import { FCC } from '../types';

interface IErrorMessageProps {
    ariaProps?: DOMAttributes<HTMLDivElement>;
}

export const ErrorMessage: FCC<IErrorMessageProps> = ({ ariaProps, children }) => {
    return (
        <div {...ariaProps} className={'font-medium text-red-500 dark:text-red-600'}>
            {children}
        </div>
    );
};