import { MenuBar } from '../components';

import type { FCC } from '../types';

export const PublicLayout: FCC = ({ children }) => {
    return (
        <>
            <MenuBar isRegister />
            <div className={'min-h-screen w-full bg-gray-100 dark:bg-gray-dark-800'}>{children}</div>
        </>
    );
};