interface IQueryKey {
    claim: string;
    claimFieldConfig: string;
    claimInit: string;
    markets: string;
    offices: string;
    reportingTech: string;
    techDashboard: string;
}

export const queryKeys: IQueryKey = {
    claim: 'claim',
    claimFieldConfig: 'claimFieldConfig',
    claimInit: 'claimInit',
    markets: 'markets',
    offices: 'offices',
    reportingTech: 'reportingTech',
    techDashboard: 'techDashboard',
};