import { useButton } from '@react-aria/button';
import { useRef } from 'react';
import clsx from 'clsx';

import type { AriaButtonProps } from '@react-aria/button';
import type { FCC } from '../../../types';

interface IFieldButtonProps {
    ariaProps: AriaButtonProps<'button'>;
    hasValue?: boolean;
    isRequired?: boolean;
}

export const FieldButton: FCC<IFieldButtonProps> = ({ ariaProps, children, hasValue, isRequired }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { buttonProps } = useButton(ariaProps, buttonRef);

    return (
        <button
            {...buttonProps}
            className={clsx(
                'keyboard-focus absolute right-2 top-9 outline-none hover:scale-110',
                'focus-visible:rounded-sm disabled:cursor-not-allowed disabled:hover:scale-100',
                ariaProps?.isDisabled ? 'text-gray-300 dark:text-gray-500' : 'text-gray-600',
                isRequired && !hasValue ? 'dark:text-gray-600' : ' dark:text-gray-300'
            )}
            ref={buttonRef}
        >
            {children}
        </button>
    );
};
