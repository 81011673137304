import { useDarkMode, useErrorHandling, useModalToggle } from '../../hooks';
import { MoonIcon, SunIcon } from '@heroicons/react/20/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { UserMenuButton } from './UserMenuButton';
import { ContactInfoPanel } from '../contactInfo';
import { EmailDisplay } from './EmailDisplay';
import { UserMenuItem } from './UserMenuItem';
import { useMsal } from '@azure/msal-react';
import { ConfirmModal } from '../modals';
import { appRoutes } from '../../config';
import clsx from 'clsx';

export const UserMenu = () => {
    const { handleApiError } = useErrorHandling();
    const { instance } = useMsal();
    const { isDarkMode, isLightMode, toggleDarkMode } = useDarkMode();
    const location = useLocation();
    const navigate = useNavigate();

    const confirmDashboardNavigateModal = useModalToggle();
    const confirmSignOutModal = useModalToggle();
    const updateContactModalToggle = useModalToggle();

    const onDashboardClick = () => {
        if (location.pathname === appRoutes.dashboard) return;
        confirmDashboardNavigateModal.open();
    };

    const onDashboardConfirmed = () => navigate(appRoutes.dashboard);

    const onUpdateContact = () => updateContactModalToggle.open();

    const onSignOut = async () =>
        instance.logoutRedirect({ postLogoutRedirectUri: appRoutes.signIn }).catch(handleApiError);

    return (
        <div className={'relative z-40 ml-1 mr-2 inline-block text-left md:mr-8'}>
            <Menu>
                {({ close, open }) => (
                    <>
                        {/* MENU BUTTON/ICON */}
                        <UserMenuButton />

                        {/* MENU ITEMS */}
                        <Transition
                            className={'z-50'}
                            enter={'transition ease-out duration-100'}
                            enterFrom={'transform opacity-0 scale-95'}
                            enterTo={'transform opacity-100 scale-100'}
                            leave={'transition ease-in duration-75'}
                            leaveFrom={'transform opacity-100 scale-100'}
                            leaveTo={'transform opacity-0 scale-95'}
                        >
                            <Menu.Items
                                className={clsx(
                                    'absolute right-0 mt-2 w-56 origin-top-right bg-white dark:bg-gray-dark-900',
                                    'divide-y divide-gray-100 rounded-md border border-gray-200 shadow-lg',
                                    'outline-none dark:divide-gray-600 dark:border-gray-600 dark:text-gray-200'
                                )}
                                static
                            >
                                {/* EMAIL DISPLAY */}
                                <EmailDisplay />

                                <div>
                                    {/* DASHBOARD */}
                                    <UserMenuItem onClick={onDashboardClick}>
                                        <span className={'font-semibold'}>Dashboard</span>
                                    </UserMenuItem>

                                    {/* UPDATE CONTACT INFO */}
                                    <UserMenuItem onClick={onUpdateContact}>
                                        <span className={'font-semibold'}>Update Contact Info</span>
                                    </UserMenuItem>

                                    {/* TOGGLE DARK MODE */}
                                    <UserMenuItem onClick={toggleDarkMode}>
                                        <div className={'flex'}>
                                            <span className={'mr-2 whitespace-nowrap'}>
                                                {/* TEXT */}
                                                <span className={'font-semibold'}>
                                                    Change to {isLightMode ? 'Dark' : 'Light'} Mode
                                                </span>
                                            </span>
                                            {/* LIGHT MODE */}
                                            {isLightMode && (
                                                <div className={'text-orange-500'}>
                                                    {/* LIGHT ICON */}
                                                    <SunIcon className={'h-5 w-5'} />
                                                </div>
                                            )}
                                            {/* DARK MODE */}
                                            {isDarkMode && (
                                                <div className={'text-white'}>
                                                    {/* DARK ICON */}
                                                    <MoonIcon className={'-mt-[1px] h-5 w-5'} />
                                                </div>
                                            )}
                                        </div>
                                    </UserMenuItem>
                                </div>

                                {/* SIGN OUT */}
                                <UserMenuItem onClick={confirmSignOutModal.open}>
                                    <span className={'font-semibold'}>Sign Out</span>
                                </UserMenuItem>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>

            {/* CONTACT INFO SIDE PANEL */}
            {<ContactInfoPanel panelToggle={updateContactModalToggle} />}

            {/* CONFIRM DASHBOARD */}
            <ConfirmModal
                message={'Are you sure you want to return to the dashboard?'}
                modalToggle={confirmDashboardNavigateModal}
                onConfirm={onDashboardConfirmed}
                title={'Return to Dashboard'}
            />

            {/* CONFIRM SIGN OUT */}
            <ConfirmModal
                message={'Are you sure you want to sign out?'}
                modalToggle={confirmSignOutModal}
                onConfirm={onSignOut}
                title={'Sign Out'}
            />
        </div>
    );
};