import { useState } from 'react';

export interface IToggle {
    isOff: boolean;
    isOn: boolean;
    off: () => void;
    on: () => void;
    toggle: () => void;
}

export const useToggle = (defaultValue: boolean = false): IToggle => {
    const [value, setValue] = useState<boolean>(defaultValue);

    return {
        isOff: !value,
        isOn: value,
        off: () => setValue(false),
        on: () => setValue(true),
        toggle: () => setValue((prev: boolean) => !prev)
    };
};