import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDarkMode, useModalToggle } from '../../hooks';
import logoInverse from '../../assets/mrs-light.png';
import { appRoutes } from '../../config';
import { ConfirmModal } from '../modals';
import logo from '../../assets/mrs.png';
import clsx from 'clsx';

import type { FC } from 'react';

interface IMRSMenuLink {
    isDisabled?: boolean;
}

export const MRSMenuLink: FC<IMRSMenuLink> = ({ isDisabled }) => {
    const { isDarkMode, isLightMode } = useDarkMode();
    const location = useLocation();
    const navigate = useNavigate();

    const confirmNavigateModal = useModalToggle();

    const onDashboard = location.pathname === appRoutes.dashboard;

    const onLogoClick = () => {
        if (isDisabled) return;
        onDashboard ? navigate(appRoutes.dashboard) : confirmNavigateModal.open();
    };

    const onDashboardConfirmed = () => navigate(appRoutes.dashboard);

    return (
        <>
            {/* MRS LOGO */}
            {!onDashboard && (
                <Link
                    className={clsx(
                        'keyboard-focus ml-1 -mt-1 outline-none xxs:w-[250px] md:ml-8',
                        isDisabled ? 'cursor-default' : 'cursor-pointer'
                    )}
                    onClick={(e) => e.preventDefault()}
                    to={appRoutes.dashboard}
                >
                    {isDarkMode && <img alt={'mobile reporting system logo'} onClick={onLogoClick} src={logoInverse} />}
                    {isLightMode && <img alt={'mobile reporting system logo'} onClick={onLogoClick} src={logo} />}
                </Link>
            )}

            {onDashboard && (
                <div className={'ml-1 -mt-1 outline-none xxs:w-[250px] md:ml-8'}>
                    {isDarkMode && <img alt={'mobile reporting system logo'} onClick={onLogoClick} src={logoInverse} />}{' '}
                    {isLightMode && <img alt={'mobile reporting system logo'} onClick={onLogoClick} src={logo} />}
                </div>
            )}

            {/* CONFIRM RETURN TO DASHBOARD */}
            <ConfirmModal
                message={'Are you sure you want to return to the dashboard?'}
                onConfirm={onDashboardConfirmed}
                modalToggle={confirmNavigateModal}
                title={'Return to Dashboard?'}
            />
        </>
    );
};